import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  accordionContainer: {
    borderRadius: '4px',
    '&::before': {
      height: 0
    }
  },
  title: {
    fontWeight: "lighter"
  }
});

export default useStyles;
