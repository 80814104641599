import React from 'react';

import { Container, Content } from './styles';

import Divider from '../../components/Divider';
import Button from '../../components/Button';
import Label from '../../components/Form/Label';

import { seePlans } from '../../services/navigation';

import shutterIcon from '../../assets/icon/shutter.png';

const SubscriptionExpired = () => {
  const handleSeePlans = async () => {
    seePlans();
  };

  return (
    <Container>
      <Divider size="xlarge" />

      <Content>
        <Divider size="xlarge" />

        <img src={shutterIcon} />

        <Divider size="xlarge" />

        <Label>
          O período de testes da sua conta terminou.
        </Label>

        <Divider size="medium" />

        <p>
          Não se preocupe com as galerias criadas e as fotos já enviadas, elas estão guardadas
          <br />
          com segurança e serão liberadas após a contratação de algum plano.
        </p>

        <Divider size="xlarge" />

        <Button id="btn_VerPlanos" type="primary" onClick={() => handleSeePlans()}>
          VER PLANOS
        </Button>

        <Divider size="xlarge" />
      </Content>
    </Container>
  );
};

export default SubscriptionExpired;
