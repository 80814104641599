import ReactDOM from 'react-dom';
import React, { useState } from 'react';

import { ThemeProvider } from '@material-ui/core';
import PropTypes from 'prop-types';

import {
  Typography,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Button,
  Box,
  useMediaQuery,
  useTheme,
  CircularProgress,
  Link
} from '@material-ui/core';
import { CheckOutlined as CheckIcon } from '@material-ui/icons';

import Divider from '../Divider';
import Pill from '../Pill';
import Dialog from '../Dialog';
import { getElementNode } from '../../services/elementNode';
import { notifyError } from '../../utils/notificationService';

import useStyles from './styles';

import upgradeIllustrationImg from '../../assets/illustration/upgradeIllustration.svg';
import rocketIllustrationImg from '../../assets/illustration/rocketIllustration.svg';
import api from '../../services/api';
import { supportContactInfo } from '../../services/contact';

import theme from '../../styles/theme';

const UpgradePlan = (props) => {
  const { suggestion_plan, onUpdateImmediately } = props;
  const {
    need_support,
    block_type,
    suggestion_plan_name,
    suggestion_plan_benefits,
    suggestion_plan_price,
    current_plan_limit,
    suggestion_plan_limit,
    checkout_upgrade_id
  } = suggestion_plan;

  const [upgradePlanVisibility, setUpgradePlanVisibility] = useState(true);
  const [successUpgradeVisibility, setSuccessUpgradeVisibility] = useState(
    false
  );
  const [
    loadingRequestPlanImmediately,
    setLoadingRequestPlanImmediately
  ] = useState(false);

  const classes = useStyles();
  const MDTheme = useTheme();
  const isSmall = useMediaQuery(MDTheme.breakpoints.down('sm'));

  const handleSuccessUpgradeClose = () => {
    setSuccessUpgradeVisibility(false);
  };

  const requestPlanImmediately = async () => {
    setLoadingRequestPlanImmediately(true);
    try {
      await api.put(`/checkout/plan/upgrade/${checkout_upgrade_id}`);

      setUpgradePlanVisibility(false);
      setSuccessUpgradeVisibility(true);

      await onUpdateImmediately();
    } catch (error) {
      notifyError('Erro ao solicitar upgrade de plano');
    }
    setLoadingRequestPlanImmediately(false);
  };

  const handleUpgradePlanClose = () => {
    setUpgradePlanVisibility(false);
  };

  const featOrLimitContent = () => {
    return (
      <Grid
        container
        direction="column"
        className={classes.contentUpgradeContainer}
      >
        <Grid
          item
          className={classes.viewUpgradeWrapper}
          style={block_type === 'limit' ? { paddingBottom: '16px' } : {}}
        >
          {block_type === 'feat' ? (
            <>
              <Typography variant="h3" className={classes.textContentUpgrade}>
                Para utilizar essa funcionalidade é necessário fazer um upgrade
                de plano.
              </Typography>

              <Divider size="xsmall" />

              <Typography variant="h3" className={classes.textContentUpgrade}>
                Teste grátis por 1 mês!
              </Typography>
            </>
          ) : (
            <>
              <Typography variant="h3" className={classes.textContentUpgrade}>
                Você atingiu o <strong>limite</strong> de espaço de
                <strong> {current_plan_limit}</strong>
              </Typography>

              <Divider size="xsmall" />

              <Typography variant="h3" className={classes.textContentUpgrade}>
                Temos uma proposta pra você fazer um teste!
              </Typography>
            </>
          )}

          <Divider size="medium" />

          <Grid container>
            <Grid item xs={12}>
              <Pill
                style={{
                  width: isSmall ? '100%' : '90%',
                  maxWidth: '350px',
                  marginLeft: '0px',
                  height: 'auto',
                  padding: isSmall ? '3px 20px' : '3px',
                  margin: isSmall && '0 auto'
                }}
              >
                <Typography className={classes.pillUpgrade}>
                  1 MÊS GRATUITO NO <strong>{`${suggestion_plan_name}`}</strong>
                </Typography>
              </Pill>
            </Grid>
          </Grid>

          <Divider size="large" />

          <Typography className={classes.benefitsUpgrade}>
            Vantagens do plano {`${suggestion_plan_name}`}
          </Typography>

          {block_type === 'feat' ? (
            <List>
              {suggestion_plan_benefits &&
                !!suggestion_plan_benefits.length &&
                suggestion_plan_benefits.map((benefit, index) => {
                  return (
                    <ListItem key={index} className={classes.listItemUpgrade}>
                      <ListItemIcon className={classes.listIconUpgrade}>
                        <CheckIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography
                            className={[
                              index === 0 && classes.fontBold,
                              classes.itemTextUpgrade
                            ]}
                          >
                            {benefit}
                          </Typography>
                        }
                      />
                    </ListItem>
                  );
                })}
              <ListItem className={classes.listItemUpgrade}>
                <ListItemIcon className={classes.listIconUpgrade}>
                  <CheckIcon />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography className={classes.itemTextUpgrade}>
                      {current_plan_limit} de espaço
                    </Typography>
                  }
                />
              </ListItem>
            </List>
          ) : (
            <List>
              <ListItem className={classes.listItemUpgrade}>
                <ListItemIcon className={classes.listIconUpgrade}>
                  <CheckIcon />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography
                      className={[classes.fontBold, classes.itemTextUpgrade]}
                    >
                      {suggestion_plan_limit} de espaço
                    </Typography>
                  }
                />
              </ListItem>
              {suggestion_plan_benefits &&
                !!suggestion_plan_benefits.length &&
                suggestion_plan_benefits.map((benefit, index) => {
                  return (
                    <ListItem key={index} className={classes.listItemUpgrade}>
                      <ListItemIcon className={classes.listIconUpgrade}>
                        <CheckIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography className={classes.itemTextUpgrade}>
                            {benefit}
                          </Typography>
                        }
                      />
                    </ListItem>
                  );
                })}
            </List>
          )}

          <Divider size="small" />
        </Grid>

        <Grid item className={classes.actionUpgradeWrapper}>
          <Grid container justify="center" alignItems="center" spacing={3}>
            <Button
              size="large"
              className={classes.buttonUpgrade}
              variant="contained"
              onClick={() => requestPlanImmediately()}
              disabled={loadingRequestPlanImmediately}
              endIcon={
                loadingRequestPlanImmediately && (
                  <CircularProgress
                    size={20}
                    className={classes.loadingButton}
                  />
                )
              }
            >
              FAZER UPGRADE AGORA
            </Button>

            <Grid item xs={12}>
              <Typography
                align="center"
                className={classes.warningPriceTextUpgradeMain}
              >
                O valor da sua assinatura passará para R${' '}
                {`${suggestion_plan_price &&
                  suggestion_plan_price.monthly
                    .toString()
                    .replace('.', ',')}`}{' '}
                a partir do 2º mês
              </Typography>

              <Divider size="small" />

              <Typography
                align="center"
                className={classes.warningPriceTextUpgradeObs}
              >
                Se o seu pagamento for anual, será cobrado apenas a diferença do
                período restante descontanto o mês de teste.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const supportContent = () => {
    return (
      <Grid
        container
        direction="column"
        justify="center"
        className={classes.contentUpgradeContainer}
        style={{ background: '#fff', minHeight: '300px' }}
      >
        <Grid
          item
          className={classes.viewUpgradeWrapper}
          style={{ borderBottom: '0px' }}
        >
          {block_type === 'feat' ? (
            <Typography
              align="center"
              variant="h3"
              className={classes.textContentUpgrade}
            >
              Para utilizar essa funcionalidade é necessário fazer a alteração
              de plano.
            </Typography>
          ) : (
            <Typography
              align="center"
              variant="h3"
              className={classes.textContentUpgrade}
            >
              Você atingiu o seu limite máximo de espaço, de
              <strong> {current_plan_limit}</strong>, será necessário fazer o
              upgrade do seu plano.
            </Typography>
          )}

          <Divider size="large" />

          <Grid container justify="center" alignItems="center" spacing={3}>
            <Link
              href={supportContactInfo().whatsappLink}
              target="blank"
              rel="noreferrer"
            >
              <Button
                size="large"
                className={classes.buttonUpgrade}
                variant="contained"
                color="secondary"
              >
                Fale com o suporte
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const upgradeContent = () => {
    if (need_support) {
      return supportContent();
    } else {
      const optionsWithoutSupport = {
        feat: featOrLimitContent(),
        limit: featOrLimitContent()
      };

      return optionsWithoutSupport[block_type];
    }
  };

  return (
    <>
      <Dialog
        isOpen={upgradePlanVisibility}
        onClose={handleUpgradePlanClose}
        noPadding
        maxWidth={'md'}
        fullWidth
        noMaxHeight
      >
        <Grid
          container
          justify="space-between"
          className={classes.upgradeContainer}
        >
          <Grid item xs={12} md={6}>
            {upgradeContent()}
          </Grid>

          {!isSmall && (
            <Grid item xs={12} md={6}>
              <Box className={classes.upgradeImageContainer}>
                {suggestion_plan_name && (
                  <Grid
                    container
                    justify="center"
                    className={classes.pillWrapper}
                  >
                    <Grid item>
                      <Pill style={{ width: 'auto' }}>
                        <Typography className={classes.fontBold}>
                          Recurso {`${suggestion_plan_name}`}
                        </Typography>
                      </Pill>
                    </Grid>
                  </Grid>
                )}

                <Grid container justify="center" alignItems="center">
                  <Grid item>
                    <img
                      className={classes.upgradeIllustration}
                      src={upgradeIllustrationImg}
                    />
                  </Grid>
                </Grid>

                <Divider size="xlarge" />

                <Grid
                  container
                  justify="center"
                  className={classes.pillWrapper}
                >
                  <Grid item xs={12}>
                    <Typography align="center" className={classes.textUpgrade}>
                      Que tal gostou da proposta? Então vamos lá!
                    </Typography>
                  </Grid>
                </Grid>

                <Divider size="large" />
              </Box>
            </Grid>
          )}
        </Grid>
      </Dialog>

      <Dialog
        isOpen={successUpgradeVisibility}
        onClose={handleSuccessUpgradeClose}
        noPadding
        maxWidth={'sm'}
        fullWidth
      >
        <Grid direction="column">
          <Grid item xs={12} className={classes.headerSuccessDialog}>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
              alignContent="center"
            >
              <Grid item>
                <img
                  src={rocketIllustrationImg}
                  alt="Upgrade realizado com sucesso"
                />
              </Grid>

              <Divider size="medium" />

              <Grid item>
                <Typography
                  align="center"
                  className={classes.textHeaderSuccessDialog}
                >
                  Parabéns, você subiu de nível!
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} className={classes.contentSuccessDialog}>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
              alignContent="center"
            >
              <Grid item>
                <Typography align="center">
                  O seu plano foi migrado e você já pode começar a testar
                  imediatemente.
                  <br />
                  Divirta-se!
                </Typography>
              </Grid>

              <Divider size="medium" />

              <Grid item>
                <Typography
                  align="center"
                  className={classes.warningPriceTextUpgradeMain}
                >
                  O valor da sua assinatura passará para R${' '}
                  {`${suggestion_plan_price &&
                    suggestion_plan_price.monthly
                      .toString()
                      .replace('.', ',')}`}{' '}
                  a partir do 2º mês
                </Typography>

                <Divider size="small" />

                <Typography
                  align="center"
                  className={classes.warningPriceTextUpgradeObs}
                >
                  Se o seu pagamento for anual, será cobrado apenas a diferença
                  do período restante descontanto o mês de teste.
                </Typography>
              </Grid>

              <Divider size="medium" />

              <Grid item>
                <Button
                  size="large"
                  className={classes.buttonUpgrade}
                  variant="contained"
                  color="secondary"
                  onClick={() => handleSuccessUpgradeClose()}
                >
                  OK
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};

UpgradePlan.propTypes = {
  suggestion_plan: PropTypes.shape({
    need_support: PropTypes.bool.isRequired,
    block_type: PropTypes.oneOf(['feat', 'limit']).isRequired,
    current_plan_limit: PropTypes.string,
    suggestion_plan_limit: PropTypes.string,
    suggestion_plan_name: PropTypes.string,
    suggestion_plan_price: PropTypes.shape({
      monthly: PropTypes.number,
      annually: PropTypes.number
    }),
    suggestion_plan_time_range: PropTypes.number,
    suggestion_plan_benefits: PropTypes.arrayOf([PropTypes.string]),
    checkout_upgrade_id: PropTypes.number
  }).isRequired,
  onUpdateImmediately: PropTypes.func.isRequired
};

UpgradePlan.open = (suggestion_plan, onUpdateImmediately) => {
  const upgradeDialogNode = getElementNode('upgrade-dialog');

  ReactDOM.render(
    <ThemeProvider theme={theme}>
      <UpgradePlan
        suggestion_plan={suggestion_plan}
        onUpdateImmediately={onUpdateImmediately}
      />
    </ThemeProvider>,
    upgradeDialogNode
  );
};

export default UpgradePlan;
