import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'antd';

import {
	Container,
	PlanName,
	PlanPrice,
	PlanPriceValue,
	ExtraInfoBeforeButton,
	ExtraInfoAfterButton,
	FeatureOverview,
	FeatureContainer,
	FeatureInfo,
	FeatureDescription,
	FeatureSpotlight,
} from './styles';

import Small from '../../../../components/Form/Small';
import Divider from '../../../../components/Divider';
import Button from '../../../../components/Button';

const PlanCard = (props) => {
	const {
		spotlight,
		planName,
		planDisplayName,
		planPriceValue,
		extraInfoTextBeforeButton,
		extraInfoTextAfterButton,
		planFeatures,
		planPeriodicity,
		onConfirm,
		customButtonText,
		buttonDisabled
	} = props;

	const getPlanValue = () => {
		if (planPeriodicity === 'monthly') {
			return planPriceValue
		} else if (planPeriodicity === 'annually') {
			return planPriceValue / 12
		}
	}

	const handleConfirm = () => {
		onConfirm();
	}

	return (
		<Container spotlight={spotlight} isMobile={window.innerWidth < 740}>
			<PlanName>
				{planDisplayName}
			</PlanName>

			<Divider size="medium" />

			<PlanPrice>
				<PlanPriceValue>
					R$<strong>{getPlanValue().toFixed(2).split('.')[0]},</strong>{getPlanValue().toFixed(2).split('.')[1]}
				</PlanPriceValue>
				<Small>
					Por mês
				</Small>
			</PlanPrice>

			<Divider size="medium" />

			{extraInfoTextBeforeButton && planPeriodicity === 'annually' && (
				<>
					<ExtraInfoBeforeButton>
						{extraInfoTextBeforeButton}
					</ExtraInfoBeforeButton>

					<Divider size="medium" />
				</>
			)}

			<Button
				type={spotlight ? 'success' : 'primary'}
				style={{ width: '100%' }}
				onClick={handleConfirm}
				disabled={buttonDisabled}
			>
				{customButtonText ? customButtonText : `ASSINAR O ${planName.toUpperCase()}`}
			</Button>

			{extraInfoTextAfterButton && (
				<>
					<Divider size="medium" />

					<ExtraInfoAfterButton>
						{extraInfoTextAfterButton}
					</ExtraInfoAfterButton>
				</>
			)}

			<Divider size="medium" />

			{planFeatures.map(feature => (
				<>
					<FeatureContainer>
						<Icon type="check" style={{ color: "#828282" }} />
						<FeatureInfo>
							<FeatureOverview>
								{feature.name}{" "}<FeatureSpotlight>{feature.value}</FeatureSpotlight>
							</FeatureOverview>
							<br />
							{feature.value ? (
								<FeatureDescription>
									{feature.description}
								</FeatureDescription>
							) : (
								<FeatureSpotlight>
									{feature.description}
								</FeatureSpotlight>
							)}
						</FeatureInfo>
					</FeatureContainer>

					<Divider size="medium" />
				</>
			))}
		</Container>
	)
};

PlanCard.defaultProps = {
	spotlight: false,
	planPriceValue: '',
	planName: '',
	planDisplayName: '',
	extraInfoTextBeforeButton: '',
	extraInfoTextAfterButton: '',
	planFeatures: [],
	planPeriodicity: 'monthly',
	onConfirm: () => {},
	customButtonText: '',
	buttonDisabled: false,
}

PlanCard.propTypes = {
	spotlight: PropTypes.bool,
	planName: PropTypes.string,
	planDisplayName: PropTypes.string,
	planPriceValue: PropTypes.string,
	extraInfoTextBeforeButton: PropTypes.string,
	extraInfoTextAfterButton: PropTypes.string,
	planFeatures: PropTypes.arrayOf(PropTypes.object),
	planPeriodicity: PropTypes.oneOf(['monthly', 'annually']),
	onConfirm: PropTypes.func,
	customButtonText: PropTypes.string,
	buttonDisabled: PropTypes.bool,
};

export default PlanCard;