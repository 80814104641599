import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Switch, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import GlobalStyle from './styles/global';
import { Wrapper } from './styles/components';

import Login from './pages/Login';
import Signup from './pages/Signup/Main';
import SignupArchitecture from './pages/Signup/Architecture';
import Logout from './pages/Logout';
import RequestRecovery from './pages/Recovery/RequestRecovery';
import ResetPassword from './pages/Recovery/ResetPassword';
import InactiveAccount from './pages/InactiveAccount';
import MissingPlan from './pages/MissingPlan';

import { initGoogleTagManager, addDataLayerVariables } from './services/google';
import api from './services/api';
import { getSiteCrossAuthUrl } from './services/auth';
import { retrieveParsedQueryParams } from './services/navigation';

import { Creators as CustomerActions } from './store/ducks/customer';

import DefaultLayout from './components/DefaultLayout';
import Loading from './components/Loading';
import InfoModal from './components/InfoModal';

// That's the route that only logged in customers have access to
const PrivateRoute = ({ component: Component }, ...rest) => {
  const dispatch = useDispatch();
  const [, setCustomerPlan] = useState('');
  const [, setCustomerDays] = useState(null);
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  const { welcome_to_v2 } = retrieveParsedQueryParams(location);

  const getUserData = async () => {
    setLoading(true);

    const [response] = await Promise.all([
      api.get('/customer/info'),
      addDataLayerVariables()
    ]);

    const customerInfo = response.data;

    if (customerInfo.user_data.avatar) {
      dispatch(
        CustomerActions.setUserData({ avatar: customerInfo.user_data.avatar })
      );
    } else {
      dispatch(CustomerActions.setUserData({ avatar: null }));
    }

    if (customerInfo.user_subscription.expired) {
      setCustomerPlan('hideAlert');
    } else if (customerInfo.user_subscription.trial) {
      setCustomerPlan('trial');
      setCustomerDays(customerInfo.user_subscription.expiration_days);
    } else if (customerInfo.user_subscription.plan_ids.includes(20)) {
      // 20 is the id for Free
      setCustomerPlan('free');
    } else if (customerInfo.user_subscription.plan_ids.includes(15)) {
      // 15 is the id for Fremium
      setCustomerPlan('fremium');
    } else {
      setCustomerPlan('hideAlert');
    }

    /**
     * We don't need to show this button on that page
     */
    if (
      location.pathname.includes('wizard-solution-switch') ||
      location.pathname.includes('checkout')
    ) {
      setCustomerPlan('hideAlert');
    }

    dispatch(
      CustomerActions.setUserData({
        display_name: customerInfo.user_data.display_name,
        display_email: customerInfo.user_data.display_email,
        flags: customerInfo.user_data.flags || {},
        firstAccess:
          customerInfo &&
          customerInfo.user_data.flags &&
          customerInfo.user_data.flags.first_access,
        isTester: customerInfo.user_data.tester
      })
    );
    dispatch(
      CustomerActions.setUserSubscription(customerInfo.user_subscription)
    );
    dispatch(CustomerActions.setUserUsage(customerInfo.user_usage));

    // The next block only works if the user is not an admin neither a tester
    if (
      customerInfo.user_data.auth_method !== 'admin-cp' &&
      customerInfo.user_data.tester === false
    ) {
      // In case the user has only site plan we redirect him to this platform
      if (customerInfo.user_subscription.onlySite) {
        const url = await getSiteCrossAuthUrl();
        return (window.location = url);
      }

      // If it is the first access of customer and the page loaded, it
      // means that his first access has been done successfully and we
      // can turn the customer first access flag as false
      if (
        customerInfo.user_data.flags &&
        customerInfo.user_data.flags.first_access === true
      ) {
        window.onload = api.put('/customer/flag/revoke/first-access');
      }
    }

    if (welcome_to_v2 === 'true') {
      InfoModal.success({
        title: 'Seja bem-vindo ao Gallery Pro 2.0',
        info: (
          <p>
            Com o intuito de melhoria continua, agora a nossa versão 2.0 é a
            <br />
            versão oficial, isso siginifica que todos os nossos esforços estarão
            <br />
            voltados para essa versão, e novidades virão com frequência!
            <br />
            <br />
            Temos certeza de que ficará satisfeito com as melhorias!
            <br />
            <br />
            Caso tenha qualquer dúvida, fale com nosso suporte,
            <br />
            estamos prontos para atendê-lo!
            <br />
            <br />
            <strong>Desbrave a nova versão agora mesmo!</strong>
          </p>
        ),
        buttonText: 'IR PARA O GALLERY PRO 2.0',
        width: 600
      });
    }

    setLoading(false);
  };

  useEffect(() => {
    getUserData();
  }, []);

  return (
    <Loading
      loading={loading}
      style={{
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        margin: 'auto'
      }}
    >
      <Route
        {...rest}
        render={(props) => (
          <>
            {/* {loading ? null : (
              <>
                {window.innerWidth < 600 ? (
                  <div
                    style={{
                      width: '100%',
                      position: 'fixed',
                      bottom: '0',
                      display: 'flex',
                      flexWrap: 'wrap-reverse',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                      zIndex: '2'
                    }}
                  >
                    <div style={{ position: 'relative' }}>
                      <PlanAlert
                        mobile={window.innerWidth < 600}
                        plan={customerPlan}
                        days={customerDays}
                      />
                    </div>
                    <div
                      style={{
                        position: 'relative',
                        marginBottom: '16px',
                        marginRight: '16px',
                        marginLeft: 'auto'
                      }}
                    >
                      <Support mobile={window.innerWidth < 600} />
                    </div>
                  </div>
                ) : (
                  <>
                    <div
                      style={{
                        position: 'fixed',
                        bottom: '0',
                        right: '0',
                        zIndex: '2',
                        marginRight: '16px',
                        marginBottom: '16px'
                      }}
                    >
                      <Support mobile={window.innerWidth < 600} />
                    </div>
                    <div
                      style={{
                        position: 'fixed',
                        bottom: '0',
                        left: '0',
                        zIndex: '2',
                        marginBottom: '16px'
                      }}
                    >
                      <PlanAlert plan={customerPlan} days={customerDays} />
                    </div>
                  </>
                )}
              </>
            )} */}
            <Component {...props} />
          </>
        )}
      />
    </Loading>
  );
};

const App = () => {
  const [alertPlanData, setAlertPlanData] = useState({});

  const userSubscription = useSelector(
    (state) => state.customer.user_subscription
  );

  const getGalleriesToExpired = async () => {
    const { data } = await api.get('/gallery/expired');

    const amountExpired = data.galleriesToExpire.length;

    return amountExpired;
  };

  const getAlertByPlan = async () => {
    const userPlan = userSubscription && userSubscription.plan_ids;

    if (userPlan) {
      if (userSubscription.trial) {
        setAlertPlanData({
          type: 'endOfTest',
          additionalInfo: {
            expirationDate: userSubscription.expiration_date
          }
        });
      } else if (userPlan.includes(20) || userPlan.includes(15)) {
        setAlertPlanData({
          type: 'planClosure'
        });
      } else if (userPlan.includes(16)) {
        const galleriesExpired = await getGalleriesToExpired();

        setAlertPlanData({
          type: 'expirationGallery',
          additionalInfo: {
            howManyGalleries: galleriesExpired
          }
        });
      }
    }
  };

  useEffect(() => {
    initGoogleTagManager();
  }, []);

  useEffect(() => {
    getAlertByPlan();
  }, [userSubscription]);

  return (
    <Wrapper>
      <GlobalStyle />
      <BrowserRouter>
        <Switch>
          <Route
            exact
            path="/logout"
            name="Logout"
            render={(props) => <Logout {...props} />}
          />
          <Route
            exact
            path="/signup"
            name="Signup"
            render={(props) => <Signup {...props} />}
          />
          <Route
            exact
            path="/signup-architecture"
            name="Signup Architecture"
            render={(props) => <SignupArchitecture {...props} />}
          />
          <Route
            exact
            path="/login"
            name="Login"
            render={(props) => <Login {...props} />}
          />
          <Route
            exact
            path="/recovery/request"
            name="RequestRecovery"
            render={(props) => <RequestRecovery {...props} />}
          />
          <Route
            exact
            path="/recovery/reset"
            name="ResetPassword"
            render={(props) => <ResetPassword {...props} />}
          />
          <Route
            exact
            path="/inactive"
            name="InactiveAccount"
            render={(props) => <InactiveAccount {...props} />}
          />
          <Route
            exact
            path="/noplan"
            name="MissingPlan"
            render={(props) => <MissingPlan {...props} />}
          />
          <PrivateRoute
            path="/"
            component={(props) => (
              <DefaultLayout alertPlanData={alertPlanData} {...props} />
            )}
          />
        </Switch>
      </BrowserRouter>
    </Wrapper>
  );
};

export default App;
