import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	width: 100%;

	flex-direction: column;

	div {
		width: 100%;
	}
`;

export const ButtonContainer = styled.div`
	display: flex;

	margin-top: 30px;
`;

export const ButtonWrapper = styled.div`
	button {
		color: #A0A0A0;
	}
`;

export const VerticalDivider = styled.div`
	height: 100%;
	width: 15px !important;
`;

export const MessageContainer = styled.div`
	display: flex;
	align-items: center;

	margin-top: 30px;

	opacity: 0.7;
`;

export const Line = styled.div`
	height: 1px;

	background-color: #A0A0A0; 
`;

export const Message = styled.span`
	white-space: nowrap;
	text-align: center;

	padding: 0 10px;
`;