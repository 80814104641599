/* eslint-disable no-nested-ternary */
import styled from 'styled-components';

export const Container = styled.div`
	height: ${
  ({ size }) => (
    size === 'small' ? '8px'
      : size === 'xsmall' ? '16px'
        : size === 'medium' ? '24px'
          : size === 'large' ? '32px'
            : size === 'xlarge' ? '40px'
              : null
  )
};
`;