import styled from 'styled-components';

export const DropzoneContent = styled.div`
  width: 100%;
  height: 100%;
  padding: ${({ uploadStatus }) => uploadStatus === 'work-empty' ? '10%' : '30px'};
  text-align: center;

  outline: none;
  border: 1px dashed #ff4949;

  transition: all 0.25s ease-in-out;

  &:hover{
    transition: all 0.25s ease-in-out;
    background-color: rgba(0, 0, 0, 0.02);
    cursor: pointer;
  }
`;

export const CloseButton = styled.div`
  position: absolute;
  top: 25px;
  right: 25px;

  cursor: pointer;

  width: 25px;
  height: 25px;

  color: #FFF;

  background-color: #FF4949;

  display: flex;

  transition: all 0.1s ease-in-out;

  i {
    margin: auto;
  }

  &:hover {
    transition: all 0.1s ease-in-out;
    background-color: #DD3535;
  }
`;
