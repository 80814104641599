import styled from 'styled-components';

export const Container = styled.div`
	background-color: #FFF;
`;

export const IconWrapper = styled.div`
	height: 80px;
	min-width: 220px;
	max-width: 220px;

	background-color: #FFF;
	border-radius: 5px;
	box-shadow: 0px 4px 12px rgba(8, 35, 48, 0.12);

  display: flex;
  justify-content: center;
	align-items: center;

  img {
    max-height: 40px;
		width: auto;
  }

	cursor: pointer;

	transition: all 0.1s ease-in-out;

	user-select: none;

	cursor: ${({ disabled }) => disabled && 'not-allowed'};
	opacity: ${({ disabled }) => disabled && '0.5'};

	&:hover {
		background-color: rgba(0, 0, 0, 0.01);
		transition: all 0.1s ease-in-out;
	}

	border: ${({ selected }) => selected ? 'solid 4px #39C395' : 'solid 4px transparent'};

  .ant-radio-input:focus + .ant-radio-inner {
    border-color: #39C395;
  }

  .ant-radio:hover {
    .ant-radio-inner {
      border-color: #39C395;
    }
  }

  .ant-radio-checked {
    .ant-radio-inner {
      border-color: #39C395;
    }

    .ant-radio-inner:after {
      background-color: #39C395;
    }
  }

  .ant-radio-checked:after {
    border-color: #39C395;
  }

	.ant-radio {
		margin-right: 10px;
	}
`;

export const Email = styled.span`
	font-weight: normal;
	line-height: 25px;
	color: #EB4A66;
	margin-left: 20px;
	font-size: 18px;
`;

export const ShareMethodContainer = styled.div`
	margin-bottom: 40px;

	display: flex;

	width: 100%;

	text-align: left;
`;

export const ShareForm = styled.div`
`;

export const ShareFormTitle = styled.span`
	font-weight: bold;
	color: #949494;
`;

export const CopyLink = styled.div`
	width: 100%;
`;

export const RecipientInfo = styled.span`
  font-size: 16px;
`;

export const SubmitButtonWrapper = styled.div`
	div {
		text-align: right;
	}
`;

export const LinkShareField = styled.input`
	background-color: #F9F9F9;
	
	outline: none;

	color: #0077B5;

	border: 5px;

	margin: 0;

	padding: 10px 16px;

	border-bottom-right-radius: 0;
	border-top-right-radius: 0;

	word-break: break-all;

	width: 100%;
`

export const LinkShareContainer = styled.div`
	display: flex;

	button {
		box-shadow: none;
		height: 100%;
	}
`
