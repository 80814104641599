import React, { useState } from 'react';
import { Modal, Divider as AntDivider, Tag, Checkbox, Rate } from 'antd';
import PropTypes from 'prop-types';

import { Content, Opener, Alert, TotalSuggested, TagWrapper } from './styles';

import Loading from '../../../../Loading';

const SuggestionModal = (props) => {
	const {
		children,
		initSuggestion,
		handleChangeSuggestion,
		tags,
		showArr,
	} = props;

	const [visible, setVisible] = useState(false);
	const [loading, setLoading] = useState(false);

	const handleChange = (tag) => {
		handleChangeSuggestion(tag);
	}

	const handleInitSuggestion = async () => {
		setVisible(true);
		setLoading(true);
		await initSuggestion();
		setLoading(false);
	}

  return (
		<>
			<Opener onClick={handleInitSuggestion}>{children}</Opener>
			<Modal
				visible={visible}
				onOk={() => setVisible(false)}
				onCancel={() => setVisible(false)}
				title='Sugerir fotos'
				footer={null}
			>
				<Alert
					type="warning"
					description={Object.values(showArr).map((arr) => arr.length).reduce((acc, curr) => acc + curr) <= 0
						? `Não encontramos nenhuma foto com Avaliação de Estrelas ou Rótulo de Cor definidos no Lightroom.` 
						: `Identificamos automaticamente as marcações que você definiu no Lightroom. 
						Assim, você pode sugerir fotos em lote, pela Avaliação de Estrelas ou pelo Rótulo de Cor.`
					}
					style={Object.values(showArr).map((arr) => arr.length).reduce((acc, curr) => acc + curr) <= 0 ? {marginBottom: '0'} : {marginBottom: '24px'}}
				/>
				<Loading loading={loading}>
						{
							Object.values(showArr).map((arr) => arr.length).reduce((acc, curr) => acc + curr) <= 0
							? null
							:	(Object.keys(tags).map((tag, idx) => (
									<Content key={idx} onClick={() => handleChange(tag)}>
										<Checkbox
											style={{ marginLeft: '10px' }}
											checked={tags[tag].checked}
											indeterminate={tags[tag].indeterminate}
										/>
										<TagWrapper>
											{
												isNaN(tag)
													? (
														<Tag color={tags[tag].color}>{tag}</Tag>
													) 
													: (
														<Rate
															value={+tag}
															disabled={true}
															style={{ fontSize: '12pt' }}
														/>
													)
											}
										</TagWrapper>
										{
											showArr[tag].length === 0 ? (
												<TotalSuggested>
													{
														`Não encontramos nenhuma foto com essa Avaliação ou Rótulo`
													}
												</TotalSuggested>
											) : (
												<TotalSuggested>
													{
														`${showArr[tag].length} ${showArr[tag].length > 1 ? 'fotos' : 'foto'}`
													}
												</TotalSuggested>
											)
										}
									</Content>
								))
							)
						}
				</Loading>
			</Modal>
		</>
  );
};

SuggestionModal.propTypes = {
	children: PropTypes.node.isRequired,
	handleChangeSuggestion: PropTypes.func.isRequired,
	initSuggestion: PropTypes.func.isRequired,
	tags: PropTypes.arrayOf([PropTypes.object]).isRequired,
	showArr: PropTypes.arrayOf([PropTypes.object]).isRequired,
};

export default SuggestionModal;
