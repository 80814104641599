import React from 'react';
import { Switch, Route, withRouter, Redirect } from 'react-router-dom';

import Plans from '../pages/Checkout/Plans';
import Overview from '../pages/Checkout/Overview';
import PaymentStatus from '../pages/Checkout/PaymentStatus';
import PaymentStatusCreditCardPaymentSuccess from '../pages/Checkout/PaymentStatus/CreditCardPaymentSuccess';
import PaymentStatusCreditCardPaymentFailed from '../pages/Checkout/PaymentStatus/CreditCardPaymentFailed';

const CheckoutRoutes = () => (
  <>
    <Switch>
      <Route exact path="/checkout"><Redirect to="/checkout/gallery-pro/plans" /></Route>
      <Route exact path="/checkout/:solution_name" render={(props) => (<Redirect to={`/checkout/${props.match.params.solution_name}/plans`} />)} />
      <Route path="/checkout/:solution_name/plans" component={Plans} />
      <Route path="/checkout/:solution_name/overview" component={Overview} />
      <Route exact path="/checkout/:solution_name/payment-status" component={PaymentStatus} />
      <Route path="/checkout/:solution_name/payment-status/credit-card-payment-success" component={PaymentStatusCreditCardPaymentSuccess} />
      <Route path="/checkout/:solution_name/payment-status/credit-card-payment-failed" component={PaymentStatusCreditCardPaymentFailed} />
    </Switch>
  </>
);

export default withRouter(CheckoutRoutes);
