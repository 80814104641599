// Action Types
export const Types = {
  SELECT_IMAGE: 'gallery/SELECT_IMAGE',
  SELECT_ALL: 'gallery/SELECT_ALL',
  UNSELECT_ALL: 'gallery/UNSELECT_ALL',
  SET_IMAGES: 'gallery/SET_IMAGES',
  SUGGEST_IMAGE: 'gallery/SUGGEST_IMAGE',
  SET_INFO: 'gallery/SET_INFO',
};


// Reducer
const initialState = {
  info: {
    collection_id: null,
    title: '',
    total_pictures: 0,
    pictures_ordenation: 7,
    selection_activated: null,
    download_activated: false,
    page: 1,
    url: '',
    cover: '',
    uploadStatus: 'invisible'
  },
  images: [],
};

const gallery = (state = initialState, action) => {
  switch (action.type) {
    case Types.SUGGEST_IMAGE:
      return {
        ...state,
        images: state.images.map((image, idx) => {
          if (idx === action.payload.idx) {
            if ('isSuggested' in image && image.isSuggested) {
              return {
                ...image,
                isSuggested: false,
              };
            }
            return {
              ...image,
              isSuggested: true,
            };
          }
          return image;
        }),
	  };
    case Types.SELECT_IMAGE:
      return {
        ...state,
        images: state.images.map((image, idx) => {
          if (idx === action.payload.idx) {
            if ('isSelected' in image && image.isSelected) {
              return {
                ...image,
                isSelected: false,
              };
            }
            return {
              ...image,
              isSelected: true,
            };
          }
          return image;
        }),
      };
    case Types.SELECT_ALL:
      return {
        ...state,
        images: state.images.map((image) => (
          {
            ...image,
            isSelected: true,
          }
        )),
      };
    case Types.UNSELECT_ALL:
      return {
        ...state,
        images: state.images.map((image) => (
          {
            ...image,
            isSelected: false,
          }
        )),
      };
    case Types.SET_IMAGES:
      return {
        ...state,
        images: action.payload.images,
      };
    case Types.SET_INFO:
      return {
        ...state,
        info: {
          ...state.info,
          ...action.payload.info,
        },
      };
    default:
      return state;
  }
};

export default gallery;


// Action Creators
export const Creators = {
  selectImage: (idx) => ({
    type: Types.SELECT_IMAGE,
    payload: { idx },
  }),
  selectAll: () => ({
    type: Types.SELECT_ALL,
  }),
  unselectAll: () => ({
    type: Types.UNSELECT_ALL,
  }),
  setImages: (images) => ({
    type: Types.SET_IMAGES,
    payload: { images },
  }),
  suggestImage: (idx) => ({
    type: Types.SUGGEST_IMAGE,
    payload: { idx },
  }),
  setInfo: (info) => ({
    type: Types.SET_INFO,
    payload: { info },
  }),
};
