import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';

import useStyles from './styles';

const InfoCard = (props) => {
  const { severity, title, children } = props;

  const classes = useStyles();

  return (
    <Grid container spacing={3} className={classes.alertContainer}>
      <Grid item xs={12}>
        <Alert severity={severity}>
          {title && <AlertTitle>{title}</AlertTitle>}

          {children}
        </Alert>
      </Grid>
    </Grid>
  );
};

InfoCard.defaultProps = {
  title: '',
};

InfoCard.propTypes = {
  severity: PropTypes.oneOf(['error', 'info', 'success', 'warning']).isRequired,
  title: PropTypes.node,
  children: PropTypes.node.isRequired,
};

export default InfoCard;
