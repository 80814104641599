import React, { useState, useEffect } from 'react';
import moment from 'moment';
import {
  Row,
  Col,
  Avatar,
  Menu as AntMenu,
  Dropdown,
  Icon,
  Pagination,
  Modal
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import Input from '../../components/Form/Input';
import Button from '../../components/Button';
import PopConfirm from '../../components/PopConfirm';
import Loading from '../../components/Loading';
import ChangeClientModal from '../../components/Gallery/ChangeClientModal';
import EditDialog from '../../components/EditDialog';
import Divider from '../../components/Divider';

import api from '../../services/api';

import {
  notifySuccess,
  notifyBackendError
} from '../../utils/notificationService';

import {
  Container,
  Header,
  ClientCard,
  ClientContainer,
  ClientName,
  ClientInfo,
  MenuContainer,
  PaginationWrapper,
  Footer,
  InfoPopupContainer,
  InfoPopupTitle,
  InfoPopupSubtitle
} from './styles';
import UpgradePlan from '../../components/UpgradePlan';

const Clients = () => {
  const clientsPerPage = 15;

  const [clients, setClients] = useState([]);
  const [filteredClients, setFilteredClients] = useState([]);
  const [getClientsLoading, setGetClientsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [infoPopup, setInfoPopup] = useState({
    title: '',
    info: '',
    visible: false
  });

  const handleOpenInfoPopup = (data) => {
    const { title, info } = data;

    setInfoPopup({ title, info, visible: true });
  };

  const handleCloseInfoPopup = () => {
    setInfoPopup({ ...infoPopup, visible: false });
  };

  const handleChangePage = (page) => {
    setCurrentPage(page);
  };

  const getPaginationLocation = (where) => {
    if (where === 'start') {
      return (currentPage - 1) * clientsPerPage;
    }

    if (where === 'end') {
      return (currentPage - 1) * clientsPerPage + clientsPerPage;
    }
  };

  const handleCreateClient = async (clientData) => {
    await api.post('/customer/clients', { ...clientData });
    notifySuccess('Cliente criado com sucesso!');
    getData();
  };

  const handleUpdateClient = async (clientData, clientId) => {
    await api.put(`/customer/clients/${clientId}`, { ...clientData });
    notifySuccess('Os dados do cliente foram atualizados com sucesso!');
    getData();
  };

  const handleSearch = (value) => {
    const filtered = clients.filter((client) => {
      return (
        client.name.toLowerCase().includes(value.toLowerCase()) ||
        client.email.toLowerCase().includes(value.toLowerCase()) ||
        (client.tel || '').replace(/(\D)/g, '').includes(value)
      );
    });

    setFilteredClients(filtered);
  };

  const handleDeleteClient = async (client_id) => {
    try {
      await api.delete(`/customer/clients?client_id=${client_id}`);
      notifySuccess('Cliente deletado com sucesso!');
      getData();
    } catch (error) {
      notifyBackendError(error);
    }
  };

  const handleChangeClientPassword = async (password, customer_client_id) => {
    try {
      await api.put(`/customer/clients/${customer_client_id}/password`, {
        password
      });

      await getData();

      handleCloseInfoPopup();

      notifySuccess('A senha foi alterada com sucesso.');

      return true;
    } catch (error) {
      return error;
    }
  };

  const getData = async () => {
    const response = await api.get('/customer/clients');

    setFilteredClients(response.data.clients);
    setClients(response.data.clients);

    setGetClientsLoading(false);
  };

  const handleOpenConfirmDeleteClientModal = (client_id) => {
    PopConfirm.open({
      onConfirm: () => handleDeleteClient(client_id),
      info:
        'Deletar o cliente é uma ação irreversível. Você perderá todos os dados deste cliente.'
    });
  };

  const Menu = (client) => {
    return (
      <AntMenu>
        <AntMenu.Item
          key="0"
          onClick={() => handleOpenConfirmDeleteClientModal(client.id)}
        >
          Excluir
        </AntMenu.Item>
        <AntMenu.Item
          key="1"
          onClick={() => {
            ChangeClientModal.open({
              onUpdate: (clientData) =>
                handleUpdateClient(clientData, client.id),
              action: 'update',
              data: client
            });
          }}
        >
          Editar
        </AntMenu.Item>
        <AntMenu.Item
          key="2"
          onClick={() => {
            const password =
              client.extradata && client.extradata.first_password;

            handleOpenInfoPopup({
              title: 'Senha do cliente',
              info: (
                <p align="center">
                  {password
                    ? password
                    : 'Seu cliente alterou a senha dele e por isso você não tem mais acesso a ela.'}
                  <br />
                  <br />
                  <EditDialog
                    value={password}
                    name="Senha do cliente"
                    onSave={(password) =>
                      handleChangeClientPassword(password, client.id)
                    }
                    inputName="password"
                    type="password"
                  >
                    <div style={{ margin: 'auto' }}>
                      <Button type="success" style={{ margin: 'auto' }}>
                        Redefinir senha
                      </Button>
                    </div>
                  </EditDialog>
                </p>
              )
            });
          }}
        >
          Senha
        </AntMenu.Item>
        <AntMenu.Item
          key="3"
          onClick={() => {
            const origin =
              client.creation_metadata &&
              client.creation_metadata.gallery_origin_title;

            const info = (
              <p align="left">
                <b>Origem do Cadastro:</b>
                {origin ? ` Galeria - ${origin}` : ' Cadastrado por você.'}
                <br />
                <b>Data em que foi cadastrado:</b>
                {` ${moment(client.created_at).calendar()}`}
                <br />
                <b>Data da última alteração do cadastro:</b>
                {` ${moment(client.updated_at).calendar()}`}
              </p>
            );

            handleOpenInfoPopup({
              title: 'Detalhes',
              info
            });
          }}
        >
          Detalhes
        </AntMenu.Item>
      </AntMenu>
    );
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Container isMobile={window.innerWidth < 600}>
      <Modal
        visible={infoPopup.visible}
        onOk={handleCloseInfoPopup}
        onCancel={handleCloseInfoPopup}
        footer={null}
      >
        <InfoPopupContainer>
          <InfoPopupTitle>{infoPopup.title}</InfoPopupTitle>

          <Divider size="large" />

          <InfoPopupSubtitle>{infoPopup.info}</InfoPopupSubtitle>

          <Divider size="large" />

          <Button type="primary" onClick={handleCloseInfoPopup}>
            FECHAR
          </Button>
        </InfoPopupContainer>
      </Modal>
      <Loading loading={getClientsLoading}>
        <Header>
          <Row gutter={[16, 16]}>
            <Col lg={24} xl={5} xxl={4}>
              <Button
                type="primary"
                onClick={() => {
                  ChangeClientModal.open({
                    onCreate: handleCreateClient,
                    action: 'create'
                  });
                }}
              >
                <i style={{ marginRight: '8px' }}>
                  <FontAwesomeIcon icon={faPlus} />
                </i>
                NOVO CLIENTE
              </Button>
            </Col>
            <Col lg={0} xl={14} xxl={16} style={{ padding: '0' }}></Col>
            <Col lg={24} xl={5} xxl={4}>
              <Input
                type="search"
                placeholder="Procurar cliente"
                onChange={({ target }) => handleSearch(target.value)}
              />
            </Col>
          </Row>
        </Header>

        {filteredClients
          .slice(getPaginationLocation('start'), getPaginationLocation('end'))
          .map((client) => (
            <ClientCard>
              <Avatar
                size="large"
                style={{
                  backgroundColor: '#39C395',
                  width: '40px',
                  height: '40px'
                }}
              >
                {client.name
                  .split(' ')
                  .slice(0, 2)
                  .map((name) => name[0])
                  .join('')}
              </Avatar>
              <ClientContainer>
                <ClientName>{client.name}</ClientName>
                <ClientInfo>
                  {client.email}
                  {client.tel ? ` | ${client.tel}` : null}
                </ClientInfo>
              </ClientContainer>
              <MenuContainer>
                <Dropdown overlay={Menu(client)} trigger={['click']}>
                  <Icon type="more" style={{ fontSize: 25 }} />
                </Dropdown>
              </MenuContainer>
            </ClientCard>
          ))}

        <Footer>
          <PaginationWrapper>
            <Pagination
              defaultCurrent={1}
              total={clients.length}
              onChange={handleChangePage}
              defaultPageSize={clientsPerPage}
            />
          </PaginationWrapper>
        </Footer>
      </Loading>
    </Container>
  );
};

export default Clients;
