/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Container,
  Grid,
  Link,
  Typography,
  Button
} from '@material-ui/core';

import moment from 'moment';
import { useHistory } from 'react-router-dom';
import Divider from '../../../Divider';
import InfoCard from '../../../InfoCard';
import Dialog from '../../../Dialog';
import Portlet from '../../../Portlet';

import useStyles from './styles';

import hourGlassIllustrationImg from '../../../../assets/illustration/hourglassIllustration.svg';

const AlertsPlan = (props) => {
  const { type, additionalInfo } = props;

  const [openExpirationDialog, setOpenExpirationDialog] = useState(false);

  const [, setSeconds] = useState(0);

  const classes = useStyles();
  const history = useHistory();

  const handlePagePlans = () => {
    history.push('/checkout/gallery-pro/plans');
  };

  const handleExpirationDialogOpen = () => {
    setOpenExpirationDialog(true);
  };

  const expirationTitle = (howManyGalleryExpired) => (
    <Typography className={classes.titleText} variant="body1">
      <strong>{howManyGalleryExpired}</strong> GALERIAS À EXPIRAR
    </Typography>
  );

  const expirationDescription = () => (
    <>
      <Divider size="xsmall" />

      <Typography className={classes.descriptionText} variant="body1">
        No dia <strong>13 de JANEIRO de 2021</strong> todas as suas galerias que
        já estão expiradas, ou seja, galerias cadastradas a mais de 12 meses,
        serão excluídas de forma definitiva. Essa é uma ação irreversível,
        portanto caso tenha qualquer dúvida,
        <Link onClick={handleExpirationDialogOpen} href="#" variant="body1">
          {' '}
          clique aqui{' '}
        </Link>
        ou fale em nosso suporte.
      </Typography>
    </>
  );

  const planClosureTitle = () => {
    const currentDay = moment();
    const dayToExpiration = moment('01/22/2021');

    const remainsDays = dayToExpiration.diff(currentDay, 'days');

    return (
      <Typography className={classes.titleText} variant="body1">
        <strong>ENCERRAMENTO DO PLANO GRÁTIS</strong> (encerra em{' '}
        {remainsDays > 0 ? remainsDays : 0} dias)
      </Typography>
    );
  };

  const planClosureDescription = () => (
    <>
      <Divider size="xsmall" />

      <Typography className={classes.descriptionText} variant="body1">
        O plano gratuito atualmente disponibilizado pela Picsize será
        descontinuado, mas não se preocupe, temos planos acessíveis para atender
        todas as necessidades.
        <br />
        <br />
        Confira os planos:{' '}
        <Link target="_BLANK" onClick={handlePagePlans} variant="body1">
          Ver planos{' '}
        </Link>
      </Typography>
    </>
  );

  const handleExpirationDialogClose = () => {
    setOpenExpirationDialog(false);
  };

  const getExpirationDate = (expiration_date) => {
    if (expiration_date) {
      const expirationDate = new Date(expiration_date).setHours(23, 59, 59);
      const currentDate = new Date();

      const differenceDate = expirationDate - currentDate;

      const differenceDataInSeconds = Math.floor(differenceDate / 1000);
      const differenceDataInMinutes = Math.floor(differenceDataInSeconds / 60);
      const differenceDataInHours = Math.floor(differenceDataInMinutes / 60);
      const differenceDataInDays = Math.floor(differenceDataInHours / 24);

      let daysCountdown = differenceDataInDays;

      let hoursCountdown = differenceDataInHours - daysCountdown * 24;

      let minutesCountdown =
        differenceDataInMinutes -
        differenceDataInDays * 24 * 60 -
        hoursCountdown * 60;

      let secondsCountdown =
        differenceDataInSeconds -
        daysCountdown * 24 * 60 * 60 -
        hoursCountdown * 60 * 60 -
        minutesCountdown * 60;

      if (expirationDate < currentDate) {
        daysCountdown = 0;
        hoursCountdown = 0;
        minutesCountdown = 0;
        secondsCountdown = 0;
      }

      return {
        daysCountdown,
        hoursCountdown,
        minutesCountdown,
        secondsCountdown
      };
    }
    return null;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((seconds) => seconds + 1);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  if (type === 'planClosure') {
    return (
      <InfoCard severity="warning" title={planClosureTitle()}>
        {planClosureDescription()}
      </InfoCard>
    );
  }
  if (type === 'expirationGallery') {
    return (
      <>
        <InfoCard
          severity="warning"
          title={expirationTitle(additionalInfo.howManyGalleries)}
        >
          {expirationDescription()}
        </InfoCard>

        <Dialog
          title="Como funciona a expiração das galerias."
          isOpen={openExpirationDialog}
          onClose={handleExpirationDialogClose}
        >
          <Container maxWidth="sm">
            <Typography variant="body1">
              No plano <strong>“Infinity Pro”</strong> a galeria tem uma data de
              validade de 12 meses contadas a partir da data de cadastro, após
              esse prazo a galeria é expirada.
              <br />
              <br />
              No momento em que a galeria expira, todas as informações da
              galeria são deletadas, inclusive as fotos. Essa ação é
              <strong> irreversível</strong>, ou seja, não salvamos nenhum
              backup dessas informações, sendo impossível restaurar a galeria.
              <br />
              <br />
              Sugerimos que fique sempre atendo as galerias que estão com a data
              de expiração próxima.
            </Typography>
          </Container>
        </Dialog>
      </>
    );
  }
  if (type === 'endOfTest') {
    return (
      <Container maxWidth="md" className={classes.endOfTestContainer}>
        <Portlet>
          <Grid
            container
            justify="space-around"
            alignItems="center"
            alignContent="center"
          >
            <Grid item xs={12} sm={5}>
              <Grid
                container
                justify="space-around"
                alignItems="center"
                alignContent="center"
              >
                <Grid
                  item
                  xs={5}
                  sm
                  className={classes.endOfTestItem}
                  style={{ textAlign: 'center' }}
                >
                  <img
                    src={hourGlassIllustrationImg}
                    alt="Fim do periodo de teste"
                  />
                </Grid>
                <Grid item xs={7} className={classes.endOfTestItem}>
                  <Typography className={classes.endOfTestText}>
                    O seu período de <strong>teste</strong> termina em:
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={7}>
              <Grid
                container
                alignItems="center"
                alignContent="center"
                spacing={2}
              >
                <Grid
                  item
                  xs={12}
                  lg={7}
                  className={classes.endOfTestItem}
                  style={{ margin: 0 }}
                >
                  <Grid container justify="space-around">
                    <Grid item>
                      <Box className={classes.boxNumber}>
                        {additionalInfo.expirationDate &&
                          getExpirationDate(additionalInfo.expirationDate)
                            .daysCountdown}
                      </Box>
                      <Typography align="center">dias</Typography>
                    </Grid>
                    <Grid item>
                      <Box className={classes.boxNumber}>
                        {additionalInfo.expirationDate &&
                          getExpirationDate(additionalInfo.expirationDate)
                            .hoursCountdown}
                      </Box>
                      <Typography align="center">horas</Typography>
                    </Grid>
                    <Grid item>
                      <Box className={classes.boxNumber}>
                        {additionalInfo.expirationDate &&
                          getExpirationDate(additionalInfo.expirationDate)
                            .minutesCountdown}
                      </Box>
                      <Typography align="center">min</Typography>
                    </Grid>
                    <Grid item>
                      <Box className={classes.boxNumber}>
                        {additionalInfo.expirationDate &&
                          getExpirationDate(additionalInfo.expirationDate)
                            .secondsCountdown}
                      </Box>
                      <Typography align="center">seg</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} lg={5} className={classes.endOfTestItem}>
                  <Button
                    fullWidth
                    variant="contained"
                    onClick={handlePagePlans}
                    className={classes.buttonViewPlan}
                  >
                    Ver planos
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Portlet>
      </Container>
    );
  }
  return null;
};

AlertsPlan.defaultProps = {
  additionalInfo: {}
};

AlertsPlan.propTypes = {
  type: PropTypes.string.isRequired,
  additionalInfo: PropTypes.shape({
    howManyGalleries: PropTypes.number,
    expirationDate: PropTypes.instanceOf(Date)
  })
};

export default AlertsPlan;
