import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  gridSelector: {
    display: 'grid',
    gridTemplateColumns: (props) => `repeat(3, ${props.gridSizeInPixel}px)`,
    gridTemplateRows: (props) => `repeat(3, ${props.gridSizeInPixel}px)`,
    gridGap: '5px',
    marginLeft: '16px'
  },
  gridSelectorItem: {
    cursor: (props) => (props.disabled ? 'not-allowed' : 'pointer')
  }
});

export default useStyles;
