import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  container: {
    margin: 'auto',
    padding: 16
  },
  title: {
    fontSize: 20,
    fontWeight: '500'
  },
  textContent: {
    fontSize: '15px'
  },
  image: {
    width: 'auto',
    height: '100%',
    maxHeight: '200px',
    display: 'flex',
    margin: '0 auto'
  },
  supportBtn: {
    fontSize: '14px',
    fontWeight: 'bold'
  },
  iframe: {
    width: "100%",
    height: 400,
    border: "1px solid #EFEFEF",
    backgroundColor: "#EFEFEF"
  },
  link: {
    textDecoration: "underline"
  },
  warning: {
    fontSize: 12,
    "& b": {
      color: "#FF0000",
      fontWeight: "normal"
    }
  }
});

export default useStyles;
