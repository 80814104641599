import React from 'react';
import { useSelector } from 'react-redux';

import Preferences from "./Preferences";
import FollowUp from "./FollowUp";

const Selection = (props) => {
	const galleryInfo = useSelector((state) => state.gallery.info);

	const { collection_id } = props.match.params

	// Redirects
	if (props.location.pathname === `/gallery/${collection_id}/selection`) {
		if (galleryInfo.selection_activated === true) {
			props.history.push(`/gallery/${collection_id}/selection/followup`)
		} else if (galleryInfo.selection_activated === false) {
			props.history.push(`/gallery/${collection_id}/selection/preferences`)
		}
	}

	return <div />
}

export default Selection