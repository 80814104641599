import styled from 'styled-components';
import { Radio as AntRadio } from 'antd';

export const Content = styled.div`
	& > label {
		display: flex;
		flex-direction: row;
		align-items: center;

		line-height: 32px;
	}

	label {
		button {
			height: 30px;
		}
	}

	label:hover {
		color: #FF4949;

		.ant-radio-inner {
			border-color: #56C568;
		}
	}

	label > span:nth-child(2) {
		margin-left: 24px;
	}
	
	.ant-radio-input:focus + .ant-radio-inner {
		border-color: #56C568;
	}

	.ant-radio:hover {
		.ant-radio-inner {
			border-color: #56C568;
		}
	}

	.ant-radio-checked {
		.ant-radio-inner {
			border-color: #56C568;
		}

		.ant-radio-inner:after {
			background-color: #56C568;
		}
	}

	.ant-radio-checked:after {
		border-color: #56C568;
	}

	.ant-divider {
		margin: 16px 0;
	}
`;

export const Radio = styled(AntRadio)`
	color: ${({ marked }) => marked ? '#FF4949' : 'rgba(0, 0, 0, 0.65)'};
`;

export const Opener = styled.div`

`;