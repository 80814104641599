import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Aside = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Wrapper = styled.div`
  padding: 0 16px;
  width: 100%;

  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  background-color: #fff;
  text-align: center;
  border-radius: .25rem;
  margin-top: 8px;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;

  button{
    margin: 8px 0 16px 0;
  }
`;

export const ExpireInfo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 6px;
  font-weight: 400;
`;

export const Cover = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  background-image: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), ${(props) => `url(${props.background})`};
  border-radius: 4px;
  background-position: center;
  background-size: cover;
  outline: none;
  display: flex;
  
  div {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin: auto;
  }

  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 48px;
    object-fit: contain;
  }

  button {
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0px 4px 4px rgba(135, 135, 135, 0);
    border-color: transparent;
    border-radius: 50px;
  }

  button:hover {
    background-color: rgba(0, 0, 0, 0.7);
    box-shadow: 0px 4px 4px rgba(135, 135, 135, 0);
    border-color: transparent;
  }

  button:focus {
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0px 4px 4px rgba(135, 135, 135, 0);
    border-color: transparent;
    border-radius: 30px;
  }
`;

export const AsideMenu = styled.div`
  width: 100%;
  background-color: #fff;
  height: 370px;
`;

export const ButtonWrapper = styled.div`
  width: 100%;

  div {
    padding: 0;
    width: 100%;
  }

  button {
    width: 100%;
    box-shadow: 0px 4px 4px rgba(135, 135, 135, 0);
  }

  button:focus {
    box-shadow: 0px 4px 4px rgba(135, 135, 135, 0);
  }
`;

export const PaginationWrapper = styled.div`
  li:hover,
  li:focus {
    color: #fff;
    border-color: #56C568;
    
    a,
    .ant-pagination-item-link {
      color: #fff;
      background-color: #56C568;
      border-color: #56C568;
    }
  }

  .ant-pagination-disabled:hover {
    a {    
      color: rgba(0, 0, 0, 0.25);
      border-color: #d9d9d9;
      background-color: transparent;
    }
  }

  .ant-pagination-item-active {
    background-color: #56C568;
    border-color: #56C568;

    a {
      color: #fff;
    }
  }

  a:hover {
    color: #fff;
  }

  .ant-pagination-jump-next,
  .ant-pagination-jump-prev {
    .ant-pagination-item-container {
      .ant-pagination-item-link-icon {
        color: #56C568;
      }
    }
  }
`;