import React from 'react';
import { Row, Col } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import Button from '../../../components/Button';
import Divider from '../../../components/Divider';

import img from '../../../assets/ativar_provas.png';
import {
  Content, ButtonWrapper, EmptyImageContainer,
} from './styles';

const Empty = ({ onClick }) => (
  <Row gutter={[16, 16]} type='flex' align='middle' justify='center'>
    <Col xs={24} lg={12}>
      <Content>
        <Divider size="medium" />
        <div>
          As suas galerias serão listadas aqui.
          <Divider size="small" />
          Agora a lista está vazia, mas você pode
          <b> criar sua primeira galeria</b> clicando abaixo:
          <Divider size="large" />
          <ButtonWrapper>
            <Button type="primary" onClick={onClick}>
              <i style={{marginRight: '8px'}}><FontAwesomeIcon icon={faPlus} /></i>
              Criar Galeria
            </Button>
          </ButtonWrapper>
        </div>
        <Divider size="medium" />
      </Content>
    </Col>
    <Col xs={0} lg={12}>
      <EmptyImageContainer>
        <img src={img} alt="galeria vazia" />
      </EmptyImageContainer>
    </Col>
  </Row>
);

export default Empty;
