import React from 'react';
import { Icon } from 'antd';

import Divider from '../../../../components/Divider';
import Label from '../../../../components/Form/Label';

import { Container } from '../../Overview/styles';

import { CreditCardBrandsContainer, CreditCardBrand } from '../../Overview/PaymentInfo/styles';

import creditCardMasterCard from '../../../../assets/icon/credit_card_mastercard.png';
import creditCardVisa from '../../../../assets/icon/credit_card_visa.png';
import creditCardElo from '../../../../assets/icon/credit_card_elo.png';
import creditCardDinersClub from '../../../../assets/icon/credit_card_dinnersclub.png';
import creditCardHiperCard from '../../../../assets/icon/credit_card_hipercard.png';

import {
	InfoTitle,
	CardContainer,
	ExtraInfo,
} from '../styles';

const CreditCardPaymentFailed = () => (
	<Container>
		<Divider size="large" />

		<CardContainer>
			<Divider size="xlarge" />

			<Icon type="loading-3-quarters" spin style={{ fontSize: 80, color: "#858585" }} />

			<Divider size="medium" />

			<InfoTitle>
				Assinatura em processamento...
			</InfoTitle>

			<Divider size="medium" />

			<Label>
				Estamos processando o seu cartão de crédito
			</Label>

			<Divider size="medium" />

			<CreditCardBrandsContainer>
				<CreditCardBrand src={creditCardMasterCard} />
				<CreditCardBrand src={creditCardVisa} />
				<CreditCardBrand src={creditCardElo} />
				<CreditCardBrand src={creditCardHiperCard} />
				<CreditCardBrand src={creditCardDinersClub} />
			</CreditCardBrandsContainer>

			<Divider size="medium" />

			<ExtraInfo>
				Imediatamente após a aprovação do cartão de crédito, a sua assinatura será ativada!
				<br />
				Te enviaremos um e-mail de confirmação assim que aprovado!
			</ExtraInfo>
		</CardContainer>
	</Container>
);

export default CreditCardPaymentFailed;
