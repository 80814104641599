import React from 'react';
import PropTypes from 'prop-types';

import { Container, PlanName, PlanType } from './styles';

import { TitlePortlet} from '../../../../styles/components';

import Divider from '../../../../components/Divider';
import UsageBar from '../../../../components/UsageBar';
import Small from '../../../../components/Form/Small';

import { KBtoGB, MBtoGB, toBytes, MBtoKB } from '../../../../utils/sanitization';

const ColPortlet = (props) => {
	const {
		galleries,
		maxGalleries,
		gallerySpace,
		maxGallerySpace,
		galleryPictures,
		maxGalleryPictures,
		planName,
		planType,
	} = props;

	return (
		<Container>
			<TitlePortlet>
				<PlanName>{planName}</PlanName>
				{" "}
				<PlanType>({planType})</PlanType>
			</TitlePortlet>
			<Small>Passe o mouse em cima das barras de uso para ter acesso a informações mais detalhadas.</Small>

			<Divider size="medium" />

			<UsageBar
				title="Quantidade de galerias"
				hoverCurrent={`Você possui ${Number(galleries).toLocaleString()} ${galleries === 1 ? 'galeria' : 'galerias'}`}
				hoverRest={`Você pode criar mais ${Number(maxGalleries-galleries).toLocaleString()} ${maxGalleries-galleries === 1 ? 'galeria' : 'galerias'}`}
				percentMessage={`${Number(galleries).toLocaleString()} ${galleries === 1 ? 'galeria' : 'galerias'}`}
				singleUnity="galeria"
				multiUnity="galerias"
				progress={maxGalleries-galleries <= 0 ? 100 : (galleries/maxGalleries * 100)}
				current={galleries}
				total={maxGalleries}
				width="100%"
			/>

			<Divider size="medium" />

			<UsageBar
				title="Uso de espaço"
				hoverCurrent={`Você está usando ${toBytes(gallerySpace, 2)}`}
				hoverRest={`Você possui ${toBytes(MBtoKB(maxGallerySpace) - gallerySpace, 2)} disponíveis`}
				percentMessage={toBytes(gallerySpace, 2)}
				singleUnity="GB"
				multiUnity="GB"
				progress={MBtoGB(maxGallerySpace)-KBtoGB(gallerySpace) <= 0 ? 100 : (KBtoGB(gallerySpace)/MBtoGB(maxGallerySpace) * 100)}
				current={KBtoGB(gallerySpace).toFixed(2)}
				total={maxGallerySpace === 'no_limit' ? 'no_limit' : MBtoGB(maxGallerySpace).toFixed(0)}
				width="100%"
			/>

			<Divider size="medium" />

			<UsageBar
				title="Quantidade de fotos"
				hoverCurrent={`Você possui ${Number(galleryPictures).toLocaleString()} ${galleryPictures === 1 ? 'foto' : 'fotos'}`}
				hoverRest={`Você pode enviar mais ${Number(maxGalleryPictures-galleryPictures).toLocaleString()} ${maxGalleryPictures-galleryPictures === 1 ? 'foto' : 'fotos'}`}
				percentMessage={`${Number(galleryPictures).toLocaleString()} ${galleryPictures === 1 ? 'foto' : 'fotos'}`}
				singleUnity="foto"
				multiUnity="fotos"
				progress={maxGalleryPictures-galleryPictures <= 0 ? 100 : (galleryPictures/maxGalleryPictures * 100)}
				current={galleryPictures}
				total={maxGalleryPictures}
				width="100%"
			/>
		</Container>
	)
};

ColPortlet.propTypes = {
	galleries: PropTypes.number.isRequired,
	maxGalleries: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
	gallerySpace: PropTypes.number.isRequired,
	maxGallerySpace: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
	galleryPictures: PropTypes.number.isRequired,
	maxGalleryPictures: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
	planName: PropTypes.string.isRequired,
	planType: PropTypes.string.isRequired,
}

export default ColPortlet;
