import React, { useState, useEffect } from 'react';
import { Progress } from 'antd';
import { Prompt } from 'react-router-dom';

import Api from '../../../../services/api';
import queue from '../../../../services/queue';

import { getPercentage } from '../../../../utils/sanitization';

import { Container } from './styles';

import Divider from '../../../../components/Divider';

const UploadProgress = (props) => {
  const [requested, setRequested] = useState(0);
  const [sent, setSent] = useState(0);
  const [picturesSent, setPicturesSent] = useState(0);
  const [totalSize, setTotalSize] = useState(0);
  const [payloadId, setPayloadId] = useState(null);

  const { work_id, files, handleUploadCompleted, handleCancelUpload } = props;

  useEffect(() => {
    initPicturesUpload(files, work_id);
  }, []);

  const handleConfirmChangeRoute = ({ pathname }) => {
    if (pathname.includes(`works/${work_id}`)) {
      return true
    }

    const sure = window.confirm('Ao sair desse trabalho o envio das fotos será cancelado.');

    if (sure === true) {
      queue.kill();

      Api.put("/portfolio/pictures/event/customer-closed-work-during-upload", {
        work_id,
        payload_id: payloadId
      }).then(() => {
        window.onbeforeunload = () => undefined;
        window.location.href = pathname;
      });
    }

    return false;
  }

  const initPicturesUpload = async (files, work_id) => {
    // Creating variable because of the async behavior from useState causing delay
    const requestedFiles = files;
    const payload_list = [];
    const fileList = [];
    let totalSent = 0;
    let totalPicturesSent = 0;
    let totalPicturesSize = 0;
    const lastPictureProgress = {};
    const payload_info = {
      failedList: [],
      succededList: []
    }

    for (let i = 0; i < requestedFiles.length; i++) {
      payload_list.push({
        picture_title: requestedFiles[i].name,
        size: requestedFiles[i].size,
        overwrite: requestedFiles[i].options && requestedFiles[i].options.overwrite
      });
      requestedFiles[i].index = i;
      fileList.push(requestedFiles[i]);
      totalPicturesSize += requestedFiles[i].size / 1024 / 1024;
    }

    setRequested(fileList.length);
    setTotalSize(totalPicturesSize);

    if(fileList.length === 0) {
      return handleCancelUpload();
    }

    const pageTitle = document.title

    // Set the initial tracking for upload progress, so,
    // since it started right now, the current progress is 0
    document.title = `(0%) ${pageTitle}`

    // Creates a payload with all the pictures expected to be uploaded and
    // receives a hash that will be used to tag every picture of this payload.
    const response = await Api.post(`/portfolio/pictures/log/payload/work/${work_id}`, {
      payload_list,
    });

    const { payload_id, picture_ids } = response.data;

    setPayloadId(payload_id);

    window.onbeforeunload = async () => {
      return await Api.put("/portfolio/pictures/event/customer-closed-work-during-upload", {
        work_id,
        payload_id
      });
    }

    const worker = async (file) => {
      const index = file.metadata.jobId;
      lastPictureProgress[index] = 0;

      const formData = new FormData();
      formData.append('picture', file);
      formData.append('options', JSON.stringify(file.options || {}));

      try {
        // Receives a signed_url from AWS in order to make the upload
        // directly to S3.
        await Api.post(
          `/portfolio/pictures/${picture_ids[file.name]}/work/${work_id}/payload/${payload_id}`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/data',
            },
            onUploadProgress: (progress) => {
              let currentProgress = progress.loaded / progress.total;

              // If the current progress is equal one, it means the loaded
              // progress reached the total expected and so it means
              // a picture has been uploaded
              if (currentProgress === 1) {
                totalPicturesSent++;
                setPicturesSent(totalPicturesSent);
              }

              // Since the axios give us the total value of upload,
              // we have to do it minus the last progress in order
              // to get the uploaded percentage
              currentProgress -= lastPictureProgress[index];
              lastPictureProgress[index] = progress.loaded / progress.total;

              totalSent += currentProgress;
              setSent(totalSent);

              // Add upload progress to page title
              document.title = `(${getPercentage(totalSent, fileList.length)}%) ${pageTitle}`
            },
          },
        );

        payload_info.succededList.push({ title: file.name, error: null })

      } catch (error) {
        const message = error && error.message;

        // In case the server is down, we reject the upload an try to send it again
        // till it reaches the maximum number of retries
        if (message === "Network Error" && file.metadata.retries < queue.info().retries) {
          return Promise.reject(error);
        }

        // If there's an error provided by back-end we take it
        let errorMessage = error && error.response && error.response.data && error.response.data.message
        let errorCode = error && error.response && error.response.data && error.response.data.error

        if (!errorCode) {
          errorCode = "ConnectionBroken"
        }

        if (!errorMessage) {
          errorMessage = "Erro inesperado"
        }

        payload_info.failedList.push({ title: file.name, error: errorMessage })
        await Api.put(`/pictures/${picture_ids[file.name]}/error`, { error: errorCode }).catch(() => {})
      }
    };

    queue.set(worker, {
      concurrency: 5,
      retries: 1,
      delay: 5000,
    });

    queue.add(fileList);

    await queue.process();

    // Set back over the initial page title, since we were
    // tracking the upload progress on page title
    document.title = pageTitle
    window.onbeforeunload = () => undefined;

    Api.put("/portfolio/pictures/event/upload-completed", { work_id });
    Api.put(`/portfolio/pictures/log/payload/${payload_id}/finish`);
    handleUploadCompleted(payload_info);
  };

  return (
    <Container>
      <Prompt
        when={(location) => !location.pathname.includes(`portfolio/works/${work_id}`)}
        message={handleConfirmChangeRoute}
      />

      <Progress
        // We get the percent that way because using toFixed(0) makes
        // the string automatically rounded and the download gets into 100%
        // before really ending
        percent={getPercentage(sent, requested)}
        showInfo={true}
        strokeColor="#56C568"
        strokeWidth={40}
        strokeLinecap="round"
        width="90%"
      />

      <Divider size="medium" />

      <b>
        Subindo fotos ({picturesSent}/{requested})
      </b>
      <div>
        {((sent / requested) * totalSize).toFixed(2)} mb /{' '}
        {totalSize.toFixed(2)} mb
      </div>
    </Container>
  );
};

export default UploadProgress;
