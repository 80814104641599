let newData = null;

export const query = {
	from: function (data) {
		newData = data;

		const from = {
			where: function(func) {
				try {
					if (Array.isArray(newData)) {
						const selected = newData.find(func)
	
						if (selected) {
							newData = selected
						} else {
							newData = null
						}
					}
				} catch(error) {
					console.error("error trying to find data")
					newData = null
				}

				return this;
			},
			select: function(func) {
				try {
					if (!Array.isArray(newData) && Object.keys(newData).length) {
						const arrayFromObject = Object.keys(newData).map(key => ({ [key]: newData[key] }))
	
						const selected = arrayFromObject.find(func)
	
						if (selected) {
							newData = Object.values(selected).shift()
						} else {
							newData = null
						}
					}
				} catch(error) {
					console.error("error trying to get data")
					newData = null
				}
				
				return this;
			},
			retrieve: function() {
				return newData;
			}
		}

		return from;
	},
}