import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
`;

export const Logo = styled.img`
  margin: 32px auto;
  width: 100%;
  max-width: 200px;
`;

export const AsideInfo = styled.div`
  position: relative;
  background-color: #FF4949;
  width: 100%;
  height: 100%;
`;

export const AdvertisingImage = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 820px;
  height: auto;
`;

export const LoginForm = styled.div`
  max-width: 400px;
  height: 100%;
  margin: auto;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  div {
    width: 100%;
  }
`;

export const ForgotPassword = styled.a`
  width: 100%;
  text-align: right;

  div {
    display: flex;
  }

  div span, span{
    color: #FF4949;
    margin-left: auto;
    font-size: 12px;
  }
`;
