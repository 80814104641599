import styled from 'styled-components';

export const Container = styled.div`
	width: 100%;
	height: 100%;

	display: flex;
	flex-direction: column;

	align-items: center;
`;

export const CardContainer = styled.div`
	width: 70%;
	background-color: #FFF;
	border-radius: 5px;

	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

	display: flex;
	flex-direction: column;

	@media only screen and (max-width: 1155px) {
		width: 90%;
	}

	@media only screen and (max-width: 780px) {
		width: 100%;
	}
`;

export const CardTitle = styled.h1`
	font-size: 30px;

	font-weight: lighter;
`;

export const CardHeader = styled.div`
	padding: 18px 24px 0;
`;

export const CardBody = styled.div`
	width: 100%;
	padding: 0 24px;

	.ant-table-thead > tr > th {
		background-color: transparent;
	}

	.ant-table-column-title {
		font-weight: bold;
	}

	.ant-radio {
		vertical-align: -25%;
	}

	label {
		color: #000;
	}

	label:hover {
    .ant-radio-inner {
      border-color: #808080;
    }
  }

  .ant-radio-input:focus + .ant-radio-inner {
    border-color: #808080;
  }

  .ant-radio:hover {
    .ant-radio-inner {
      border-color: #808080;
    }
  }

  .ant-radio-checked {
    .ant-radio-inner {
      border-color: #808080;
    }

    .ant-radio-inner:after {
      background-color: #808080;
    }
  }

  .ant-radio-checked:after {
    border-color: #808080;
  }
`;