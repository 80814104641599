import React, { useEffect } from 'react';

import { logout } from '../../services/auth';

const Logout = (props) => {
	const redirect = () => props.history.push('/login')

	useEffect(() => {
		logout()
			.then(() => redirect())
			.catch(() => redirect());
	}, [])

	return (
		<></>
	)
};

export default Logout;