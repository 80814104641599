import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Row, Col, Icon, Tag, Typography, Modal, Pagination, Select } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import { Creators as PortfolioActions } from '../../../store/ducks/portfolio';

import Button from '../../../components/Button';
import Divider from '../../../components/Divider';
import Loading from '../../../components/Loading';
import PopConfirm from '../../../components/PopConfirm';
import Input from '../../../components/Form/Input';
import FormItem from '../../../components/Form/FormItem';
import SubmitContainer from '../../../components/Form/SubmitContainer';

import api from '../../../services/api';

import cover_placeholder from '../../../assets/avatar-no-gallery-cover-pb.png';
import photoIcon from '../../../assets/ativar_provas.png';

import { notifySuccess, notifyError } from '../../../utils/notificationService';
import { getStatusCode } from '../../../utils/response';
import { removeAccentuation } from '../../../utils/text';
import { chunck } from '../../../utils/array';
import { useValidation } from '../../../services/validation';

import {
  Portlet,
} from '../../../styles/components';
import {
  NewButtonWrapper,
  Header,
  Body,
  BodyTitle,
  Card,
  CardContent,
  CardCover,
  CardBody,
  CardIcons,
  CardTags,
  Container,
  ModalContent,
	PaginationWrapper,
	NoWorks,
	NoWorksTitle,
} from './styles';

const { Title } = Typography;
const { Option } = Select;

const WorksList = (props) => {
	const inputs = ["work_title", "category_title"]

	const dispatch = useDispatch();
	const portSelectedCategory = useSelector((state) => state.portfolio.info.category);
	const portfolioInfo = useSelector((state) => state.portfolio.info);
	const totalWorks = useSelector((state) => state.portfolio.info.total_works);
  const portCategories = useSelector((state) => state.portfolio.outside_categories);
	const [loading, setLoading] = useState(false);
	const [loadingBtn, setLoadingBtn] = useState(false);
	const [modalVisibility, setModalVisibility] = useState(false);
	const [newWorkData, setNewWorkData] = useState({});
	const [works, setWorks] = useState([]);
  const [paginatedData, setPaginatedData] = useState(works);
  const [filteredData, setFilteredData] = useState(works);
  const [validation, clearValidation, triggerValidation] = useValidation(inputs);
  const [currentPage, setCurrentPage] = useState(1);
	const [worksPerPage] = useState(10);
	const [noWorks, setNoWorks] = useState(false);

	const [categories, setCategories] = useState([]);

	const getCategData = async () => {
		setLoadingBtn(true);

		// TODO: remove try catch when default categories are defined
		try {
			const { data } = await api.get('/portfolio/categories');
			setCategories(data.categories);
		} catch (err) {
			setCategories([]);
		}

		setLoadingBtn(false);
	};

	useEffect(() => {
		getCategData();
	}, [portCategories]);

	useEffect(() => {
		setFilteredData(paginatedData);
	}, [paginatedData]);

	const handleEnterKey = async (e) => {
    // //
	};

	const getData = async () => {
		setLoading(true);

		try {
			let data = {};

			if (!portSelectedCategory) {
				const result = await api.get('/portfolio/works');
				data = result.data;
			} else {
				const result = await api.get(`/portfolio/works?category_id=${portSelectedCategory.id}`);
				data = result.data;
			}

			const resWorks = data.works.map((work) => ({
				...work,
				cover: work.cover ? work.cover : cover_placeholder,
			}));

			const chuncked = chunck(resWorks, worksPerPage);
			setWorks(chuncked);
			setPaginatedData(chuncked[0]);
			setFilteredData(chuncked[0]);

			dispatch(PortfolioActions.setInfo({ total_works: data.works.length }));
			setNoWorks(false);
		} catch (error) {
			returnToDefaultState();
		}

		setLoading(false);
	};

	const returnToDefaultState = () => {
		setWorks([]);
		setPaginatedData([]);
		setFilteredData([]);
		setNewWorkData({});
		setCurrentPage(1);
		dispatch(PortfolioActions.setInfo({ total_works: 0 }));
		setNoWorks(true);
	};

	useEffect(() => {
		getData();
	}, [portSelectedCategory]);

	const init = async () => {
    await dispatch(PortfolioActions.setInfo({
			work_id: null,
			title: '',
			total_works: 0,
			pictures_ordenation: [],
			page: 1,
			cover: '',
			uploadStatus: 'invisible',
		}));

		getData();
  };

	useEffect(() => {
		init();
	}, []);

	const handleSearchChange = (e) => {
    const { value } = e.target;
    const result = works.flat().filter((item) => {
      const { title } = item;
      if (
        removeAccentuation(title.toLocaleLowerCase().trim()).includes(
          value.toLocaleLowerCase().trim()
        ) ||
        title
          .toLocaleLowerCase()
          .trim()
          .includes(value.toLocaleLowerCase().trim())
      ) {
        return true;
      } else {
        return false;
      }
		});

		setCurrentPage(1);

		if (result.length === works.flat().length) {
			setPaginatedData(works[0]);
			setFilteredData(works[0]);
			dispatch(PortfolioActions.setInfo({ total_works: works.flat().length }));
		} else {
			dispatch(PortfolioActions.setInfo({ total_works: result.length }));
			setFilteredData(result);
		}
	};

	const handleOpenModal = () => {
    setNewWorkData({});
		setModalVisibility(true);
	};

	const handleCloseModal = () => {
		setModalVisibility(false);
	};

  const handleChange = (key, value) => {
		clearValidation(key);
		if (key === 'work_title') {
			key = 'title';
		} else if (key === 'category_title') {
			key = 'category_id';
		}

    setNewWorkData({
			...newWorkData,
			[key]: value,
		});
  };

	const handleCreateWork = async () => {
    setLoadingBtn(true);

    let categ_id;
    if (portSelectedCategory && !newWorkData.category_id) {
      categ_id = portSelectedCategory.id;

      setNewWorkData({
        ...newWorkData,
        category_id: portSelectedCategory.id,
      });
    } else {
      categ_id = newWorkData.category_id;
    }

		try {
			const portfolioInfo = {
				total_works: paginatedData.flat().length + 1,
				cover: '',
			}

      const { data } = await api.post('/portfolio/work', { title: newWorkData.title, category_id: categ_id });
			dispatch(PortfolioActions.setInfo(portfolioInfo));
      props.history.push(`/portfolio/works/${data.work.id}/pictures`);
			setNoWorks(false);
			setModalVisibility(false);
		} catch (error) {
      const statusCode = getStatusCode(error);

      if (statusCode === 500) {
        notifyError('Algo deu errado.');
				setModalVisibility(false);
      } else {
				triggerValidation(error);
			}
		}

		setLoadingBtn(false);
	}

	const handleDeleteWork = async (work_id) => {
    setNewWorkData({
			...newWorkData,
			id: work_id,
		});

		try {
			await api.delete(`/portfolio/work/?work_id=${work_id}`);
			getData();
		} catch (error) {
			notifyError('Algo deu errado.');
		}
	};

  const handlePageChange = (page) => {
		setCurrentPage(page);
		setPaginatedData(works[page-1]);
  };

  const handleSelect = (id) => {
    handleChange('category_title', id);

    if (document) {
      document.activeElement.blur();
      const submit = document.querySelector('button[type="submit"]');
      submit.focus();
    }
	};
	
	const handleOpenConfirmDeleteWorkModal = (work_id) => {
		PopConfirm.open({
			title: "Tem certeza?",
			cancelText: "NÃO",
			confirmText: "SIM",
			confirmIcon: "delete",
			info: 'Deletar o trabalho é uma ação irreversível. Deseja continuar?',
			onConfirm: () => handleDeleteWork(work_id)
		})
	};

	return (
		<Container>
			<Modal
        footer={null}
        closeIcon={null}
        visible={modalVisibility}
        onCancel={handleCloseModal}
      >
        <ModalContent>
					<SubmitContainer onSubmit={handleCreateWork}>
						<Title level={3}>Dê um nome ao seu trabalho</Title>

						<Divider size="medium" />

						<FormItem
							validateStatus={validation.work_title.validateStatus}
							help={validation.work_title.help}
						>
							<Input
								onChange={({ target }) => handleChange('work_title', target.value)}
								value={newWorkData.title}
								placeholder="Casamento Guilherme &amp; Camilla"
							/>
						</FormItem>

						<Divider size="medium" />

						<Title level={3}>Escolha uma categoria</Title>

						<Divider size="medium" />

						<FormItem
							validateStatus={validation.category_title.validateStatus}
							help={validation.category_title.help}
						>
							<Select
                value={portSelectedCategory && (newWorkData.category_id || portSelectedCategory.id) || newWorkData.category_id || undefined}
								showSearch
								size='large'
								placeholder="Procure e selecione uma categoria"
								optionFilterProp="children"
								onInputKeyDown={(e) => handleEnterKey(e)}
								onSearch={({ target }) => target ? handleChange('category_title', target.value) : null}
								onSelect={(optionId) => handleSelect(optionId)}
								filterOption={(input, option) =>
									option.props.children.toLocaleLowerCase().indexOf(input.toLocaleLowerCase()) >= 0
								}
								loading={loadingBtn}
							>
								{categories.map((categ) => (
									<Option value={categ.id}>{categ.title}</Option>
								))}
							</Select>
						</FormItem>

						<Divider size="medium" />

						<Button
              htmlType="submit"
              key="submit"
							type="primary"
							loading={loadingBtn}
							block
							icon="check"
						>
							CRIAR TRABALHO
						</Button>
					</SubmitContainer>
        </ModalContent>
      </Modal>
			<Loading loading={loading}>
			{noWorks || works.length === 0 ? (
				<Portlet>
					<Row justify="center">
						<Col sm={{ span: 24 }} md={{ span: 16, offset: 4 }} xl={{ span: 12, offset: 6 }}>
							<NoWorks>
								<img src={photoIcon} alt="foto" />
								<Divider size="medium" />
								<NoWorksTitle>Vamos criar o seu portfólio?</NoWorksTitle>
								<p>
								</p>
								<p>
									Você ainda não subiu nenhum trabalho! <br />
									<b>Clique abaixo</b> para subir agora mesmo o seu primeiro trabalho para compor o seu portfólio!
								</p>
								<Divider size="medium" />
								<Button
									size="large"
									type="primary"
									loading={loadingBtn}
									onClick={handleOpenModal}
								>
									SUBIR O MEU 1º TRABALHO
								</Button>
								<Divider size="medium" />
							</NoWorks>
						</Col>
					</Row>
				</Portlet>
			)
				: (
					<>
						<Header>
							<Portlet>
								<Row gutter={[16, 16]}>
									<Col xs={5} sm={5} md={5} lg={5} xl={5} xxl={4}>
										<NewButtonWrapper>
											<Button
												type="primary"
												onClick={() => handleOpenModal()}
											>
												<FontAwesomeIcon icon={faPlus} />
											</Button>
										</NewButtonWrapper>
									</Col>
									<Col xs={3} sm={3} md={5} lg={5} xl={9} xxl={12} style={{ padding: '0' }}></Col>
									<Col xs={16} sm={16} md={14} lg={14} xl={10} xxl={8}>
										<Input
											type="search"
											placeholder="Procurar trabalho"
											onChange={(e) => handleSearchChange(e)}
										/>
									</Col>
								</Row>
							</Portlet>
						</Header>
						<Body>
							<BodyTitle>
								MEUS TRABALHOS
							</BodyTitle>
							{works && filteredData.map((work) => {
								return (
									<>
										<Card>
											<CardCover isPlaceholder={work.cover === cover_placeholder}>
												<Link to={`/portfolio/works/${work.id}/pictures`}>
													<img
														src={work.cover}
														alt={'Cover'}
													/>
												</Link>
											</CardCover>
											<CardContent>
												<CardBody>
													<Link to={`/portfolio/works/${work.id}/pictures`}>
														{work.title}
													</Link>
													<Divider size="xsmall" />
													<span>
														{work.info.total_pictures} {work.info.total_pictures === 1 ? 'foto' : 'fotos'}
													</span>
													<Divider size="small" />
													<CardTags>
														<Tag color='#5CC395'>{work.category_title}</Tag>
													</CardTags>
													<Divider size="small" />
												</CardBody>
												<CardIcons>
													<Icon style={{ cursor: 'default', visibility: 'hidden' }} type='menu' />
													<Icon type='delete' onClick={() => handleOpenConfirmDeleteWorkModal(work.id)} />
												</CardIcons>
											</CardContent>
										</Card>
										<Divider size='medium' />
									</>
								)
							})}
							<PaginationWrapper>
								<Pagination
									defaultCurrent={1}
									current={currentPage}
									onChange={handlePageChange}
									defaultPageSize={worksPerPage}
									total={totalWorks}
								/>
							</PaginationWrapper>
						</Body>
					</>
				)
			}
			</Loading>
		</Container>
  );
};

WorksList.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default WorksList;
