import React, { useState } from 'react';
import { Modal, Radio } from 'antd';
import PropTypes from 'prop-types';

import Button from '../../../../../components/Button';

import { Opener, ButtonGroup, ButtonWrapper, RadioGroup } from './styles';

import {
  notifyError,
  notifyBackendError
} from '../../../../../utils/notificationService';
import { usePlan } from '../../../../../services/plan';

const SwitchModal = (props) => {
  const {
    name,
    onSave,
    children,
    value,
    validateSolution,
    limitTypeKey,
    limitKey
  } = props;

  const [modalVisible, setModalVisible] = useState(false);
  const [radioValue, setRadioValue] = useState('');
  const [loading, setLoading] = useState(false);
  const { checkSolution, triggerPlanChange } = usePlan();

  const close = () => {
    setModalVisible(false);
    setLoading(false);
    setRadioValue('');
  };

  const handleCancel = () => {
    close();
  };

  const handleOpen = async () => {
    if (validateSolution) {
      const isEnabled = checkSolution(limitTypeKey, limitKey);

      if (!isEnabled) {
        return await triggerPlanChange('feat', limitKey);
      }
    }

    setModalVisible(true);
  };

  const handleOk = async () => {
    if (typeof onSave !== 'function') {
      return;
    }

    setLoading(true);

    let extra = {};

    // As the switch value is a boolean, we received the current value,
    // and the user wanna change it, the new value will be the opposite
    const newValue = !value;

    // In case the user selected the value 'all' to start using the option
    // as default for all the next collections, we add this info to
    // the extra object, that will be used on the back end
    if (name === 'watermark' && radioValue === 'all') {
      extra.use_watermark = newValue;
    } else if (name === 'anti_copy' && radioValue === 'all') {
      extra.use_antiCopy = newValue;
    }

    // As you guess, if the user select the value 'single',
    // the extra object gets no information, so the back end
    // will change the option only for this current collection

    if (radioValue === '') {
      setLoading(false);
      notifyError('Você precisa selecionar uma opção.');
      return;
    }

    const result = await onSave(newValue, extra);

    if (result !== true) {
      notifyBackendError(result);
    }

    close();
  };

  return (
    <>
      <Opener onClick={handleOpen}>{children}</Opener>
      <Modal
        title={value == true ? 'Desativar' : 'Ativar'}
        visible={modalVisible}
        onCancel={handleCancel}
        destroyOnClose={true}
        footer={[
          <ButtonWrapper>
            <ButtonGroup>
              <Button icon="close" onClick={handleCancel}>
                CANCELAR
              </Button>
              <Button
                type="primary"
                icon="check"
                loading={loading}
                onClick={handleOk}
              >
                SALVAR
              </Button>
            </ButtonGroup>
          </ButtonWrapper>
        ]}
      >
        <RadioGroup
          onChange={({ target }) => setRadioValue(target.value)}
          value={radioValue}
          style={{ width: '100%' }}
        >
          <Radio value="single">
            {value == true ? 'Desativar' : 'Ativar'} apenas para essa galeria
          </Radio>
          <Radio
            value="all"
            style={window.innerWidth < 600 ? { lineHeight: '20px' } : null}
          >
            {value == true ? 'Desativar' : 'Ativar'} para essa galeria e{' '}
            {window.innerWidth < 600 ? <br /> : null}para as próximas
            cadastradas
          </Radio>
        </RadioGroup>
      </Modal>
    </>
  );
};

SwitchModal.defaultProps = {
  validateSolution: false
};

SwitchModal.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onSave: PropTypes.func.isRequired,
  value: PropTypes.any.isRequired,
  validateSolution: PropTypes.bool,
  limitTypeKey: PropTypes.string,
  limitKey: PropTypes.string
};

export default SwitchModal;
