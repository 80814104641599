import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: 100%;
`;

export const FormWrapper = styled.div`
  padding: 16px;
  flex: 1;
`;

export const PreviewDisplay = styled.p`
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 14px;
  color: #333333;
`;

export const PreviewWrapper = styled.div`
  background: #fff;
  padding: 12px;
  margin-bottom: ${(props) => props.isMobile ? '60px' : null};

	background-color: rgba(0, 0, 0, 0.02);
`;

export const PreviewHeader = styled.div`
  font-family: 'Google Sans',Roboto,RobotoDraft,Helvetica,Arial,sans-serif;
  color: #202124;
  font-size: 22px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 28px;

  p {
    margin-bottom: 0;
  }
`;

export const PreviewInbox = styled.p`
  height: auto;
  font-size: 12px;
  background-color: rgb(221, 221, 221);
  color: rgb(102, 102, 102);
  border-radius: 4px;
  margin-left: 10px;

  span {
    width: auto;
    padding: 0 4px;
    display: flex;
    align-items: center;
  }
`;

export const PreviewSender = styled.div`
  font-family: 'Google Sans',Roboto,RobotoDraft,Helvetica,Arial,sans-serif;
  color: #555;
`;

export const PreviewSubject = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  p {
    margin: 0;
    font-size: 14px;
    font-weight: bold;
    color: #202124;
  }

  small {
    color: #555;
    margin: 0;
    margin-left: 4px;
    font-size: 12px;
  }
`;

export const PreviewContainer = styled.div`
  font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
  border-top: 1px solid #edeff2;
  font-size: 16px;
  color: #74787e;
  width: 100%;
  padding: 35px;
  flex: 1;
  background-color: #ffffff;

  p {
    margin-bottom: 16px;
  }
`;

export const ButtonWrapper = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;

  margin-bottom: ${({ enlarge }) => enlarge ? 0 : '30px'};

	div button {
		color: #FFF;
		border-color: #56C568;
		background-color: #56C568;

    pointer-events: none;

    padding: ${({ enlarge }) => enlarge && "0px 40px"};
    font-size: ${({ enlarge }) => enlarge && "12px"};
    font-weight: ${({ enlarge }) => enlarge && "bold"};
	}
`;

export const Message = styled.div`
  white-space: pre-line;
  margin-bottom: 20px;

	word-break: break-all;
`;

export const FooterPreview = styled.div`
  padding-top: 25px;
  border-top: 1px solid #edeff2;
  font-size: 12px;

  small {
    font-size: 12px;
  }

  p{
    margin: 12px 0;
    color: #477cd8;
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const Footer = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e9e9e9;
  padding: 10px 16px;
  background: #fff;
  text-align: right;
`;

export const Field = styled.div`
  display: flex;
  height: 35px;
`;

export const FieldKey = styled.div`
  width: 70px;
  display: flex;
  align-items: center;
`;

export const FieldValue = styled.div`
  background-color: #EDEFF2;
  display: flex;
  align-items: center;
  padding: 0 10px;
  border-radius: 5px;
  color: #0093E8;
`;

export const FieldInfo = styled.div`
  color: #FAAD14;
  font-style: italic;
  max-width: 230px;
  font-size: 14px;
`;
