import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Grid,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Radio,
  Typography
} from '@material-ui/core';
import {
  DeleteForever as DeleteForeverIcon,
  FormatColorResetOutlined as WithOutWaterMarkIcon,
  MoreVert as MenuIcon
} from '@material-ui/icons';

import { calculateMaxWidthAndMaxHeight, positions } from '../../utils/image';

import useStyles from './styles';

const CardOptionWaterMark = (props) => {
  const {
    isChecked,
    radioValue,
    waterMark,
    watermarkSettings,
    onChange,
    onDelete
  } = props;

  const newWatermarkId = `watermark-preview-thumb-${radioValue}`;
  const previewBoxId = `preview-box-thumb-${radioValue}`;

  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles({ isChecked });

  const watermarkElementRef = useRef(null);

  const handleChange = async (value) => {
    await onChange(value);
  };

  const handleOpenMenuEdit = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenuEdit = () => {
    setAnchorEl(null);
  };

  const handleDeleteWatermark = async () => {
    await onDelete(radioValue);
    handleCloseMenuEdit();
  };

  const initializeWatermarkProps = () => {
    if (watermarkSettings) {
      const previewBoxElement = document.getElementById(previewBoxId);
      const watermarkElement = document.getElementById(newWatermarkId);

      const sizeWatermark = watermarkSettings.size_image || 1;
      const positionWatermark = watermarkSettings.position_image || 5;
      let marginWatermark = {
        t: 0,
        l: 0,
        b: 0,
        r: 0
      };

      if (watermarkSettings.margin_image) {
        marginWatermark = {
          t: watermarkSettings.margin_image.t || 0,
          l: watermarkSettings.margin_image.l || 0,

          b: watermarkSettings.margin_image.b || 0,
          r: watermarkSettings.margin_image.r || 0
        };
      }

      if (watermarkElement) {
        const previewBoxWidth =
          previewBoxElement && previewBoxElement.offsetWidth;
        const previewBoxHeight =
          previewBoxElement && previewBoxElement.offsetHeight;

        const maxHeightWatermark =
          watermarkElement && watermarkElement.clientHeight;
        const maxWidthWatermark =
          watermarkElement && watermarkElement.clientWidth;

        const naturalHeightWatermark = watermarkElement.naturalHeight;
        const naturalWidthWatermark = watermarkElement.naturalWidth;

        let heightWatermark = '100%';
        let widthWatermark = '100%';

        if (naturalWidthWatermark > naturalHeightWatermark) {
          heightWatermark = 'auto';
          widthWatermark = '100%';
        } else {
          heightWatermark = '100%';
          widthWatermark = 'auto';
        }

        const { t: top, l: left, b: bottom, r: right } = marginWatermark;

        let marginY = bottom > top ? bottom : top;
        let marginX = left > right ? left : right;

        marginY = [4, 6].includes(positionWatermark)
          ? 0
          : Math.floor((marginY / 100) * previewBoxHeight);

        marginX = [2, 8].includes(positionWatermark)
          ? 0
          : Math.floor((marginX / 100) * previewBoxWidth);

        const maxHeight = Math.floor(sizeWatermark * previewBoxHeight);
        const maxWidth = Math.floor(sizeWatermark * previewBoxWidth);

        const heightParams = {
          maxHeightWatermark,
          marginY,
          maxHeight
        };

        const widthParams = {
          maxWidthWatermark,
          marginX,
          maxWidth
        };
        const newMaxSizeWatermark = calculateMaxWidthAndMaxHeight(
          heightParams,
          widthParams
        );

        watermarkElementRef.current.style.width = `${widthWatermark}`;
        watermarkElementRef.current.style.height = `${heightWatermark}`;

        watermarkElementRef.current.style.maxHeight = `${newMaxSizeWatermark.maxHeightWatermark}px`;
        watermarkElementRef.current.style.maxWidth = `${newMaxSizeWatermark.maxWidthWatermark}px`;

        const marginOptions = {
          1: `${marginY}px auto auto ${marginX}px`,
          2: `${marginY}px auto auto`,
          3: `${marginY}px ${marginX}px auto auto`,
          4: `auto auto auto ${marginX}px`,
          6: `auto ${marginX}px auto auto`,
          7: `auto auto ${marginY}px ${marginX}px`,
          8: `auto auto ${marginY}px`,
          9: `auto ${marginX}px ${marginY}px auto`,
          default: `auto`
        };

        watermarkElementRef.current.style.margin =
          marginOptions[positionWatermark] || marginOptions.default;

        return;
      }
    }
  };

  return (
    <Box className={classes.boxContainer}>
      <Grid
        container
        alignContent="center"
        alignItems="center"
        className={classes.boxContent}
      >
        <Grid item xs={12}>
          <Grid
            container
            alignItems="center"
            alignContent="ccenter+"
            justify="space-between"
          >
            <Grid item xs={6}>
              <Radio
                className={classes.radioButton}
                checked={isChecked}
                onChange={() => handleChange(radioValue)}
                value={radioValue}
                name="radio-button-watermark"
                inputProps={{ 'aria-label': `${radioValue}` }}
                color="default"
              />
            </Grid>

            <Grid item xs={6} className={classes.menuButtonWrapper}>
              {waterMark && (
                <IconButton
                  aria-controls="edit-watermark"
                  aria-haspopup="true"
                  className={classes.menuButtonIcon}
                  onClick={handleOpenMenuEdit}
                >
                  <MenuIcon />
                </IconButton>
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} className={classes.cardOptionWrapper}>
          {waterMark ? (
            <Box
              component="span"
              className={[
                `${classes.cardOption}`,
                `${classes.cardOptionWithBackground}`
              ]}
            >
              <Grid
                id={previewBoxId}
                container
                justify="center"
                alignItems="center"
                className={classes.containerWatermark}
              >
                <img
                  id={newWatermarkId}
                  ref={watermarkElementRef}
                  src={waterMark}
                  alt="WaterMark"
                  className={classes.waterMarkImg}
                  onLoad={initializeWatermarkProps}
                  style={{ ...positions[watermarkSettings.position_image] }}
                />
              </Grid>
            </Box>
          ) : (
            <Grid
              container
              className={classes.cardOption}
              style={{ padding: '20px' }}
            >
              <Grid item xs={12}>
                <WithOutWaterMarkIcon
                  className={classes.iconWithOutWaterMark}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  align="center"
                  className={classes.textWithOutWaterMark}
                >
                  Sem marca d’água
                </Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>

      <Menu
        id="edit-watermark"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenuEdit}
      >
        <MenuItem onClick={handleDeleteWatermark}>
          <ListItemIcon>
            <DeleteForeverIcon />
          </ListItemIcon>
          <Typography>Excluir</Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
};

CardOptionWaterMark.defaultProps = {
  waterMark: null
};

CardOptionWaterMark.propTypes = {
  isChecked: PropTypes.bool.isRequired,
  radioValue: PropTypes.string.isRequired,
  waterMark: PropTypes.node,
  waterMarkSettings: PropTypes.shape({
    size_image: PropTypes.number,
    margin_image: PropTypes.shape({
      l: PropTypes.number,
      r: PropTypes.number,

      t: PropTypes.number,
      b: PropTypes.number
    }),
    position_image: PropTypes.number.isRequired
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
};

export default CardOptionWaterMark;
