import React from 'react';
import { Row, Col, Divider as AntDivider } from 'antd';

import { Portlet } from '../../styles/components';
import { BulletList } from './styles';

import Button from '../../components/Button';
import Divider from '../../components/Divider';


const Financial = () => {
  return (
    <>
      <Row gutter={[16, 0]}>
        <Col>
          <Portlet>
            <p>No painel do financeiro você poderá:</p>

            <Divider size="small" />

            <BulletList>
              <li>Ver o histórico de pagamentos</li>
              <li>Gerar 2ª via do boleto</li>
              <li>Alterar os dados do seu cartão de crédito</li>
            </BulletList>

            <Divider size="small" />

            <p>Para acessá-lo, basta clicar no botão abaixo.</p>

            <Divider size="medium" />

            <p>
              <b>Obs.:</b> Será solicitado seu e-mail e sua senha, porém lembre-se que esses dados não são os
              mesmos utilizados para acessar essa plataforma. Utilize o recurso de lembrar a senha caso não a possua.
            </p>

            <AntDivider />

            <a href="https://picsize.superlogica.net/clients/areadocliente/clientes/formadepagamento" target="_blank" rel="noopener noreferrer">
              <Button type="primary">ACESSAR PAINEL FINANCEIRO</Button>
            </a>
          </Portlet>
        </Col>
      </Row>
    </>
  );
};

export default Financial;
