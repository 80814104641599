import { makeStyles } from '@material-ui/core';

import colors from '../../../styles/colors';

import transparentGridImg from '../../../assets/transparent_placeholder.png';
import defaultPreviewImg from '../../../assets/background/defaultPreviewImg.jpg';

const useStyles = makeStyles((theme) => ({
  editWatermarkContainer: {
    [theme.breakpoints.up('sm')]: {
      minHeight: '500px'
    }
  },
  viewEditPanelContainer: {
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  viewEditPanelButton: {
    backgroundColor: '#000000',
    color: colors.greyScale[10],
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: '#444444',
      opacity: 0.8
    }
  },
  panelEditorContainer: {
    position: (props) =>
      props.isViewPanelContainerActive && props.isSmall
        ? 'absolute'
        : 'inherit',
    top: (props) =>
      props.isViewPanelContainerActive && props.isSmall ? '-9999px' : 'inherit',
    left: (props) =>
      props.isViewPanelContainerActive && props.isSmall ? '-9999px' : 'inherit',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      position: 'inherit',
      top: 'inherit',
      left: 'inherit'
    }
  },
  previewContainer: {
    position: (props) =>
      props.isViewPanelContainerActive && props.isSmall
        ? 'inherit'
        : 'absolute',
    top: (props) =>
      props.isViewPanelContainerActive || !props.isSmall
        ? 'inherit'
        : '-9999px',
    left: (props) =>
      props.isViewPanelContainerActive || !props.isSmall
        ? 'inherit'
        : '-9999px',
    [theme.breakpoints.up('md')]: {
      position: 'inherit !important',
      top: 'inherit',
      left: 'inherit'
    }
  },
  uploadContainer: {
    margin: '0 auto',
    padding: '10px',

    width: '218px',
    height: '218px',
    backgroundImage: `url(${transparentGridImg})`,
    textAlign: 'center',

    position: 'relative',

    cursor: 'pointer'
  },
  uploadIcon: {
    fontSize: '90px'
  },
  titleItem: {
    color: '#333'
  },
  previewBox: {
    position: 'relative',

    height: '90%',
    minHeight: '490px',
    width: '100%',
    backgroundImage: `url(${defaultPreviewImg})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',

    overflow: 'hidden',

    [theme.breakpoints.down('sm')]: {
      minHeight: '60vh'
    }
  },
  boxIsBlocked: {
    opacity: (props) => (props.watermarkBlobUrl ? 1 : 0.4),
    cursor: (props) => (props.watermarkBlobUrl ? 'default' : 'not-allowed')
  },
  previewNewWatermark: {
    width: 'auto',
    height: 'auto',
    maxWidth: '100%',
    maxHeight: '100%'
  },
  newWatermark: {
    display: 'block',
    width: 'auto',
    height: 'auto',

    position: 'absolute',
    margin: 'auto',

    maxWidth: '100%',
    maxHeight: '100%'
  },
  watermarkPlaceholder: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    zIndex: 9,
    backgroundColor: 'rgba(0, 0, 0, 0.85)'
  },
  watermarkPlaceholderIcon: {
    fontSize: '80px'
  },
  watermarkPlaceholderText: {
    fontWeight: 'bold'
  },
  sliderAction: {
    maxWidth: '35vw'
  }
}));

export default useStyles;
