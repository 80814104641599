import React, { useState, useEffect } from 'react';
import { Alert } from 'antd';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { notifyWarn, notifySuccess } from '../../../utils/notificationService';

import Api from '../../../services/api';
import { usePlan } from '../../../services/plan';

import { Creators as GalleryActions } from '../../../store/ducks/gallery';
import { FormSwitch, Portlet, SwitchContent } from '../../../styles/components';

import Label from '../../../components/Form/Label';
import Small from '../../../components/Form/Small';
import Switch from '../../../components/Form/Switch';
import SwitchGroup from '../../../components/Form/SwitchGroup';
import Divider from '../../../components/Divider';
import Loading from '../../../components/Loading';
import Pill from '../../../components/Pill';

const GalleryDownload = (props) => {
  const dispatch = useDispatch();
  const { checkSolution, triggerPlanChange } = usePlan();

  const { collection_id } = props.match.params;

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    single_download: null,
    download_all: null
  });

  const getData = async () => {
    const res = await Api.get(
      `/gallery/settings?collection_id=${collection_id}`
    );
    const { single_download, download_all } = res.data;

    setData({
      single_download,
      download_all
    });

    if (single_download === false && download_all === false) {
      dispatch(
        GalleryActions.setInfo({
          download_activated: false
        })
      );
    } else {
      dispatch(
        GalleryActions.setInfo({
          download_activated: true
        })
      );
    }

    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const set = async (key, value) => {
    if (key === 'download_all') {
      const isEnabled = checkSolution(
        'gallery_limits',
        'download_all_pictures'
      );

      if (!isEnabled) {
        return await triggerPlanChange('feat', 'download_all_pictures');
      }
    }

    const newData = {
      ...data,
      [key]: value
    };

    setData(newData);

    try {
      await Api.put(`/gallery/${collection_id}/settings`, { ...newData });

      if (newData.single_download === false && newData.download_all === false) {
        dispatch(
          GalleryActions.setInfo({
            download_activated: false
          })
        );
      } else {
        dispatch(
          GalleryActions.setInfo({
            download_activated: true
          })
        );
      }

      notifySuccess('As alterações foram salvas.');
    } catch (error) {
      notifyWarn('Não foi possível salvar as alterações.');
    }
  };

  return (
    <Loading loading={loading}>
      <Portlet>
        <Alert
          type="warning"
          showIcon
          message="Informações importantes"
          description="Para download serão disponibilizadas as fotos originais enviadas por você,
          sem nenhuma redução de tamanho, marca d’água ou carimbo de cópia não autorizada."
        />

        <Divider size="medium" />

        <FormSwitch>
          <SwitchGroup>
            <Label>Permitir download de fotos individuais</Label>
            <Switch
              checked={data.single_download}
              onChange={(v) => set('single_download', v)}
            />
          </SwitchGroup>
          <Small>
            O seu cliente poderá fazer o download das fotos individualmente.
          </Small>
        </FormSwitch>

        <Divider size="medium" />

        <FormSwitch>
          <SwitchGroup>
            <Label>Permitir download de todas as fotos</Label>
            <SwitchContent>
              <Switch
                checked={data.download_all}
                onChange={(v) => set('download_all', v)}
              />
              {!checkSolution('gallery_limits', 'download_all_pictures') && (
                <Pill />
              )}
            </SwitchContent>
          </SwitchGroup>
          <Small>
            O sistema irá gerar automaticamente um arquivo ZIP com todas as
            fotos e disponibilizar para o seu cliente fazer o download.
          </Small>
        </FormSwitch>
      </Portlet>
    </Loading>
  );
};

GalleryDownload.propTypes = {
  link_id: PropTypes.number.isRequired
};

export default GalleryDownload;
