import React, { useState } from 'react';
import { Row, Col } from 'antd';
import { useSelector } from 'react-redux';

import {
  postLoginRedirect,
  Query,
  activateSolution,
} from '../../services/solution';

import Divider from '../../components/Divider';
import Button from '../../components/Button';
import Small from '../../components/Form/Small';

import { ContainerTransparent } from '../../styles/components';
import {
  Content,
  Header,
  Body,
  Title,
  SolutionCard,
  SolutionName,
  SolutionInfo,
  ButtonWrapper,
  HeaderContainer,
  WelcomeText,
} from './styles';

import siteSolution from '../../assets/site_solution.png';
import gallerySolution from '../../assets/gallery_solution.png';

const WizardSolutionSwitch = () => {
  const [loadingSite, setLoadingSite] = useState(false);
  const [loadingGallery, setLoadingGallery] = useState(false);
  const customer = useSelector((state) => state.customer);

  const handleActivateProduct = async (productName) => {
    if (productName === 'site_pro') {
      setLoadingSite(true);
    } else if (productName === 'gallery_pro') {
      setLoadingGallery(true);
    }

    let query = '';

    if (customer.user_data.firstAccess === true && productName === 'site_pro') {
      query = Query.NEW_USER_SITE_PRO;
    }

    await activateSolution(productName);

    postLoginRedirect(productName, query);
  };

  return (
    <ContainerTransparent>
      <Content>
        <Header>
          <HeaderContainer>
            <WelcomeText>SEJA BEM VINDO, {(customer.user_data.display_name || '').toUpperCase()}</WelcomeText>
            <Title>Por qual solução deseja iniciar?</Title>
          </HeaderContainer>
        </Header>
        <Body>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <SolutionCard>
                <img src={siteSolution} alt="solução de sites" />

                <Divider size="small" />

                <SolutionName>Site Pro</SolutionName>

                <Divider size="large" />

                <SolutionInfo>
                  Portfólio
                  <br />
                  Depoimentos
                  <br />
                  Blog
                </SolutionInfo>

                <ButtonWrapper>
                  <Button
                    loading={loadingSite}
                    type="primary"
                    onClick={() => handleActivateProduct('site_pro')}
                  >
                    Criar meu site
                  </Button>

                  <Small>Utilizar nosso plano gratuito</Small>
                </ButtonWrapper>
              </SolutionCard>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <SolutionCard>
                <img src={gallerySolution} alt="solução de galerias" />

                <Divider size="small" />

                <SolutionName>Gallery Pro</SolutionName>

                <Divider size="large" />

                <Divider size="small" />

                <SolutionInfo>
                  Entrega de fotos em alta
                  <br />
                  Seleção de fotos
                  <br />
                  Aprovação de álbums
                  <br />
                  Venda de fotos
                </SolutionInfo>

                <ButtonWrapper>
                  <Button
                    loading={loadingGallery}
                    type="primary"
                    onClick={() => handleActivateProduct('gallery_pro')}
                  >
                    Criar galeria
                  </Button>

                  <Small>Testar por 14 dias</Small>
                </ButtonWrapper>
              </SolutionCard>
            </Col>
          </Row>
        </Body>
      </Content>
    </ContainerTransparent>
  );
};

export default WizardSolutionSwitch;
