import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  description: {
    lineHeight: '24px',
    color: '#888'
  },
  content: {
    order: (props) => (props.isSmall ? '1' : '0')
  },
  planPill: {
    order: (props) => (props.isSmall ? '0' : '1')
  },
  premiumChipContainer: {
    [theme.breakpoints.down('sm')]: {
      margin: '0 auto'
    }
  },
  premiumChipWrapper: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      marginTop: theme.spacing(1)
    }
  }
}));

export default useStyles;
