import React from 'react';
import PropTypes from 'prop-types';
import { Divider as AntDivider } from 'antd';

import { Container, PlanName, PlanType, Info } from './styles';

import { TitlePortlet} from '../../../../styles/components';

import Divider from '../../../../components/Divider';

const SitePortlet = (props) => {
	const {
		planName,
		planType,
	} = props;

	return (
		<Container>
			<Divider size="small" />

			<AntDivider dashed />

			<Divider size="small" />

			<TitlePortlet>
				<PlanName>{planName}</PlanName>
				{" "}
				<PlanType>({planType})</PlanType>
			</TitlePortlet>

			<Divider size="medium" />

			<Info>
				Quantidade máxima de Álbuns: <b>Ilimitado</b>
			</Info>

			<Divider size="medium" />

			<Info>
				Quantidade máxima de Fotos: <b>Ilimitado</b>
			</Info>

		</Container>
	)
};

SitePortlet.propTypes = {
	planName: PropTypes.string.isRequired,
	planType: PropTypes.string.isRequired,
}

export default SitePortlet;
