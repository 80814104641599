import styled from 'styled-components';

export const Container = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: ${(props) => (props.loading ? 'center' : '')};
`;

export const InactiveFollowup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const Portlet = styled.div`
	width: 100%;

	background-color: #FFF;
	border-radius: 5px;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.07);
`;

export const PortletHeader = styled.div`
	width: 100%;
	height: 100px;
	padding: 30px 24px;
	border: 0 5px 0 0;

	position: relative;
`;

export const PortletFooter = styled.div`
	width: 100%;
	padding: 30px 24px;
	border: 0 5px 0 0;

	position: relative;
`;

export const ClientInfo = styled.div`

`;

export const ClientName = styled.h1`
	font-size: 16px;
	font-weight: bold;
	line-height: 16px;
	margin-bottom: 4px;
`;

export const ClientContact = styled.span`
	font-size: 14px;
`;


export const Menu = styled.div`
	position: absolute;
	top: 20px;
	right: 20px;
`;

export const PortletBody = styled.div`
	width: 100%;
	height: 100%;
	padding: 30px 24px;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 20px;

	@media (max-width: 1023px) {
    grid-template-columns: repeat(1, 1fr) !important;
  }

	border-radius: 0 0 5px 0;
`;

export const Box = styled.div`
	padding: 5px 15px;
	border-left: 1px solid #C4C4C4;
`;

export const FormView = styled.div`
	display: flex;
	flex-direction: column;
`;

export const FormEdit = styled.div`
	display: flex;
	flex-direction: column;
`;

export const FormSwitch = styled.div`
	display: flex;
	flex-direction: column;
`;

export const ViewField1 = styled.div`
	font-size: 24px;
	line-height: 24px;
	font-weight: bold;
	color: #444;
	margin-right: 10px;
`;

export const ViewField2 = styled.div`
	font-size: 18px;
	line-height: 18px;
	font-weight: normal;
	color: #333;
`;

export const ViewExtraInfo = styled.span`
	font-size: 18px;
	color: #666;

	margin: auto auto 0 0;
`;

export const ViewExtraInfoLink = styled.a`
	font-size: 14px;
	color: #0077B5;

	margin: auto auto 0 0;
`;

export const EditField = styled.div`
	font-size: 18px;
	color: #333;
	line-height: 18px;
`;

export const EditGroup = styled.div`
	display: flex;
	align-items: center;
	align-content: space-between;

	i {
		color: rgba(0, 0, 0, 0.3);
		margin-left: 10px;
		font-size: 18px;
		cursor: pointer;
	}
`;

export const ViewGroup = styled.div`
	display: flex;
`;

export const Label1 = styled.span`
	font-size: 18px;
	line-height: 18px;
	color: #666;
	margin-bottom: 8px;
`;

export const Label2 = styled.span`
	font-size: 14px;
	line-height: 14px;
	color: #666;
	margin-bottom: 8px;
`;

export const ReportLabel = styled.span`
	font-size: 18px;
	color: #666;
	line-height: 18px;
	margin-bottom: 8px;
`;

export const MagicLinkContainer = styled.div`
	padding: 16px;
	background-color: #F9F9F9;
	
	border-radius: 5px;

	margin: 0 24px;

	a {
		color: #0077B5;
	}
`;

export const SelectionPreferences = styled.div`
	width: 100%;
	height: 100%;
`

export const AboutTitle = styled.h1`
	margin-bottom: 20px;

	font-size: 16px;
`

export const SelectionPreferencesBody = styled.div`
	border-radius: 5px;
	margin-bottom: 20px;
	padding: 24px;
	background-color: #FFF;
	border-radius: 0 0 5px 0;

	display: flex;
`

export const SelectionPreferencesForm = styled.div`
	display: grid;
	grid-template-columns: repeat(6, 1fr);
	grid-gap: 20px;

	@media (max-width: 1023px) {
    grid-template-columns: repeat(1, 1fr) !important;
  }
`;

export const SelectionPreferencesMenu = styled.div`
	margin: auto 0 auto auto;
`;

export const LinkShare = styled.div`
	background-color: #FFF;

	padding: 24px;

	margin-bottom: 40px;
`

export const LinkShareTitle = styled(ClientName)``

export const LinkShareSubTitle = styled(ClientInfo)``

export const LinkShareField = styled(MagicLinkContainer)`
	margin: 0;

	padding: 10px 16px;

	border-bottom-right-radius: 0;
	border-top-right-radius: 0;

	word-break: break-all;

	width: 100%;
`

export const LinkShareContainer = styled.div`
	display: flex;

	button {
		box-shadow: none;
		height: 100%;
	}
`

export const CreateLinkShareTitle = styled(ClientName)``;

export const CreateLinkShareSubTitle = styled(ClientInfo)``;

export const CreateLinkShareContainer = styled.div`
	display: flex;

	justify-content: space-between;
	align-items: center;

	width: 100%;

	flex-wrap: wrap;
`;

export const CreateLinkShareInfo = styled.div``;
