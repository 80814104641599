import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Icon, Menu as AntMenu, Dropdown, Tooltip } from 'antd';

import {
	Portlet,
	PortletHeader,
	PortletBody,
	ClientContact,
	ClientInfo,
	ClientName,
	FormView,
	FormEdit,
	FormSwitch,
	ViewExtraInfo,
	ViewExtraInfoLink,
	ViewField1,
	ViewField2,
	ViewGroup,
	EditField,
	EditGroup,
	Label1,
	Label2,
	Box,
  Menu,
  PortletFooter,
} from '../../styles';

import { usePlan } from '../../../../../../services/plan';

import Divider from '../../../../../../components/Divider';
import Switch from '../../../../../../components/Form/Switch';
import Button from '../../../../../../components/Button';
import EditDialog from '../../../../../../components/EditDialog';
import Pill from '../../../../../../components/Pill';
import PopConfirm from '../../../../../../components/PopConfirm';

import LinkShareBox from '../../LinkShareBox';

const ExpiredPortlet = (props) => {
	const {
		totalSelected,
		sellingPhotos,
		limitDate,
		lastAccess,
		contractedPictures,
		maxPictures,
		picturePrice,
		clientName,
		clientMail,
		clientTel,
		selectionId,
		summaryUrl,
		handleSellPictures,
		handleExportSelection,
    handleDeleteSelection,
    selOldSelectionLink,
    edit,
    handleMakeSelectionInactive,
    selLinkType,
    homeFPMagicLink,
    magicLinkEnabled,
    handleShare,
    clientFirstPassword,
    galleryShareUrl,
    handleToggleMagicLink,
  } = props;

  const magicLink = selOldSelectionLink || homeFPMagicLink;

  const { checkSolution } = usePlan();

  const handleOpenConfirmMakeSelectionInactiveModal = (selectionId) => {
    PopConfirm.open({
      onConfirm: () => handleMakeSelectionInactive(selectionId),
      info: `Desativar a seleção é uma ação irreversível. Você perderá todos os dados desta seleção. Tem certeza que deseja continuar?`,
      waitConfirmPromise: false,
      confirmText: 'SIM'
    })
  };

  const handleOpenConfirmDeleteSelectionModal = (selectionId) => {
    PopConfirm.open({
      onConfirm: () => handleDeleteSelection(selectionId),
      info: `Deletar a seleção é uma ação irreversível. Você perderá todos os dados desta seleção.`,
    })
  };

  const handleOpenConfirmSellPicturesModal = (selectionId, value) => {
    PopConfirm.open({
      onConfirm: () => handleSellPictures(selectionId, value),
      info: `Ao desativar a venda de fotos a quantidade e o valor das fotos serão zerados. Tem certeza que deseja continuar?`,
      waitConfirmPromise: false,
    })
  };

	const extraPicturesSelected = totalSelected - contractedPictures < 0 ? 0 : totalSelected - contractedPictures

	const menu = (
    <AntMenu>
      <AntMenu.Item key="0">
        <a href={summaryUrl} target="_blank">
				  Ver fotos selecionadas
        </a>
      </AntMenu.Item>
      <AntMenu.Item key="1" disabled>
        <Tooltip title="A exportação só pode ser realizada quando o seu cliente finalizar a seleção. Certifique-se de que ele tenha concluído o processo todo.">
          Exportar selecionadas
        </Tooltip>
      </AntMenu.Item>
      <AntMenu.Item key="2">
        <EditDialog
          name="Prazo limite"
          value={
            limitDate ? moment(new Date(limitDate).setHours(0, 0, 0, 0)) : null
          }
          onSave={(value) => edit('limit_date', value, selectionId)}
          inputName="limitDate"
          type="datepicker"
        >
          Alterar prazo limite
        </EditDialog>
      </AntMenu.Item>
      {
        selLinkType === "private" ? (
          <AntMenu.Item key="3"
            onClick={() => handleOpenConfirmMakeSelectionInactiveModal(selectionId)}
          >
            Desativar seleção
          </AntMenu.Item>
        ) : (
          <AntMenu.Item key="3"
          onClick={() => handleOpenConfirmDeleteSelectionModal(selectionId)}
          >
            Excluir seleção
          </AntMenu.Item>
        )
      }
    </AntMenu>
  );

	return (
    <Portlet style={{ borderLeft: '8px solid #FF0000' }}>
      <PortletHeader>
        <ClientInfo>
          <ClientName>{clientName}</ClientName>
          <ClientContact>
            {clientMail} {clientTel ? '| ' + clientTel : ''}
          </ClientContact>
        </ClientInfo>
        <Menu>
          <Dropdown overlay={menu} trigger={['click']}>
            <Icon type="menu" />
          </Dropdown>
        </Menu>
      </PortletHeader>

      <PortletBody>
        <Box>
          <FormView>
            <Label2>Último acesso</Label2>
            <ViewField2>
              {lastAccess ? moment(lastAccess).format('L') : 'Não acessou'}
            </ViewField2>
          </FormView>

          <Divider size="xlarge" />

          <FormEdit>
            <Label2>Prazo limite</Label2>
            <EditGroup>
              <EditDialog
                name="Prazo limite"
                value={limitDate ? moment(new Date(limitDate).setHours(0, 0, 0, 0)) : null}
                onSave={(value) => edit('limit_date', value, selectionId)}
                inputName="limitDate"
                type="datepicker"
              >
                <EditField>
                  {limitDate ? moment(limitDate).format('L') : 'Sem prazo'}
                </EditField>
                <Icon type="edit" />
              </EditDialog>
            </EditGroup>

            <Divider size="small" />

            <EditDialog
              name="Prazo limite"
              value={limitDate ? moment(limitDate) : null}
              onSave={(value) => edit('limit_date', value, selectionId)}
              inputName="limitDate"
              type="datepicker"
            >
              <ViewExtraInfoLink>Altere o prazo limite</ViewExtraInfoLink>
            </EditDialog>
          </FormEdit>
        </Box>

        <Box>
          <FormSwitch>
            {!checkSolution('gallery_limits', 'buy_photos') && <Pill style={{ marginLeft: 0, marginBottom: 8 }} />}
            <Label2>Venda de fotos</Label2>
            <Switch
              checked={sellingPhotos}
              onChange={(value) => {
                if (value === false) {
                  handleOpenConfirmSellPicturesModal(selectionId, value);
                } else {
                  handleSellPictures(selectionId, value);
                }
              }}
            />
          </FormSwitch>

          <Divider size="xlarge" />

          {sellingPhotos && (
            <>
              <FormEdit>
                <Label2>Quantidade contratada</Label2>
                <EditGroup>
                  <EditDialog
                    name="Quantidade contratada"
                    value={contractedPictures ? contractedPictures : 0}
                    onSave={(value) =>
                      edit('contracted_pictures', value, selectionId)
                    }
                    inputName="pictureAmount"
                  >
                    <EditField>
                      {contractedPictures ? contractedPictures : 0}
                    </EditField>
                    <Icon type="edit" />
                  </EditDialog>
                </EditGroup>
              </FormEdit>

              <Divider size="xlarge" />

              <FormEdit>
                <Label2>Valor de cada foto</Label2>
                <EditGroup>
                  <EditDialog
                    name="Valor de cada foto"
                    value={picturePrice ? picturePrice : 0}
                    onSave={(value) =>
                      edit('extra_picture_price', value, selectionId)
                    }
                    inputName="picturePrice"
                    type="bill"
                  >
                    <EditField>
                      {picturePrice
                        ? 'R$' +
                          (picturePrice / 100).toFixed(2).replace('.', ',')
                        : 'R$ 0,00'}
                    </EditField>
                    <Icon type="edit" />
                  </EditDialog>
                </EditGroup>
              </FormEdit>
            </>
          )}

          {!sellingPhotos && (
            <FormEdit>
              <Label2>Quantidade máxima de fotos</Label2>
              <EditGroup>
                <EditDialog
                  name="Quantidade máxima de fotos"
                  value={maxPictures ? maxPictures : 0}
                  onSave={(value) =>
                    edit('contracted_pictures', value, selectionId)
                  }
                  inputName="pictureAmount"
                >
                  <EditField>
                    {maxPictures ? maxPictures : 'Sem limite'}
                  </EditField>
                  <Icon type="edit" />
                </EditDialog>
              </EditGroup>
            </FormEdit>
          )}
        </Box>

        <Box>
          <FormView>
            <Label1>Total selecionadas</Label1>
            <ViewGroup>
              <ViewField1>{totalSelected}</ViewField1>
              <ViewExtraInfoLink
                href={summaryUrl} target="_blank"
              >
                (Ver fotos selecionadas)
              </ViewExtraInfoLink>
            </ViewGroup>
          </FormView>

          <Divider size="xlarge" />

          {sellingPhotos && (
            <>
              <FormView>
                <Label1>Total em vendas</Label1>
                <ViewGroup>
                  <ViewField1>
                    R${' '}
                    {((extraPicturesSelected * picturePrice) / 100)
                      .toFixed(2)
                      .replace('.', ',')}
                  </ViewField1>
                  <ViewExtraInfo>
                    ({extraPicturesSelected}{' '}
                    {extraPicturesSelected !== 1 ? 'fotos adicionais' : 'foto adicional'})
                  </ViewExtraInfo>
                </ViewGroup>
              </FormView>

              <Divider size="xlarge" />
            </>
          )}

          <Tooltip placement="topLeft" title="A exportação só pode ser realizada quando o seu cliente finalizar a seleção. Certifique-se de que ele tenha concluído o processo todo.">
            <Button disabled>
              EXPORTAR SELEÇÃO
            </Button>
          </Tooltip>
        </Box>
      </PortletBody>

      {
        selLinkType === 'private' && (
          <PortletFooter style={{ paddingTop: 0 }}>
            <LinkShareBox
              clientFirstPassword={clientFirstPassword}
              galleryShareUrl={galleryShareUrl}
              handleShare={handleShare}
              handleToggleMagicLink={handleToggleMagicLink}
              magicLink={magicLink}
              magicLinkEnabled={magicLinkEnabled}
              selectionId={selectionId}
            />
          </PortletFooter>
        )
      }
    </Portlet>
  );
};

ExpiredPortlet.propTypes = {
	totalSelected: PropTypes.number.isRequired,
	sellingPhotos: PropTypes.bool.isRequired,
	limitDate: PropTypes.string.isRequired,
	lastAccess:  PropTypes.string.isRequired,
	contractedPictures: PropTypes.number.isRequired,
	maxPictures: PropTypes.number.isRequired,
	picturePrice: PropTypes.number.isRequired,
	clientName: PropTypes.string.isRequired,
	clientMail: PropTypes.string.isRequired,
	clientTel: PropTypes.string.isRequired,
	extraPicturesSelected: PropTypes.number.isRequired,
	handleSellPictures: PropTypes.func.isRequired,
	handleExportSelection: PropTypes.func.isRequired,
	handleDeleteSelection: PropTypes.func.isRequired,
  selectionId: PropTypes.number.isRequired,
  selOldSelectionLink: PropTypes.string.isRequired,
  edit: PropTypes.func.isRequired,
  handleMakeSelectionInactive: PropTypes.string,
  selLinkType: PropTypes.string,
};

export default ExpiredPortlet
