import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Modal, Icon, Row, Col } from 'antd';
import smileIcon from '../../../../../../assets/icon/smile.png';
import {
  Container, Title, Subtitle, ButtonWrapper } from './styles';

import Button from '../../../../../Button';

const UploadSuccessModal = (props) => {
  const {
    succededList,
    openPhotos,
  } = props;

  const [visibility, setVisibility] = useState(true);

  const handleClick = (type) => {
    if (type === 'close') {
      setVisibility(false);
    } else if (type === 'photos') {
      openPhotos();
      setVisibility(false);
    }
  }

  return (
    <Modal
      visible={visibility}
      onOk={() => handleClick('close')}
      onCancel={() => handleClick('close')}
      footer={null}
      width={550}
    >
      <Container>
        <img src={smileIcon} alt="Smile Icon" />
        <Title>Maravilha!</Title>
        <Subtitle mobile={window.innerWidth < 600}>
          {succededList.length === 1
            ? 'As fotos subiram com sucesso!'
            : (<span>As <b>{succededList.length}</b> fotos subiram com sucesso!</span>)}
        </Subtitle>
        <ButtonWrapper>
          <Row type="flex" justify="space-around" style={window.innerWidth < 600 ? {padding: '18px 0', width: '90%'} : {padding: '52px 0', width: '90%'}}>
            <Col xs={24} sm={20} md={15} lg={15} xl={15} style={window.innerWidth < 600 ? {marginBottom: '18px', textAlign: 'center'} : {marginBottom: '0', textAlign: 'center'}}>
              <Button onClick={() => handleClick('photos')} type="primary" style={{ width: '100%' }}>FECHAR</Button>
            </Col>
          </Row>
        </ButtonWrapper>
      </Container>
    </Modal>
  )
};

UploadSuccessModal.open = (props) => {
  const {
    succededList,
    openPhotos,
  } = props;

  let uploadSuccessModalNode = document.getElementById("upload-success-modal");

  if (uploadSuccessModalNode) {
    ReactDOM.unmountComponentAtNode(uploadSuccessModalNode);
  } else {
    uploadSuccessModalNode = document.createElement("div");
    uploadSuccessModalNode.setAttribute("id", "upload-success-modal");
    document.body.appendChild(uploadSuccessModalNode);
  }

  ReactDOM.render(
    <UploadSuccessModal
      succededList={succededList}
      openPhotos={openPhotos}
    />,
    uploadSuccessModalNode
  );
}

UploadSuccessModal.propTypes = {
  succededList: PropTypes.array.isRequired,
  openPhotos: PropTypes.func.isRequired,
};

export default UploadSuccessModal;
