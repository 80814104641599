import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Modal, Icon, Row, Col } from 'antd';
import sadIcon from '../../../../../../assets/icon/sad.png';
import smileIcon from '../../../../../../assets/icon/smile.png';
import {
  Container,
  TableWrapper,
  TextUnderline,
  Left,
  Right,
  ModalButton,
  IconsWrapper,
  FirstIcon,
  SecondIcon
} from './styles';

import { getElementNode } from '../../../../../../services/elementNode';

const UploadWarningModal = (props) => {
  const { succededList, failedList, openSelection, openGallery } = props;

  const [visibility, setVisibility] = useState(true);

  const handleClick = (type) => {
    if (type === 'close') {
      setVisibility(false);
    } else if (type === 'selection') {
      openSelection();
      setVisibility(false);
    } else if (type === 'gallery') {
      openGallery();
      setVisibility(false);
    }
  };

  return (
    <Modal
      visible={visibility}
      onOk={() => handleClick('close')}
      onCancel={() => handleClick('close')}
      footer={null}
      width={950}
      bodyStyle={{ padding: '0' }}
    >
      <Container>
        <Row type="flex">
          <Col xs={24} sm={24} lg={8} xl={10}>
            <Left mobile={window.innerWidth < 980}>
              <h3>
                Ative facilmente vários recursos <br /> à sua galeria, deseja
                começar por qual opção?
              </h3>
              <ModalButton onClick={() => handleClick('selection')}>
                <h3>
                  <Icon type="select" />
                  Seleção de fotos
                </h3>
                <span>
                  Ative, configure e envie para o seu cliente realizar a seleção
                  das fotos
                </span>
              </ModalButton>
              <ModalButton onClick={() => handleClick('gallery')}>
                <h3>
                  <Icon type="download" />
                  Entrega de fotos em alta
                </h3>
                <span>
                  Configure e libere o download das fotos para o seu cliente
                </span>
              </ModalButton>
            </Left>
          </Col>
          <Col xs={24} sm={24} lg={16} xl={14}>
            <Right mobile={window.innerWidth < 980}>
              <h3>Detalhes do envio das suas fotos</h3>
              <IconsWrapper>
                <FirstIcon>
                  <img src={smileIcon} width={28} alt="Smile Icon" />
                  <span>
                    <b>{succededList.length}</b> fotos subiram com sucesso.
                  </span>
                </FirstIcon>
                <SecondIcon>
                  <img src={sadIcon} width={28} alt="Sad Icon" />
                  <span>
                    Não conseguimos completar o envio de{' '}
                    <b>{failedList.length}</b> fotos. Veja o relatório abaixo e
                    envie novamente.
                  </span>
                </SecondIcon>
              </IconsWrapper>
              <TextUnderline>
                <a
                  href="https://blog.picsize.com.br/erro-de-upload/"
                  target="_blank"
                >
                  Entenda os principais motivos que causam problemas ao subir
                  fotos.
                </a>
              </TextUnderline>

              <TableWrapper>
                <h2>Lista de fotos que não subiram</h2>
                <table>
                  <tbody>
                    {failedList.map((picture, index) => (
                      <tr>
                        <td>{index + 1}</td>
                        <td>{picture.title}</td>
                        <td>{picture.error}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </TableWrapper>
            </Right>
          </Col>
        </Row>
      </Container>
    </Modal>
  );
};

UploadWarningModal.open = (props) => {
  const { succededList, failedList, openSelection, openGallery } = props;

  const uploadWarningModalNode = getElementNode('upload-warning-modal');

  ReactDOM.render(
    <UploadWarningModal
      succededList={succededList}
      failedList={failedList}
      openSelection={openSelection}
      openGallery={openGallery}
    />,
    uploadWarningModalNode
  );
};

UploadWarningModal.propTypes = {
  succededList: PropTypes.array.isRequired,
  failedList: PropTypes.array.isRequired,
  openSelection: PropTypes.func.isRequired,
  openGallery: PropTypes.func.isRequired
};

export default UploadWarningModal;
