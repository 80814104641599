import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb as AntBreadcrumb } from 'antd';

import { Container } from './styles';

const Breadcrumb = (props) => {
  const { pathname, galleryInfo, portfolioOpts } = props;
  const { title, collection_id } = galleryInfo;

  const breadcrumbNameMap = {
    '/account': 'Minha Conta',
    '/financial': 'Financeiro',
    '/settings': 'Configurações',
    '/settings/profile': 'Perfil',
    '/settings/watermark': "Marca D'água",
    '/settings/subdomain': 'Subdomínio',
    '/settings/notifications': 'Notificações',
    '/albuns': 'Prova de Álbuns',
    '/gallery': 'Minhas Galerias',
    '/clients': 'Meus clientes',
    [`/gallery/${collection_id}`]: title,
    [`/gallery/${collection_id}/pictures`]: 'Fotos',
    [`/gallery/${collection_id}/pictures/completed`]: 'Resumo do Envio',
    [`/gallery/${collection_id}/upload`]: 'Subir Fotos',
    [`/gallery/${collection_id}/uploading`]: 'Subindo Fotos',
    [`/gallery/${collection_id}/download`]: 'Entrega em Alta',
    [`/gallery/${collection_id}/share`]: 'Compartilhar',
    [`/gallery/${collection_id}/settings`]: 'Configurações',
    [`/gallery/${collection_id}/selection`]: 'Seleção de Fotos',
    [`/gallery/${collection_id}/selection/preferences`]: 'Preferências',
    [`/gallery/${collection_id}/selection/share`]: 'Envio',
    [`/gallery/${collection_id}/selection/followup`]: 'Acompanhamento',
    '/portfolio': 'Meu Portfólio',
    '/portfolio/works': 'Meus Trabalhos',
    [`/portfolio/works/${portfolioOpts.work.work_id}`]: portfolioOpts.work
      .title,
    [`/portfolio/works/${portfolioOpts.work.work_id}/pictures`]: 'Fotos',
    [`/portfolio/works/${portfolioOpts.work.work_id}/settings`]: 'Configurações'
  };

  const pathSnippets = pathname.split('/').filter((i) => i);
  const extraBreadcrumbItems = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;

    // In case the specified path exists, it is added to array
    // of breadcrumbs

    // If breadcrumb url is settings user cannot click on it as a link
    // & if url is portfolio/works user is redirected to /portfolio
    if (url === '/settings') {
      return (
        <AntBreadcrumb.Item key={url}>
          <span>{breadcrumbNameMap[url]}</span>
        </AntBreadcrumb.Item>
      );
    }
    if (url === '/portfolio/works') {
      return (
        <AntBreadcrumb.Item key={url}>
          <Link to="/portfolio">{breadcrumbNameMap[url]}</Link>
        </AntBreadcrumb.Item>
      );
    }
    if (breadcrumbNameMap[url]) {
      return (
        <AntBreadcrumb.Item key={url}>
          {// In case it is the last item we change its font-weight
          index === pathSnippets.length - 1 ? (
            <b>{breadcrumbNameMap[url]}</b>
          ) : (
            <Link to={url}>{breadcrumbNameMap[url]}</Link>
          )}
        </AntBreadcrumb.Item>
      );
    }
    return <AntBreadcrumb.Item>...</AntBreadcrumb.Item>;
  });

  return (
    <Container>
      <AntBreadcrumb>{extraBreadcrumbItems}</AntBreadcrumb>
    </Container>
  );
};

export default Breadcrumb;
