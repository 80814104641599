import React from 'react';

import { Content } from './styles';

import Divider from '../../components/Divider';

import subscriptionImg from '../../assets/subscription-block.jpg';

const MissingPlan = () => {
  return (
    <Content>
      <Divider size="medium" />
      <img src={subscriptionImg} alt="é necessario ser usuario premium" />
      <Divider size="medium" />
      <p>
				Parece que não encontramos nenhum plano vinculado com a sua conta, <br /> entre em contanto com o nosso <a href="https://www.picsize.com.br/contato" target="_blank">suporte</a> para maiores informações.
      </p>
      <Divider size="medium" />
    </Content>
  );
};

export default MissingPlan;
