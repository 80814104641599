import styled from 'styled-components';

export const BreadcrumbWrapper = styled.div`
  width: 100%;
  margin-top: 32px;
  padding-left: 18px;

  span{
    font-weight: normal;
    font-size: 24px;
    line-height: 33px;
    color: #323232;
  }
`;

export const RoutesWrapper = styled.div`
  display: inline-block;
  padding: 16px 16px 100px;
  height: 100%;
`;

export const SubHeaderContainer = styled.div`
  display: flex;
  width: 100%;
  height: auto;

  justify-items: center;
`;

export const PortfolioLink = styled.span`
  i {
    margin-right: 8px;
  }

  a {
    color: #0077B5 !important;

    &:hover {
      color: #40a9ff !important;
    }
  }
`;

export const PortfolioLinkContainer = styled.div`
  height: ${({ isMobile }) => isMobile ? '30px' : '54px'};
  padding-right: ${({ isMobile }) => !isMobile && '16px'};
  padding-left: ${({ isMobile }) => isMobile && '16px'};

  display: flex;

  justify-content: ${({ isMobile }) => isMobile ? 'flex-start' : 'flex-end'};
  align-items: center;
`;