import React, { useState, useEffect } from 'react';
import {
  Button,
  Container,
  Grid,
  useTheme,
  useMediaQuery
} from '@material-ui/core';
import { AddOutlined as AddIcon } from '@material-ui/icons';

import CardOptionWaterMark from '../components/CardOptionWaterMark';
import api from '../../../services/api';

import Dialog from '../../Dialog';
import EditWaterMark from '../Edit';
import Loading from '../../Loading';

import { convertObjectURLInFormData } from '../../../utils/image';

import {
  notifySuccess,
  notifyWarn,
  notifyError
} from '../../../utils/notificationService';

import { usePlan } from '../../../services/plan';

import useStyles from './styles';
import { getStatusCode } from '../../../utils/response';
import Divider from '../../Divider';
import PopConfirm from '../../PopConfirm';

const SelectWaterMark = (props) => {
  const { collectionId, onError } = props;

  const [watermarks, setWatermarks] = useState([]);
  const [loadingWatermarks, setLoadingWatermarks] = useState(true);

  const [createWatermarkId, setCreateWatermarkId] = useState(null);

  const [newWatermark, setNewWatermark] = useState({});

  const [loadingCreateWatermark, setLoadingCreateWatermark] = useState(false);

  const [selectWaterMark, setSelectWaterMark] = useState('');
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);

  const { triggerPlanChange } = usePlan();

  const MDTheme = useTheme();
  const isLarger = useMediaQuery(MDTheme.breakpoints.up('lg'));

  const classes = useStyles({ loadingCreateWatermark, isLarger });

  const handleUploadDialogOpen = () => {
    setNewWatermark({});
    setUploadDialogOpen(true);
  };

  const handleUploadDialogClose = async () => {
    setNewWatermark({});
    setUploadDialogOpen(false);
  };

  const getWatermark = async () => {
    setLoadingWatermarks(true);

    try {
      const { data } = await api.get(
        `/gallery/watermark?collection_id=${collectionId}`
      );

      const { watermarks, selected_watermark } = data;

      setWatermarks(watermarks);
      setSelectWaterMark(selected_watermark);
    } catch (error) {
      notifyError("Erro ao carregar a marca d'água");
    }

    setLoadingWatermarks(false);
  };

  const changeWaterMark = async (waterMarkId) => {
    notifyWarn('Carregando...');
    try {
      if (collectionId) {
        await api.put('/gallery/watermark/collection', {
          watermark_id: waterMarkId,
          collection_id: collectionId
        });
      } else {
        await api.put('/gallery/watermark/default', {
          watermark_id: waterMarkId
        });
      }

      setSelectWaterMark(waterMarkId);

      notifySuccess("Marca d'água alterada!");
    } catch (error) {
      if (getStatusCode(error) === 402) {
        setLoadingCreateWatermark(false);
        handleUploadDialogClose();
        onError && onError();
        return await triggerPlanChange('feat', 'custom_watermark');
      }
      notifyError("Erro ao alterar marca d'água");
    }
  };

  const createWatermark = async () => {
    if (!loadingCreateWatermark) {
      setLoadingCreateWatermark(true);

      const nonEditedWatermark = watermarks.find(
        (watermark) => !watermark.was_edited
      );

      let watermarkId;

      if (nonEditedWatermark) {
        watermarkId = nonEditedWatermark.id;
      } else {
        try {
          const { data } = await api.post('/gallery/watermark/logo');

          watermarkId = data.watermark.id;
        } catch (error) {
          if (getStatusCode(error) === 402) {
            handleUploadDialogClose();
            onError && onError();
            const { data: data_error } = error.response;
            return await triggerPlanChange('feat', 'custom_watermark');
          }
        }
      }

      setCreateWatermarkId(watermarkId);

      setLoadingCreateWatermark(false);
      handleUploadDialogOpen();
    }
  };

  const handleUploadSave = async () => {
    const promises = [];
    try {
      const formData = await convertObjectURLInFormData(newWatermark.media);

      formData.append('watermark', formData);

      promises.push(
        api.put(`/gallery/watermark/picture/${createWatermarkId}`, formData, {
          headers: {
            'Content-Type': 'multipart/data'
          }
        })
      );

      promises.push(
        api.put(`/gallery/watermark/${createWatermarkId}`, {
          ...newWatermark.watermarkData
        })
      );

      await Promise.all(promises);

      await changeWaterMark(createWatermarkId);

      await getWatermark().then(async () => {
        handleUploadDialogClose();
      });

      notifySuccess("Marca d'água adicionada!");
    } catch (error) {
      if (getStatusCode(error) === 402) {
        handleUploadDialogClose();
        onError && onError();
        return await triggerPlanChange('feat', 'custom_watermark');
      }
      return notifyError("Erro ao adicionar marca d'água");
    }
  };

  const changeNewWatermark = (newWatermarkData) => {
    const {
      position_image,
      size_image,
      margin_image
    } = newWatermarkData.watermarkData.settings;

    const transformSizeInPercentage = size_image && size_image / 100;

    setNewWatermark({
      ...newWatermarkData,
      watermarkData: {
        settings: {
          position_image: position_image,
          size_image: transformSizeInPercentage,
          margin_image: margin_image
        }
      }
    });
  };

  const handleOpenDeleteDialog = () => {
    PopConfirm.open({
      title: "Não é possivel excluir a marca d'água",
      cancelText: 'OK!',
      cancelIcon: 'check',
      info:
        "Essa marca d'água está sendo utilizada em uma ou mais galerias, por isso ela não poderá ser excluída."
    });
  };

  const deleteWatermark = async (watermarkId) => {
    setLoadingWatermarks(true);
    try {
      await api.delete(`/gallery/watermark/${watermarkId}`);

      await getWatermark();

      notifySuccess("Marca d'água deletada com sucesso!");
    } catch (error) {
      if (getStatusCode(error) === 406) {
        handleOpenDeleteDialog();
      } else {
        notifyError("Erro ao deletar a marca d'água");
      }
    }

    setLoadingWatermarks(false);
  };

  useEffect(() => {
    getWatermark();
  }, []);

  return (
    <Loading loading={loadingWatermarks}>
      <Container maxWidth={false} className={classes.container}>
        <Button
          onClick={createWatermark}
          startIcon={<AddIcon />}
          color="primary"
          variant="contained"
          className={classes.addButton}
        >
          ADICIONAR MARCA D’ÁGUA
        </Button>

        <Divider size="medium" />

        <Grid container alignItems="center" spacing={2}>
          <Grid
            item
            xs={12}
            sm={4}
            md={3}
            lg={3}
            className={classes.cardWatermark}
          >
            <Grid container justify="center">
              <CardOptionWaterMark
                isChecked={!selectWaterMark}
                radioValue={null}
                onChange={changeWaterMark}
              />
            </Grid>
          </Grid>

          {!!watermarks.length &&
            watermarks
              .filter((watermark) => watermark.was_edited)
              .map((watermark, index) => (
                <Grid
                  item
                  key={index}
                  xs={12}
                  sm={4}
                  md={3}
                  lg={3}
                  className={classes.cardWatermark}
                >
                  <Grid container justify="center">
                    <CardOptionWaterMark
                      isChecked={selectWaterMark === watermark.id}
                      radioValue={watermark.id}
                      onChange={changeWaterMark}
                      onDelete={deleteWatermark}
                      waterMark={watermark.media.media_url}
                      watermarkSettings={watermark.settings}
                    />
                  </Grid>
                </Grid>
              ))}
        </Grid>
      </Container>
      <Dialog
        isOpen={uploadDialogOpen}
        onClose={handleUploadDialogClose}
        onSave={handleUploadSave}
        buttonSaveTitle="ATIVAR MARCA D’AGUA"
        fullWidth
        maxWidth="lg"
      >
        <EditWaterMark onChange={changeNewWatermark} />
      </Dialog>
    </Loading>
  );
};

export default SelectWaterMark;
