import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { Icon, Avatar } from 'antd';

import {
  Menu,
  SearchContainer,
  Search,
  MenuContainer,
  MenuItem,
  NoClientFound,
  NewClientContainer,
  NewClientTitle,
} from './styles';

import {
  SelectFieldContainer,
  ClientName,
  ClientInfo,
} from '../styles';

const ClientList = (props) => {
  const {
    clients,
    handleSelectClient,
    handleOpenCreateClientModal,
    visible,
  } = props;

  const [filteredClients, setFilteredClients] = useState(clients);

  const MemoizedMenuContainer = useMemo(() => (
    <MenuContainer>
      {filteredClients.length ? (
        filteredClients.map((client) => (
          <MenuItem key={client.id} onClick={() => handleSelectClient(client)}>
            <Avatar style={{ backgroundColor: '#39C395' }}>
              {client.name
                .split(' ')
                .slice(0, 2)
                .map((name) => name[0])
                .join('')}
            </Avatar>
            <SelectFieldContainer>
              <ClientName>{client.name}</ClientName>
              <ClientInfo>
                {/* {`<${client.email}>`} {client.tel ? ` <${client.tel}>` : ''} */}
                {client.email} {client.tel}
              </ClientInfo>
            </SelectFieldContainer>
          </MenuItem>
        ))
      ) : (
        <NoClientFound>Nenhum cliente encontrado...</NoClientFound>
      )}
    </MenuContainer>
  ), [filteredClients]);

  const searchInputRef = useRef();

  const handleSearch = (value) => {
    const filtered = clients.filter((client) => (
      ((client && client.name) || '').toLowerCase().includes(value.toLowerCase())
      || ((client && client.email) || '').toLowerCase().includes(value.toLowerCase())
      || ((client && client.tel) || '').replace(/(\D)/g, '').includes(value)
    ));

    setFilteredClients((lastState) => {
      if (lastState.length !== filtered.length) {
        return filtered;
      }

      return lastState;
    });
  };

  const focusOnSearchInput = () => {
    if (searchInputRef) {
      searchInputRef.current.focus();
    }
  };

  // When clients change from outside, make a new render with de given filter
  useEffect(() => {
    setFilteredClients(clients);
  }, [clients]);

  useEffect(() => {
    focusOnSearchInput();
  }, [visible]);

  return (
    <Menu>
      <SearchContainer>
        <Search
          size="large"
          placeholder="Procurar cliente"
          allowClear
          onChange={({ target }) => handleSearch(target.value)}
          ref={searchInputRef}
        />
      </SearchContainer>

      {MemoizedMenuContainer}

      <NewClientContainer onClick={handleOpenCreateClientModal}>
        <Icon type="plus" />
        <NewClientTitle>NOVO CLIENTE</NewClientTitle>
      </NewClientContainer>
    </Menu>
  );
};

ClientList.propTypes = {
  clients: PropTypes.arrayOf([]).isRequired,
  handleSelectClient: PropTypes.func.isRequired,
  handleOpenCreateClientModal: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
};

export default ClientList;
