import styled from 'styled-components';

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;

	& > div {
		margin: 0;
	}
	
	img {
		width: 100%;
		max-width: 200px;
		height: auto;
	}
`;