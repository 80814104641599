import React, { useRef } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faPhoneAlt, faEllipsisV, faVideo, faUser, faWifi, faBatteryThreeQuarters, faTimes } from '@fortawesome/free-solid-svg-icons';
import { faBellSlash } from '@fortawesome/free-regular-svg-icons';
import { faBluetoothB } from '@fortawesome/free-brands-svg-icons';

import whatsappBackground from '../../../../assets/whatsapp_background.png';

import {
  PreviewWrapper,
  PreviewDevice,
  PreviewDeviceContent,
  PreviewDeviceStatusBar as StatusBar,
  PreviewDeviceChat as Chat,
  PreviewDeviceChatBar as ChatBar,
  PreviewDeviceChatBarAvatar as ChatBarAvatar,
  PreviewDeviceChatBarName as ChatBarName,
  PreviewDeviceChatBarActions as ChatBarActions,
  PreviewDeviceChatContent as ChatContent,
  PreviewDeviceChatContentMessage as ChatContentMessage,
  PreviewDeviceChatActions as ChatActions,
  PreviewDeviceChatActionsInput as ChatActionsInput,
  PreviewDeviceChatActionsPreviewGallery as PreviewGallery,
} from './styles';

const WhatsAppPreview = (props) => {
	const {
		message,
		galleryUrl,
    customerName,
    galleryCover,
    galleryTitle,
    privateSelection,
    clientPassword,
    clientEmail,
    magicLinkEnabled,
    magicLink,
	} = props;

  return (
    <PreviewWrapper>
      <PreviewDevice>
        <PreviewDeviceContent>
          <StatusBar>
            <FontAwesomeIcon icon={faBluetoothB} />
            <FontAwesomeIcon icon={faBellSlash} />
            <FontAwesomeIcon icon={faWifi} />
            <FontAwesomeIcon icon={faBatteryThreeQuarters} />
            <div>{new Date().getHours() + ':' + new Date().getMinutes()}</div>
          </StatusBar>
          <Chat style={{ background: `url(${whatsappBackground})`}}>
            <ChatBar>
              <FontAwesomeIcon icon={faArrowLeft} />
              <ChatBarAvatar>
                <FontAwesomeIcon icon={faUser} />
              </ChatBarAvatar>
              <ChatBarName>
                <span>
                  {customerName}
                </span>
                <span>online</span>
              </ChatBarName>
              <ChatBarActions>
                <FontAwesomeIcon icon={faVideo} />
                <FontAwesomeIcon icon={faPhoneAlt} />
                <FontAwesomeIcon icon={faEllipsisV} />
              </ChatBarActions>
            </ChatBar>
            <ChatContent>
              <ChatContentMessage>
              <PreviewGallery>
                {galleryCover ? <img src={galleryCover} /> : null}
                <div>
                  <span>{galleryTitle}</span>
                  <span>{(galleryUrl || "").replace("https://", "").split("/").shift()}</span>
                </div>
              </PreviewGallery>
                <div style={{ wordBreak: 'break-all', whiteSpace: 'pre-line'}}>
                  {message}
                </div>
                {privateSelection ? (
                  <>
                    <br />
                    DADOS DE ACESSO:
                    {magicLinkEnabled ? (
                      <p style={{ wordBreak: "break-all" }}>
                        <br />
                        <b>Link:</b>
                        <br />
                        <b style={{ color: "#0093e8", fontWeight: "lighter" }}>{magicLink}</b>
                      </p>
                    ) : (
                      <p style={{ wordBreak: "break-all" }}>
                        <br />
                        <b>Link:</b>
                        <br />
                        <b style={{ color: "#0093e8", fontWeight: "lighter" }}>{galleryUrl}</b>
                        <br />
                        <br />
                        <b>Email:</b>
                        <br />
                        <b style={{ color: "#0093e8", fontWeight: "lighter" }}>{clientEmail}</b>
                        <br />
                        <br />
                        <b>Senha:</b>
                        <br />
                        {clientPassword}
                      </p>
                    )}
                  </>
                ): (
                  <>
                    Link para acesso:
                    <div style={{ width: "100%", color: "#0093e8" }}>{galleryUrl}</div>
                  </>
                )}

                <div>
                  <span>
                    {moment(new Date).format('LT') + ' AM'}
                  </span>
                </div>
              </ChatContentMessage>
            </ChatContent>
          </Chat>
        </PreviewDeviceContent>
      </PreviewDevice>
    </PreviewWrapper>
  );
};

WhatsAppPreview.defaultProps = {
	whatsapp: "",
	message: "",
  galleryUrl: "",
  galleryCover: "",
  galleryTitle: "",
  customerName: "",
  privateSelection: "",
  clientPassword: "",
  clientEmail: "",
  magicLinkEnabled: false,
  magicLink: '',
}

WhatsAppPreview.propTypes = {
	whatsapp: PropTypes.string,
	message: PropTypes.string,
  galleryUrl: PropTypes.string,
  galleryCover: PropTypes.string,
  galleryTitle: PropTypes.string,
  customerName: PropTypes.string,
  privateSelection: PropTypes.bool,
  clientPassword: PropTypes.string,
  clientEmail: PropTypes.string,
  magicLinkEnabled: PropTypes.bool,
  magicLink: PropTypes.string,
}

export default WhatsAppPreview;
