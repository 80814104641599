import { isMobile } from '../utils/device';

// eslint-disable-next-line
export const financesContactInfo = () => {
  const phoneNumber = '(31) 9129-5545';
  const whatsappLink = `https://${isMobile() ? 'api' : 'web'}.whatsapp.com/send?phone=553191295545`;

  return {
    phoneNumber,
    whatsappLink,
  };
};

export const supportContactInfo = () => {
  const phoneNumber = '(31) 9318-7817';
  const whatsappLink = `https://${isMobile() ? 'api' : 'web'}.whatsapp.com/send?phone=553193187817`;

  return {
    whatsappLink,
    phoneNumber,
  };
};
