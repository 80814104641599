import React from 'react';
import { useSelector } from 'react-redux';
import { triggerGoogleAnalyticsPageView } from '../services/google';
import { handlePageRedirects } from '../services/navigation';

const Main = (props) => {
	const userSubscription = useSelector((state) => state.customer.user_subscription)
	const userData = useSelector((state) => state.customer.user_data)

	// Runs when redux is updated or page suddenly finished loading
	handlePageRedirects({
		changePath: props.history.push,
		currentPathName: props.location.pathname,
		userSubscription,
		userData
	})

	props.history.listen(({ pathname }) => {
		// Runs on every page change
		handlePageRedirects({
			changePath: props.history.push,
			currentPathName: pathname,
			userSubscription,
			userData
		})
		triggerGoogleAnalyticsPageView(pathname);
		window.scrollTo(0, 0);
	})

	return <></>
}

export default Main