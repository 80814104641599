import styled from 'styled-components';

export const Content = styled.div`
	max-width: 800px;
	width: 100%;
	max-height: 450px;
	height: 100%;

	display: flex;
	flex-direction: column;

	align-items: center;

	background-color: #FFF;
	border-radius: 5px;

	text-align: center;

	& > div {
		margin: 0;
	}
`;

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-items: center;
	align-items: center;

	width: 100%;
	height: 100%;
`;