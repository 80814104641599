import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Radio, Tooltip, Icon } from 'antd';

import {
	MiniTitle,
	CreditCardBrandsContainer,
	CreditCardBrand,
	PaymentSlip,
	Footer,
	PaymentDetailsContainer,
} from './styles';

import { CardContainer, CardTitle, CardHeader, CardBody } from '../styles';

import Divider from '../../../../components/Divider';
import Input from '../../../../components/Form/Input';
import FormItem from '../../../../components/Form/FormItem';
import Button from '../../../../components/Button';
import SubmitContainer from '../../../../components/Form/SubmitContainer';

import { FormInput } from '../../../../styles/components';

import creditCardMasterCard from '../../../../assets/icon/credit_card_mastercard.png';
import creditCardVisa from '../../../../assets/icon/credit_card_visa.png';
import creditCardElo from '../../../../assets/icon/credit_card_elo.png';
import creditCardDinersClub from '../../../../assets/icon/credit_card_dinnersclub.png';
import creditCardHiperCard from '../../../../assets/icon/credit_card_hipercard.png';
import paymentSlip from '../../../../assets/icon/payment_slip.png';

const defaultCreditCardData = {
	creditCardOwnerName: '',
	creditCardNumber: '',
	creditCardExpirationDate: '',
	creditCardSecureCode: '',
}

const PaymentInfo = (props) => {
	const {
		onSubmit,
		paymentSlipNotAllowed,
		validation,
		clearValidation,
		clearAllValidations,
		paymentSlipNotAllowedMessage,
	} = props;

	const [loadingSubmit, setLoadingSubmit] = useState(false);
	const [paymentMethod, setPaymentMethod] = useState('credit-card');
	const [creditCardBrand, setCreditCardBrand] = useState('');
	const [isValidatingCreditCardBrand, setIsValidatingCreditCardBrand] = useState(false);
	const [creditCardData, setCreditCardData] = useState(defaultCreditCardData)

  const creditCardExpirationDateMask = rawInput => {
    if (rawInput) {
			/**
			 * In case the expiration date is in the format xx/xxxx we turn into xx/xx
			 * Ex: 01/2026 turns into 01/26
			 */
			if (rawInput.split("/").length === 2 && rawInput.split("/")[1].length === 4) {
				rawInput = `${rawInput.split('/')[0]}/${rawInput.split("/")[1].slice(2, 4)}`
			}

      return rawInput
      .replace(/(\D)/g, '') 
      .replace(/(\d{1,2})(\d{2,4})/, '$1/$2')
      .replace(/(\d{2})\d+?$/, '$1' )
    }
  }

	const handleChangePaymentMethod = ({ target }) => {
		const { value: paymentMethod } = target;

		setPaymentMethod(paymentMethod);
		setCreditCardData(defaultCreditCardData);
		clearAllValidations();
	}

	const recognizeBrand = (value) => {
		const brandValidation = (number) => value.toString().startsWith(number.toString())

		const validators = {
			visa: [4],
			masterCard: [51, 52, 53, 54, 55],
			dinnersClub: [301, 305, 36, 38],
			elo: [
				636368, 438935, 504175, 451416, 509048, 509067,
				509049, 509069, 509050, 509074, 509068, 509040,
				509045, 509051, 509046, 509066, 509047, 509042,
				509052, 509043, 509064, 509040, 36297, 5067,
				4576, 4011, 6011, 622, 64, 65
			],
			hiperCard: [38, 60],
		}

		if (value) {
			const isVisa = validators.visa.some(brandValidation);
			const isMasterCard = validators.masterCard.some(brandValidation);
			const isDinnersClub = validators.dinnersClub.some(brandValidation);
			const isElo = validators.elo.some(brandValidation);
			const isHiperCard = validators.hiperCard.some(brandValidation);

			if (isVisa) {
				setCreditCardBrand('visa');
				setIsValidatingCreditCardBrand(true);
			} else if (isMasterCard) {
				setCreditCardBrand('mastercard');
				setIsValidatingCreditCardBrand(true);
			} else if (isDinnersClub) {
				setCreditCardBrand('diners');
				setIsValidatingCreditCardBrand(true);
			} else if (isElo) {
				setCreditCardBrand('elo');
				setIsValidatingCreditCardBrand(true);
			} else if (isHiperCard) {
				setCreditCardBrand('hipercard');
				setIsValidatingCreditCardBrand(true);
			}
		} else {
				setIsValidatingCreditCardBrand(false);
		}
	}

	const handleChange = (key, value) => {
		if (key === 'creditCardExpirationDate') {
			value = creditCardExpirationDateMask(value);
		}

		if (key === 'creditCardOwnerName') {
			value = value.toUpperCase();
		}

		setCreditCardData({
			...creditCardData,
			[key]: value,
		});

		if (key === 'creditCardNumber') {
			recognizeBrand(value);
		}

		clearValidation(key);
	}

	const handleSubmit = async () => {
		setLoadingSubmit(true);
		
		await onSubmit({ ...creditCardData, paymentMethod, creditCardBrand });

		setLoadingSubmit(false);
	}

	const onPaymentSlipNotAllowedChange = () => {
		if (paymentSlipNotAllowed === true) {
			setPaymentMethod('credit-card');
		}
	}

	useEffect(() => {
		onPaymentSlipNotAllowedChange();
	}, [paymentSlipNotAllowed])

	return (
		<CardContainer>
			<SubmitContainer onSubmit={handleSubmit}>
				<CardHeader>
					<CardTitle>
						Informações do pagamento
					</CardTitle>
				</CardHeader>

				<Divider size="small" />

				<CardBody>
					<MiniTitle>
						FORMA DE PAGAMENTO
					</MiniTitle>

					<Divider size="medium" />

					<Row gutter={[24, 24]}>
						<Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
							<Radio onChange={handleChangePaymentMethod} checked={paymentMethod === 'credit-card'} value="credit-card">
								Cartão de crédito
							</Radio>

							<Divider size="medium" />

							<PaymentDetailsContainer isEnabled={paymentMethod === 'credit-card'}>
								<CreditCardBrandsContainer>
									<CreditCardBrand
										isSelected={creditCardBrand === 'mastercard'}
										isValidating={isValidatingCreditCardBrand}
										src={creditCardMasterCard}
									/>
									<CreditCardBrand
										isSelected={creditCardBrand === 'visa'}
										isValidating={isValidatingCreditCardBrand}
										src={creditCardVisa}
									/>
									<CreditCardBrand
										isSelected={creditCardBrand === 'elo'}
										src={creditCardElo}
										isValidating={isValidatingCreditCardBrand}
									/>
									<CreditCardBrand
										isSelected={creditCardBrand === 'hipercard'}
										src={creditCardHiperCard}
										isValidating={isValidatingCreditCardBrand}
									/>
									<CreditCardBrand
										isSelected={creditCardBrand === 'diners'}
										src={creditCardDinersClub}
										isValidating={isValidatingCreditCardBrand}
									/>
								</CreditCardBrandsContainer>

								<Divider size="xlarge" />

								<Row gutter={[16, 16]}>
									<Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={14}>
										<MiniTitle>
											NÚMERO DO<br />CARTÃO
										</MiniTitle>

										<FormInput>
											<FormItem
												validateStatus={validation.creditCardNumber.validateStatus}
												help={validation.creditCardNumber.help}
											>
												<Input
													prefix={<Icon type="lock" theme="filled" style={{ color: 'rgba(0, 0, 0, 0.5)' }} />}
													type="digits"
													onChange={({ target }) => handleChange('creditCardNumber', target.value)}
													onPaste={({ target }) => setTimeout(handleChange('creditCardNumber', target.value), 0)}
													value={creditCardData.creditCardNumber}
													autocomplete="cc-number"
												/>
											</FormItem>
										</FormInput>
									</Col>
									<Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={5}>
										<MiniTitle>
											VALIDADE DO<br />CARTÃO
										</MiniTitle>

										<FormInput>
											<FormItem
												validateStatus={validation.creditCardExpirationDate.validateStatus}
												help={validation.creditCardExpirationDate.help}
											>
												<Input
													placeholder="xx/xx"
													onChange={({ target }) => handleChange('creditCardExpirationDate', target.value) }
													onPaste={({ target }) => setTimeout(handleChange('creditCardExpirationDate', target.value), 0)}
													value={creditCardData.creditCardExpirationDate}
													autocomplete="cc-exp"
												/>
											</FormItem>
										</FormInput>
									</Col>
									<Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={5}>
										<MiniTitle>
											CÓDIGO DE<br />SEGURANÇA
										</MiniTitle>

										<FormInput>
											<FormItem
												validateStatus={validation.creditCardSecureCode.validateStatus}
												help={validation.creditCardSecureCode.help}
											>
												<Input
													type="digits"
													onChange={({ target }) => handleChange('creditCardSecureCode', target.value) }
													onPaste={({ target }) => setTimeout(handleChange('creditCardSecureCode', target.value), 0)}
													value={creditCardData.creditCardSecureCode}
													autocomplete="cc-csc"
												/>
											</FormItem>
										</FormInput>
									</Col>
								</Row>

								<Divider size="medium" />

								<MiniTitle>
									NOME DO<br />TITULAR
								</MiniTitle>

								<FormInput>
									<FormItem
										validateStatus={validation.creditCardOwnerName.validateStatus}
										help={validation.creditCardOwnerName.help}
									>
										<Input
											onChange={({ target }) => handleChange('creditCardOwnerName', target.value)}
											onPaste={({ target }) => setTimeout(handleChange('creditCardOwnerName', target.value), 0)}
											value={creditCardData.creditCardOwnerName}
											autocomplete="cc-name"
										/>
									</FormItem>
								</FormInput>
							</PaymentDetailsContainer>
						</Col>
						<Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
							{paymentSlipNotAllowed ? (
								<Tooltip title={paymentSlipNotAllowedMessage}>
									<div style={{ width: 150 }}>
										<PaymentDetailsContainer isEnabled={false}>
											<Radio onChange={handleChangePaymentMethod} checked={paymentMethod === 'payment-slip'} value="payment-slip">
												Boleto bancário
											</Radio>

											<Divider size="medium" />

											<PaymentSlip src={paymentSlip} />
										</PaymentDetailsContainer>
									</div>
								</Tooltip>
							) : (
								<>
									<Radio onChange={handleChangePaymentMethod} checked={paymentMethod === 'payment-slip'} value="payment-slip">
										Boleto bancário
									</Radio>

									<Divider size="medium" />

									<PaymentDetailsContainer isEnabled={paymentMethod === 'payment-slip'}>
										<PaymentSlip src={paymentSlip} />

										{paymentMethod === 'payment-slip' && (
											<>
												<Divider size="xlarge" />

												<b>Ao finalizar o pedido, será gerado um boleto.</b>
				
												<Divider size="small" />

												<span>
													A sua conta será ativada após a confirmação do
													<br />
													pagamento do boleto.
												</span>
											</>
										)}
									</PaymentDetailsContainer>
								</>
							)}
						</Col>
					</Row>
				</CardBody>

				<Divider size="xlarge" />

				<Footer>
					<Button
						loading={loadingSubmit}
						type="success"
						htmlType="submit"
					>
						{loadingSubmit ? 'PROCESSANDO PEDIDO' : 'FINALIZAR PEDIDO'}
					</Button>
				</Footer>

				<Divider size="xlarge" />
			</SubmitContainer>
		</CardContainer>
	)
};

PaymentInfo.defaultProps = {
	onSubmit: () => {},
	paymentSlipNotAllowed: false,
	paymentSlipNotAllowedMessage: "",
	validation: {},
	clearValidation: () => {},
	clearAllValidations: () => {},
};

PaymentInfo.propTypes = {
	onSubmit: PropTypes.func,
	paymentSlipNotAllowed: PropTypes.bool,
	paymentSlipNotAllowedMessage: PropTypes.string,
	validation: PropTypes.object,
	clearValidation: PropTypes.func,
	clearAllValidations: PropTypes.func,
};

export default PaymentInfo;