import styled from 'styled-components';
import { Container as MContainer, ListItem as MListItem } from '@material-ui/core';

export const MaterialContainer = styled(MContainer)`
  background-color: #666666;
  border-radius: 6px;
  flex-direction: row;
  justify-content: space-between;
  display: flex !important;
  padding: 10px 15px !important;
  width: 150px !important;
  cursor: pointer;
`;

export const ListItemHeader = styled(MListItem)`
  background-color: #F0F0F0;
  border-top-right-radius: 5;
  border-top-left-radius: 5;
  display: flex !important;
  justify-content: center !important;
`;

export const Container = styled.div`
  background-color: #fff;

  li{
    border-right: 1px solid #E8E8E8 !important;
  }
`;

export const FirstHeaderRow = styled.div`
  min-height: 60px;
  padding: 0 16px;
  border-bottom: 1px solid #E8E8E8;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  img{
    max-width: 150px;
  }
`;

export const SolutionsWrapper = styled.span`
  margin-left: 16px;
  font-weight: bold;

  a{
    color: #333333;
  }

  a:hover{
    color: #666;
  }
`;

export const ProfileName = styled.span`
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  font-weight: 300;
  margin-right: 20px;

  a {
    margin-left: 10px;
    color: #707070;
  }

  a:hover {
	  color: #303030;
  }
`;

export const MenuWrapper = styled.div`
  .ant-menu-horizontal {
    font-size: 12px;
    
    .ant-menu-item-active,
    .ant-menu-item-selected, 
    .ant-menu-submenu-selected,
    .ant-menu-item-active,
    .ant-menu-submenu-active, 
    li:hover {
      color: #ff4949;
      border-bottom: 2px solid #ff4949;

      a:hover {
        color: #ff4949;
      }

      a {
        color: #ff4949;
      }
    }
  }
`;