import React from 'react';
import { Input as AntInput } from 'antd';
import PropTypes from 'prop-types';
import { Container } from './styles';

const Input = (props) => {
  const { type, value } = props;

  const phoneNumberMask = (rawNumber) => {
    if (rawNumber !== null) {
      return rawNumber
        .replace(/(\D)/g, '')
        .replace(/(\d{1,2})(\d{0,5})(\d{3,4})/, '($1) $2-$3')
        .replace(/(-\d{4})\d+?$/, '$1');
    }
  };

  const digitsMask = (rawNumber) => {
    if (rawNumber !== null) {
      return rawNumber.replace(/[^0-9\.]/g, '');
    }
  };

  return (
    <Container type={type}>
      {type === 'search' && <AntInput.Search {...props} />}
      {type === 'textarea' && <AntInput.TextArea {...props} />}
      {type === 'password' && <AntInput.Password {...props} />}
      {type === 'default' && <AntInput {...props} />}
      {type === 'phone' && (
        <AntInput {...props} value={phoneNumberMask(value)} />
      )}
      {type === 'digits' && <AntInput {...props} value={digitsMask(value)} />}
    </Container>
  );
};

Input.defaultProps = {
  type: 'default',
  size: 'large'
};

Input.propTypes = {
  type: PropTypes.oneOf(['textarea', 'search', 'number']),
  size: PropTypes.oneOf(['large']),
  value: PropTypes.string,
  onInput: PropTypes.func,
  onChange: PropTypes.func
};

export default Input;
