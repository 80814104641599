import React from 'react';
import PropTypes from 'prop-types';

import {
  Accordion as AccordionMui,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box
} from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';

import useStyles from './styles';
import Divider from '../Divider';

const Accordion = (props) => {
  const { contentData } = props;

  const classes = useStyles();

  return (
    <Box style={{ width: '100%' }}>
      {!!contentData.length &&
        contentData.map((item, index) => (
          <>
            <AccordionMui className={classes.accordionContainer}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`faq${index}-content`}
                id={`faq${index}-header`}
              >
                <Typography variant="h3" className={classes.title}>
                  {item.title}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{item.description}</Typography>
              </AccordionDetails>
            </AccordionMui>

            <Divider size="small" />
          </>
        ))}
    </Box>
  );
};

Accordion.propTypes = {
  contentData: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.required,
      description: PropTypes.string.required
    })
  ).isRequired
};

export default Accordion;
