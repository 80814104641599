import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'antd';
import PropTypes from 'prop-types';
import { Creators as PortfolioActions } from '../../../store/ducks/portfolio';

import PortfolioRoutes from '../../../routes/portfolio';

import Button from '../../../components/Button';
import Divider from '../../../components/Divider';
import Dropzone from '../../../components/Dropzone';
import Upload from '../../../components/Portfolio/Upload';
import { Content } from '../../../styles/components';

import api from '../../../services/api';

import { notifyBackendError } from '../../../utils/notificationService';

import { Cover } from './styles';

import cover_placeholder from '../../../assets/avatar-no-gallery-cover-pb.png';

import AsidePortfolioMenu from './AsidePortfolioMenu';

const WorkCloseup = (props) => {
	const dispatch = useDispatch();
	const portfolioInfo = useSelector((state) => state.portfolio.info);
	const [work, setWork] = useState({});
	const [loading, setLoading] = useState(false);

	const { work_id } = props.match.params;
	const { location } = props;
	const { pathname } = location;

	const getData = async () => {
		setLoading(true);

    try {
      const res = await api.get('/portfolio');
      dispatch(PortfolioActions.setUrl({ url: res.data.url }));

      const { data } = await api.get(`/portfolio/work?work_id=${work_id}`);
      setWork(data.work);

      const portfolioWork = {
        title: data.work.title,
        work_id: data.work.id,
      };

      const portInfo = { cover: data.work.cover };

      dispatch(PortfolioActions.setInfo(portInfo));
      dispatch(PortfolioActions.setWork(portfolioWork));
    } catch (err) {
      notifyBackendError(err);
    }

		setLoading(false);
	};

  const onDrop = async ([file]) => {
    setLoading(true);

    const formData = new FormData();
    formData.append('cover', file);

    try{
      await api.put(`/portfolio/work/${work_id}/cover`, formData, {
        headers: {
          'Content-Type': 'multipart/data',
        },
      });

      await getData();
    } catch(error) {
      notifyBackendError(error);
      setLoading(false);
    }
  };

  const redirect = () => {
    // In case the user changes his location to the portfolio routes root
    props.history.listen((location) => {
      if (location.pathname === `/portfolio/works/${work_id}`) {
        props.history.push(`/portfolio/works/${work_id}/pictures`);
        dispatch(PortfolioActions.selectMenu('pictures'));
      }
    });

    // In case the user reloads the page
    if (pathname === `/portfolio/works/${work_id}`) {
      props.history.push(`/portfolio/works/${work_id}/pictures`);
      dispatch(PortfolioActions.selectMenu('pictures'));
    }
  };

	useEffect(() => {
		getData();
		redirect();
	}, []);

	return (
    <Content>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={10} lg={8} xl={6} xxl={5}>
          <Dropzone onDrop={onDrop}>
            <Cover
              background={portfolioInfo.cover ? portfolioInfo.cover : null}
            >
              {portfolioInfo.cover ? null : (
                <img src={cover_placeholder} alt="Foto de Capa" />
              )}
              <Button loading={loading} type="primary">
                {// eslint-disable-next-line no-nested-ternary
                loading
                  ? 'CARREGANDO...'
                  : portfolioInfo.cover
                  ? 'ALTERAR FOTO DE CAPA'
                  : 'INSERIR FOTO DE CAPA'}
              </Button>
            </Cover>
          </Dropzone>

          <Divider size="medium" />

          <AsidePortfolioMenu workId={work_id} pathname={pathname} />
        </Col>

        <Col xs={24} sm={24} md={14} lg={16} xl={18} xxl={19}>
          <Upload {...props} />
          <PortfolioRoutes />
        </Col>
      </Row>
    </Content>
  );
};

WorkCloseup.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      work_id: PropTypes.number.isRequired,
    })
	}).isRequired,
};

export default WorkCloseup;
