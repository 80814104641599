import styled from 'styled-components';
import {
  Checkbox as CheckboxAntd,
} from 'antd';


export const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;

  @media only screen and (max-width: 500px){
    flex-direction: column;
  }
`;

export const Registered = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  padding: 15px 20px;
  text-align: right;
  z-index: 2;

  a{
    font-weight: bold;
    color: #000;
  }

  a:hover{
    color: #333;
  }

  @media only screen and (max-width: 500px){
    width: 100vw;
    padding: 20px 30px 0 0;
    position: relative;
  }
`;

export const AsideInfo = styled.div`
  background-color: #FF4949;
  padding: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
`;

export const Logo = styled.img`
  margin: 0 auto;
  width: 100%;
  max-width: 327px;
  height: auto;
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  @media only screen and (max-width: 1200px){
    width: 100vw;
    flex-direction: column;
  }
`;

export const SignupForm = styled.div`
  width: 508px;
  margin: auto;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media only screen and (max-width: 500px){
    width: auto;
  }
`;

export const SmallWrapper = styled.div`
  margin-top: 5px;
  display: flex;

  div{
    display: flex;
  }

  span{
    margin: auto;
    color: #ff4949;
    font-size: 11px;
  }
`;

export const Title = styled.h3`
  text-align: center;
  width: 100%;
  font-size: 18px;
  margin-bottom: 0;
`;

export const TitleWrapper = styled.div`
  h3{
    color: #FFF;
  }
`;

export const LogoMobile = styled.img`
  width: 200px;
  height: auto;

  margin: 32px auto;

  @media only screen and (min-width: 1200px){
    display: none;
  }
`;

export const Checkbox = styled(CheckboxAntd)`
display: flex;
align-content: center;
align-items: center;

:hover,
.ant-checkbox-wrapper:hover,
  .ant-checkbox:hover {
    .ant-checkbox-inner {
      border-color: #56C568;
    }
  }

  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #56C568;
  }

  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: #56C568;
      border-color: #56C568;
    }
  }

  .ant-checkbox-checked:after {
    border-color: #56C568;
  }

  .ant-checkbox-indeterminate {
    .ant-checkbox-inner:after {
      background-color: #56C568;
    }
  }

.text-content-checkbox{
  margin-bottom: 0;
  color: #aaaaaa;
  font-weight: normal;
  line-height: 18px;
}

`;
