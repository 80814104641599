import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';

import Downloads from '../pages/Gallery/Download';
import GallerySettings from '../pages/Gallery/Settings';
import Picture from '../pages/Gallery/Picture';

import Selection from '../pages/Gallery/Selection';
import SelectionFollowUp from '../pages/Gallery/Selection/FollowUp';
import SelectionPreferences from '../pages/Gallery/Selection/Preferences'

const GalleryRoutes = () => (
  <>
    <Route path="/gallery/:collection_id/selection" component={Selection} />

    <Switch>
      <Route path="/gallery/:collection_id/settings" component={GallerySettings} />
      <Route path="/gallery/:collection_id/download" component={Downloads} />
      <Route path="/gallery/:collection_id/pictures" component={Picture} />
      <Route path="/gallery/:collection_id/selection/preferences" component={SelectionPreferences} />
      <Route path="/gallery/:collection_id/selection/followup" component={SelectionFollowUp} />
    </Switch>
  </>
);

export default withRouter(GalleryRoutes);
