import styled from 'styled-components';

export const Container = styled.div`
  text-align: left;

  span{
    font-size: 14px;
    font-weight: bold;
    line-height: 22px;
    color: #333333;
  }
`;

export const Span = styled.span`
   &:after{
    content: '${(props) => (props.isRequired ? ' *' : '')}';
    color: red;
    font-size: 12px;
  }
`;
