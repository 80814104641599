import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Icon, Modal, Typography } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';

import { Creators as PortfolioActions } from '../../../store/ducks/portfolio';

import Button from '../../../components/Button';
import Divider from '../../../components/Divider';
import Loading from '../../../components/Loading';
import EditDialog from '../../../components/EditDialog';
import PopConfirm from '../../../components/PopConfirm';
import Input from '../../../components/Form/Input';
import FormItem from '../../../components/Form/FormItem';
import SubmitContainer from '../../../components/Form/SubmitContainer';

import api from '../../../services/api';

import { notifySuccess, notifyError, notifyBackendError } from '../../../utils/notificationService';
import { getStatusCode } from '../../../utils/response';
import { useValidation } from '../../../services/validation';

import { Portlet, Menu, MenuItem } from '../../../styles/components';
import { Container, SubMenuCategory, SubMenuCategoryItem, SubMenuLastCategoryItem, ModalContent, CategoryToolbar, SortHandler } from './styles';

const { Title } = Typography;

const CategoriesList = (props) => {
	const inputs = ["category_title"]

  const dispatch = useDispatch();
  const portCategories = useSelector((state) => state.portfolio.inside_categories);
  const portInfo = useSelector((state) => state.portfolio.info);
  const selectedMenu = useSelector((state) => state.portfolio.menu);
  const selectedSubMenu = useSelector((state) => state.portfolio.submenu);
	const [hoveringObj, setHoveringObj] = useState(null);
	const [loading, setLoading] = useState(false);
	const [loadingBtn, setLoadingBtn] = useState(false);
	const [categories, setCategories] = useState([]);
	const [newCategoryData, setNewCategoryData] = useState({});
	const [modalVisibility, setModalVisibility] = useState(false);
  const [validation, clearValidation, triggerValidation] = useValidation(inputs);

	const getData = async () => {
		setLoading(true);

		// TODO: remove try catch when default categories are defined
    try {
      const { data } = await api.get('/portfolio/categories');
      setCategories(data.categories);
    } catch (err) {
      setCategories([]);
    }

		setLoading(false);
  };

	useEffect(() => {
    getData();
    if (!selectedSubMenu) {
      dispatch(PortfolioActions.selectMenu('categories'));
    }
  }, []);

	useEffect(() => {
		getData();
  }, [portCategories]);

	const handleCreateCategory = async () => {
		setLoadingBtn(true);

		try {
			const { data } = await api.post('/portfolio/category', { title: newCategoryData.title });
			notifySuccess('Categoria criada com sucesso.');
      setCategories([data.category, ...categories]);
      dispatch(PortfolioActions.setOutsideCategories(categories));
      clearValidation('category_title');
      setNewCategoryData({
        ...newCategoryData,
        title: '',
      });
			setModalVisibility(false);
		} catch (error) {
      const statusCode = getStatusCode(error);

      if (statusCode === 500) {
        notifyError('Algo deu errado.');
				setModalVisibility(false);
      } else {
				triggerValidation(error);
			}
		}

		setLoadingBtn(false);
	};

	const handleUpdateCategory = async (title, category_id) => {
		try {
      await api.put(`/portfolio/category/${category_id}`, { title });
      notifySuccess('A alteração foi feita com sucesso.');
      dispatch(PortfolioActions.setInfo({ category: { title, id: category_id } }));
      dispatch(PortfolioActions.selectSubMenu(title));
      dispatch(PortfolioActions.selectMenu(''));
      dispatch(PortfolioActions.setOutsideCategories(categories));
      getData();
      setModalVisibility(false);
      return true;
		} catch (error) {
			return error;
    }
	};

	const handleDeleteCategory = async (category_id) => {
    setNewCategoryData({
      ...newCategoryData,
      id: category_id,
    });

    try {
			await api.delete(`/portfolio/category/?category_id=${category_id}`);
      const newArr = categories.filter((categ) => categ.id !== category_id);
      const data = Object.values(newArr).map((categ) => categ.id);
      await api.put('/portfolio/categories/reorder', data);
      notifySuccess('A categoria foi deletada com sucesso.');
      dispatch(PortfolioActions.setOutsideCategories(categories));
      if (portInfo.category && portInfo.category.id === category_id) {
        dispatch(PortfolioActions.setInfo({ category: null }));
        dispatch(PortfolioActions.selectSubMenu(''));
        dispatch(PortfolioActions.selectMenu('categories'));
      }
			getData();
		} catch (error) {
      notifyBackendError(error);
		}
	};

	const handleOpenModal = () => {
		setModalVisibility(true);
	};

	const handleCloseModal = () => {
		setModalVisibility(false);
	};

  const handleChange = (key, value) => {
		clearValidation(key);
		if (key === 'category_title') {
			key = 'title';
    }

    setNewCategoryData({
      ...newCategoryData,
      [key]: value,
    });
	};

  const handleOnHove = (idx) => {
		setHoveringObj(idx);
	};

  const handleMouseLeave = () => {
    setHoveringObj(null);
	};

  const handleShowWorks = (category) => {
    dispatch(PortfolioActions.selectMenu(''));
    dispatch(PortfolioActions.selectSubMenu(category.title));
    dispatch(PortfolioActions.setInfo({ category }));
  };

  const SortableList = SortableContainer(({ items }) => {
    return (
      <ul onMouseLeave={handleMouseLeave}>
        {items.map((value, index) => (
          <SortableItem
            key={`item-${value.id.toString()}`}
            index={index}
            value={value}
          />
        ))}
        <SubMenuLastCategoryItem onClick={() => handleOpenModal()}>
          <FontAwesomeIcon icon={faPlus} style={{ margin: '0 6px' }} />
          <span>Nova categoria</span>
        </SubMenuLastCategoryItem>
      </ul>
    );
  });

  const Handle = SortableHandle(() => (
    <SortHandler>
      <FontAwesomeIcon icon={faEllipsisV} style={{ cursor: 'move' }} />
    </SortHandler>
  ));

  const handleOpenConfirmDeleteCategoryModal = (categId) => {
    PopConfirm.open({
      title: "Tem certeza?",
      cancelText: "NÃO",
      confirmText: "SIM",
      confirmIcon: "delete",
      info: 'Excluir a categoria é uma ação irreversível. Deseja continuar?',
      onConfirm: () => handleDeleteCategory(categId)
    })
  };

  const SortableItem = SortableElement(({ value }) => {
    const categId = value.id;
    const [filteredCategory] = categories.filter((categ) => categ.id === categId);
    return (
      <SubMenuCategoryItem isSelected={selectedSubMenu === filteredCategory.title}>
        <Handle />
        <span onClick={() => handleShowWorks(filteredCategory)}>{filteredCategory.title}</span>
        <CategoryToolbar>
          <EditDialog
            name="Categoria"
            value={filteredCategory.title}
            onSave={(value) => handleUpdateCategory(value, categId)}
            inputName="category_title"
          >
            <Icon type="edit" />
          </EditDialog>
            <Icon
              type="delete"
              onClick={() => handleOpenConfirmDeleteCategoryModal(categId)}
            />
        </CategoryToolbar>
      </SubMenuCategoryItem>
    );
  });

  const handleSortEnd = async ({ oldIndex, newIndex }) => {
    const newArr = arrayMove(categories, oldIndex, newIndex);
    setCategories(newArr);
    const data = Object.values(newArr).map((categ) => categ.id);
    await api.put('/portfolio/categories/reorder', data);
    dispatch(PortfolioActions.setOutsideCategories(newArr));
  };

	return (
    <Container>
      <Modal
        footer={null}
        closeIcon={null}
        visible={modalVisibility}
        onCancel={handleCloseModal}
      >
        <ModalContent>
          <SubmitContainer onSubmit={handleCreateCategory}>
            <Title level={3}>Nome da Categoria</Title>

            <Divider size="medium" />

            <FormItem
              validateStatus={validation.category_title.validateStatus}
              help={validation.category_title.help}
            >
              <Input
                onChange={({ target }) =>
                  handleChange('category_title', target.value)
                }
                value={newCategoryData.title}
              />
            </FormItem>

            <Divider size="medium" />

            <Button
              id="submit"
              htmlType="submit"
              type="primary"
              loading={loadingBtn}
              onClick={() => handleCreateCategory()}
              block
              icon="check"
            >
              CRIAR CATEGORIA
            </Button>
          </SubmitContainer>
        </ModalContent>
      </Modal>
      <Loading loading={loading}>
        <Portlet>
          <Menu>
            <MenuItem
              onClick={() => {
                dispatch(PortfolioActions.setInfo({ ...portInfo, category: null }));
                dispatch(PortfolioActions.selectMenu('categories'));
                dispatch(PortfolioActions.selectSubMenu(''));
              }}
              isSelected={selectedMenu === 'categories'}
            >
              <a onClick={(e) => e.preventDefault()}>
                <Icon type="folder-open" />
                CATEGORIAS
              </a>
            </MenuItem>
            <SubMenuCategory>
              <SortableList
                items={categories}
                onSortEnd={handleSortEnd}
                useDragHandle
              />
            </SubMenuCategory>
          </Menu>
        </Portlet>
      </Loading>
    </Container>
  );
};

CategoriesList.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default CategoriesList;
