import React from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';

import { Container } from './styles';

const Dropzone = (props) => {
	const { onDrop, children, disabled } = props;

	const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <Container {...getRootProps()} style={{ pointerEvents: disabled && 'none' }}>
			<input 
				{...getInputProps()}
				accept=".jpg, .jpeg, .png"
				type="file" 
				hidden 
			/>
			{children}
    </Container>
  );
};

Dropzone.defaultProps = {
	disabled: false,
}

Dropzone.propTypes = {
	disabled: PropTypes.bool,
	onDrop: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
	]),
};

export default Dropzone;
