/* eslint-disable no-nested-ternary */
import styled from 'styled-components';

export const Container = styled.div`
	width: 100%;
	
  .ant-input {
    min-height: ${
      ({ size }) => (size)
    }
  }
`;
