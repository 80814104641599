import { makeStyles } from '@material-ui/core';
import colors from '../../../../styles/colors';

import transparentGridImg from '../../../../assets/transparent_placeholder.png';

const useStyles = makeStyles((theme) => ({
  radioButton: {
    marginLeft: '-6px'
  },
  boxContainer: {
    width: '215px',
    height: '245px',

    padding: theme.spacing(0.5, 1),

    backgroundColor: (props) => (props.isChecked ? '#39C395' : '#f7f7f7'),
    filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))'
  },
  boxContent: {
    height: '100%'
  },
  cardOptionWrapper: {
    height: '80%',
    margin: 'auto'
  },
  cardOption: {
    textAlign: 'center',
    display: 'inline-block',
    height: '100%',
    width: '100%'
  },
  cardOptionWithBackground: {
    backgroundImage: `url(${transparentGridImg})`
  },
  containerWatermark: {
    height: '100%',
    position: 'relative'
  },
  waterMarkImg: {
    display: 'block',
    width: 'auto',
    height: 'auto',

    position: 'absolute',
    margin: 'auto',

    maxWidth: '100%',
    maxHeight: '100%'
  },
  iconWithOutWaterMark: {
    fontSize: '90px',
    color: (props) =>
      props.isChecked ? colors.greyScale[11] : colors.greyScale[3]
  },
  textWithOutWaterMark: {
    color: (props) =>
      props.isChecked ? colors.greyScale[10] : colors.greyScale[6],
    fontWeight: 'bold'
  },
  menuButtonWrapper: {
    textAlign: 'end'
  },
  menuButtonIcon: {
    marginRight: '-12px'
  }
}));

export default useStyles;
