import styled from 'styled-components';

export const Container = styled.div`
	width: ${({ isMobile }) => isMobile ? '100%' : '60%'};
	margin: 0 auto;
`;

export const Header = styled.div`
	background-color: #FFF;
	padding: 10px;
	border-radius: 5px;

  button, .ant-input-search {
    width: 100%;
  }

	margin-bottom: 20px;
`;

export const ClientCard = styled.div`
	display: flex;
	width: 100%;

	background-color: #FFF;
	border-radius: 5px;
	padding: 20px 15px;

	align-items: center;

	margin-bottom: 10px;
`;

export const ClientContainer = styled.div`
	margin-right: auto;
	margin-left: 15px;
`;

export const ClientName = styled.h1`
	margin-bottom: 0;
	font-size: 16px;
	font-weight: bold;

	line-height: 16px;
`;

export const ClientInfo = styled.span``;

export const MenuContainer = styled.div``;

export const ButtonWrapper = styled.div`
	width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
	margin: 8px 0 8px auto;
  
  button {
    margin-left: 10px;
  }
`;

export const PaginationWrapper = styled.div`
	li:hover,
	li:focus {
		color: #fff;
		border-color: #56C568;
		
		a,
		.ant-pagination-item-link {
			color: #fff;
			background-color: #56C568;
			border-color: #56C568;
		}
	}

	.ant-pagination-disabled:hover {
		a {    
			color: rgba(0, 0, 0, 0.25);
			border-color: #d9d9d9;
			background-color: transparent;
		}
	}

	.ant-pagination-item-active {
		background-color: #56C568;
		border-color: #56C568;

		a {
			color: #fff;
		}
	}

	a:hover {
		color: #fff;
	}

	.ant-pagination-jump-next,
	.ant-pagination-jump-prev {
		.ant-pagination-item-container {
			.ant-pagination-item-link-icon {
				color: #56C568;
			}
		}
	}
`;

export const Footer = styled.div`
	display: flex;
	width: 100%;
	justify-content: flex-end;

	background-color: #FFF;
	border-radius: 5px;

	padding: 10px;
`;

export const InfoPopupContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const InfoPopupTitle = styled.span`
  font-size: 20px;
  font-weight: bold;
  color: black;
`;

export const InfoPopupSubtitle = styled.span`
  text-align: center;
  font-size: 16px;
  color: #666666;
`;
