import React from 'react';
import { InputNumber as AntInputNumber } from 'antd';
import PropTypes from 'prop-types';
import { Container } from './styles';

const InputNumber = (props) => {
  const { type } = props;

  const billFormatter = (value) => (`R$ ${(value / 100).toFixed(2).replace('.', ',')}`)
  const billParser = (value) => (parseInt(value.replace(/\D/g, ''), 10))

  return (
    <Container>
      {type === 'default' && <AntInputNumber {...props} />}
      {type === 'bill' && <AntInputNumber {...props} formatter={billFormatter} parser={billParser} min={0} />}
    </Container>
  );
};

InputNumber.defaultProps = {
  size: 'large',
  type: 'default'
};

InputNumber.propTypes = {
  size: PropTypes.oneOf(['large']),
  type: PropTypes.oneOf(['default', 'bill'])
};

export default InputNumber;
