import React from 'react';
import { Row, Col } from 'antd';

import SettingsRoutes from '../../routes/settings';
import AsideMenu from './AsideMenu';

import { Content } from '../../styles/components';

const Settings = () => {
  return (
    <Content>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={10} lg={8} xl={6} xxl={5}>
          <AsideMenu />
        </Col>
        <Col xs={24} sm={24} md={14} lg={16} xl={18} xxl={19}>
          <SettingsRoutes />
        </Col>
      </Row>
    </Content>
  );
};

export default Settings;
