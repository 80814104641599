import styled from 'styled-components';

import { ExtraInfo } from '../styles';

export const Container = styled.div`
	max-width: 80%;
	width: 500px;

	display: flex;
	flex-direction: column;
	align-items: center;

	margin: auto;
	margin-top: ${({ spotlight, isMobile }) => !spotlight && !isMobile && '50px'};

	background-color: ${({ spotlight }) => spotlight ? '#FFF' : '#FDFDFD'};
	border-radius: 5px;
	border: ${({ spotlight}) => spotlight ? '1px solid #EDEDED' : '1px solid #E8E8E8'};

	padding: 24px;

	box-shadow: ${({ spotlight }) => spotlight &&	'0px 4px 12px rgba(8, 35, 48, 0.12)'};

	div {
		width: 100%;
	}

	@media only screen and (max-width: 600px) {
		max-width: 100vw;
		width: 100%;
	}
`;

export const PlanName = styled.h1`
	color: #555555;
	font-size: 24px;
	font-weight: bolder;

	margin-bottom: 0;
`;

export const PlanPrice = styled.div`
	text-align: center;

	div {
		text-align: center;
	}
`;

export const PlanPriceValue = styled.span`
	color: #555555;
	font-weight: bolder;

	strong {
		font-size: 36px;
	}
`;

export const ExtraInfoBeforeButton = styled.span`
	font-size: 12px;
	color: #0787AF;
`;

export const ExtraInfoAfterButton = styled(ExtraInfo)`

`;

export const FeatureContainer = styled.div`
	display: flex;

	align-items: center;
`;

export const FeatureInfo = styled.div`
	margin-left: 14px;
`;

export const FeatureOverview = styled.span`
	color: #828282;
`;

export const FeatureDescription = styled(FeatureOverview)`
	font-size: 12px;
	line-height: 12px;
`;

export const FeatureSpotlight = styled.span`
	color: #000;
	font-size: 14px;
	font-weight: bolder;
`;