import api from './api';

export const sendMail = async ({
	client_name,
	client_email,
	client_message,
	client_password,
	collection_id,
	subject,
	magicLink
}) => {
	await api.post(`/gallery/${collection_id}/share`, {
		client_name,
		client_email,
		client_message,
		client_password,
		subject,
		magicLink,
	}).catch(() => {});
}

export const sendWhatsAppMessage = async ({
	galleryUrl,
	message,
	tel,
	client_email,
	client_password,
	privateSelection,
	magicLink
}) => {
	if (privateSelection) {
		if (magicLink) {
			message = message + `\n\nDADOS DE ACESSO:\n\n*Link:*\n${magicLink}`
		} else {
			message = message + `\n\nDADOS DE ACESSO:\n\n*Link:*\n${galleryUrl}\n\n*Email:*\n${client_email}\n\n*Senha:*\n${client_password}`
		}
	} else {
		message = message + `\nLink para acesso: ${galleryUrl}`;
	}

	const encodedMessage = encodeURI(message);

	const number = tel.replace(/(\D)/g, '');

	const url = (window.innerWidth < 600)
	? `https://api.whatsapp.com/send?phone=55${number}&text=${encodedMessage}`
	: `https://web.whatsapp.com/send?phone=55${number}&text=${encodedMessage}`

	await window.open(url, '_blank');
}