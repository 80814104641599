let callbackFunction;

export const LWF = {
	scope: 'email,public_profile',

	init: (callback) => {
		window.fbAsyncInit = function () {
			window.FB.init({
				appId: '722975741225855',
				cookie: true,
				xfbml: true,
				version: 'v3.2'
			});
		};
		(function (d, s, id) {
				var js, fjs = d.getElementsByTagName(s)[0];
				if (d.getElementById(id)) {
						return;
				}
				js = d.createElement(s);
				js.id = id;
				js.src = "https://connect.facebook.net/pt_BR/sdk.js";
				fjs.parentNode.insertBefore(js, fjs);
		}(document, 'script', 'facebook-jssdk'));
	},

	_getData: (authResponse) => {
		window.FB.api('/me?fields=name,email,picture', function (res) {
			callbackFunction({
				auth_with: 'facebook',
				auth: authResponse,
				token: authResponse.accessToken,
				id: res.id,
				name: res.name,
				email: res.email,
				img: "http://graph.facebook.com/" + res.id + "/picture?width=200&height=200"
			});
		});
	},

	onLoginStatus: (response) => {
		if (response.status === 'connected' && response.authResponse) {
				LWF._getData(response.authResponse);
		}
		else {
				console.log(response.status);
		}
	},

	login: () => {
		window.FB.login(LWF.onLoginStatus, { scope: LWF.scope });
	}
}

export const LWG = {
	scope: 'openid profile email',
	auth2: null,
	_loginBtn: document.createElement('button'),

	init: () => {
		(function (id) {
				var s = document.getElementById(id);
				if (s) {
					LWG._init();
					return;
				}
				s = document.createElement('script');
				s.id = id;
				s.type = 'text/javascript';
				s.src = 'https://apis.google.com/js/platform.js';
				s.addEventListener('load', () => LWG._init());
				document.body.appendChild(s);
		}('google-api-js'))
	},

	_onUser: (googleUser) => {
		var profile = googleUser.getBasicProfile();
		callbackFunction({
			auth_with: 'google',
			auth: googleUser.getAuthResponse(),
			token: googleUser.getAuthResponse().id_token,
			id: profile.getId(),
			name: profile.getName(),
			img: profile.getImageUrl(),
			email: profile.getEmail()
		});
	},

	_onError: (error) => {
		console.warn(error);
	},
	
	_init: () => {
		window.gapi.load('auth2', function () {
				LWG.auth2 = window.gapi.auth2.init({
						client_id: '405949484086-3ecv06msavjqege28en64bjttiddk7ke.apps.googleusercontent.com',
						cookiepolicy: 'single_host_origin',
						scope: LWG.scope
				});
				LWG.auth2.attachClickHandler(LWG._loginBtn, {}, LWG._onUser, LWG._onError);
		});
	},

	login: () => {
		LWG._loginBtn.click();
	}
}

export const initExternalAuth = (callback) => {
	callbackFunction = callback;
  LWF.init();
  LWG.init();   
}