import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import api from '../../../services/api';
import { notifyWarn } from '../../../utils/notificationService';

import { Creators as GalleryActions } from '../../../store/ducks/gallery';
import { Creators as AsideGalleryMenuActions } from '../../../store/ducks/asideGalleryMenu';

import { Portlet } from '../../../styles/components';

import UploadProgress from './UploadProgress';
import UploadStart from './UploadStart';

import {
	UploadWarningModal,
	UploadSuccessModal,
	UploadFailedModal,
} from './UploadCompleted';

const Upload = (props) => {
	const dispatch = useDispatch();
	const [files, setFiles] = useState([]);
	const galleryInfo = useSelector((state) => state.gallery.info);

	const { uploadStatus } = galleryInfo;

	const { collection_id } = props.match.params;
	const { pathname } = props.location;
	const { history } = props;

	const refreshGalleryCover = async () => {
    const response = await api.get(`/gallery?collection_id=${collection_id}`);

    const { cover } = response.data;

    dispatch(GalleryActions.setInfo({ cover }));
  };

	const getImages = async () => {
		try {
			const response = await api.get(`/pictures?collection_id=${collection_id}&page=${galleryInfo.page}`);
			const { pictures, total_pictures } = response.data;
			dispatch(GalleryActions.setInfo({ total_pictures }));
			dispatch(GalleryActions.setImages(pictures));
			dispatch(GalleryActions.setInfo({ uploadStatus: 'invisible' }));
		} catch(error) {
			const status = error && error.response && error.response.status;

			if (status === 404) {
        dispatch(GalleryActions.setInfo({ uploadStatus: 'gallery-empty' }));
        dispatch(GalleryActions.setImages([]));
      }
		}
  };

	const handleStartUpload = (filesList) => {
		setFiles(filesList);

		if (galleryInfo.uploadStatus === 'gallery-empty') {
			dispatch(GalleryActions.setInfo({ uploadStatus: 'uploading-empty' }));
		} else {
			dispatch(GalleryActions.setInfo({ uploadStatus: 'uploading' }));
		}
	};

	const handleUploadCompleted = async (payloadInfo) => {
		if (!galleryInfo.cover) {
      refreshGalleryCover();
    }

		await getImages();

		openReport(payloadInfo);
	};

	const handleOpenUpload = () => {
    if (!galleryInfo.uploadStatus.includes('empty')) {
      dispatch(GalleryActions.setInfo({ uploadStatus: 'waiting' }));
		} else {
			history.push(`/gallery/${collection_id}/pictures`);
		}
  };

  const handleOpenSelection = () => {
    dispatch(AsideGalleryMenuActions.selectMenu('selection'));
    history.push(`/gallery/${collection_id}/selection`);
  };

  const handleOpenGallery = () => {
    dispatch(AsideGalleryMenuActions.selectMenu('download'));
    history.push(`/gallery/${collection_id}/download`);
	};
	
	const handleCancelUpload = () => {
		notifyWarn('Parece que você não possui novas fotos para serem enviadas.');

		if (!galleryInfo.uploadStatus.includes('empty')) {
      dispatch(GalleryActions.setInfo({ uploadStatus: 'invisible' }));
		} else {
			dispatch(GalleryActions.setInfo({ uploadStatus: 'gallery-empty' }));
		}
	};

	const openReport = (payloadInfo) => {
		const {
			succededList,
			failedList
		} = payloadInfo;

		// In case nothing has succeded we open the failing modal type
    if (succededList.length === 0) {
      UploadFailedModal.open({
				failedList,
				openUpload: handleOpenUpload,
			})
		// In case nothing has failed we open the succeded modal type
    } else if (failedList.length === 0) {
      UploadSuccessModal.open({
        succededList,
        openSelection: handleOpenSelection,
        openGallery: handleOpenGallery,
			});
		// If some failed and succeded we open the warning modal type
    } else if (failedList.length !== 0 && succededList.length !== 0) {
      UploadWarningModal.open({
        succededList,
        failedList,
        openSelection: handleOpenSelection,
        openGallery: handleOpenGallery,
      });
    }
	};

	if (uploadStatus === 'waiting' || (uploadStatus === 'gallery-empty' && pathname.includes('pictures'))) {
		return (
			<Portlet style={{ marginBottom: '20px' }}>
				<UploadStart
					collection_id={collection_id}
					handleStartUpload={handleStartUpload}
					uploadStatus={uploadStatus}
				/>
			</Portlet>
		)
	} else if (uploadStatus === 'uploading' || uploadStatus === 'uploading-empty') {
		return (
			<Portlet style={{ marginBottom: '20px' }}>
				<UploadProgress
					handleCancelUpload={handleCancelUpload}
					handleUploadCompleted={handleUploadCompleted}
					collection_id={collection_id}
					files={files}
				/>
			</Portlet>
		)
	} else {
		return null
	}
}

export default Upload;
