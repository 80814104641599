import React from 'react';
import Iframe from 'react-iframe';
import PropTypes from 'prop-types';

import { IframeWrapper } from './styles';
import { Content } from '../../styles/components';

const Albuns = (props) => {
  return (
    <Content>
      <Iframe
        url="https://albums.picsize.com.br"
        width="100%"
        height="100%"
        display="initial"
      />
    </Content>
  );
};

Albuns.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default Albuns;
