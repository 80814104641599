import React, { useState, useEffect } from 'react';
import { Icon } from 'antd';
import { Typography } from '@material-ui/core';
import moment from 'moment';
import { useDispatch } from 'react-redux';

import { Creators as GalleryActions } from '../../../store/ducks/gallery';

import Api from '../../../services/api';
import { usePlan } from '../../../services/plan';

import useStyles, { Content } from './styles';

import { notifyWarn, notifySuccess } from '../../../utils/notificationService';

import {
  FormEdit,
  FormSubGroup,
  FormDate,
  FormSwitch,
  FormLabel,
  EmptyEditPlaceholder,
  InputGroup,
  EditField,
  SwitchContent,
  Portlet
} from '../../../styles/components';

import Label from '../../../components/Form/Label';
import Small from '../../../components/Form/Small';
import Switch from '../../../components/Form/Switch';
import Input from '../../../components/Form/Input';
import TextArea from '../../../components/TextArea';
import EditDialog from '../../../components/EditDialog';
import Pill from '../../../components/Pill';
import DatePicker from '../../../components/Form/DatePicker';
import SwitchGroup from '../../../components/Form/SwitchGroup';
import Divider from '../../../components/Divider';
import Loading from '../../../components/Loading';

import SwitchModal from './Modals/Switch';
import InfoCard from '../../../components/InfoCard';

const GallerySettings = (props) => {
  const dispatch = useDispatch();
  const { checkSolution } = usePlan();
  const classes = useStyles();
  const { collection_id } = props.match.params;

  const [loading, setLoading] = useState(true);

  const [data, setData] = useState({
    ready: false,
    title: '',
    event_date: moment(),
    allow_share: false,
    right_click: false,
    share_title: '',
    share_description: '',
    description: '',
    anti_copy: false,
    showWatermarkWarning: false
  });

  const getData = async () => {
    const res = await Api.get(
      `/gallery/settings?collection_id=${collection_id}`
    );
    const {
      title,
      event_date,
      allow_share,
      share_title,
      share_description,
      description,
      anti_copy,
      showWatermarkWarning
    } = res.data;
    setData({
      ready: true,
      title,
      event_date: event_date ? moment(event_date) : null,
      allow_share,
      share_title,
      share_description,
      description,
      anti_copy,
      showWatermarkWarning: !!showWatermarkWarning
    });
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const edit = async (key, value) => {
    setData({ [key]: value });

    const prev = { [key]: data[key] };

    setData({ ...data, [key]: value });

    try {
      await Api.put(`/gallery/${collection_id}/settings`, { [key]: value });

      if (key === 'title') {
        dispatch(GalleryActions.setInfo({ [key]: value }));
      }

      notifySuccess('As alterações foram salvas.');

      return true;
    } catch (ex) {
      setData({ ...data, ...prev });
      notifyWarn('Não foi possível salvar as alterações.');
      return false;
    }
  };

  const switchEdit = async (key, value, extra) => {
    try {
      await Api.put(`/gallery/${collection_id}/settings`, {
        [key]: value,
        ...extra
      });

      notifySuccess('As alterações foram salvas.');

      setData({ ...data, [key]: value });

      return true;
    } catch (ex) {
      return ex;
    }
  };

  return (
    <Loading loading={loading}>
      <Portlet>
        <Content>
          <FormEdit>
            <Label>Nome da galeria</Label>

            <EditField>
              <EditDialog
                name="Nome da galeria"
                value={data.title}
                onSave={(value) => edit('title', value)}
              >
                <Input value={data.title} readOnly />
                <Icon type="edit" />
              </EditDialog>
            </EditField>
          </FormEdit>

          <Divider size="medium" />

          <FormDate>
            <Label>Data das fotos</Label>
            <InputGroup>
              <DatePicker
                onChange={(value) => edit('event_date', value)}
                value={data.event_date}
                format="DD/MM/YYYY"
                placeholder="Sem data"
              />
              <EmptyEditPlaceholder />
            </InputGroup>
            <Small>Informe a data do evento.</Small>
          </FormDate>

          {data?.showWatermarkWarning && (
            <>
              <Divider size="medium" />

              <InfoCard
                severity="warning"
                title="APLICAÇÃO DE MARCA D’ÁGUA ALTERADA"
              >
                <Typography className={classes.contentWatermarkWarning}>
                  A aplicação da Marca D’água agora é feita na página de fotos.
                  <br />
                  Acesse “Fotos” no menu ao lado e em seguida acesse “Marca
                  D’água” no menu acima da listagem das fotos.
                </Typography>
              </InfoCard>
            </>
          )}

          <Divider size="medium" />

          <FormLabel>
            <Label>Proteção contra cópia</Label>
          </FormLabel>

          <Divider size="small" />

          <FormSubGroup>
            <FormSwitch>
              <SwitchGroup>
                <Label>Aplicar carimbo de cópia não autorizada</Label>
                <SwitchContent>
                  <SwitchModal
                    name="anti_copy"
                    value={data.anti_copy}
                    onSave={(value, extra) =>
                      switchEdit('anti_copy', value, extra)
                    }
                    validateSolution={true}
                    limitTypeKey="gallery_limits"
                    limitKey="custom_no_copy"
                  >
                    <Switch checked={data.anti_copy} />
                  </SwitchModal>
                  {!checkSolution('gallery_limits', 'custom_no_copy') && (
                    <Pill />
                  )}
                </SwitchContent>
              </SwitchGroup>
              <Small>
                Para maior segurança, será exibido em todas as fotos de um
                carimbo com a mensagem "<b>Cópia não Autorizada</b>" evitando
                cópias, impressões e divulgações não autorizadas.
              </Small>
            </FormSwitch>
          </FormSubGroup>

          <Divider size="medium" />

          <FormSwitch>
            <SwitchGroup>
              <Label>Permitir compartilhamento?</Label>
              <Switch
                checked={data.allow_share}
                onChange={(value) => edit('allow_share', value)}
              />
            </SwitchGroup>
            <Small>
              Ative caso deseje que o seu cliente compartilhe a galeria nas
              redes sociais.
            </Small>
          </FormSwitch>

          {data.allow_share ? (
            <>
              <Divider size="small" />

              <FormSubGroup>
                <FormEdit>
                  <Label>Título</Label>
                  <EditField>
                    <EditDialog
                      name="Título"
                      value={data.share_title}
                      onSave={(value) => edit('share_title', value)}
                    >
                      <Input value={data.share_title} readOnly />
                      <Icon type="edit" />
                    </EditDialog>
                  </EditField>
                </FormEdit>

                <Divider size="medium" />

                <FormEdit>
                  <Label>Descrição</Label>
                  <EditField>
                    <EditDialog
                      name="Descrição"
                      value={data.share_description}
                      type="textarea"
                      onSave={(value) => edit('share_description', value)}
                    >
                      <TextArea value={data.share_description} readOnly />
                      <Icon type="edit" />
                    </EditDialog>
                  </EditField>
                </FormEdit>
              </FormSubGroup>
            </>
          ) : null}

          <Divider size="medium" />

          <FormEdit>
            <Label>Texto de instrução</Label>
            <EditField>
              <EditDialog
                name="Texto de instrução"
                smallText="Esse é um espaço reservado para você inserir alguma mensagem para o seu cliente. Será apresentado apenas na seleção de fotos."
                value={data.description}
                type="textarea"
                onSave={(value) => edit('description', value)}
              >
                <TextArea size="200px" value={data.description} readOnly />
                <Icon type="edit" />
              </EditDialog>
            </EditField>
            <Small>
              Esse é um espaço reservado para você inserir alguma mensagem para
              o seu cliente. Será apresentado apenas na seleção de fotos.
            </Small>
          </FormEdit>
        </Content>
      </Portlet>
    </Loading>
  );
};

export default GallerySettings;
