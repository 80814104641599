import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Modal, Row, Col } from 'antd';
import sadIcon from '../../../../../../assets/icon/sad.png';
import smileIcon from '../../../../../../assets/icon/smile.png';
import {
  Container,
  TableWrapper,
  TextUnderline,
  Left,
  Right,
  IconsWrapper,
  FirstIcon,
  SecondIcon
} from './styles';

import Button from '../../../../../Button';

const UploadWarningModal = (props) => {
  const { succededList, failedList, openPhotos } = props;

  const [visibility, setVisibility] = useState(true);

  const handleClick = (type) => {
    if (type === 'close') {
      setVisibility(false);
    } else if (type === 'photos') {
      openPhotos();
      setVisibility(false);
    }
  };

  return (
    <Modal
      visible={visibility}
      onOk={() => handleClick('close')}
      onCancel={() => handleClick('close')}
      footer={null}
      width={950}
      bodyStyle={{ padding: '0' }}
    >
      <Container>
        <Row type="flex">
          <Col xs={24} sm={24} lg={8} xl={10}>
            <Left mobile={window.innerWidth < 980}>
              <Button
                onClick={() => handleClick('photos')}
                type="neutral"
                style={{ width: '80%' }}
              >
                FECHAR
              </Button>
            </Left>
          </Col>
          <Col xs={24} sm={24} lg={16} xl={14}>
            <Right mobile={window.innerWidth < 980}>
              <h3>Detalhes do envio das suas fotos</h3>
              <IconsWrapper>
                <FirstIcon>
                  <img src={smileIcon} width={28} alt="Smile Icon" />
                  <span>
                    <b>{succededList.length}</b> fotos subiram com sucesso.
                  </span>
                </FirstIcon>
                <SecondIcon>
                  <img src={sadIcon} width={28} alt="Sad Icon" />
                  <span>
                    Não conseguimos completar o envio de{' '}
                    <b>{failedList.length}</b> fotos. Veja o relatório abaixo e
                    envie novamente.
                  </span>
                </SecondIcon>
              </IconsWrapper>
              <TextUnderline>
                <a
                  href="https://blog.picsize.com.br/erro-de-upload/"
                  target="_blank"
                >
                  Entenda os principais motivos que causam problemas ao subir
                  fotos.
                </a>
              </TextUnderline>

              <TableWrapper>
                <h2>Lista de fotos que não subiram</h2>
                <table>
                  <tbody>
                    {failedList.map((picture, index) => (
                      <tr>
                        <td>{index + 1}</td>
                        <td>{picture.title}</td>
                        <td>{picture.error}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </TableWrapper>
            </Right>
          </Col>
        </Row>
      </Container>
    </Modal>
  );
};

UploadWarningModal.open = (props) => {
  const { succededList, failedList, openPhotos } = props;

  let uploadWarningModalNode = document.getElementById('upload-warning-modal');

  if (uploadWarningModalNode) {
    ReactDOM.unmountComponentAtNode(uploadWarningModalNode);
  } else {
    uploadWarningModalNode = document.createElement('div');
    uploadWarningModalNode.setAttribute('id', 'upload-warning-modal');
    document.body.appendChild(uploadWarningModalNode);
  }

  ReactDOM.render(
    <UploadWarningModal
      succededList={succededList}
      failedList={failedList}
      openPhotos={openPhotos}
    />,
    uploadWarningModalNode
  );
};

UploadWarningModal.propTypes = {
  succededList: PropTypes.array.isRequired,
  failedList: PropTypes.array.isRequired,
  openPhotos: PropTypes.func.isRequired
};

export default UploadWarningModal;
