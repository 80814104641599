class LocalStorageService {
  supportsLocalStorage() {
    try {
      return 'localStorage' in window && window['localStorage'] !== null;
    } catch (e) {
      return false;
    }
  }

  set(key, data) {
    if (this.supportsLocalStorage()) {
      const stringData = JSON.stringify(data);

      const encodedData = btoa(stringData);

      localStorage.setItem(key, encodedData);
    }
  }

  get(key) {
    if (this.supportsLocalStorage()) {
      const encodedData = localStorage.getItem(key);

      if (!encodedData) {
        return null;
      }

      const stringData = atob(encodedData);

      const data = JSON.parse(stringData);

      return data;
    } else {
      return null;
    }
  }

  delete(key) {
    if (this.supportsLocalStorage()) {
      localStorage.removeItem(key);
    }
  }
}

export default new LocalStorageService();
