import styled from 'styled-components';

export const ModalContent = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	width: 100%;
	margin: auto;

	i {
		margin-right: 16px;
	}
`;

export const ButtonWrapper = styled.div`
	width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
	margin: 8px 0 8px auto;
  
  button {
    margin-left: 10px;
  }
`;