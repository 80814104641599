import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';

import Picture from '../pages/Portfolio/WorkCloseup/Picture';
import Settings from '../pages/Portfolio/WorkCloseup/Settings';

const PortfolioRoutes = () => (
  <>
    <Switch>
      <Route path="/portfolio/works/:work_id/pictures" component={Picture} />
      <Route path="/portfolio/works/:work_id/settings" component={Settings} />
    </Switch>
  </>
);

export default withRouter(PortfolioRoutes);
