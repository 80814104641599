import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Icon } from 'antd';

import {
  notifyWarn,
  notifySuccess,
  notifyError
} from '../../../utils/notificationService';

import Button from '../../Button';
import PopConfirm from '../../PopConfirm';
import Dialog from '../../Dialog';

import { Creators as GalleryActions } from '../../../store/ducks/gallery';

import Api from '../../../services/api';

import useStyles, {
  Container,
  ButtonWrapper,
  ButtonsList,
  ButtonsListItem
} from './styles';

import OrdinationModal from './Modals/Ordination';
import SuggestionModal from './Modals/Suggestion';

import waterMarkIconImg from '../../../assets/icon/waterMarkIcon.svg';
import SelectWaterMark from '../../Watermark/Select';
import Divider from '../../Divider';
import { Grid, Typography } from '@material-ui/core';
import Pill from '../../Pill';
import { usePlan } from '../../../services/plan';

const PictureHeader = (props) => {
  const listRef = useRef(null);
  const dispatch = useDispatch();
  const images = useSelector((state) => state.gallery.images);
  const classes = useStyles();
  const { checkSolution } = usePlan();

  const galleryInfo = useSelector((state) => state.gallery.info);
  const { pictures_ordenation, uploadStatus } = galleryInfo;

  const [showDelete, setShowDelete] = useState(false);
  const [showUnselectAll, setShowUnselectAll] = useState(false);
  const [showArr, setShowArr] = useState({
    1: [images],
    2: [images],
    3: [images],
    4: [images],
    5: [images],
    Azul: [images],
    Vermelho: [images],
    Verde: [images],
    Amarelo: [images],
    Púrpura: [images]
  });
  const [tags, setTags] = useState({
    1: { checked: false, indeterminate: false },
    2: { checked: false, indeterminate: false },
    3: { checked: false, indeterminate: false },
    4: { checked: false, indeterminate: false },
    5: { checked: false, indeterminate: false },
    Azul: { checked: false, indeterminate: false, color: 'blue' },
    Vermelho: { checked: false, indeterminate: false, color: 'red' },
    Verde: { checked: false, indeterminate: false, color: 'green' },
    Amarelo: { checked: false, indeterminate: false, color: 'gold' },
    Púrpura: { checked: false, indeterminate: false, color: 'purple' }
  });

  const [openWaterMarkDialog, setOpenWaterMarkDialog] = useState(false);

  const { collection_id } = props;

  useEffect(() => {
    const shouldShowDelete = images.find((image) => image.isSelected);
    setShowDelete(shouldShowDelete);

    // verify if all images was selected
    const imagesSelected = images.filter((image) => image.isSelected);
    setShowUnselectAll(imagesSelected.length === images.length);
  }, [images]);

  useEffect(() => {
    showDelete && listRef.current.scrollTo(2000, 0);
  }, [showDelete]);

  const handleDeletePictures = async () => {
    const selectedImageIds = images
      .filter((image) => image.isSelected)
      .map((image) => image.id)
      .join(',');

    try {
      await Api.delete(
        `/pictures?collection_id=${collection_id}&picture_ids=${selectedImageIds}`
      );
      notifySuccess('Fotos deletadas com sucesso.');
    } catch (err) {
      notifyError('Não foi possível deletar as fotos.');
    }

    await getImages();
  };

  const getAllImages = async () => {
    const res = await Api.get(`/pictures?collection_id=${collection_id}`);
    return Object.values(res.data.pictures);
  };

  const showArrUpdate = (all_images) => {
    const picturesWithMeta = all_images.filter(
      (image) =>
        image.classification &&
        (image.classification.label || image.classification.rating)
    );

    const arr = {};

    Object.keys(tags).map((tag) => {
      arr[tag] = picturesWithMeta.filter(
        (image) =>
          image.classification.rating === +tag ||
          image.classification.label === tag
      );
    });

    return arr;
  };

  const tagsUpdate = (show_arr) => {
    let tmpObj = tags;
    Object.keys(show_arr).map((tag) => {
      const full = show_arr[tag].filter((img) => img.suggested === true);
      if (full.length === show_arr[tag].length)
        tmpObj = { ...tmpObj, [tag]: { ...tmpObj[tag], checked: true } };

      const none = show_arr[tag].filter((img) => img.suggested === false);
      if (none.length === show_arr[tag].length)
        tmpObj = { ...tmpObj, [tag]: { ...tmpObj[tag], checked: false } };

      if (full.length && full.length < show_arr[tag].length)
        tmpObj = {
          ...tmpObj,
          [tag]: { ...tmpObj[tag], checked: false, indeterminate: true }
        };
      else
        tmpObj = { ...tmpObj, [tag]: { ...tmpObj[tag], indeterminate: false } };
    });

    return tmpObj;
  };

  const selectAll = () => {
    dispatch(GalleryActions.selectAll());
  };

  const unselectAll = () => {
    dispatch(GalleryActions.unselectAll());
  };

  const handleChangeOrdination = async (pictures_ordenation) => {
    dispatch(GalleryActions.setInfo({ pictures_ordenation }));

    try {
      await Api.put(
        `/gallery/${collection_id}/pictures/ordenation/${pictures_ordenation}`
      );
      notifySuccess('Ordenação alterada com sucesso!');
    } catch (error) {
      notifyWarn('Não foi possível alterar a ordenação.');
    }

    getImages();
  };

  const initSuggestion = async () => {
    const all_images = await getAllImages();
    const show_arr = showArrUpdate(all_images);
    setShowArr(show_arr);

    const obj_tags = tagsUpdate(show_arr);

    setTags(obj_tags);
  };

  const handleChangeSuggestion = async (tag) => {
    const bool = !tags[tag].checked;
    setTags({
      ...tags,
      [tag]: { ...tags[tag], checked: bool, indeterminate: false }
    });

    try {
      await Api.put(
        `/pictures/gallery/${collection_id}/tag/${tag}/suggest/${bool}`
      );

      if (bool === true) {
        notifySuccess('As imagens foram sugeridas.');
      } else if (bool === false) {
        notifySuccess('As alterações foram salvas.');
      }

      getImages();
    } catch (err) {
      setTags({ ...tags, [tag]: { ...tags[tag], checked: !bool } });
      notifyWarn('Não foi possível sugerir.');
    }
  };

  const getImages = async () => {
    try {
      const response = await Api.get(
        `/pictures?collection_id=${collection_id}&page=${galleryInfo.page}`
      );
      const { pictures, total_pictures } = response.data;

      dispatch(GalleryActions.setInfo({ total_pictures }));
      dispatch(GalleryActions.setImages(pictures));
    } catch (error) {
      const status = error && error.response && error.response.status;

      if (status === 404) {
        dispatch(GalleryActions.setImages([]));
        dispatch(GalleryActions.setInfo({ uploadStatus: 'gallery-empty' }));
      }
    }
  };

  const handleUploadPictures = () => {
    if (
      galleryInfo.uploadStatus === 'uploading' ||
      galleryInfo.uploadStatus === 'uploading-empty'
    ) {
      return;
    }

    dispatch(GalleryActions.setInfo({ uploadStatus: 'waiting' }));
  };

  const handleOpenConfirmDeletePicturesModal = () => {
    PopConfirm.open({
      title: 'Tem certeza?',
      cancelText: 'CANCELAR',
      confirmText: 'DELETAR',
      confirmIcon: 'delete',
      info:
        'Deletar fotos é uma ação irreversível. Você perderá todos os dados destas fotos.',
      onConfirm: handleDeletePictures
    });
  };

  const handleWaterMarkOpen = () => {
    setOpenWaterMarkDialog(true);
  };

  const handleWaterMarkClose = async () => {
    await getImages();
    setOpenWaterMarkDialog(false);
  };

  return (
    <Container ref={listRef}>
      <ButtonsList>
        <ButtonsListItem>
          <Button
            type="neutral"
            onClick={() => handleUploadPictures()}
            style={{ color: uploadStatus === 'waiting' && '#FF4949' }}
          >
            <Icon type="cloud-upload" />
            SUBIR FOTOS
          </Button>
        </ButtonsListItem>

        <ButtonsListItem>
          <Button type="neutral" onClick={() => handleWaterMarkOpen()}>
            <Grid
              container
              alignItems="center"
              style={{ height: '100%' }}
              wrap="nowrap"
            >
              <img
                className="listIcon"
                src={waterMarkIconImg}
                alt="waterMarkIcon"
              />
              MARCA D'ÁGUA
            </Grid>
          </Button>
        </ButtonsListItem>

        <ButtonsListItem>
          <OrdinationModal
            handleChangeOrdination={handleChangeOrdination}
            pictures_ordenation={pictures_ordenation}
          >
            <Button type="neutral">
              <Icon type="sort-ascending" />
              ORDENAÇÃO
            </Button>
          </OrdinationModal>
        </ButtonsListItem>
        <ButtonsListItem>
          <SuggestionModal
            initSuggestion={initSuggestion}
            handleChangeSuggestion={handleChangeSuggestion}
            tags={tags}
            showArr={showArr}
          >
            <Button type="neutral">
              <Icon type="star" theme="filled" />
              SUGERIR EM LOTE
            </Button>
          </SuggestionModal>
        </ButtonsListItem>
        {showUnselectAll ? (
          <ButtonsListItem>
            <Button type="neutral" onClick={unselectAll}>
              <Icon type="close-circle" />
              SELECIONAR TODAS
            </Button>
          </ButtonsListItem>
        ) : (
          <ButtonsListItem>
            <Button type="neutral" onClick={selectAll}>
              <Icon type="check-circle" />
              SELECIONAR TODAS
            </Button>
          </ButtonsListItem>
        )}
      </ButtonsList>

      {showDelete && (
        <ButtonWrapper>
          <Button
            type="primary"
            icon="delete"
            onClick={handleOpenConfirmDeletePicturesModal}
          >
            DELETAR SELECIONADAS
          </Button>
        </ButtonWrapper>
      )}

      <Dialog
        title={
          <Grid container justify="space-between">
            <Grid item xs={12} sm={7}>
              <Typography className={classes.titleDialog}>
                Selecione a marca d’água para essa galeria
              </Typography>
            </Grid>

            {!checkSolution('gallery_limits', 'custom_watermark') && (
              <Grid
                item
                xs={12}
                sm={4}
                className={classes.premiumChipContainer}
              >
                <Grid
                  container
                  justify="flex-end"
                  className={classes.premiumChipWrapper}
                >
                  <Pill />
                </Grid>
              </Grid>
            )}
          </Grid>
        }
        isOpen={openWaterMarkDialog}
        onClose={handleWaterMarkClose}
        fullWidth
        maxWidth="lg"
      >
        <SelectWaterMark
          onError={() => handleWaterMarkClose()}
          collectionId={collection_id}
        />

        <Divider size="medium" />
      </Dialog>
    </Container>
  );
};

export default withRouter(PictureHeader);
