import styled from 'styled-components';

export const Container = styled.div`
	width: 100%;
	height: 100%;
`;

export const Header = styled.div`
	button, .ant-input-search {
		width: 100%;
	}
`;

export const ModalContent = styled.div`
	display: flex;
	flex-direction: column;
	padding: 40px;

	justify-content: center;

	h3 {
		text-align: center;
	}
`;

export const NewButtonWrapper = styled.div`
	button {
		font-size: 14px;
		border-radius: 50%;
		padding: 6px 12px;
		width: 40px;
		height: 40px;
	}
`;

export const Body = styled.div`
	width: 100%;
	height: 100%;
`;

export const BodyTitle = styled.div`
	width: 100%;
  padding: 0 16px;
  font-weight: 600;
  font-size: 14px;
  line-height: 40px;
  color: #444444;
  text-transform: uppercase;
	margin: 16px 0;
`;

export const Card = styled.div`
	width: 100%;
	height: 145px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 30px 24px;

	background-color: #FFF;
	border-radius: 5px;
	/* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.07); */
`;

export const CardCover = styled.div`
	width: 100px;
	height: 100px;
	min-width: 100px;
	min-height: 100px;

  line-height: ${(props) => (props.isPlaceholder ? '100px' : null)};
  background: ${(props) => (props.isPlaceholder ? '#F8F8F8' : null)};
  text-align: ${(props) => (props.isPlaceholder ? 'center' : null)};
  vertical-align: ${(props) => (props.isPlaceholder ? 'middle' : null)};

  img {
    width: ${(props) => (props.isPlaceholder ? '45px' : '100px')};
    height: ${(props) => (props.isPlaceholder ? 'auto' : '100px')};
    object-fit: cover !important;
		object-position: center;
    border-radius: 4px;
  }
`;

export const CardTitle = styled.div`
`;

export const CardContent = styled.div`
	width: 100%;
	height: 100%;
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	margin-left: 30px;
`;

export const CardBody = styled.div`
	width: auto;
	height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
	flex-direction: column;

	a {
		display: block;
		width: 100%;
		height: 100%;
		font-size: 20px;
		color: #323232;
		position: relative;
		cursor: pointer;
    line-height: 1.2;

		&:hover {
			color: #ff4949;
		}
	}
`;

export const CardIcons = styled.div`
	position: absolute;
	right: 0;
	width: auto;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;

	i {
		cursor: pointer;
	}
`;

export const CardTags = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
`;

export const PaginationWrapper = styled.div`
	text-align: right;

  li:hover,
  li:focus {
    color: #fff;
    border-color: #56C568;

    a,
    .ant-pagination-item-link {
      color: #fff;
      background-color: #56C568;
      border-color: #56C568;
    }
  }

  .ant-pagination-disabled:hover {
    a {
      color: rgba(0, 0, 0, 0.25);
      border-color: #d9d9d9;
      background-color: transparent;
    }
  }

  .ant-pagination-item-active {
    background-color: #56C568;
    border-color: #56C568;

    a {
      color: #fff;
    }
  }

  a:hover {
    color: #fff;
  }

  .ant-pagination-jump-next,
  .ant-pagination-jump-prev {
    .ant-pagination-item-container {
      .ant-pagination-item-link-icon {
        color: #56C568;
      }
    }
  }
`;

export const NoWorks = styled.div`
	display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const NoWorksTitle = styled.div`
	font-size: 18px;
	font-weight: bold;
	margin-bottom: 12px;
`;
