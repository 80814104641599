import styled from 'styled-components';

export const Body = styled.div`
	width: 100%;
	padding: 0 24px;
`;

export const MiniTitle = styled.span`
	color: #8F99A6;
	font-weight: bolder;

	font-size: 12px;
	line-height: 13px;
`;

export const CreditCardBrandsContainer = styled.div`
	display: flex;

	max-width: 400px;

	-webkit-flex-wrap: wrap;
  flex-wrap: wrap;
`;

export const CreditCardBrand = styled.img`
	height: 30px;

	transition: opacity 0.15s ease-in-out;

	opacity: ${({ isSelected, isValidating }) => !isSelected && isValidating && '0.2'};

	margin-right: 15px;
	margin-bottom: 15px;
`;

export const PaymentSlip = styled.img`
	height: 45px;
	width: auto;

	user-select: none;
`;

export const Footer = styled.div`
	padding: 0 24px;

	text-align: right;
`;

export const PaymentDetailsContainer = styled.div`
	pointer-events: ${({ isEnabled }) => !isEnabled && 'none'};

	transition: opacity 0.15s ease-in-out;

	opacity: ${({ isEnabled }) => !isEnabled && '0.3'};

	user-select: ${({ isEnabled }) => !isEnabled && 'none'};
`;