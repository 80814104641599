import styled from 'styled-components';
import { Alert as AntAlert } from 'antd';

export const Opener = styled.div`
`;

export const Content = styled.div`
	width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 0;
  margin: 3px 0;
  cursor: pointer;
  transition: box-shadow 100ms ease-in;

  &:hover{
    background: #e6f7ff;
  }

  .ant-checkbox-wrapper:hover,
  .ant-checkbox:hover {
    .ant-checkbox-inner {
      border-color: #56C568;
    }
  }

  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #56C568;
  }

  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: #56C568;
      border-color: #56C568;
    }
  }

  .ant-checkbox-checked:after {
    border-color: #56C568;
  }

  .ant-checkbox-indeterminate {
    .ant-checkbox-inner:after {
      background-color: #56C568;
    }
  }
`;

export const Alert = styled(AntAlert)`
	text-align: center;
`;

export const TotalSuggested = styled.span`
	margin-left: 32px;
  font-weight: bolder;
`;

export const TagWrapper = styled.div`
	display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 50px;
  min-width: 130px;
  width: 130px;
  margin-left: 32px;

  .ant-rate-star.ant-rate-star-full,
  .ant-rate-disabled .ant-rate-star,
  .ant-rate-star.ant-rate-star-zero,
  .ant-rate-star,
  .ant-rate,
  .ant-rate-disabled,
  .ant-rate-star.ant-rate-star-half.ant-rate-star-active {
		cursor: pointer;
		transition: none;
  }

  .ant-rate-star-zero {
    .ant-rate-star-first,
    .ant-rate-star-second {
      color: #BBB;
    }
  }

  .ant-rate-star > div:hover, .ant-rate-star > div:focus {
		transform: none;
  }

  .ant-rate-star:hover {
		transform: none;
  }

  .ant-tag {
		cursor: pointer;
  }

  .ant-tag:hover {
		opacity: 1;
  }

  &:hover {
    transform: none;
  }
`;