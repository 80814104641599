import React, { useState, useEffect } from 'react';
import { Row, Col } from 'antd';

import api from '../../../services/api';
import { useValidation } from '../../../services/validation';

import { Logo, RecoverPassword } from './styles';

import Button from '../../../components/Button';
import Label from '../../../components/Form/Label';
import Input from '../../../components/Form/Input';
import Divider from '../../../components/Divider';
import FormItem from '../../../components/Form/FormItem';
import SubmitContainer from '../../../components/Form/SubmitContainer';
import InfoModal from '../../../components/InfoModal';

import { FormInput, ContainerTransparent } from '../../../styles/components';

import { notifySuccess } from '../../../utils/notificationService';
import { getStatusCode, getErrorCode } from '../../../utils/response';

import logo from '../../../assets/logo.png';

const ResetPassword = (props) => {
  const token = props.location.search.replace('?token=', '');

  const inputs = ['password'];

  const [loading, setLoading] = useState(false);
  const [validation, clearValidation, triggerValidation] = useValidation(inputs);
  const [password, setPassword] = useState('');

  const handleRecoverPassword = async () => {
    setLoading(true);

    try {
      await api.put('/customer/password/recovery', { password, token });

      notifySuccess('Senha alterada com sucesso!');

      props.history.push('/login');
    } catch (error) {
      const errorCode = getErrorCode(error);

      if (
        errorCode === 'PasswordRecoveryFailed'
        || errorCode === 'InvalidResetToken'
      ) {
        InfoModal.warning({
          title: 'Erro ao trocar de senha',
          info: 'O link para a alteração de senha expirou.',
          buttonCallback: () => props.history.push('/recovery/request'),
          buttonText: 'Redefinir Senha',
          width: 500,
        });
      } else {
        triggerValidation(error);
      }
    }

    setLoading(false);
  };

  const handleChange = (key, value) => {
    setPassword(value);
    clearValidation(key);
  };

  return (
    <ContainerTransparent>
      <Row>
        <Col style={{ padding: '30px', width: '100%' }}>
          <RecoverPassword>
            <SubmitContainer onSubmit={handleRecoverPassword}>
              <Logo src={logo} alt="logo" />

              <Divider size="large" />

              <Label>Nova senha</Label>
              <FormInput>
                <FormItem
                  validateStatus={validation.password.validateStatus}
                  help={validation.password.help}
                >
                  <Input
                    type="password"
                    onChange={({ target }) =>
                      handleChange('password', target.value)
                    }
                    value={password}
                  />
                </FormItem>
              </FormInput>

              <Divider size="large" />

              <Button
                htmlType="submit"
                loading={loading}
                type="primary"
                block
              >
                ALTERAR SENHA
              </Button>
            </SubmitContainer>
          </RecoverPassword>
        </Col>
      </Row>
    </ContainerTransparent>
  );
};

export default ResetPassword;
