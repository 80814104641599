// Action Types
export const Types = {
  SELECT_SUBMENU: 'galleryAsideMenu/SELECT_SUBMENU',
  SELECT_MENU: 'galleryAsideMenu/SELECT_MENU'
};


// Reducer
const initialState = {
  subMenuSelected: '',
  menuSelected: '',
};

const gallery = (state = initialState, action) => {
  switch (action.type) {
    case Types.SELECT_SUBMENU:
      return {
        ...state,
        subMenuSelected: action.payload,
      };
    case Types.SELECT_MENU:
      return {
        ...state,
        menuSelected: action.payload,
      };
    default:
      return state;
  }
};

export default gallery;


// Action Creators
export const Creators = {
  selectSubMenu: (selected) => ({
    type: Types.SELECT_SUBMENU,
    payload: selected,
  }),
  selectMenu: (selected) => ({
    type: Types.SELECT_MENU,
    payload: selected,
  })
};
