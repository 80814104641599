import React, { useState, useEffect } from 'react';
import { Radio, Col, Row } from 'antd';
import { useHistory, useParams } from 'react-router-dom';

import Divider from '../../../components/Divider';
import Loading from '../../../components/Loading';
import Accordion from '../../../components/Accordion';

import api from '../../../services/api';

import PlanCard from './PlanCard';

import { galleryProData } from './PlanData/galleryPro';
import { siteProData } from './PlanData/sitePro';

import {
  RadioGroup,
  Container,
  Title,
  SubTitle,
  ExtraInfo,
  useStyles
} from './styles';

import { Typography, Container as ContainerMui } from '@material-ui/core';

const defaultPlanData = {
  price: { monthly: 0, annually: 0 },
  display_name: '',
  name: ''
};

const faqQuestions = [
  {
    title: 'Eu pago alguma taxa para realizar o cancelamento?',
    description:
      'Não. Caso queira cancelar basta entrar em contato com o nosso financeiro solicitando o cancelamento e não será cobrado nenhum taxa adicional.'
  },
  {
    title: 'O plano anual pode ser pago por boleto?',
    description:
      'Sim. Porém, para pagamento do plano anual em boleto não é possível parcelar. A cobrança será à vista.'
  },
  {
    title:
      'Os recursos que usei no teste de 14 dias são referentes a qual plano?',
    description:
      'O teste grátis de 14 dias do Gallery Pro te oferece a experiência do plano Plus.'
  },
  {
    title: 'No plano mensal eu só pago 1 mês?',
    description:
      'Os planos da Picsize são mediante assinaturas recorrentes. Desse modo, assinando um plano mensal a cobrança será realizada todos os meses enquanto sua conta estiver ativa.'
  },
  {
    title:
      'Os trabalhos que foram usados durante o teste continuam funcionando?',
    description:
      'Sim, assim que ativar o seu plano você continua com a mesma conta e consequentemente com as informações cadastradas durante o teste.'
  },
  {
    title: 'Por onde faço o pagamento das cobranças mensais?',
    description:
      'Se você optou por boleto bancário, ele será enviado mensalmente para o seu e-mail no caso de planos mensais e anualmente para planos anuais. Se optou por cartão de crédito, a cobrança é feita automaticamente no seu cartão todos os meses ou anualmente.'
  },
  {
    title:
      'Contratando um plano do Gallery Pro eu tenho direito ao Site Premium também?',
    description:
      'Não. A comercialização das galerias e do site são separadas. Portanto, para ter as duas soluções será necessário realizar a contratação de dois planos, um do Gallery Pro e outro que é o Site Premium.'
  },
  {
    title: 'Você oferece desconto no plano anual?',
    description:
      'Sim. A Picsize oferece desconto no plano anual de todos os produtos'
  }
];

const Plans = () => {
  const [loading, setLoading] = useState(true);
  const [planPeriodicity, setPlanPeriodicity] = useState('annually');
  const [plans, setPlans] = useState({
    col_basic_2: defaultPlanData,
    col_standard: defaultPlanData,
    col_plus: defaultPlanData,
    col_infinity: defaultPlanData,
    pro_site: defaultPlanData
  });

  const classes = useStyles();
  const history = useHistory();
  const { solution_name } = useParams();

  const handleChangePlanPeriodicity = ({ target }) => {
    setPlanPeriodicity(target.value);
  };

  const findInMapWhereKeyLike = (value, map) => {
    const selectedKey = Object.keys(map).find((key) => key.includes(value));

    return map[selectedKey] || {};
  };

  const getGalleryProSolutionData = async () => {
    const response = await api.get(
      `/checkout/plans/map?plan_ids=${galleryProData.planIds.join(
        ','
      )}&coupon_name=${galleryProData.coupon}`
    );

    const { plansMap } = response.data;

    setPlans({
      ...plansMap
    });

    setLoading(false);
  };

  const getSiteProSolutionData = async () => {
    const response = await api.get(
      `/checkout/plans/map?plan_ids=${siteProData.planIds.join(
        ','
      )}&coupon_name=${siteProData.coupon}`
    );

    const { plansMap } = response.data;

    setPlans({
      ...plansMap,
      SitePro: {
        ...findInMapWhereKeyLike('Pro', plansMap)
      }
    });

    setLoading(false);
  };

  const getData = async () => {
    if (solution_name === 'gallery-pro') {
      await getGalleryProSolutionData();
    } else if (solution_name === 'site-pro') {
      await getSiteProSolutionData();
    }
  };

  const handleGoToOverview = (planId) => {
    let couponName = '';

    if (planPeriodicity === 'annually') {
      if (solution_name === 'site-pro') {
        couponName = siteProData.coupon;
      }

      if (solution_name === 'gallery-pro') {
        couponName = galleryProData.coupon;
      }
    }

    history.push(
      `/checkout/${solution_name}/overview?plan_id=${planId}&periodicity_type=${planPeriodicity}&coupon_name=${couponName}`
    );
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Loading loading={loading} backgroundColor={null}>
      <Container>
        <Divider size="large" />
        <Title>
          Escolha o seu plano do{' '}
          {solution_name === 'gallery-pro' ? 'Gallery Pro' : 'Site Pro'}
        </Title>
        <Divider size="xlarge" />
        <SubTitle>
          A melhor solução{' '}
          {solution_name === 'gallery-pro'
            ? 'para entrega de fotos'
            : 'em sites para fotógrafo!'}
        </SubTitle>
        <Divider size="xlarge" />
        <RadioGroup
          value={planPeriodicity}
          onChange={handleChangePlanPeriodicity}
          buttonStyle="solid"
        >
          <Radio.Button value="monthly">Mensal</Radio.Button>
          <Radio.Button value="annually">Anual</Radio.Button>
        </RadioGroup>
        <Divider size="xlarge" />
        <ExtraInfo>
          Plano anual com 20% de desconto dividido em até 12 vezes sem juros.
        </ExtraInfo>
        <Divider size="xlarge" />
        {solution_name === 'gallery-pro' && (
          <Row gutter={[0, 48]}>
            {/* <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
              <PlanCard
                planName={plans.col_basic_2.name}
                planDisplayName={plans.col_basic_2.display_name}
                planPriceValue={plans.col_basic_2.price[planPeriodicity]}
                extraInfoTextBeforeButton={`(Cobrado R$ ${plans.col_basic_2.price[
                  planPeriodicity
                ]
                  .toFixed(2)
                  .replace('.', ',')} por ano divididos em até 12x)`}
                planFeatures={galleryProData.features.BASIC}
                planPeriodicity={planPeriodicity}
                onConfirm={() => handleGoToOverview(plans.col_basic_2.id)}
              />
            </Col> */}
            <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
              <PlanCard
                planName={plans.col_standard.name}
                planDisplayName={plans.col_standard.display_name}
                planPriceValue={plans.col_standard.price[planPeriodicity]}
                extraInfoTextBeforeButton={`(Cobrado R$ ${plans.col_standard.price[
                  planPeriodicity
                ]
                  .toFixed(2)
                  .replace('.', ',')} por ano divididos em até 12x)`}
                planFeatures={galleryProData.features.STANDARD}
                planPeriodicity={planPeriodicity}
                onConfirm={() => handleGoToOverview(plans.col_standard.id)}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8}>
              <PlanCard
                spotlight={true}
                planName={plans.col_plus.name}
                planDisplayName={plans.col_plus.display_name}
                planPriceValue={plans.col_plus.price[planPeriodicity]}
                extraInfoTextBeforeButton={`(Cobrado R$ ${plans.col_plus.price[
                  planPeriodicity
                ]
                  .toFixed(2)
                  .replace('.', ',')} por ano divididos em até 12x)`}
                extraInfoTextAfterButton={
                  <>
                    Todos os benefícios do plano
                    <br />
                    Standard 20GB mais:
                  </>
                }
                planFeatures={galleryProData.features.PLUS}
                planPeriodicity={planPeriodicity}
                onConfirm={() => handleGoToOverview(plans.col_plus.id)}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
              <PlanCard
                planName={plans.col_infinity.name}
                planDisplayName={plans.col_infinity.display_name}
                planPriceValue={plans.col_infinity.price[planPeriodicity]}
                extraInfoTextBeforeButton={`(Cobrado R$ ${plans.col_infinity.price[
                  planPeriodicity
                ].toFixed(2)} por ano divididos em até 12x)`}
                extraInfoTextAfterButton={
                  <>
                    Todos os benefícios do plano
                    <br />
                    Plus 100GB mais:
                  </>
                }
                planFeatures={galleryProData.features.INFINITY}
                planPeriodicity={planPeriodicity}
                onConfirm={() => handleGoToOverview(plans.col_infinity.id)}
              />
            </Col>
          </Row>
        )}
        {solution_name === 'site-pro' && (
          <Row gutter={[0, 48]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <PlanCard
                planName="Grátis"
                planDisplayName="Grátis"
                planPriceValue={0}
                planFeatures={siteProData.features.FREE}
                planPeriodicity={planPeriodicity}
                onConfirm={() => {}}
                buttonDisabled={true}
                customButtonText="Manter no Grátis"
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <PlanCard
                spotlight={true}
                planName={plans.pro_site.name}
                planDisplayName={plans.pro_site.display_name}
                planPriceValue={plans.pro_site.price[planPeriodicity]}
                extraInfoTextBeforeButton={`(Cobrado R$ ${plans.pro_site.price[
                  planPeriodicity
                ]
                  .toFixed(2)
                  .replace('.', ',')} por ano divididos em até 12x)`}
                extraInfoTextAfterButton="Todos os benefícios do plano Grátis mais:"
                planFeatures={siteProData.features.PROFESSIONAL}
                planPeriodicity={planPeriodicity}
                onConfirm={() => handleGoToOverview(plans.pro_site.id)}
              />
            </Col>
          </Row>
        )}
        <Divider size="large" />

        <Typography variant="h3" className={classes.titleFaq}>
          Perguntas frequentes
        </Typography>

        <Divider size="large" />

        <ContainerMui>
          <Accordion contentData={faqQuestions} />
        </ContainerMui>
      </Container>

      <Divider size="large" />
    </Loading>
  );
};

export default Plans;
