import React from 'react';
import { DatePicker as AntDatePicker } from 'antd';
import locale from 'antd/es/date-picker/locale/pt_BR';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Container } from './styles';

const DatePicker = (props) => {
  return (
    <Container>
      <AntDatePicker 
        placeholder="Sem prazo"
        /*disabledDate={(current) => !moment().isBefore(current)}*/
        locale={locale}
        format="DD/MM/YYYY"
        {...props}
      />
    </Container>
  );
};

DatePicker.defaultProps = {
  size: 'large',
};

DatePicker.propTypes = {
  size: PropTypes.oneOf(['large']),
};

export default DatePicker;
