import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap');
  @import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');

  *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;

    /* -------------    ANTD OVERWRITE    --------------- */
    /* Menu */
    .ant-menu-submenu {
      .ant-menu-submenu-title:hover {
        color: #ff4949;
      }
      .ant-menu-item-selected {
        a, a:hover {
          color: #ff4949;
        }
      }

      .ant-menu-item {
        a:hover {
          color: #ff4949;
        }
      }

      .ant-menu-item:hover, .ant-menu-item-active {
        color: #ff4949;
      }

      .ant-menu-item-selected {
        background-color: #fff !important;
      }
    }
    /* End Menu */

    /* Checkbox */
    .ant-checkbox-wrapper:hover,
    .ant-checkbox:hover {
      .ant-checkbox-inner {
        border-color: #56C568;
      }
    }

    .ant-checkbox-input:focus + .ant-checkbox-inner {
      border-color: #56C568;
    }

    .ant-checkbox-checked {
      .ant-checkbox-inner {
        background-color: #56C568;
        border-color: #56C568;
      }
    }

    .ant-checkbox-checked:after {
      border-color: #56C568;
    }

    .ant-checkbox-indeterminate {
      .ant-checkbox-inner:after {
        background-color: #56C568;
      }
    }
    /* End Checkbox */

    /* Modal */
    .ant-modal-title {
      font-weight: bold;
      font-size: 14px;
      color: #555555;
    }
    /* End Modal */

    /* Inputs */
    .ant-input-number {
      width: 100%;
    }
    /* End Inputs*/

    /* Drawer */
    .ant-drawer-title {
      font-weight: bold;
      font-size: 14px;
      color: #555555;
    }
    /* End Drawer */

    /* Notification */
    .ant-notification-notice-message, .ant-notification-notice-icon, .ant-notification-close-icon {
      color: white !important;
    }
    .ant-notification{
      z-index: 1500;
    }
    /* End Notification */

    /* Select */
    .ant-select-dropdown-menu-item {
      white-space: pre-wrap;
    }
    /* End Select */
    /* -------------------------------------------------- */
  }

  html, body, #root {
    height: 100%;
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;

    /*@media screen and (min-width: 1366px) {
      font-size: calc(12px + (14 - 12) * ((100vw - 1366px) / (1920 - 1366)));
    }*/
  }

  body{
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    background: #FFF;
  }

  button {
    cursor: pointer;
    text-transform: uppercase;
  }

  label{
    /*font-size: 18px;*/
    font-weight: bold;
    line-height: 25px;
  }

  input{
    /*font-size: 16px;*/
    line-height: 22px;
    color: #444444;
  }
`;

export default GlobalStyle;
