import api from './api';

const Pages = {
  SIGNUP: 'signup',
  LOGIN: 'login'
};

export const Query = {
  NEW_USER_SITE_PRO: '?site_pro_new_customer=true'
};

export const RedirectUrls = {
  SHOW_SOLUTION_WIZARD: `${window.origin}/wizard-solution-switch`,
  SITE_PRO: `${process.env.REACT_APP_SITE_PRO_URL}/portfolio`,
  GALLERY_PRO: window.origin
};

const generatePostLoginRedirectLog = async ({ redirectUrl, page, context }) => {
  const data = {
    key: 'login',
    value: {
      redirectUrl,
      page,
      context
    }
  };

  try {
    await api.post('/customer/log', data);
  } catch (error) {
    // eslint-disable-next-line
    console.error(error.message);
  }
};

export const postLoginRedirect = async (context, query = '') => {
  const lastUrlAccessedBeforeLogin = document.referrer || '';
  const currentUrl = window.location.href;

  const picsizeSolutionsUrl = [
    'gallery.picsize.com.br',
    'sitepro.picsize.com.br'
  ];

  if (process.env.NODE_ENV === 'development') {
    picsizeSolutionsUrl.push('localhost:3001');
    picsizeSolutionsUrl.push(process.env.REACT_APP_SITE_PRO_URL);
  }

  const lastUrlAccessedIsFromValidPicsizeSolution = picsizeSolutionsUrl.some(
    (solutionUrl) => lastUrlAccessedBeforeLogin.includes(solutionUrl)
  );

  const lastUrlAccessedIsLogoutPage = lastUrlAccessedBeforeLogin.includes(
    'logout'
  );
  const lastUrlAccessedIsSignupPage = lastUrlAccessedBeforeLogin.includes(
    'signup'
  );
  const currentUrlAccessedIsSignupPage = currentUrl.includes('signup');
  const currentUrlAccessedIsLoginPage = currentUrl.includes('login');

  let location = '';
  let page = '';

  if (currentUrlAccessedIsSignupPage) {
    page = Pages.SIGNUP;
  } else if (currentUrlAccessedIsLoginPage) {
    page = Pages.LOGIN;
  } else {
    page = '';
  }

  if (context === 'show_solution_wizard') {
    location = RedirectUrls.SHOW_SOLUTION_WIZARD;
  } else if (context === 'site_pro') {
    location = `${RedirectUrls.SITE_PRO}`;
  } else if (context === 'gallery_pro') {
    location = RedirectUrls.GALLERY_PRO;
  } else if (
    lastUrlAccessedBeforeLogin &&
    lastUrlAccessedBeforeLogin !== currentUrl &&
    !lastUrlAccessedIsLogoutPage &&
    !lastUrlAccessedIsSignupPage &&
    !currentUrlAccessedIsSignupPage &&
    lastUrlAccessedIsFromValidPicsizeSolution
  ) {
    location = lastUrlAccessedBeforeLogin;
  } else {
    location = `${window.origin}/`;
  }

  location = `${location}${query}`;

  /**
   * It must be done first since the requests are canceled
   * after we change location.
   */
  await generatePostLoginRedirectLog({
    redirectUrl: location,
    page,
    context
  });

  window.location = location;
};

export const activateSolution = async (context) => {
  try {
    if (context !== 'gallery_pro' && context !== 'site_pro') {
      return;
    }

    await api.post(`/subscription/product/${context}/activate`);
  } catch (error) {
    // eslint-disable-next-line
    console.error(error.message);
  }
};

export const getContextFromAuthData = (authData) => {
  if (!authData) {
    return null;
  }

  return authData.redirect_user;
};
