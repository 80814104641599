import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: ${({ width }) => width};
`;

export const ProgressContainer = styled.div`
  width: 100%;

  display: flex;

  height: ${({ height }) => height};

  border-radius: 5px;

  background-color: #d6d6d6;
`;

export const Text = styled.span``;

export const TextContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  justify-content: space-between;
`;

export const Title = styled.h1`
  margin-bottom: 5px;

  font-size: 14px;
`;

export const CurrentProgress = styled.div`
  width: ${({ progress }) => `${progress}%`};
  height: 100%;

  background-color: ${({ color }) => color};

  border-radius: 5px;

  display: flex;
`;

export const ProgressText = styled.span`
  font-weight: bold;
  color: #fff;
  font-size: 14px;
  line-height: 14px;
  padding-left: 5px;
  white-space: nowrap;

  margin: auto;

  user-select: none;
`;

export const Rest = styled.div`
  width: ${({ rest }) => `${rest}%`};
  height: 100%;
`;
