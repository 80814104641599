import { makeStyles } from '@material-ui/core';
import styled from 'styled-components';

export const Content = styled.div`
  padding: 8px;
`;

const useStyles = makeStyles({
  contentWatermarkWarning: {
    fontSize: '14px',
    color: '#8B8B8B',
    lineHeight: '21px'
  }
});

export default useStyles;
