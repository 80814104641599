import 'date-fns';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import {
  Tooltip,
  Table,
  Icon,
  Menu,
  Dropdown,
  Modal,
  Typography,
  Col,
  Row,
  Alert
} from 'antd';

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Typography as TypographyMui,
  Link as LinkMui
} from '@material-ui/core';

import {
  FilterListOutlined as FilterIcon,
  ClearOutlined as DeleteIcon
} from '@material-ui/icons';
import { ptBR } from 'date-fns/locale';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';

import { Creators as GalleryCreators } from '../../../store/ducks/gallery';

import useStyles, {
  Container,
  Header,
  TableWrapper,
  GalleryName,
  Pill,
  ModalContent,
  CoverContainer,
  CounterCard,
  SelectionStatusContainer
} from './styles';

import cover_placeholder from '../../../assets/avatar-no-gallery-cover-pb.png';

import Button from '../../../components/Button';
import Input from '../../../components/Form/Input';
import Loading from '../../../components/Loading';
import FormItem from '../../../components/Form/FormItem';
import Divider from '../../../components/Divider';
import LimitReachedPopup from '../../../components/LimitReachedPopup';
import PopConfirm from '../../../components/PopConfirm';
import SubmitContainer from '../../../components/Form/SubmitContainer';

import { ModalContent as PopConfirmModalContent } from '../../../components/PopConfirm/styles';

import api from '../../../services/api';

import {
  notifySuccess,
  notifyWarn,
  notifyError
} from '../../../utils/notificationService';
import { getStatusCode } from '../../../utils/response';
import { useValidation } from '../../../services/validation';
import SessionStorageService from '../../../services/sessionStorage';

import { removeAccentuation } from '../../../utils/text';
import { objectIsEmpty } from '../../../utils/object';
import { isMobile } from '../../../utils/device';

import Empty from '../Empty';
import { FormLabel, Portlet } from '../../../styles/components';

const { Title } = Typography;

const defaultGalleriesData = {
  galleries: [],
  galleriesCount: 0
};

const defaultFilterData = {
  done_selection: false,
  open_selection: false,
  expired_selection: false,
  not_started_selection: false,
  inactive_selection: false,

  active_download: false,
  inactive_download: false,

  start_created: null,
  end_created: null,

  search_data: null,
  pagination_page: 1
};

const Dashboard = (props) => {
  const inputs = ['title'];

  const [galleriesData, setGalleriesData] = useState(defaultGalleriesData);
  const [filteredData, setFilteredData] = useState(galleriesData);
  const [newGalleryModalVisible, setNewGalleryModalVisible] = useState(false);
  const [newGalleryTitle, setNewGalleryTitle] = useState('');
  const [loading, setLoading] = useState(true);
  const [tableLoading, setTableLoading] = useState(loading);
  const [newGalleryLoading, setNewGalleryLoading] = useState(false);
  const [validation, clearValidation, triggerValidation] = useValidation(
    inputs
  );

  const [filterData, setFilterData] = useState(defaultFilterData);

  const classes = useStyles();
  const dispatch = useDispatch();

  const resetUploadStatus = () => {
    dispatch(GalleryCreators.setInfo({ uploadStatus: null }));
  };

  const getGalleries = async (newFilterData) => {
    setTableLoading(true);

    const filter = newFilterData || filterData || {};
    let filterDataQuery = {};

    if (filter) {
      const objectToEntries = Object.entries(filter);
      const filterObject = objectToEntries.filter(
        ([key, value]) =>
          key !== 'search_data' && key !== 'pagination_page' && !!value
      );

      filterObject.forEach(([key, value]) => {
        filterDataQuery[key] = value;
      });
    }

    const response = await api.get(`/gallery/list`, {
      params: filterDataQuery
    });

    const galleries = response.data.galleries.galleryList.map((gallery) => ({
      ...gallery,
      cover: gallery.cover ? gallery.cover : cover_placeholder,
      selection: gallery.solutions.selection_activated ? 'Ativo' : 'Inativo',
      download: gallery.solutions.download_activated ? 'Ativo' : 'Inativo'
    }));

    const galleryData = {
      galleries,
      galleriesCount: response.data.galleries.galleriesCount
    };

    setGalleriesData(galleryData);
    const { search_data } = filter;

    if (search_data) {
      getGalleriesWithSearchFilter(search_data, galleryData);
    } else {
      setFilteredData(galleryData);
    }

    setTableLoading(false);
    setLoading(false);
  };

  const handleDeleteGallery = async (collectionId) => {
    try {
      await api.delete(`/gallery?collection_id=${collectionId}`);
      notifySuccess('Galeria deletada com sucesso!');
    } catch (error) {
      notifyWarn('Algo deu errado');
    }

    await getGalleries();
  };

  const handleOpenConfirmDeleteGalleryModal = (collection_id) => {
    PopConfirm.open({
      title: 'Tem certeza?',
      cancelText: 'CANCELAR',
      confirmText: 'DELETAR',
      confirmIcon: 'delete',
      showWarningIcon: false,
      info: (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <PopConfirmModalContent>
            <Icon
              type="exclamation-circle"
              theme="twoTone"
              twoToneColor="#FF4949"
              style={{ fontSize: '18px' }}
            />
            Deletar a galeria é uma ação irreversível. Você perderá todos os
            dados desta galeria.
          </PopConfirmModalContent>

          <Divider size="medium" />

          <Alert
            message={
              <p>
                <b>ATENÇÃO: </b> Após excluir essa galeria, você terá no máximo
                7 dias para solicitar o backup (confira o custos de backup).{' '}
                <b>Após 7 dias as fotos são excluídas definitivamente</b>.
              </p>
            }
            type="warning"
          />
        </div>
      ),
      onConfirm: () => handleDeleteGallery(collection_id)
    });
  };

  const { history } = props;

  const menu = (collection_id) => (
    <Menu>
      <Menu.Item
        key="0"
        onClick={() => handleOpenConfirmDeleteGalleryModal(collection_id)}
      >
        Excluir
      </Menu.Item>
    </Menu>
  );

  const columns = [
    {
      title: 'Galeria',
      dataIndex: 'title',
      width: '30%',
      render: (text, record) => (
        <GalleryName to={`/gallery/${record.id}/pictures`}>
          <CoverContainer isPlaceholder={record.cover === cover_placeholder}>
            <img src={record.cover} alt={text} />
          </CoverContainer>
          <p>{text}</p>

          {record.expired && (
            <Pill status="Expirado">
              <p>Expirada</p>
            </Pill>
          )}
        </GalleryName>
      )
    },
    {
      title: 'Nº de fotos',
      dataIndex: 'info.total_pictures',
      align: 'center',
      render: (text) => (
        <p>
          {text} {text == 1 ? 'foto' : 'fotos'}
        </p>
      )
    },
    {
      title: 'Seleção de fotos',
      dataIndex: 'selection',
      align: 'center',
      render: (_, gallery) => (
        <SelectionStatusContainer>
          {gallery.solutions.selection_activated ? (
            <>
              {gallery.selections_status.total === 0 ? (
                <Tooltip title="Nenhuma seleção iniciada">
                  <Link to={`/gallery/${gallery.id}/selection/followup`}>
                    <CounterCard>0</CounterCard>
                  </Link>
                </Tooltip>
              ) : (
                <>
                  {Object.keys(gallery.selections_status)
                    .filter((status) => status !== 'total')
                    .map((status) => {
                      const help = {
                        done: 'Seleções concluídas',
                        expired: 'Seleções expiradas',
                        open: 'Seleções em aberto'
                      };

                      return (
                        <Tooltip title={help[status]}>
                          <Link
                            to={`/gallery/${gallery.id}/selection/followup`}
                          >
                            <CounterCard status={status}>
                              {gallery.selections_status[status]}
                            </CounterCard>
                          </Link>
                        </Tooltip>
                      );
                    })}
                </>
              )}
            </>
          ) : (
            <Pill status="Inativo" style={{ margin: 'auto' }}>
              <p>Inativo</p>
            </Pill>
          )}
        </SelectionStatusContainer>
      )
    },
    {
      title: 'Entrega em alta',
      dataIndex: 'download',
      align: 'center',
      render: (text) => (
        <Pill status={text} style={{ margin: 'auto' }}>
          <p>{text}</p>
        </Pill>
      )
    },
    {
      title: 'Ações',
      dataIndex: 'id',
      align: 'center',
      render: (collection_id) => (
        <Dropdown overlay={menu(collection_id)} trigger={['click']}>
          <Icon type="more" style={{ fontSize: 25 }} />
        </Dropdown>
      )
    }
  ];

  const handleFilterDataChange = async (typeFilter, valueFilter) => {
    const newFilter = {
      ...filterData
    };

    newFilter[typeFilter] = valueFilter;

    setFilterData(newFilter);
    SessionStorageService.set('filter_data_gallery', newFilter);

    if (typeFilter !== 'search_data' && typeFilter !== 'pagination_page') {
      await getGalleries(newFilter);
    }
  };

  const getGalleriesWithSearchFilter = (value, newGalleriesData) => {
    const galleries = newGalleriesData || galleriesData;

    const result = galleries.galleries.filter((item) => {
      const { title } = item;
      if (
        removeAccentuation(title.toLocaleLowerCase().trim()).includes(
          value.toLocaleLowerCase().trim()
        ) ||
        title
          .toLocaleLowerCase()
          .trim()
          .includes(value.toLocaleLowerCase().trim())
      ) {
        return true;
      }
      return false;
    });

    setFilteredData({
      galleries: result,
      galleriesCount: result.length
    });
  };

  const handleSearchChange = (value) => {
    handleFilterDataChange('search_data', value);

    getGalleriesWithSearchFilter(value);
  };

  const handleCancel = () => {
    setNewGalleryModalVisible(false);
  };

  const handleOpenModal = () => {
    setNewGalleryModalVisible(true);

    setTimeout(() => {
      const galleryTitleInput = document.getElementById('gallery-title-input');

      if (galleryTitleInput) {
        galleryTitleInput.focus();
      }
    });
  };

  const handleCreateGallery = async () => {
    try {
      setNewGalleryLoading(true);

      const response = await api.post('/gallery/create', {
        title: newGalleryTitle
      });

      const { id } = response.data;

      history.push(`/gallery/${id}/pictures`);
    } catch (error) {
      const statusCode = getStatusCode(error);

      if (statusCode === 402) {
        const details =
          error &&
          error.response &&
          error.response.data &&
          error.response.data.details;

        if (!details) {
          return notifyError('Algo deu errado');
        }

        const message = (
          <p>
            Atualmente você possui <b>{details.current_gallery} galerias</b> e
            seu plano atual só permite a criação de{' '}
            <b>{details.max_gallery} galerias</b>.
          </p>
        );

        setNewGalleryModalVisible(false);
        LimitReachedPopup.open(message);
      } else {
        triggerValidation(error);
      }
    }

    setNewGalleryLoading(false);
  };

  const handleChange = (key, value) => {
    setNewGalleryTitle(value);
    clearValidation(key);
  };

  const handleDateFilterChange = (type, date) => {
    const newDate = date ? new Date(date).toLocaleDateString('en-US') : null;

    handleFilterDataChange(type, newDate);
  };

  const handleCheckBoxChange = (target) => {
    const { name, checked } = target;

    handleFilterDataChange(name, checked);
  };

  const initializeFilterData = async () => {
    const filterDataInSessionStorage = SessionStorageService.get(
      'filter_data_gallery'
    );

    if (filterDataInSessionStorage) {
      setFilterData(filterDataInSessionStorage);
    }

    await getGalleries(filterDataInSessionStorage);
  };

  const handlePaginationChange = (page) => {
    handleFilterDataChange('pagination_page', page);
  };

  const clearFilter = async () => {
    setFilterData(defaultFilterData);
    SessionStorageService.set('filter_data_gallery', defaultFilterData);

    await getGalleries(defaultFilterData);
  };

  const getObjectFilterValidWithoutPagination = () => {
    let filterValidWithoutPagination;

    const objectToEntries = Object.entries(filterData);
    const filterObject = objectToEntries.filter(
      ([key, value]) => !!value && key !== 'pagination_page'
    );

    filterObject.forEach(([key, value]) => {
      filterValidWithoutPagination[key] = value;
    });

    return filterValidWithoutPagination;
  };

  useEffect(() => {
    initializeFilterData();
    resetUploadStatus();
  }, []);

  return (
    <Loading loading={loading}>
      <Modal
        footer={null}
        closeIcon={null}
        visible={newGalleryModalVisible}
        onCancel={handleCancel}
      >
        <ModalContent>
          <SubmitContainer onSubmit={handleCreateGallery}>
            <Title level={3}>Nome da Galeria</Title>

            <Divider size="medium" />

            <FormItem
              validateStatus={validation.title.validateStatus}
              help={validation.title.help}
            >
              <Input
                id="gallery-title-input"
                onChange={({ target }) => handleChange('title', target.value)}
                value={newGalleryTitle}
              />
            </FormItem>

            <Divider size="medium" />

            <Button
              htmlType="submit"
              type="primary"
              loading={newGalleryLoading}
              block
              icon="check"
            >
              CRIAR GALERIA
            </Button>
          </SubmitContainer>
        </ModalContent>
      </Modal>
      {galleriesData.galleriesCount > 0 ? (
        <Grid container justify="space-between" spacing={2}>
          <Grid item xs={12} md={3} lg={2}>
            <Portlet style={{ height: 'auto', padding: '30px 16px' }}>
              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  <FilterIcon className={classes.filterIcon} />
                </Grid>
                <Grid item>
                  <TypographyMui variant="h3" className={classes.filterText}>
                    Filtros
                  </TypographyMui>
                </Grid>
              </Grid>

              <Divider size="medium" />
              <FormControl component="fieldset">
                <FormLabel component="legend" className={classes.formTitle}>
                  GALERIAS COM SELEÇÃO DE FOTO:
                </FormLabel>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={filterData.done_selection}
                        onChange={({ target }) => handleCheckBoxChange(target)}
                        name="done_selection"
                      />
                    }
                    label="Concluída"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={filterData.open_selection}
                        onChange={({ target }) => handleCheckBoxChange(target)}
                        name="open_selection"
                      />
                    }
                    label="Em aberto"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={filterData.expired_selection}
                        onChange={({ target }) => handleCheckBoxChange(target)}
                        name="expired_selection"
                      />
                    }
                    label="Expirada"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={filterData.not_started_selection}
                        onChange={({ target }) => handleCheckBoxChange(target)}
                        name="not_started_selection"
                      />
                    }
                    label="Não iniciada"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={filterData.inactive_selection}
                        onChange={({ target }) => handleCheckBoxChange(target)}
                        name="inactive_selection"
                      />
                    }
                    label="Inativa"
                  />
                </FormGroup>
              </FormControl>

              <Divider size="medium" />
              <FormControl component="fieldset" className={classes.formControl}>
                <FormLabel component="legend" className={classes.formTitle}>
                  GALERIAS COM ENTREGA EM ALTA:
                </FormLabel>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={filterData.active_download}
                        onChange={({ target }) => handleCheckBoxChange(target)}
                        name="active_download"
                      />
                    }
                    label="Entrega em alta ativa"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={filterData.inactive_download}
                        onChange={({ target }) => handleCheckBoxChange(target)}
                        name="inactive_download"
                      />
                    }
                    label="Entrega em alta inativa"
                  />
                </FormGroup>
              </FormControl>

              <Divider size="medium" />

              <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
                <FormControl component="fieldset">
                  <FormLabel component="legend" className={classes.formTitle}>
                    CADASTRADA ENTRE:
                  </FormLabel>
                  <Divider size="small" />

                  <FormGroup>
                    <Grid spacing={1} container alignItems="flex-end">
                      <Grid
                        item
                        xs={5}
                        md={12}
                        xl={5}
                        className={classes.datePickerContainer}
                      >
                        <Grid
                          container
                          alignItems="center"
                          justify="space-evenly"
                        >
                          <Grid item xs>
                            <DatePicker
                              id="start_created"
                              variant={isMobile() ? 'dialog' : 'inline'}
                              inputVariant="outlined"
                              disableFuture
                              format="dd/MM/yyyy"
                              animateYearScrolling
                              views={['year', 'month', 'date']}
                              value={filterData.start_created}
                              onChange={(date) =>
                                handleDateFilterChange('start_created', date)
                              }
                              maxDate={filterData.end_created || undefined}
                              maxDateMessage="Não pode ser maior que a data final"
                              color="secondary"
                              className={classes.datePicker}
                              InputProps={{
                                className: classes.datePickerInput
                              }}
                              cancelLabel="Cancelar"
                              autoOk
                            />
                          </Grid>
                          {filterData.start_created && (
                            <IconButton
                              className={classes.dateDeleteIcon}
                              onClick={() =>
                                handleDateFilterChange('start_created', null)
                              }
                            >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          )}
                        </Grid>
                      </Grid>

                      <Grid
                        item
                        xs={2}
                        md={12}
                        xl={2}
                        className={classes.datePickerContainer}
                      >
                        <TypographyMui
                          className={classes.datePickerText}
                          align="center"
                        >
                          à
                        </TypographyMui>
                      </Grid>

                      <Grid
                        item
                        xs={5}
                        md={12}
                        xl={5}
                        className={classes.datePickerContainer}
                      >
                        <Grid
                          container
                          alignItems="center"
                          justify="space-evenly"
                        >
                          <Grid item xs>
                            <DatePicker
                              id="end_created"
                              variant={isMobile() ? 'dialog' : 'inline'}
                              inputVariant="outlined"
                              disableFuture
                              format="dd/MM/yyyy"
                              animateYearScrolling
                              views={['year', 'month', 'date']}
                              value={filterData.end_created}
                              onChange={(date) =>
                                handleDateFilterChange('end_created', date)
                              }
                              minDate={filterData.start_created || undefined}
                              minDateMessage="Não pode ser maior que a data inicial"
                              color="secondary"
                              className={classes.datePicker}
                              InputProps={{
                                className: classes.datePickerInput
                              }}
                              cancelLabel="Cancelar"
                              autoOk
                            />
                          </Grid>

                          {filterData.end_created && (
                            <IconButton
                              className={classes.dateDeleteIcon}
                              onClick={() =>
                                handleDateFilterChange('end_created', null)
                              }
                            >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </FormGroup>
                </FormControl>
              </MuiPickersUtilsProvider>

              {!objectIsEmpty(() =>
                getObjectFilterValidWithoutPagination()
              ) && (
                <>
                  <Divider size="medium" />

                  <Grid
                    container
                    justify="center"
                    alignContent="center"
                    alignItems="center"
                    className={classes.clearFilterContainer}
                  >
                    <Grid item>
                      <LinkMui
                        onClick={clearFilter}
                        href="#limpar-filtro"
                        className={classes.clearFilter}
                      >
                        Limpar filtro
                      </LinkMui>
                    </Grid>
                  </Grid>
                </>
              )}
            </Portlet>
          </Grid>

          <Grid item xs={12} md={9} lg={10}>
            <Container>
              <Header>
                <Row gutter={[16, 16]}>
                  <Col lg={24} xl={8} xxl={6}>
                    <Button type="primary" onClick={handleOpenModal}>
                      <i style={{ marginRight: '8px' }}>
                        <FontAwesomeIcon icon={faPlus} />
                      </i>
                      CRIAR NOVA GALERIA DE FOTOS
                    </Button>
                  </Col>
                  <Col lg={0} xl={8} xxl={12} style={{ padding: '0' }} />
                  <Col lg={24} xl={8} xxl={6}>
                    <Input
                      value={filterData.search_data}
                      type="search"
                      placeholder="Procurar galeria"
                      onChange={({ target }) =>
                        handleSearchChange(target.value)
                      }
                    />
                  </Col>
                </Row>
              </Header>
              <TableWrapper>
                <Table
                  loading={{
                    indicator: (
                      <Icon
                        type="loading"
                        style={{ fontSize: '40px', color: '#666' }}
                      />
                    ),
                    spinning: tableLoading
                  }}
                  onRow={(data) => ({})}
                  columns={columns}
                  dataSource={filteredData.galleries}
                  pagination={{
                    current: filterData.pagination_page,
                    onChange: handlePaginationChange
                  }}
                />
              </TableWrapper>
            </Container>
          </Grid>
        </Grid>
      ) : (
        <Container>
          <Empty onClick={handleOpenModal} />
        </Container>
      )}
    </Loading>
  );
};

Dashboard.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
};

export default Dashboard;
