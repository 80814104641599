import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';

import useStyles from './styles';

const Portlet = (props) => {
  const { children, disablePadding } = props;

  const classes = useStyles();

  return (
    <Box className={classes.box} style={disablePadding ? { padding: 0 } : null}>
      {children}
    </Box>
  );
};

Portlet.defaultProps = {
  children: <div />,
  disablePadding: false
};

Portlet.propTypes = {
  children: PropTypes.oneOfType(
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ),
  disablePadding: PropTypes.bool
};

export default Portlet;
