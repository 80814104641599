export const retrieveParsedQueryParams = (location) => {
	const rawQueryParams = location.search.split("?").pop()

	const parsedQueryParams = {}

	// It means there are more than one query param on url
	// so we treat it with another logic
	if (rawQueryParams.includes("&")) {
		const params = rawQueryParams.split("&")

		params.forEach(param => {
			const key = param.split("=").shift()
			const value = param.split("=").pop()

			parsedQueryParams[key] = value
		})
	} else {
		const key = rawQueryParams.split("=").shift()
		const value = rawQueryParams.split("=").pop()

		parsedQueryParams[key] = value
	}

	return parsedQueryParams
}

export const seePlans = () => {
	return window.location.href = '/checkout/gallery-pro/plans';
}

const isWhiteListed = (blackList, current) => blackList.some(item => current.includes(item))

export const handlePageRedirects = ({ currentPathName, changePath, userSubscription, userData }) => {
	const defaultWhiteListPaths = ['checkout', 'logout', 'wizard-solution-switch', 'financial', 'account'];

	const expiredWhiteListPaths = [...defaultWhiteListPaths, 'expired', 'extend-trial'];
	const currentPageIsExpiredWhiteListed = isWhiteListed(expiredWhiteListPaths, currentPathName);

	const canceledWhiteListPaths = [...defaultWhiteListPaths, 'canceled'];
	const currentPageIsCanceledWhiteListed = isWhiteListed(canceledWhiteListPaths, currentPathName);

	const noGalleryProWhiteListPath = [...defaultWhiteListPaths];
	const currentPageIsNoGalleryProWhiteListed = isWhiteListed(noGalleryProWhiteListPath, currentPathName);

	const freeBlockWhiteListPaths = [...defaultWhiteListPaths, 'free-blocked'];
	const currentPageIsFreeBlockWhiteListed = isWhiteListed(freeBlockWhiteListPaths, currentPathName);
	
	const extendTrialWhiteListPaths = [...defaultWhiteListPaths, 'extend-trial'];
	const maybeCanExtendTrial = userData && userData.flags && (userData.flags.extended_trial === false || userData.flags.extended_trial === true);
	const currentPageIsExtendTrialWhiteListed = isWhiteListed(extendTrialWhiteListPaths, currentPathName);

	if (userSubscription && userSubscription.isGalleryProFreeCustomerBlocked && !currentPageIsFreeBlockWhiteListed) {
		changePath('/free-blocked');
	} else if (userSubscription && userSubscription.expired && !currentPageIsExtendTrialWhiteListed && maybeCanExtendTrial) {
		changePath('/extend-trial');
	} else if (userSubscription && userSubscription.expired && !currentPageIsExpiredWhiteListed && !maybeCanExtendTrial) {
		changePath('/expired');
	} else if (userSubscription && userSubscription.doesNotHasGalleryPro === true && !currentPageIsNoGalleryProWhiteListed) {
		changePath('/wizard-solution-switch');
	} else if (userSubscription && userSubscription.canceled && !currentPageIsCanceledWhiteListed) {
		changePath('/canceled');
	}
}