import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
`;

export const SubMenuCategory = styled.div`
	width: 100%;
  padding: 0 18px;

	li{
		list-style: none;
    line-height: 42px;
    padding: 0 8px;
	}

	ul{
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		margin: -5px 0;
		padding: 4px 0;
	}

	svg {
		font-size: 12px;
	}
`;

export const SortHandler = styled.div`
  padding: 0 6px;
  cursor: move;
  display: flex;
`;

export const SubMenuCategoryItem = styled.li`
	cursor: pointer;
  border-bottom: 1px solid #f8f8f8;
  background: #fff;
  margin: 0;
  width: 100%;
  height: auto;
  font-weight: 300;
  display: flex;
  align-items: center;
  position: relative;
  user-select: none;

	svg {
		cursor: move;
	}

  span{
		width: 100%;
		height: 100%;
  	margin-left: 10px;
    display: flex;
    align-items: center;
    transition: color .3s;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: ${(props) => props.isSelected ? '#FE494A' : '#444'}
  }

	&:hover {
		span {
			color: #FE494A;
		}

		div {
			display: flex;

			svg {
				cursor: pointer;
			}
		}
	}
`;

export const SubMenuLastCategoryItem = styled.div`
	cursor: pointer;
	border-top: 1px solid #f8f8f8;
	margin: 0;
	width: 100%;
  height: auto;
	font-weight: 300;
	display: flex;
	align-items: center;
  transition: color .3s;
  line-height: 42px;

	span{
		margin-left: 7px;
		display: flex;
		align-items: center;
  }

	&:hover {
    color: #FE494A;
  }
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px;

  justify-content: center;

  h3 {
    text-align: center;
  }
`;

export const CategoryToolbar = styled.div`
  height: 100%;
  width: auto;
	cursor: pointer;
	display: none;
	flex-direction: row;
  align-items: center;
  margin-left: 4px;

	i {
		margin: 0;
	}

	& > div {
		margin-right: 10px;
	}
`;
