import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Icon } from 'antd';

import { LWG, LWF, initExternalAuth } from '../../services/external';
import api from '../../services/api';
import { notifyError } from '../../utils/notificationService';
import {
  postLoginRedirect,
  getContextFromAuthData
} from '../../services/solution';

import Button from '../Button';

import {
  Container,
  ButtonWrapper,
  VerticalDivider,
  MessageContainer,
  ButtonContainer,
  Line,
  Message
} from './styles';

const External = (props) => {
  const history = useHistory();
  const [facebookLoading, setFacebookLoading] = useState(false);
  const [googleLoading, setGoogleLoading] = useState(false);
  const { message } = props;

  const callback = async (data) => {
    if (!data) {
      return;
    }

    if (data.auth_with === 'facebook') {
      setFacebookLoading(true);
    } else if (data.auth_with === 'google') {
      setGoogleLoading(true);
    }

    try {
      const { data: authData } = await api.post('/auth/external', data);

      const context = getContextFromAuthData(authData);

      postLoginRedirect(document.referrer || context);
    } catch (error) {
      notifyError('Algo deu errado.');
    }

    setFacebookLoading(false);
    setGoogleLoading(false);
  };

  useEffect(() => {
    initExternalAuth(callback);
  }, []);

  return (
    <Container>
      {message && (
        <MessageContainer>
          <Line />
          <Message>{message}</Message>
          <Line />
        </MessageContainer>
      )}

      <ButtonContainer>
        <ButtonWrapper>
          <Button
            onClick={() => LWF.login()}
            block={true}
            loading={facebookLoading}
          >
            {!facebookLoading && (
              <Icon
                type="facebook"
                theme="filled"
                style={{ color: '#3B5998' }}
              />
            )}
            FACEBOOK
          </Button>
        </ButtonWrapper>

        <VerticalDivider />

        <ButtonWrapper>
          <Button
            onClick={() => LWG.login()}
            block={true}
            loading={googleLoading}
          >
            {!googleLoading && (
              <Icon
                type="google-square"
                theme="filled"
                style={{ color: '#d34836' }}
              />
            )}
            GOOGLE
          </Button>
        </ButtonWrapper>
      </ButtonContainer>
    </Container>
  );
};

External.defaultProps = {
  message: null
};

External.propTypes = {
  message: PropTypes.string
};

export default External;
