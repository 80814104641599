import React from 'react';
import { Typography, Grid, useTheme, useMediaQuery } from '@material-ui/core';

import { Portlet } from '../../../styles/components';
import SelectWaterMark from '../../../components/Watermark/Select';
import Divider from '../../../components/Divider';
import Pill from '../../../components/Pill';

import { usePlan } from '../../../services/plan';

import useStyles from './styles';

const WaterMark = () => {
  const { checkSolution } = usePlan();
  const MDTheme = useTheme();

  const isSmall = useMediaQuery(MDTheme.breakpoints.down('xs'));

  const classes = useStyles({ isSmall });

  return (
    <Portlet>
      <Divider size="small" />

      <Grid container justify="space-between">
        <Grid item xs={12} sm={9} className={classes.content}>
          {isSmall && <Divider size="xsmall" />}

          <Typography variant="h3">
            Selecione a marca d’água padrão para as próximas galerias
          </Typography>

          <Typography className={classes.description}>
            Nas configurações de cada galeria, é possível habilitar/desabilitar
            a marca d’água apenas para a galeria específica.
          </Typography>
        </Grid>

        <Grid item xs={12} sm={3} className={classes.planPill}>
          <Grid container justify={isSmall ? 'center' : 'flex-end'}>
            {!checkSolution('gallery_limits', 'custom_watermark') && <Pill />}
          </Grid>
        </Grid>
      </Grid>
      <Divider size="medium" />

      <SelectWaterMark />

      <Divider size="medium" />
    </Portlet>
  );
};

export default WaterMark;
