import React from 'react';
import { Form } from 'antd';
import PropTypes from 'prop-types';

import { Container } from './styles';

const FormItem = (props) => {
	const { help, validateStatus, enlargeOnError, children } = props;
	
	return (
		<Container 
			enlargeOnError={enlargeOnError}
			validateStatus={validateStatus}
		>
			<Form.Item
				help={help}
				validateStatus={validateStatus}
			>
				{children}
			</Form.Item>
		</Container>
	)
}

FormItem.defaultProps = {
	help: '',
	validateStatus: '',
	enlargeOnError: false,
}

FormItem.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
	]).isRequired,
	help: PropTypes.string,
	validateStatus: PropTypes.string,
	enlargeOnError: PropTypes.bool,
};

export default FormItem;