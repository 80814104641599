import styled from 'styled-components';

const isMobile = window.innerWidth < 700;

export const Container = styled.div`
  margin-left: 16px;
  background-color: #ffc243;
  width: ${isMobile ? '118px' : '184px'};
  font-size: 11px;
  font-weight: 700;
  color: #786625;
  border-radius: 20px;
  height: auto;
  min-height: 20px;
  padding: 2px 14px;
  line-height: 1;

  display: flex;
  justify-content: center;
  align-items: center;

  i {
    color: #fff;
    margin-right: 8px;
  }
`;
