import LocalStorageService from './localStorage';
/*
	The code below will be used for a short time just to make tests,
	so it is not supposed to be well written
*/

const key = 'picsize.track';

export const getTrackData = () => {
  const currentTrack = LocalStorageService.get(key);

  if (!currentTrack) {
    return null;
  }

  try {
    return JSON.parse(currentTrack);
  } catch (error) {
    console.warn(error);
    return null;
  }
};

export const clearTrackData = () => {
  return LocalStorageService.delete(key);
};

export const setTrack = (location) => {
  let track = null;

  try {
    // Only if the path name looks like /track?key=something it will trigger
    track = location.search.split('=').pop();
  } catch (error) {
    console.warn(error);
  }

  if (!track) {
    return;
  }

  let currentTrack = getTrackData();
  const newTrack = {
    key: track,
    timestamp: new Date(),
    method: 'local-storage'
  };

  if (!currentTrack) {
    LocalStorageService.set(key, [newTrack]);
  } else {
    try {
      currentTrack.push(newTrack);
      LocalStorageService.set(key, currentTrack);
    } catch (error) {
      LocalStorageService.set(key, [newTrack]);
      console.warn(error);
    }
  }
};
