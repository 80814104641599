import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Container, Content, Text } from './styles';

import Divider from '../../components/Divider';
import Button from '../../components/Button';

import warningIcon from '../../assets/warning_icon.png';

const FreeBlocked = () => {
  const userData = useSelector((state) => state.customer.user_data);
  const userSubscription = useSelector((state) => state.customer.user_subscription);
  const [loading, setLoading] = useState(false);

  const handleGetPlan = async () => {
    setLoading(true);

    window.location = "https://cutt.ly/migrecom30off";
  };

  const getPlanName = () => {
    const planNames = Object.values((userSubscription || {}).plan_map || {})

    const freePlanName = planNames.find(planName => planName.includes("Fre"))

    return freePlanName || "Free"
  }

  return (
    <Container>
      <Divider size="xlarge" />

      <Content>
        <Divider size="xlarge" />

        <img src={warningIcon} alt="warning" />

        <Divider size="xlarge" />

        <Text>
          Oi, {userData.display_name}. Tenho uma mensagem importante para você. 
          <br />
          <br />
          O plano {getPlanName()} que você utiliza na nossa plataforma foi descontinuado e em breve todos os dados serão excluídos do nosso sistema.
          <br />
          <br />
          A notícia boa é que você pode migrar de plano e continuar com todos os seus trabalhos ativos com uma condição que não será liberada para mais ninguém:
          <br />
          <b>30% OFF</b> no plano que escolher.
          <br />
        </Text>

        <Divider size="small" />

        <Button
          id="btn_UpgradeUsuarioFree"
          type="primary"
          onClick={() => handleGetPlan()}
          loading={loading}
        >
          Clique aqui para aproveitar o desconto
        </Button>

        <Divider size="medium" />

        <Text>
          <b>IMPORTANTE:</b> Caso não ative outro plano até o dia 21/03/2021 todos os dados e arquivos serão excluídos do nosso sistema.
        </Text>

        <Divider size="xlarge" />
      </Content>
    </Container>
  );
};

export default FreeBlocked;
