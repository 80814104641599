import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Main from '../pages';

import Dashboard from '../pages/Dashboard';
import Account from '../pages/Account';
import Financial from '../pages/Financial';
import Settings from '../pages/Settings';
import Albuns from '../pages/Albuns';
import Portfolio from '../pages/Portfolio';
import WorkCloseup from '../pages/Portfolio/WorkCloseup';
import GalleryDashboard from '../pages/Gallery';
import GalleryList from '../pages/Gallery/Dashboard';
import Checkout from '../pages/Checkout';
import SubscriptionExpired from '../pages/SubscriptionExpired';
import ExtendTrial from '../pages/ExtendTrial';
import WizardSolutionSwitch from '../pages/WizardSolutionSwitch';
import SubscriptionCanceled from '../pages/SubscriptionCanceled';
import FreeBlocked from '../pages/FreeBlocked';

import Clients from '../pages/Clients';

const Routes = () => (
  <>
    <Switch>
      <Route exact path="/" component={() => <Redirect to="gallery" />} />
      <Route path="/account" component={Account} />
      <Route path="/financial" component={Financial} />
      <Route path="/dashboard" component={Dashboard} />
      <Route exact path="/gallery" component={GalleryList} />
      <Route exact path="/albuns" component={Albuns} />
      <Route path="/gallery/:collection_id" component={GalleryDashboard} />
      <Route path="/settings" component={Settings} />
      <Route path="/clients" component={Clients} />
      <Route exact path="/portfolio" component={Portfolio} />
      <Route path="/portfolio/works/:work_id" component={WorkCloseup} />
      <Route path="/checkout" component={Checkout} />
      <Route path="/expired" component={SubscriptionExpired} />
      <Route path="/canceled" component={SubscriptionCanceled} />
      <Route path="/extend-trial" component={ExtendTrial} />
      <Route path="/wizard-solution-switch" component={WizardSolutionSwitch} />
      <Route path="/free-blocked" component={FreeBlocked} />
    </Switch>
    {/* Main Route */}
    <Route path="/" component={Main} />
  </>
);

export default Routes;
