import styled from 'styled-components';

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 10px;

  button {
    margin-top: 15px;
    width: 70%;
    max-width: 450px;
  }
`;

export const Warning = styled.img`
  width: 60px;
  height: auto;
  margin: auto;
`;

export const Title = styled.h1`
  font-size: 16px;
  font-weight: bold;
  margin: 25px auto 10px;
`;

export const Info = styled.span`
  font-size: 14px;
  color: #666;
`;

export const Question = styled.span`
  font-size: 14px;
  color: #666;
  margin-bottom: 15px;
`;

export const ButtonWrapper = styled.div`
  button {
    box-shadow: 0 0 0 transparent;
  }

  span {
    font-weight: lighter;
  }
`;