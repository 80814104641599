import styled from 'styled-components';

export const Container = styled.div`
  background-color: #fff;

  li{
    border-right: 1px solid #E8E8E8 !important;
  }
`;

export const FirstHeaderRow = styled.div`
  min-height: 60px;
  padding: 0 16px;
  border-bottom: 1px solid #E8E8E8;

  display: flex;
  align-items: center;
  justify-content: space-between;

  img{
    max-width: 150px;
  }
`;

export const ProfileName = styled.span`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  font-weight: 300;

  a {
    margin-left: 10px;
    color: #707070;
  }

  a:hover {
	  color: #303030;
  }
`;

export const MenuWrapper = styled.div`
  .ant-menu-horizontal {
    font-size: 12px;
    
    .ant-menu-item-active,
    .ant-menu-item-selected, 
    .ant-menu-submenu-selected,
    .ant-menu-item-active,
    .ant-menu-submenu-active, 
    li:hover {
      color: #ff4949;
      border-bottom: 2px solid #ff4949;

      a:hover {
        color: #ff4949;
      }

      a {
        color: #ff4949;
      }
    }
  }
`;

export const CallbackButton = styled.button`
  background-color: transparent;
  border: none;
  text-transform: none;
  outline: none;
  transition: all .15s ease;

  i {
    margin-right: 8px;
  }

  &:hover {
	  color: #303030;
  }
`;