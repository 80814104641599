import styled from 'styled-components';

export const Container = styled.div`
  overflow-x: auto;
  scrollbar-width: thin;
  scroll-behavior: smooth;

  background-color: #fff;
  font-size: 12px;
  color: #666666;
  font-weight: 700;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
`;

export const ButtonsList = styled.div`
  display: flex;
  padding: 0;
  margin: 0;
  position: relative;
`;

export const ButtonsListItem = styled.div`
  cursor: pointer;
    list-style: none;

    display: flex;
    justify-content: center;
    align-items: center;

    i{
      margin-right: 8px;
      font-size: 16px;
    }

    button{
      color: #666;
      background-color: transparent;
      box-shadow: 0 0 0 transparent;
      border: 0;
      display: flex;
      font-size: 10px;
      height: 40px;

      span{
        margin-left: 0;
        padding-left: 0;
      }

      i{
        margin: auto;
      }
    }
    button:hover{
      background-color: transparent;
      box-shadow: 0 0 0 transparent;
      border: 0;
    }
    button:focus{
      background-color: transparent;
      box-shadow: 0 0 0 transparent;
      border: 0;
    }
`;

export const ButtonWrapper = styled.div`

`;
