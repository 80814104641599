import React from 'react';
import { Button, Container, Grid, Typography, Link } from '@material-ui/core';

import useStyles from './styles';
import { supportContactInfo } from '../../services/contact'

import Divider from '../../components/Divider';

import subscriptionImg from '../../assets/subscription-block.jpg';

const InactiveAccount = () => {
  const classes = useStyles();

  return (
    <Container
      maxWidth="md"
      className={classes.container}
    >
      <Grid
        container
        alignContent="center"
        alignItems="center"
        justify="center"
      >
        <Grid item xs={12}>
          <Typography className={classes.title} variant="h3" align="center">
            A sua conta está congelada 😔
          </Typography>
          <Divider size="large" />
        </Grid>

        <Grid item xs={12}>
          <img
            src={subscriptionImg}
            alt="Conta congelada"
            className={classes.image}
          />
          <Divider size="large" />
        </Grid>

        <Grid item xs={12}>
          <Typography
            className={classes.textContent}
            variant="body1"
            align="center"
          >
            Mas calma! Isso deve ter acontecido porque você tentou pagar com
            cartão de crédito, mas a transação não foi aprovada.
          </Typography>

          <Divider size="large" />

          <Typography
            variant="caption"
            align="center"
            className={classes.warning}
          >
            <b>Atenção:</b> A senha não é a mesma de acesso ao painel da Picsize, caso não lembre, basta utilizar o lembrar senha.
          </Typography>

          <Divider size="small" />

          <iframe
            frameBorder="0"
            className={classes.iframe}
            src="https://picsize.superlogica.net/clients/areadocliente/clientes/formadepagamento"
          />

          <Divider size="large" />

          <Typography
            className={classes.textContent}
            variant="body1"
            align="center"
          >
            Caso não consiga solucionar,
            {" "}
            <Link
              className={classes.link}
              href={supportContactInfo().whatsappLink}
              target="_blank"
            >
              fale com o nosso suporte
            </Link>
            ! 😄
          </Typography>

          <Divider size="medium" />
        </Grid>
      </Grid>
    </Container>
  );
};

export default InactiveAccount;
