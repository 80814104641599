import styled from 'styled-components';


export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img{
    margin-top: 40px;
  }
`;

export const Title = styled.span`
  margin-top: 23px;
  font-size: 20px;
  font-weight: bold;
  color: black;
`;

export const Subtitle = styled.span`
  margin-top: 16px;
  text-align: center;
  font-size: 16px;
  color: #333333;
`;

export const TextUnderline = styled.span`
  text-align: center;
  text-decoration: underline;
  color: #ff4949;
  font-size: 14px;
  line-height: 19px;
  margin-top: 58px;
  cursor: pointer;

  a, a:hover, a:visited {
    text-align: center;
    text-decoration: underline;
    color: #ff4949;
  }
`;

export const TableWrapper = styled.div`
  width: 100%;
  max-width: 500px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  h2{
    margin-bottom:0;
    padding: 10px;
    border-top: 1px  solid #D4D4D4;
    border-left: 1px  solid #D4D4D4;
    border-right: 1px  solid #D4D4D4;
    border-radius: 3px 3px 0px 0px;
    text-align: center;
    color: #333333;
    font-size: 15px;
    line-height: 25px;
    font-weight: bold;
    background: #ededed;
    width: 100%;
    display: block;
  }

  table{
    margin-bottom: 14px;
    border-top: 2px  solid white;
    border-left: 1px  solid #D4D4D4;
    border-right: 1px  solid #D4D4D4;
    border-bottom: 1px  solid #D4D4D4;
    border-radius: 4px;

    tbody{
      display: block;
      overflow-y: auto;
      max-height: 148px;
      width: 100%;
    }

    td{
      padding-left: 5px;
    }

    tr{
      background-color: #F4F4F4;
    }

    tbody tr:nth-child(odd){
      width: 100%;
      background-color: #F9F9F9;
    }

    tbody tr>td:nth-child(1){
      width: auto;
    }

    tbody tr>td:nth-child(2){
      width: auto;
    }

    tbody tr>td:nth-child(3){
      text-align: right;
      width: 100%;
      padding-right: 5px;
    }
  }

  button{
    margin-bottom: ${(props) => props.mobile ? '0' : '74px'};
  }
`;
