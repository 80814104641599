import styled from 'styled-components';

export const Wrapper = styled.div`
  min-height: 100%;
  height: auto;
  display: flex;
`;

export const Container = styled.div`
  width: 100vw;
  background-color: #f6f6f6;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
`;

export const ContainerTransparent = styled(Container)`
  background-color: transparent;
`;

export const Content = styled.div`
  padding: 0;
  background-color: transparent;
  height: 100%;
  width: 100%;

  iframe {
    border: 0;
    width: 100%;
    height: 100%;
  }
`;

export const Portlet = styled.div`
  padding: 16px;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid transparent;
  height: 100%;
  width: 100%;
`;

export const Frame = styled.div`
  width: 100%;
  height: 100%;
`;

/* -----------------    FORM    --------------------- */
/* Edit */
export const FormEdit = styled.div`
  width: 100%;
`;

export const EmptyEditPlaceholder = styled.div`
  width: 42px;
`;

export const EditField = styled.div`
  display: flex;
  align-items: stretch;
  cursor: pointer;
  text-align: left;
  margin-top: 10px;

  i.anticon {
    color: #ccc;
  }

  textarea.ant-input {
    font-size: 16px;
  }
`;
/* End Edit */

export const FormSubGroup = styled.div`
  border-left: 5px solid #ededed;
  padding: 32px;
  padding-right: 0;
  margin-top: 0;

  align-items: center;
`;

/* Groups */
export const LabelGroup = styled.div`
  display: flex;
`;

export const InputGroup = styled.div`
  display: flex;
  align-items: stretch;

  text-align: left;
  margin-top: 10px;
`;

export const TelGroup = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  .ant-select {
    margin-top: 10px;
    width: 100%;
  }
  .ant-select-selection {
    border-radius: 4px 0 0 4px;
  }

  input {
    border-radius: 0 4px 4px 0;
  }

  div:nth-child(2) {
    width: 100%;
  }
`;
/* End Groups */

export const FormDate = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 0;

  .ant-switch {
    margin: 0;
    margin-left: 12px;
  }
`;

export const FormInput = styled.div`
  margin-top: 10px;
`;

export const FormSwitch = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin-bottom: 0;

  .ant-switch {
    margin-left: 16px;
    margin-bottom: 1px;
  }
`;

export const FormLabel = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
`;

export const SwitchContent = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const CheckboxContent = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
`;

/* Title */
export const TitleSection = styled.div`
  color: #ff4949;
  font-size: 14px;
  line-height: 16px;
`;
/* End Title */
/* -------------------------------------------------- */

/* -----------------    TEXT    --------------------- */
export const TitlePortlet = styled.div`
  font-weight: bold;
  color: #333;
  font-size: 18px;
`;

export const SubtitlePortlet = styled.div`
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
`;
/* -------------------------------------------------- */

/* -----------------    MENU    --------------------- */
export const Menu = styled.div`
  width: 100%;
  background-color: white;
  padding: 0 16px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 40px;
  color: #444444;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  i {
    margin-right: 16px;
  }

  a {
    color: #444444;
  }

  a:hover {
    color: #444444;
  }

  .ant-tag {
    margin-left: 16px;
    margin-right: 0;
    font-size: 12px;

    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

export const MenuItem = styled.div`
  list-style: none;
  cursor: pointer;
  text-transform: uppercase;
  margin: ${(props) => (props.showSubmenu ? '10px 0' : '0')};

  a {
    display: flex;
    flex-direction: row;
    align-items: center;

    color: ${(props) => (props.isSelected ? '#FE494A' : '#444444')} !important;
  }
`;
/* -------------------------------------------------- */
