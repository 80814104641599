import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Profile from '../pages/Settings/Profile';
import Notifications from '../pages/Settings/Notifications';
import SubDomain from '../pages/Settings/SubDomain';
import Watermark from '../pages/Settings/Watermark';

const SettingsRoutes = () => (
  <Switch>
    <Route path="/settings/profile" component={Profile} />
    <Route path="/settings/notifications" component={Notifications} />
    <Route path="/settings/subdomain" component={SubDomain} />
    <Route path="/settings/watermark" component={Watermark} />
  </Switch>
);

export default SettingsRoutes;
