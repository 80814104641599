import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

export const Container = styled.div`
  width: 100%;
  padding: 0;

  display: flex;
  flex-direction: column;
  justify-content: ${(props) => (props.loading ? 'center' : '')};

  *{
    user-select: none;
  }
`;

export const Grid = styled.div`
  /*display: flex;
  flex-wrap: wrap;
  width: 100%;*/
  display: grid;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: repeat(2, 1fr);
  justify-items: center;

  @media (min-width: 576px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: 992px) {
    grid-template-columns: repeat(5, 1fr);
  }

  @media (min-width: 1200px) {
    grid-template-columns: repeat(6, 1fr);
  }

  @media (min-width: 1600px) {
    grid-template-columns: repeat(10, 1fr);
  }
`;

export const Footer = styled.div`
  border-top: 1px solid #F8F8F8;
  margin-top: 32px;
  padding-top: 16px;
  width: 100%;
  height: 64px;
  position: relative;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const PhotosCounter = styled.div`
  position: relative;
  left: 8px;
`;

export const ImageWrapper = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  cursor: pointer;
  position: relative;
  transition: box-shadow 100ms ease-in;
  margin: 0;

  background-color: #EDEDED;
  display: flex;
  align-items: center;

  .selected{
    background:black;
  }

  &:hover{
    background: linear-gradient(to bottom, rgba(0,0,0,0.28) 0%, rgba(237,237,237,1) 100%);
    span{
      display: block;
    }
  }
  
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -ms-user-select: none;

  & > div {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: ${(props) => (props.isSelected ? '3px solid #87D068' : '0px')};
  }
`;

export const Image = styled.img`
  position: absolute;
  text-align: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const ImageName = styled.span`
  font-size: 12px;
  color: white;
  display: none;
  position: absolute;
  bottom: 0;
  width: 100%;
  background: rgba(0,0,0,0.5);
  text-align: left;
  padding-left: 8px;
`;

export const IconsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const LeftIcons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  i{
    font-size: 90%;
    transition: color 100ms ease-in;
    padding: 5px;
    background: #87d068;
    color: #fff;
    z-index: 1;
    /* color: ${(props) => (props.isSelected ? '#87d068' : '#d6d6d6')}; */
  }

  i:nth-child(2) {
    background: #FF4949;
  }
`;

export const RightIcons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 1;

  i{
    background: rgba(0,0,0,0.5);
    font-size: 90%;
    transition: color 100ms ease-in;
    padding: 5px;
    color: #fff;
  }

  i:hover {
    color: white;
  }

  i:nth-child(2):hover {
    color: #FF4949;
  }

  i:nth-child(3):hover {
    color: ${(props) => (props.isSuggested ? 'white' : '#FFBB52')};
  }
`;

export const CheckIconWrapper = styled.div`
  i{
    position: absolute;
    left: 0;
    top: 0;
    font-size: 90%;
    transition: color 100ms ease-in;
    padding: 5px;
    background: #87d068;
    /* color: ${(props) => (props.isSelected ? '#87d068' : '#d6d6d6')}; */
  }
`;

export const ZoomIconWrapper = styled.div`
  i{
    background: rgba(0,0,0,0.5);
    position: absolute;
    right: 22%;
    top: 0;
    font-size: 90%;
    transition: color 100ms ease-in;
    padding: 5px;
    color: #fff;
    z-index: 1;
  }

  i:hover {
    color: white;
  }

  /* i:hover{
    color: ${(props) => (!props.isSelected ? 'white' : '#87d068')};
  } */
`;

export const StarIconWrapper = styled.div`
  i{
    z-index: 1;
    display: ${(props) => (props.isSuggested ? 'block' : 'none')};
    position: absolute;
    top: 0;
    right: 0;
    color: #fff;
    background: #FFBB52;
  }
`;

export const Fade = styled.div`
  display: inline-block;
  visibility: ${(props) => (props.out ? 'hidden' : 'visible')};
  animation: ${(props) => (props.out ? fadeOut : fadeIn)} 0.2s linear;
  transition: visibility 0.2s linear;
`;
