import api from './api';

export const TOKEN_KEY = '@picsize';

export const isAuthenticated = async () => {
  try {
    await api.get('/auth/session');
    return true;
  } catch (error) {
    return false;
  }
};

export const logout = async () => {
  await api.get('/auth/logout');
};

export const getSiteCrossAuthUrl = async () => {
  const response = await api.post('/auth/cross/site');

  const { url } = response.data;

  return url;
};
