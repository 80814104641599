import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  titleText: {
    color: '#333333'
  },
  descriptionText: {
    color: '#8B8B8B'
  },
  endOfTestContainer: {
    margin: '0 auto',
    [theme.breakpoints.up('lg')]: {
      marginRight: '-20px'
    }
  },
  endOfTestItem: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2)
    }
  },
  endOfTestText: {
    fontSize: '20px',
    lineHeight: '18px',
    color: '#53686A'
  },
  boxNumber: {
    width: '50px',
    height: '40px',

    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',

    background: '#FAAD14',
    border: '1px solid #FFFFFF',
    boxSizing: 'border-box',
    borderRadius: '6px',

    fontWeight: 'bold',
    fontSize: '20px',
    lineHeight: '27px',
    color: '#FFFFFF'
  },
  buttonViewPlan: {
    background: '#FAAD14',
    color: '#FFFFFF',

    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: '12px',
    lineHeight: '16px',
    textAlign: 'center',
    letterSpacing: '0.07em',

    padding: '13px auto',

    '&:hover': {
      background: '#FAAD14',
      opacity: 0.8
    }
  }
}));

export default useStyles;
