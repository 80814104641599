import React, { useState, useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Icon } from 'antd';

import { Container } from './styles';

import Divider from '../../../components/Divider';
import Loading from '../../../components/Loading';
import InfoModal from '../../../components/InfoModal';

import ShoppingCart from './ShoppingCart';
import PaymentInfo from './PaymentInfo';

import { query } from '../../../services/query';
import api from '../../../services/api';
import { retrieveParsedQueryParams } from '../../../services/navigation';
import { useValidation } from '../../../services/validation';
import { financesContactInfo } from '../../../services/contact';

import {
  notifySuccess,
  notifyBackendError
} from '../../../utils/notificationService';
import { getErrorDetails } from '../../../utils/response';

import { galleryProData } from '../Plans/PlanData/galleryPro';
import { siteProData } from '../Plans/PlanData/sitePro';

const Overview = () => {
  const [totalPrice, setTotalPrice] = useState(0);
  const [planPeriodicity, setPlanPeriodicity] = useState('monthly');
  const [solutions, setSolutions] = useState([]);
  const [coupon, setCoupon] = useState({ name: '', id: null, description: '' });
  const [couponDiscount, setCouponDiscount] = useState(null);
  const [couponEnabled, setCouponEnabled] = useState(false);
  const [paymentSlipNotAllowed, setPaymentSlipNotAllowed] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [
    paymentSlipNotAllowedMessage,
    setPaymentSlipNotAllowedMessage
  ] = useState('');

  const inputs = [
    'creditCardOwnerName',
    'creditCardNumber',
    'creditCardExpirationDate',
    'creditCardSecureCode',
    'plan'
  ];
  const [
    validation,
    clearValidation,
    triggerValidation,
    clearAllValidations
  ] = useValidation(inputs);

  const location = useLocation();
  const history = useHistory();
  const { solution_name } = useParams();

  const galleryProPlanIds = galleryProData.planIds;
  const siteProPlanIds = siteProData.planIds;

  const parsedQueryParams = retrieveParsedQueryParams(location);

  const getData = async (
    coupon_id = null,
    plan_id = null,
    periodicity_type = null
  ) => {
    // eslint-disable-next-line
    const response = await api.get(
      `/checkout/solutions?plan_ids=${
        solution_name === 'gallery-pro'
          ? galleryProPlanIds.join(',')
          : siteProPlanIds.join(',')
      }&coupon_id=${coupon_id || ''}`
    );

    // Handler to get into default page when there's an error during plan finding
    const goToDefaultOverview = () =>
      history.push(`/checkout/${solution_name}/overview`);

    const { solutions } = response.data;

    // Set default values
    let plan = solutions[0].plans[0];
    let periodicity = solutions[0].plans[0].periodicity[0];
    let installmentCount =
      solutions[0].plans[0].periodicity[0].installments[0].count;
    let isAbleToPaymentSlip =
      solutions[0].plans[0].periodicity[0].installments[0].payment_slip ===
      true;

    if (plan_id && periodicity_type) {
      const selectedPlanId = +plan_id;
      const selectedPeriodicityType = periodicity_type;

      const selectedPlan = query
        .from(solutions[0].plans)
        .where((plan) => plan.id === selectedPlanId)
        .retrieve();

      if (!selectedPlan) {
        return goToDefaultOverview();
      }

      const selectedPeriodicity = query
        .from(solutions[0].plans)
        .where((plan) => plan.id === selectedPlanId)
        .select((plan) => plan.periodicity)
        .where((periodicity) => periodicity.type === selectedPeriodicityType)
        .retrieve();

      if (!selectedPeriodicity) {
        return goToDefaultOverview();
      }

      const selectedInstallment = selectedPeriodicity.installments[0];

      if (!selectedPeriodicity) {
        return goToDefaultOverview();
      }

      plan = selectedPlan || plan;
      periodicity = selectedPeriodicity || periodicity;
      installmentCount = selectedInstallment.count || installmentCount;
      isAbleToPaymentSlip = selectedInstallment.payment_slip === true;
    }

    const solutionsArray = solutions.map((solution) => ({
      ...solution,
      selected_plan_id: plan.id,
      selected_periodicity_type: periodicity.type,
      selected_installment_count: installmentCount
    }));

    setTotalPrice(periodicity.price);
    setPlanPeriodicity(periodicity.type);
    setSolutions(solutionsArray);
    handlePaymentSlipNotAllowed(
      isAbleToPaymentSlip,
      plan.display_name,
      installmentCount,
      periodicity.coupon
    );
    handleUpdateCouponData(periodicity.coupon);
    setLoadingData(false);
  };

  const handlePaymentSlipNotAllowed = (
    isAllowed,
    planName,
    installmentCount,
    couponData = null
  ) => {
    if (isAllowed === false) {
      setPaymentSlipNotAllowed(true);

      if ((planName || '').includes('Basic') && installmentCount === 1) {
        setPaymentSlipNotAllowedMessage(
          'O pagamento por boleto não está disponível no Plano Basic 10GB mensal.'
        );
      } else if (
        (planName || '').includes('Standard') &&
        installmentCount === 1
      ) {
        setPaymentSlipNotAllowedMessage(
          'O pagamento por boleto não está disponível no Plano Standard 20GB mensal.'
        );
      } else if (
        couponData &&
        couponData.valid === true &&
        installmentCount === 1
      ) {
        setPaymentSlipNotAllowedMessage(
          'O pagamento por boleto não está disponível para esse cupom.'
        );
      } else {
        setPaymentSlipNotAllowedMessage('Somente para pagamento à vista.');
      }
    } else {
      setPaymentSlipNotAllowed(false);
    }
  };

  const handleChangePlan = (plan_id, solution_name) => {
    setSolutions((solutions) =>
      solutions.map((solution) => {
        if (solution.name === solution_name) {
          const selectedPlan = query
            .from(solution.plans || [])
            .where((plan) => plan.id === plan_id)
            .retrieve();

          const selectedPeriodicity = query
            .from(solution.plans || [])
            .where((plan) => plan.id === plan_id)
            .select((plan) => plan.periodicity)
            .where(
              (periodicity) =>
                periodicity.type === solution.selected_periodicity_type
            )
            .retrieve();

          const selectedInstallment = query
            .from(solution.plans || [])
            .where((plan) => plan.id === plan_id)
            .select((plan) => plan.periodicity)
            .where(
              (periodicity) =>
                periodicity.type === solution.selected_periodicity_type
            )
            .select((plan) => plan.installments)
            .where(
              (installment) =>
                installment.count === solution.selected_installment_count
            )
            .retrieve();

          setPlanPeriodicity(selectedPeriodicity.type);

          if (selectedPeriodicity.type === 'monthly') {
            setTotalPrice(selectedInstallment.price);
          } else {
            setTotalPrice(selectedPeriodicity.price);
          }

          handlePaymentSlipNotAllowed(
            selectedInstallment.payment_slip,
            selectedPlan.display_name,
            selectedInstallment.count,
            selectedPeriodicity.coupon
          );

          handleUpdateCouponData(selectedPeriodicity.coupon);

          return {
            ...solution,
            selected_plan_id: plan_id,
            selected_periodicity_type: selectedPeriodicity.type,
            selected_installment_count: selectedInstallment.count
          };
        } else {
          return solution;
        }
      })
    );

    clearAllValidations();
  };

  const handleAddCoupon = async (
    couponName,
    planId,
    periodicityType,
    notifyOnSuccess
  ) => {
    try {
      const response = await api.get(
        `/checkout/coupon?coupon=${couponName || ''}`
      );

      const couponData = response.data;

      await getData(couponData.id, planId, periodicityType);

      setCoupon(couponData);
      setCouponEnabled(true);

      if (notifyOnSuccess) {
        notifySuccess('Cupom adicionado com sucesso!');
      }

      return true;
    } catch (error) {
      return error;
    }
  };

  const handleChangePeriodicity = async (periodicity_type, solution_name) => {
    const formatSolutions = await Promise.all(
      solutions.map((solution) => {
        if (solution.name === solution_name) {
          const selectedPlan = query
            .from(solution.plans || [])
            .where((plan) => plan.id === solution.selected_plan_id)
            .retrieve();

          const periodicity = query
            .from(solution.plans || [])
            .where((plan) => plan.id === solution.selected_plan_id)
            .select((plan) => plan.periodicity)
            .where((periodicity) => periodicity.type === periodicity_type)
            .retrieve();

          setPlanPeriodicity(periodicity.type);

          if (periodicity.type === 'annually' && !periodicity.coupon) {
            if (solution_name === 'Gallery Pro') {
              handleAddCoupon(
                'GALLERYPRO20ANUAL',
                solution.selected_plan_id,
                periodicity.type
              );
            } else if (solution_name === 'Site Pro') {
              handleAddCoupon(
                'SITEPRO20ANUAL',
                solution.selected_plan_id,
                periodicity.type
              );
            }
          }

          handleUpdateCouponData(periodicity.coupon);

          const defaultInstallment = periodicity.installments[0];

          if (periodicity.type === 'monthly') {
            setTotalPrice(defaultInstallment.price);
          } else {
            setTotalPrice(periodicity.price);
          }

          handlePaymentSlipNotAllowed(
            defaultInstallment.payment_slip,
            selectedPlan.display_name,
            defaultInstallment.count,
            periodicity.coupon
          );

          return {
            ...solution,
            selected_periodicity_type: periodicity_type,
            selected_installment_count: defaultInstallment.count
          };
        } else {
          return solution;
        }
      })
    );

    setSolutions(formatSolutions);

    clearAllValidations();
  };

  const handleChangeInstallments = (installment_count, solution_name) => {
    setSolutions((solutions) =>
      solutions.map((solution) => {
        if (solution.name === solution_name) {
          const selectedPlan = query
            .from(solution.plans || [])
            .where((plan) => plan.id === solution.selected_plan_id)
            .retrieve();

          const selectedPeriodicity = query
            .from(solution.plans || [])
            .where((plan) => plan.id === solution.selected_plan_id)
            .select((plan) => plan.periodicity)
            .where(
              (periodicity) =>
                periodicity.type === solution.selected_periodicity_type
            )
            .retrieve();

          const installment = query
            .from(solution.plans || [])
            .where((plan) => plan.id === solution.selected_plan_id)
            .select((plan) => plan.periodicity)
            .where(
              (periodicity) =>
                periodicity.type === solution.selected_periodicity_type
            )
            .select((plan) => plan.installments)
            .where((installment) => installment.count === installment_count)
            .retrieve();

          if (solution.selected_periodicity_type === 'monthly') {
            setTotalPrice(installment.price);
          }

          handlePaymentSlipNotAllowed(
            installment.payment_slip,
            selectedPlan.display_name,
            null,
            selectedPeriodicity.coupon
          );

          handleUpdateCouponData(selectedPeriodicity.coupon);

          return {
            ...solution,
            selected_installment_count: installment.count
          };
        } else {
          return solution;
        }
      })
    );

    clearAllValidations();
  };

  const handleUpdateCouponData = (couponData) => {
    if (!couponData) {
      setCouponDiscount(null);
    } else {
      setCouponDiscount({
        discount_price: couponData.discount_price,
        discount_message: couponData.discount_message,
        price_with_discount: couponData.price_with_discount,
        valid: couponData.valid,
        invalid_message: couponData.invalid_message,
        discount_type: couponData.discount_type
      });
    }
  };

  const makeCheckout = async (data) => {
    try {
      const response = await api.post('/checkout/upgrade', {
        creditCardOwnerName: data.creditCardOwnerName,
        creditCardNumber: data.creditCardNumber,
        creditCardExpirationDate: data.creditCardExpirationDate,
        creditCardSecureCode: data.creditCardSecureCode,
        paymentMethod: data.paymentMethod,
        creditCardBrand: data.creditCardBrand,
        coupon_id: coupon.id,
        planId: solutions[0].selected_plan_id,
        periodicityType: solutions[0].selected_periodicity_type,
        installmentCount: solutions[0].selected_installment_count
      });
      const { payment_slip_url, callback_url } = response.data;
      const queryParams = [`payment_method=${data.paymentMethod}`];
      if (payment_slip_url) {
        queryParams.push(`&payment_slip_url=${payment_slip_url}`);
      }
      if (callback_url) {
        queryParams.push(`&callback_url=${callback_url}`);
      }
      queryParams.push(`&plan_id=${solutions[0].selected_plan_id}`);
      history.push(
        `/checkout/${solution_name}/payment-status?${queryParams.join('')}`
      );
    } catch (error) {
      triggerValidation(error);
      const errorDetails = getErrorDetails(error);
      if (errorDetails && errorDetails.id === 'checkout-popup') {
        notifyBackendError(error);
      }
      if (errorDetails && errorDetails.id === 'plan') {
        window.scrollTo(0, 0);
      }
      if (!errorDetails) {
        InfoModal.warning({
          title: 'Erro no processamento da compra.',
          width: 650,
          info: (
            <p>
              Ocorreu um erro ao processar a compra. Para continuar a
              contratação entre em contato com o financeiro via
              Whatsapp/Celular: <b>{financesContactInfo().phoneNumber}</b>.
              <br />
              <br />
              Você também pode clicar no botão abaixo para conversar diretamente
              com nosso time pelo Whatsapp.
            </p>
          ),
          buttonText: 'Entrar em contato pelo Whatsapp',
          buttonCallback: () =>
            window.open(financesContactInfo().whatsappLink, '_blank')
        });
      }
    }
  };

  const initCheckout = async () => {
    const { coupon_name, plan_id, periodicity_type } = parsedQueryParams;

    if (coupon_name) {
      const success = await handleAddCoupon(
        coupon_name,
        plan_id,
        periodicity_type,
        false
      );

      if (success === true) {
        return;
      }
    }

    await getData(null, plan_id, periodicity_type);
  };

  useEffect(() => {
    initCheckout();
  }, []);

  return (
    <Loading loading={loadingData} backgroundColor={null}>
      <Container>
        <Divider size="large" />

        <ShoppingCart
          products={solutions}
          totalPrice={totalPrice}
          planPeriodicity={planPeriodicity}
          handleChangePlan={handleChangePlan}
          handleChangePeriodicity={handleChangePeriodicity}
          handleChangeInstallments={handleChangeInstallments}
          coupon={coupon}
          couponDiscount={couponDiscount}
          couponEnabled={couponEnabled}
          handleAddCoupon={(couponName) =>
            handleAddCoupon(
              couponName,
              solutions[0].selected_plan_id,
              solutions[0].selected_periodicity_type,
              true
            )
          }
          validation={validation}
        />

        <Divider size="xlarge" />

        <PaymentInfo
          onSubmit={makeCheckout}
          paymentSlipNotAllowed={paymentSlipNotAllowed}
          clearValidation={clearValidation}
          validation={validation}
          clearAllValidations={clearAllValidations}
          paymentSlipNotAllowedMessage={paymentSlipNotAllowedMessage}
        />

        <Divider size="xlarge" />

        <Icon
          type="lock"
          theme="filled"
          style={{ color: 'rgba(0, 0, 0, 0.5)', fontSize: 30 }}
        />

        <Divider size="small" />

        <p align="center">
          Você está em um ambiente seguro
          <br />
          com informações criptografadas.
        </p>
      </Container>
    </Loading>
  );
};

export default Overview;
