import React, { useState } from 'react';
import { Modal } from 'antd';
import ReactDOM from 'react-dom';

import {
	ButtonWrapper,
	Info,
	ModalContent,
	Question,
	Title,
	Warning,
} from './styles';

import Button from '../../../../../Button';

import warningIcon from '../../../../../../assets/warning_icon.png';

import { getElementNode } from '../../../../../../services/elementNode';

const DuplicatedWarningModal = (props) => {
	const { callback, duplicatedPicturesCount } = props;

	const [visible, setVisible] = useState(true);

	const handleClose = (action) => {
		setVisible(false);

		callback(action);
	}

  return (
    <Modal
      visible={visible}
      onOk={() => handleClose('cancel')}
      onCancel={() => handleClose('cancel')}
      footer={null}
      closeIcon={<div />}
      width={700}
    >
      <ModalContent>
        <Warning src={warningIcon} alt="warning" />
        <Title>Duplicadas</Title>
        <Info>
          {duplicatedPicturesCount === 1
            ? 'Foi encontrada 1 foto duplicada.'
            : `Foram encontradas ${duplicatedPicturesCount} fotos duplicadas.`}
        </Info>
        <Question>
          <b>O que deseja fazer?</b>
        </Question>
        <Button block onClick={() => handleClose('overwrite')}>
          SUBSTITUIR DUPLICADAS
        </Button>
        <Button block onClick={() => handleClose('non-duplicates')}>
          IGNORAR DUPLICADAS
        </Button>
        <ButtonWrapper>
          <Button block onClick={() => handleClose('cancel')}>
            CANCELAR ENVIO
          </Button>
        </ButtonWrapper>
      </ModalContent>
    </Modal>
  );
};

DuplicatedWarningModal.open = async (duplicatedPicturesCount) => {
  const duplicatedWarningModalNode = getElementNode("duplicated-warning-modal");
	
	return new Promise((resolve) => {
		ReactDOM.render(
			<DuplicatedWarningModal
				callback={resolve}
				duplicatedPicturesCount={duplicatedPicturesCount}
			/>,
			duplicatedWarningModalNode
			);
	})
}

export default DuplicatedWarningModal;
