import React, { useState } from 'react';
import { Icon } from 'antd';
import { useSelector, useDispatch } from 'react-redux';

import { Creators as GalleryActions } from '../../../../store/ducks/gallery';

import Api from '../../../../services/api';
import { notifyError } from '../../../../utils/notificationService';
import { usePlan } from '../../../../services/plan';

import Dropzone from '../../../../components/Dropzone';
import Button from '../../../../components/Button';
import Divider from '../../../../components/Divider';
import LimitReachedPopup from '../../../../components/LimitReachedPopup';

import photoIcon from '../../../../assets/photo_icon_transparent.png';

import DuplicatedWarningModal from './Modals/DuplicatedWarning';
import OverLimitWarningModal from './Modals/OverLimitWarning';

import { DropzoneContent, CloseButton } from './styles';

const UploadStart = (props) => {
  const dispatch = useDispatch();

  const galleryInfo = useSelector((state) => state.gallery.info);
  const [loadingRequest, setLoadingRequest] = useState(false);
  const { checkLimit, getLimit, triggerPlanChange } = usePlan();

  const { collection_id, handleStartUpload, uploadStatus } = props;

  const checkSpaceLimit = async (files) => {
    let totalSize = 0;

    files.forEach((file) => {
      totalSize += file.size;
    });

    try {
      await Api.post('/subscription/gallery/check-limits', {
        pictures: files.length,
        size: totalSize
      });
    } catch (error) {
      const details =
        error &&
        error.response &&
        error.response.data &&
        error.response.data.details;

      if (!details) {
        setLoadingRequest(false);
        notifyError('Algo deu errado');
        return Promise.reject();
      }

      let message = '';

      if (details.limit === 'space') {
        await triggerPlanChange('limit');
      }

      if (details.limit === 'max_picture') {
        message = (
          <p>
            Atualmente você pode enviar{' '}
            <b>{details.available_pictures} fotos</b> e seu envio tem um total
            de <b>{details.requested_pictures} fotos</b>.
          </p>
        );

        LimitReachedPopup.open(message);
      }

      setLoadingRequest(false);

      return Promise.reject();
    }
  };

  const checkPictures = async (files) => {
    let pictureTitles = {};
    let hasDuplicatedPictures = false;
    let exceededPictureSizeLimit = false;
    let duplicatedAction = null;
    let duplicatedPicturesCount = 0;
    let nonDuplicatedPicturesList = [];

    try {
      const response = await Api.get(
        `/pictures/title?collection_id=${collection_id}`
      );
      pictureTitles = response.data.picture_titles;
    } catch (error) {
      // If it receives a 404 status, it means there are no pictures on collection
      // So we have to resolve it manually in order to avoid throwing an error to user.
      if (error.response && error.response.status !== 404) {
        return false;
      }
    }

    // In case the picture is duplicated, we tag it in order
    // to have this information on the back-end
    for (let i = 0; i < files.length; i++) {
      if (pictureTitles[files[i].name]) {
        duplicatedPicturesCount++;
        hasDuplicatedPictures = true;
        files[i].options = {
          overwrite: true
        };
      }
    }

    files = files.filter((file) => {
      // Filters all files in order to keep only the ones under plan limits
      const isUnderLimit = checkLimit(
        'gallery_limits',
        'max_picture_size',
        file.size / 1024 / 1024
      );
      if (isUnderLimit) {
        return true;
      } else {
        exceededPictureSizeLimit = true;
        return false;
      }
    });

    nonDuplicatedPicturesList = files.filter(
      (file) => !pictureTitles[file.name]
    );

    // Add cover option to picture if gallery has no cover
    if (!galleryInfo.cover) {
      if (nonDuplicatedPicturesList.length) {
        nonDuplicatedPicturesList[0].options = {
          ...nonDuplicatedPicturesList[0].options,
          cover: true
        };
      }
    }

    if (hasDuplicatedPictures) {
      duplicatedAction = await DuplicatedWarningModal.open(
        duplicatedPicturesCount
      );

      if (duplicatedAction === 'cancel') {
        setLoadingRequest(false);
        return Promise.reject();
      }
    }

    if (exceededPictureSizeLimit) {
      const info = (
        <>
          Foram detectadas uma ou mais fotos com mais de{' '}
          {getLimit('gallery_limits', 'max_picture_size')} MB,
          <br />
          estas fotos serão ignoradas.
        </>
      );

      const planExceededAction = await OverLimitWarningModal.open(info);

      if (planExceededAction === 'cancel') {
        setLoadingRequest(false);
        return Promise.reject();
      }
    }

    setLoadingRequest(false);

    if (duplicatedAction === 'non-duplicates') {
      return nonDuplicatedPicturesList;
    } else {
      return files;
    }
  };

  const onDrop = async (files) => {
    setLoadingRequest(true);

    await checkSpaceLimit(files);

    files = await checkPictures(files);

    handleStartUpload(files);
  };

  const handleCloseUploadComponent = () => {
    dispatch(GalleryActions.setInfo({ uploadStatus: 'invisible' }));
  };

  return (
    <>
      {uploadStatus !== 'gallery-empty' && (
        <CloseButton onClick={() => handleCloseUploadComponent()}>
          <Icon type="close" />
        </CloseButton>
      )}
      <Dropzone onDrop={onDrop} disabled={loadingRequest}>
        <DropzoneContent uploadStatus={uploadStatus}>
          {loadingRequest ? (
            <Icon type="loading" style={{ fontSize: 40 }} />
          ) : (
            <>
              <img src={photoIcon} alt="foto" />

              <Divider size="medium" />

              <Button type="primary" icon="cloud-upload">
                SUBIR FOTOS
              </Button>

              {uploadStatus === 'gallery-empty' && (
                <>
                  <Divider size="medium" />

                  <b>Suba suas fotos e nós cuidaremos do resto.</b>

                  <Divider size="small" />

                  <p>
                    Fique tranquilo, suas fotos estarão <b>seguras</b>, não
                    sofrerão <b>nenhuma modificação</b> e só serão
                    compartilhadas após a sua permissão.
                  </p>
                </>
              )}
            </>
          )}
        </DropzoneContent>
      </Dropzone>
      {/* <DropzoneContent>
        <img src={photoIcon} alt="foto" />

        <Divider size="medium" />

        <b>
          Upload indisponível :(
        </b>

        <Divider size="small" />

        <p>
          Pedimos desculpa pelo transtorno.
          <br />
          Estamos passando por uma instabilidade e estamos trabalhando para resolver o problema o mais rápido possível.
        </p>
      </DropzoneContent> */}
    </>
  );
};

export default UploadStart;
