import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';

import {
  Container,
  TextContainer,
  ProgressContainer,
  Title,
  Text,
  CurrentProgress,
  ProgressText,
  Rest,
} from './styles';

const UsageBar = (props) => {
  const {
    progress,
    current,
    total,
    height,
    width,
    title,
    multiUnity,
    singleUnity,
    hoverCurrent,
    hoverRest,
    percentMessage,
  } = props;

  const [color, setColor] = useState('#56C568');

  useEffect(() => {
    if (current / total < 0.5) {
      setColor('#56C568');
    } else if (current / total < 0.75) {
      setColor('#5BD1D7');
    } else {
      setColor('#FF4949');
    }
  }, [current, total]);

  return (
    <>
      <Container width={width}>
        {
          total === 'no_limit' ? (
            <>
              <Title>{title}: <b>{Number(current).toLocaleString()} {current === 1 ? singleUnity : multiUnity}</b> de <b>Ilimitado</b>.</Title>
            </>
          ) : (
            <>
              <Title>{title}</Title>
              <ProgressContainer height={height}>
                <Tooltip title={hoverCurrent}>
                  <CurrentProgress progress={progress} color={color}>
                    <ProgressText>{percentMessage ? percentMessage : `${progress}%`}</ProgressText>
                  </CurrentProgress>
                </Tooltip>
                <Tooltip title={hoverRest}>
                  <Rest rest={100 - progress} />
                </Tooltip>
              </ProgressContainer>
              <TextContainer>
                <Text>0</Text>
                <Text>
                  {Number(total).toLocaleString()} {total === 1 ? singleUnity : multiUnity}
                </Text>
              </TextContainer>
            </>
          )
        }
        
      </Container>
    </>
  );
};

UsageBar.defaultProps = {
  height: '30px',
  width: '100%',
};

UsageBar.propTypes = {
  total: PropTypes.number.isRequired,
  progress: PropTypes.number.isRequired,
  height: PropTypes.string,
  width: PropTypes.string,
  current: PropTypes.number.isRequired,
  singleUnity: PropTypes.string.isRequired,
  multiUnity: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  hoverCurrent: PropTypes.string.isRequired,
  hoverRest: PropTypes.string.isRequired,
  percentMessage: PropTypes.string,
};

export default UsageBar;
