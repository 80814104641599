import styled from 'styled-components';
import { Radio } from 'antd';

export const Opener = styled.div`
`;

export const ButtonWrapper = styled.div`
  display: flex;
`;

export const ButtonGroup = styled.div`
  display: flex;
  width: auto;
  flex-direction: row;
  margin: 8px 0 8px auto;

  button{
    margin-left: 10px;
  }
`;

export const RadioGroup = styled(Radio.Group)`
  & > label {
    display: flex;
    flex-direction: row;
    align-items: center;

    line-height: 32px;
  }

  label {
    button {
      height: 30px;
    }
  }

  label:hover {
    color: #FF4949;

    .ant-radio-inner {
      border-color: #56C568;
    }
  }

  .ant-radio-input:focus + .ant-radio-inner {
    border-color: #56C568;
  }

  .ant-radio:hover {
    .ant-radio-inner {
      border-color: #56C568;
    }
  }

  .ant-radio-checked {
    .ant-radio-inner {
      border-color: #56C568;
    }

    .ant-radio-inner:after {
      background-color: #56C568;
    }
  }

  .ant-radio-checked:after {
    border-color: #56C568;
  }
`;