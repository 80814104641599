import styled from 'styled-components';

export const Footer = styled.div`
	display: flex;
	flex-direction: column;
	
	text-align: right;

	background-color: #FBFBFB;

	padding: 18px 24px;

	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
`;

export const TotalPrice = styled.span`
	color: #818181;

	font-weight: bold;
	font-size: 18px;
`;

export const Periodicity = styled(TotalPrice)`
	font-size: 12px;
`;

export const CouponContainer = styled.div`
	width: 100%;
	display: flex;

	justify-content: flex-end;
`;

export const AddCouponText = styled.span`
	color: #0077B5;
	text-decoration: underline;

	cursor: pointer;

	transition: color 0.15s ease-in-out;

	&:hover {
		color: #0095A5;
		transition: color 0.15s ease-in-out;
	}
`;

export const CouponCard = styled.div`
	max-width: 300px;

	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

	padding: 24px;

	border-radius: 5px;
`;

export const CouponCardHeader = styled.div`
	display: flex;
	flex-wrap: wrap;

	i.anticon {
    color: #AAA;
		cursor: pointer;
  }

	div {
		width: auto;
	}
`;

export const CouponName = styled.span`
	color: #5A5A5A;
	font-weight: bolder;
	font-size: 16px;

	margin-right: 20px;
`;

export const CouponTextDescription = styled.span`
	word-wrap: break-word;
`;

export const ProductsList = styled.div`
	.ant-row {
		height: auto;
	}
`;

export const ProductHeader = styled.h1`
	font-weight: bolder;

	font-size: 14px;

	padding-bottom: ${({ isMobile }) => isMobile ? '5px' : '15px'};
	border-bottom: ${({ isMobile }) => !isMobile && '1px solid rgba(0, 0, 0, 0.1)'};

	padding-right: ${({ alignRight }) => alignRight ? 0 : '35px'};
	width: 100%;

	text-align: ${({ alignRight }) => alignRight && 'right'};
	margin-bottom: 0;
`;

export const ProductInfo = styled.div`
	padding-top: ${({ isMobile }) => isMobile ? 0 : '36px'};
	padding-bottom: ${({ isMobile }) => isMobile ? 0 : '36px'};
	height: ${({ isMobile }) => isMobile ? 'auto' : '114px'};

	display: flex;

	div {
		width: 100%;
	}

	margin-bottom: ${({ isMobile }) => isMobile && '30px'};

	padding-right: ${({ alignRight }) => alignRight ? 0 : '35px'};

	border-bottom: ${({ isMobile }) => !isMobile && '1px solid rgba(0, 0, 0, 0.1)'};

	span {
		display: flex;
		align-items: center;
		margin-left: ${({ alignRight }) => alignRight && 'auto'};
	}
`;

export const CouponDiscount = styled.div``;

export const CouponDiscountText = styled.span`
	color: #636363;

	font-weight: bolder;

	font-size: 12px;
`;

export const CouponDiscountPrice = styled.span`
	margin-left: 10px;

	color: #FF4949;

	font-weight: lighter;

	font-size: 17px;
`;

export const CouponDiscountMessage = styled.span``;