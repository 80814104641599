import React, { useState, useEffect } from 'react';
import { Row, Col } from 'antd';

import api from '../../../services/api';
import { useValidation } from '../../../services/validation';

import { Logo, RecoverPassword } from './styles';

import Button from '../../../components/Button';
import Label from '../../../components/Form/Label';
import Input from '../../../components/Form/Input';
import Divider from '../../../components/Divider';
import FormItem from '../../../components/Form/FormItem';
import Small from '../../../components/Form/Small';
import SubmitContainer from '../../../components/Form/SubmitContainer';

import { FormInput, ContainerTransparent } from '../../../styles/components';

import { notifySuccess } from '../../../utils/notificationService';

import logo from '../../../assets/logo.png';

const RequestRecovery = (props) => {
  const inputs = ['email'];

  const [loading, setLoading] = useState(false);
  const [validation, clearValidation, triggerValidation] = useValidation(inputs);
  const [email, setEmail] = useState('');

  const handleRequestPasswordRecovery = async () => {
    setLoading(true);

    try {
      await api.post('/customer/password/recovery', { email });

      notifySuccess('Email de redefinição de senha enviado com sucesso!');
    } catch (error) {
      triggerValidation(error);
    }

    setLoading(false);
  };

  const handleChange = (key, value) => {
    setEmail(value);
    clearValidation(key);
  };

  return (
    <ContainerTransparent>
      <Row type="flex" align="middle" style={{height: "100%"}}>
        <Col style={{ padding: '30px', width: '100%' }}>
          <RecoverPassword>
            <SubmitContainer onSubmit={handleRequestPasswordRecovery}>
              <Logo src={logo} alt="logo" />

              <Divider size="medium" />

              <Label>E-mail</Label>
              <FormInput>
                <FormItem
                  validateStatus={validation.email.validateStatus}
                  help={validation.email.help}
                >
                  <Input
                    onChange={({ target }) =>
                      handleChange('email', target.value)
                    }
                    value={email}
                  />
                </FormItem>
                <Small>Nesse e-mail você receberá uma mensagem com as instruções de recuperação de senha.</Small>
              </FormInput>

              <Divider size="xlarge" />

              <Button
                htmlType="submit"
                loading={loading}
                type="primary"
                block
              >
                RECUPERAR SENHA
              </Button>
            </SubmitContainer>
          </RecoverPassword>
        </Col>
      </Row>
    </ContainerTransparent>
  );
};

export default RequestRecovery;
