import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Box, useTheme } from '@material-ui/core';
import useStyles from './styles';

const GridPosition = (props) => {
  const { currentPosition, onChange, size, disabled } = props;

  const [position, setPosition] = useState(currentPosition);

  const theme = useTheme();
  const baseSpacing = theme.spacing();
  const sizeInPixels = {
    small: baseSpacing * 2,
    medium: baseSpacing * 3,
    large: baseSpacing * 4,
    xlarge: baseSpacing * 5,
    xxlarge: baseSpacing * 6
  };

  const classes = useStyles({ gridSizeInPixel: sizeInPixels[size], disabled });

  const handleChangePosition = async (position) => {
    if (!disabled) {
      setPosition(position);

      await onChange(position);
    }
  };

  const gridSelectorItems = () => {
    const items = [];
    // eslint-disable-next-line no-plusplus
    for (let item = 1; item < 10; item++) {
      const isSelected = position === item;
      items.push(
        <Box
          className={classes.gridSelectorItem}
          onClick={() => handleChangePosition(item)}
          style={{
            backgroundColor: `${isSelected ? '#39C395' : '#dfdfdf'}`
          }}
        />
      );
    }

    return items;
  };

  return <Box className={classes.gridSelector}>{gridSelectorItems()}</Box>;
};

GridPosition.defaultProps = {
  disabled: false
};

GridPosition.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large', 'xlarge', 'xxlarge'])
    .isRequired,
  currentPosition: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

export default GridPosition;
