import React from 'react';
import PropTypes from 'prop-types';
import { Container, Span } from './styles';

const Label = ({ children, isRequired }) => (
  <Container>
    <Span isRequired={isRequired}>
      {children}
    </Span>
  </Container>
);

Label.defaultProps = {
  isRequired: false,
};

Label.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  isRequired: PropTypes.bool,
};

export default Label;
