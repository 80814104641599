import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog as DialogMui,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Button,
  CircularProgress
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';

import useStyles from './styles';

const Dialog = (props) => {
  const {
    title,
    isOpen,
    onClose,
    onSave,
    children,
    buttonSaveTitle,
    fullWidth,
    maxWidth,
    noPadding,
    noMaxHeight
  } = props;

  const [loadingSave, setLoadingSave] = useState(false);

  const classes = useStyles({ noPadding });

  const handleSave = async () => {
    setLoadingSave(true);
    await onSave();
    setLoadingSave(false);
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <DialogMui
      maxWidth={maxWidth ?? 'md'}
      fullWidth={fullWidth}
      open={isOpen}
      onClose={handleClose}
      classes={{
        paperFullWidth: classes.paperHeight,
        paperScrollPaper: noMaxHeight && classes.paperScrollPaper
      }}
    >
      <IconButton onClick={handleClose} className={classes.closeIcon}>
        <CloseIcon />
      </IconButton>
      {title && (
        <DialogTitle className={classes.dialogTitle}>{title}</DialogTitle>
      )}

      <DialogContent className={classes.dialogContent}>
        {children}
      </DialogContent>

      {onSave && (
        <DialogActions>
          <Button onClick={handleClose} color="inherit">
            Cancelar
          </Button>
          <Button
            endIcon={
              loadingSave && (
                <CircularProgress
                  size={20}
                  className={classes.circularProgress}
                />
              )
            }
            onClick={handleSave}
            color="primary"
            variant="contained"
            className={classes.buttonSave}
          >
            {buttonSaveTitle || 'SALVAR '}
          </Button>
        </DialogActions>
      )}
    </DialogMui>
  );
};

Dialog.defaultProps = {
  title: '',
  buttonSaveTitle: '',
  onSave: null,
  fullWidth: false,
  maxWidth: null,
  noMaxHeight: false,
  noPadding: null
};

Dialog.propTypes = {
  title: PropTypes.string,
  buttonSaveTitle: PropTypes.string,
  isOpen: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func,
  children: PropTypes.node.isRequired,
  fullWidth: PropTypes.bool,
  noMaxHeight: PropTypes.bool,
  maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
  noPadding: PropTypes.bool
};

export default Dialog;
