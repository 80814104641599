import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Modal, Icon, Row, Col } from 'antd';
import smileIcon from '../../../../../../assets/icon/smile.png';
import {
  Container, Title, Subtitle, ButtonWrapper, Button,
} from './styles';

import { getElementNode } from '../../../../../../services/elementNode';

const UploadSuccessModal = (props) => { 
  const {
    succededList,
    openSelection,
    openGallery,
  } = props;

  const [visibility, setVisibility] = useState(true);

  const handleClick = (type) => {
    if (type === 'close') {
      setVisibility(false);
    } else if (type === 'selection') {
      openSelection();
      setVisibility(false);
    } else if (type === 'gallery') {
      openGallery();
      setVisibility(false);
    }
  }

  return (
    <Modal
      visible={visibility}
      onOk={() => handleClick('close')}
      onCancel={() => handleClick('close')}
      footer={null}
      width={850}
    >
      <Container>
        <img src={smileIcon} alt="Smile Icon" />
        <Title>Maravilha!</Title>
        <Subtitle mobile={window.innerWidth < 600}>
          {succededList.length === 1
            ? 'As fotos subiram com sucesso!'
            : `As ${succededList.length} fotos subiram com sucesso!`}
          <br /> <b>Clique abaixo</b> e ative facilmente vários recursos à sua
          galeria, deseja começar por qual opção?
        </Subtitle>
        <ButtonWrapper>
          <Row type="flex" justify="space-around" style={window.innerWidth < 600 ? {padding: '18px 0', width: '90%'} : {padding: '52px 0', width: '90%'}}>
            <Col xs={24} sm={10} md={10} lg={11} xl={11} style={window.innerWidth < 600 ? {marginBottom: '18px'} : {marginBottom: '0'}}>
              <Button onClick={() => handleClick('selection')} mobile={window.innerWidth < 600}>
                <h3>
                  <Icon type="select" />
                  Seleção de fotos
                </h3>
                <span>
                  Ative, configure e envie para o seu cliente realizar a seleção
                  das fotos
                </span>
              </Button>
            </Col>
            <Col xs={24} sm={10} md={10} lg={11} xl={11} style={window.innerWidth < 600 ? {marginTop: '18px'} : {marginTop: '0'}}>
              <Button onClick={() => handleClick('gallery')} mobile={window.innerWidth < 600}>
                <h3>
                  <Icon type="download" />
                  Entrega de fotos em alta
                </h3>
                <span>
                  Configure e libere o download das fotos para o seu cliente
                </span>
              </Button>
            </Col>
          </Row>
        </ButtonWrapper>
      </Container>
    </Modal>
  )
};

UploadSuccessModal.open = (props) => {
  const {
    succededList,
    openSelection,
    openGallery,
  } = props;

  const uploadSuccessModalNode = getElementNode("upload-success-modal");

  ReactDOM.render(
    <UploadSuccessModal
      succededList={succededList}
      openSelection={openSelection}
      openGallery={openGallery}
    />,
    uploadSuccessModalNode
  );
}

UploadSuccessModal.propTypes = {
  succededList: PropTypes.array.isRequired,
  openSelection: PropTypes.func.isRequired,
  openGallery: PropTypes.func.isRequired,
};

export default UploadSuccessModal;
