/**
 * This component is used to handle the form submit action with 'ENTER' key.
 * In order to use it, you have to wrap this component around Form components (such inputs, etc)
 * and add the prop htmlType='submit' to the button that handles the submit action.
 * 
 * Obs: The function responsible for making the submit action need to be given to this component.
 * 
 * Example of use:
 * 
 * --------------------------------------------------------------
 * 
 * const handleSubmit = () => {
 * 	console.log(`Your name is: ${name}`)
 * }
 * 
 * <SubmitContainer onSubmit={handleSubmit}>
 * 	<input
 * 		placeholder="type your name"
 * 		value={name}
 * 		onChange={({ target }) => setName(target.value)}
 * 	/>
 * 	<button htmlType="submit">SUBMIT</Button>
 * </SubmitContainer>
 * 
 * --------------------------------------------------------------
 * 
 * Following the example above, the user will be able to submit the data
 * by clicking on the button or even pressing 'ENTER' after typing something
 * on some input.
 */

import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

const SubmitContainer = (props) => {
	const { onSubmit, children } = props;

	const handleSubmit = (event) => {
		event.preventDefault();

		onSubmit();
	}

	return (
		<Container onSubmit={handleSubmit}>
			{children}
		</Container>
	)
};

SubmitContainer.defaultProps = {
	onSubmit: () => {},
}

SubmitContainer.propTypes = {
	onSubmit: PropTypes.func,
	children: PropTypes.oneOf([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
}

export default SubmitContainer;