import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
`;

export const FormWrapper = styled.div`
  padding: 16px;
  flex: 1;
`;

export const Footer = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e9e9e9;
  padding: 10px 16px;
  background: #fff;
  text-align: right;
`;

export const PreviewWrapper = styled.div`
  padding: 16px;
  margin-bottom: 60px;
`;

export const PreviewDevice = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  max-width: 380px;
  margin: auto;
  padding: 90px 10px;
  border: 5px solid transparent;
  border-radius: 60px;
  background-color: rgba(0,0,0,0.05);

  &:before, &:after {
    content: '';
    position: absolute;
  }

  &:before {
    width: 20%;
    height: 10px;
    top: 40px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 10px;
    background-color: rgba(0,0,0,0.2);;
  }

  &:after {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: solid 5px rgba(0,0,0,0.2);;
    left: 50%;
    transform: translateX(-50%);
    bottom: 20px;
  }
`;

export const PreviewDeviceContent = styled.div`
  width: 100%;
  height: 580px;
  background-color: #ECE5DD;
  position: relative;
`;

export const PreviewDeviceStatusBar = styled.div`
  height: 25px;
  background: #004e45;
  color: #fff;
  font-size: 14px;
  padding: 0 8px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;

  &:after {
    content: "";
    display: table;
    clear: both;
  }

  div, svg {
    position: relative;
    margin: 0 0 0 8px;
    font-weight: 600;
  }
`;

export const PreviewDeviceChat = styled.div`
  width: 100%;
  height: calc(100% - 25px);
  font-size: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const PreviewDeviceChatBar = styled.div`
  height: 60px;
  background: #075E54;
  color: #fff;
  padding: 0 8px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    transform: translateY(100%);
    box-shadow: inset 0 11px 10px -10px rgba(0, 0, 0, 0.7);
  }

  svg {
    display: block;
  }
`;

export const PreviewDeviceChatBarAvatar = styled.div`
  margin-left: 8px;
  width: 100%;
  max-width: 36px;
  min-width: 36px;
  height: 36px;
  border-radius: 50%;
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.1);
  display: block;
  background-color: #dfe5e7;

  svg {
    margin: auto;
    font-size: 24px;
    overflow: hidden;
    height: 100%;
  }
`;

export const PreviewDeviceChatBarName = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 8px;
  width: 100%;
  overflow: hidden;

  span:first-child {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.3px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
  }

  span:nth-child(2) {
    display: block;
    font-size: 11px;
    font-weight: 400;
    letter-spacing: 0;
  }
`;

export const PreviewDeviceChatBarActions = styled.div`
  float: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 8px;

  svg {
    margin-left: 32px;
    opacity: 0.5;
  }

  svg:last-of-type {
    opacity: 1;
  }
`;

export const PreviewDeviceChatContent = styled.div`
  overflow-x: hidden;
  padding: 0 16px;
  margin-bottom: 5px;
  height: 100%;
  width: 100%;

  &:after {
    content: "";
    display: table;
    clear: both;
  }
`;

export const PreviewDeviceChatContentMessage = styled.div`
  color: #000;
  clear: both;
  line-height: 18px;
  font-size: 15px;
  padding: 4px 8px 8px;
  position: relative;
  margin: 16px 0;
  max-width: 85%;
  word-wrap: break-word;
  background: #FFF;
  border-radius: 0 5px 5px 5px;
  float: left;

  h1 {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 4px;
    color: #56C568;
  }

  &:after {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 0 0 10px;
    border-color: #FFF transparent transparent transparent;
    top: 0;
    left: -10px;
  }

  div:first-of-type {
    display: inline-block;
  }

  div:last-of-type {
    display: inline-block;
    float: right;
    padding: 0 0 0 7px;
    position: relative;
    bottom: -4px;
  }

  span:first-of-type {
    color: rgba(0, 0, 0, .45);
    font-size: 11px;
    display: inline-block;
  }

  span:last-of-type {
    display: inline-block;
    margin-left: 2px;
    margin-bottom: 4px;
    position: relative;
    top: 4px;
    height: 16px;

    svg {
      position: absolute;
    }
  }
`;

export const PreviewDeviceChatActions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 45px;
  height: 100%;
  max-height: 260px;
  width: 100%;
  margin-bottom: 12px;
  position: relative;

  section {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    max-height: 160px;

    div {
      background: #fff;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: white;
      flex: 0 0 auto;
      margin: 0 auto;
      width: 42px;
      border-radius: 0 0 0 25px;

      svg {
        font-size: 18px;
        margin: 0 auto;
        margin-bottom: 14px;
        position: absolute;
        bottom: 0;
      }
    }

    div:first-of-type {
      margin-left: 8px;
    }

    div:last-of-type {
      flex: 0 0 auto;
      border-radius: 0 0 25px 0;
      text-align: center;

      .fa-paperclip {
        transform: rotate(-90deg);
      }
    }

    button {
      cursor: default;
      flex: 0 0 auto;
      margin-left: 8px;
      margin-right: 8px;
      padding: 0;
      outline: none;
      border: none;
      background: #128C7E;
      border-radius: 50%;
      color: #fff;
      position: relative;
      width: 42px;
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        color: #fff;
        font-size: 16px;
        margin-left: 3px;
        background: none;
        padding: 2px;

        path {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
`;

export const PreviewDeviceChatActionsInput = styled.span`
  border: 0;
  flex: 1 1 auto;
  font-size: 16px;
  margin: 0;
  outline: none;
  width: 100%;
  height: 100%;
  white-space: pre-wrap;
  background: #fff;
  padding: 8px;
  overflow: hidden;
  color: #000;
`;

export const PreviewDeviceChatActionsPreviewGallery = styled.div`
  display: flex !important;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 260px;
  height: 100%;
  background: #fff;
  border-radius: 25px 25px 0 0;
  margin-top: 3px;
  margin-bottom: 5px;
  word-wrap: break-word;
  justify-items: center;

  img {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  div {
    width: 175px;
    height: 80px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 5px !important;
    background: #f3f3f3;
    display: flex !important;
    flex-direction: column !important;
    position: relative;
    bottom: 0 !important;
  }

  span:first-of-type {
    margin-top: auto;
    font-size: 14px;
    font-weight: bold;
    display: flex;
    align-items: flex-end;
    color: #000;
  }

  span:last-of-type {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.7);
    top: 0 !important;
    margin-left: 0 !important;
    margin-bottom: auto;
  }
`;
