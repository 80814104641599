import React from 'react';
import { Switch as AntSwitch } from 'antd';
import PropTypes from 'prop-types';
import { Container } from './styles';

const Switch = (props) => {
  const { checked, defaultChecked } = props;

  return (
    <Container checked={checked || defaultChecked}>
      <AntSwitch {...props} />
    </Container>
  );
};

Switch.propTypes = {
  checked: PropTypes.bool,
  defaultChecked: PropTypes.bool,
};

export default Switch;
