import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Divider as AntDivider, Row, Col, Tooltip, Radio } from 'antd';

import { useValidation } from '../../../services/validation';

import { isEmailPatternValid, hasUpperCaseLetter } from '../../../utils/pattern';

import {
  Container,
  IconWrapper,
  ShareMethodContainer,
  ShareForm,
	ShareFormTitle,
  RecipientInfo,
  SubmitButtonWrapper,
  LinkShareContainer,
  LinkShareField,
  Email,
} from './styles';

import { FormInput } from '../../../styles/components';

import Input from '../../../components/Form/Input';
import Divider from '../../../components/Divider';
import Button from '../../../components/Button';
import FormItem from '../../../components/Form/FormItem';
import Label from '../../../components/Form/Label';
import SubmitContainer from '../../../components/Form/SubmitContainer';

import MagicLinkCheckbox from '../../../pages/Gallery/Selection/FollowUp/LinkShareBox/MagicLinkCheckbox';

import WhatsAppPreview from './WhatsAppPreview';
import MailPreview from './MailPreview';

import whatsapp from '../../../assets/wpp.png';
import email from '../../../assets/email.png';
import link from '../../../assets/link.png';

const Share = (props) => {
  const {
    modes,
    defaultSubject,
    defaultMessage,
    onConfirm,
    confirmText,
    recipientName,
    recipientTel,
    recipientEmail,
    recipientPassword,
    canChooseClient,
    privateSelection,
    magicLinkCheckbox,
    magicLink,
    magicLinkEnabled,
  } = props;

  const galleryInfo = useSelector((state) => state.gallery.info);
  const customerInfo = useSelector((state) => state.customer);

  const [data, setData] = useState({
    recipientName,
    subject: defaultSubject,
    message: defaultMessage,
    recipientTel,
    recipientEmail,
    recipientPassword: recipientPassword || "Senha criada por você.",
    recipientTypedTel: '',
    magicLinkEnabled: magicLinkEnabled || false,
  });

  const shareLink = data.magicLinkEnabled ? magicLink : galleryInfo.url;

  const [shareMode, setShareMode] = useState(modes[0]);
	const [loading, setLoading] = useState(false);
  const [copied, setCopied] = useState(false);
  
  const inputs = ["recipientTypedTel", "recipientName", "recipientEmail", "subject", "message"]
  const [validation, clearValidation, triggerValidation] = useValidation(inputs);
  const [errors, setErrors] = useState([])

	const handleCopy = () => {
    document.execCommand('copy', false, shareLink);

    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  const handleChange = (key, value) => {
    setData({
      ...data,
      [key]: value
    });
  
    clearValidation(key);
  };

  const setRecipient = () => {
    setData({
      ...data,
      recipientName,
      recipientTel,
      recipientEmail,
      recipientPassword: recipientPassword || "Senha criada por você.",
    })

    if (!recipientTel && shareMode === 'whatsapp') {
      setShareMode('email');
    }
  }

  const setMessage = () => {
    setData({
      ...data,
      message: defaultMessage,
    })
  }

  const sanitizeMessage = () => {
    if (data.message) {
      return data.message.replace(recipientName && "[nome-do-cliente]", recipientName)
    }
  }

  const validatePayload = async (payload) => {
    return new Promise((resolve, reject) => {
      const errors = [];

      if (!payload.message || payload.message === '') {
        errors.push({
          id: 'message',
          help: 'A mensagem não pode estar em branco.'
        })
      }

      if (shareMode === 'whatsapp' && (!payload.recipientTel || payload.recipientTel === '')) {
        errors.push({
          id: 'recipientTypedTel',
          help: 'O número do whatsapp não pode estar em branco.'
        })
      }

      if (shareMode === 'email' && (!payload.subject || payload.subject === '')) {
        errors.push({
          id: 'subject',
          help: 'O assunto da mensagem não pode estar em branco.'
        })
      }

      if (shareMode === 'email' && (!payload.recipientEmail || payload.recipientEmail === '')) {
        errors.push({
          id: 'recipientEmail',
          help: 'O email não pode estar em branco.'
        })
      }

      if (shareMode === 'email' && (!payload.recipientName || payload.recipientName === '')) {
        errors.push({
          id: 'recipientName',
          help: 'O nome não pode estar em branco.'
        })
      }

      setErrors(errors);

      if (errors.length) {
        triggerValidation(errors);
        reject();
      } else {
        resolve();
      }
    })
  }

  const handleConfirm = async () => {
    const payload = {
      ...data,
      recipientEmail: (data.recipientEmail || "").toLowerCase(),
      message: sanitizeMessage(data.message),
      recipientTel: data.recipientTel || data.recipientTypedTel,
      shareMode,
      magicLink: data.magicLinkEnabled ? magicLink : null,
    };

    await validatePayload(payload);

    setLoading(true);

    await onConfirm(payload);

    setLoading(false);
  };

  const handleCopyTextClick = (event) => {
    event.currentTarget.select()
  }

  // In case recipient has changed from outside, make a new render with new data
  useEffect(() => {
    setRecipient()
  }, [recipientName, recipientTel, recipientEmail, recipientPassword]);

  // In case message has changed from outside, make a new render with new data
  useEffect(() => {
    setMessage();
  }, [defaultMessage])

  return (
    <Container>
      <SubmitContainer onSubmit={handleConfirm}>
        <Label>Como deseja enviar?</Label>

        <Divider size="small" />

        <ShareMethodContainer>
          <Row gutter={[16, 16]}>
            {modes.includes('email') && (
              <Col xs={24} sm={24} md={24} lg={24} xl={24/(modes.length)} xxl={24/(modes.length)}>
                <IconWrapper
                  onClick={() => setShareMode('email')}
                  selected={shareMode === 'email'}
                >
                  <Radio checked={shareMode === 'email'}>
                    <img src={email} alt="email" />
                    <Email>E-mail</Email>
                  </Radio>
                </IconWrapper>
              </Col>
            )}

            {modes.includes('whatsapp') && (
              <Col xs={24} sm={24} md={24} lg={24} xl={24/(modes.length)} xxl={24/(modes.length)}>
                <Tooltip title={!canChooseClient && !recipientTel && 'O cliente selecionado não possui telefone/whatsapp cadastrado.'}>
                  <IconWrapper
                    onClick={() => !(!canChooseClient && !recipientTel) && setShareMode('whatsapp')}
                    selected={shareMode === 'whatsapp'}
                    disabled={!canChooseClient && !recipientTel}
                  >
                    <Radio checked={shareMode === 'whatsapp'} />
                    <img src={whatsapp} alt="whatsapp" />
                  </IconWrapper>
                </Tooltip>
              </Col>
            )}

            {modes.includes('link') && (
              <Col xs={24} sm={24} md={24} lg={24} xl={24/(modes.length)} xxl={24/(modes.length)}>
                <IconWrapper
                  onClick={() => setShareMode('link')}
                  selected={shareMode === 'link'}
                >
                  <Radio checked={shareMode === 'link'} />
                  <img src={link} alt="link" />
                </IconWrapper>
              </Col>
            )}
          </Row>
        </ShareMethodContainer>

        {(shareMode === 'whatsapp' ||
          shareMode === 'email') && (
            <>
              <Label>Resumo do envio</Label>

              <Divider size="small" />

              <AntDivider style={{ marginTop: 0 }} />

              <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
                  <ShareForm>
                    {(shareMode === 'email' || (shareMode === 'whatsapp' && data.recipientTel)) && (
                      <>
                        {canChooseClient ? (
                          <>
                            <ShareFormTitle>Nome</ShareFormTitle>

                            <FormInput>
                              <FormItem
                                validateStatus={validation.recipientName.validateStatus}
                                help={validation.recipientName.help}
                              >
                                <Input
                                  onChange={({ target }) =>
                                    handleChange('recipientName', target.value)
                                  }
                                  value={data.recipientName}
                                />
                              </FormItem>
                            </FormInput>

                            <Divider size="medium" />

                            <ShareFormTitle>Email</ShareFormTitle>

                            <FormInput>
                              <FormItem
                                validateStatus={validation.recipientEmail.validateStatus}
                                help={validation.recipientEmail.help}
                              >
                                <Input
                                  onChange={({ target }) =>
                                    handleChange('recipientEmail', target.value)
                                  }
                                  value={data.recipientEmail}
                                />
                              </FormItem>
                            </FormInput>
                          </>
                        ): (
                          <>
                            <ShareFormTitle>Para</ShareFormTitle>

                            <Divider size="small" />

                            <RecipientInfo>
                              {data.recipientName}
                              {shareMode === "whatsapp" && data.recipientTel && (
                                ` <${data.recipientTel}>`
                              )}
                              {shareMode === "email" && data.recipientEmail && (
                                ` <${data.recipientEmail}>`
                              )}
                            </RecipientInfo>
                          </>
                        )}

                        <Divider size="medium" />
                      </>
                    )}

                    {!data.recipientTel && shareMode === "whatsapp" && (<>
                        <ShareFormTitle>Telefone / Whatsapp</ShareFormTitle>

                        <FormInput>
                          <FormItem
                            validateStatus={validation.recipientTypedTel.validateStatus}
                            help={validation.recipientTypedTel.help}
                          >
                            <Input
                              onChange={({ target }) =>
                                handleChange('recipientTypedTel', target.value)
                              }
                              value={data.recipientTypedTel}
                              type="phone"
                            />
                          </FormItem>
                        </FormInput>

                        <Divider size="medium" />
                      </>
                    )}

                    {shareMode === "email" && (
                      <>
                        <ShareFormTitle>Assunto</ShareFormTitle>

                        <FormInput>
                          <FormItem
                            validateStatus={validation.subject.validateStatus}
                            help={validation.subject.help}
                          >
                            <Input
                              onChange={({ target }) =>
                                handleChange('subject', target.value)
                              }
                              value={data.subject}
                            />
                          </FormItem>
                        </FormInput>

                        <Divider size="medium" />
                      </>
                    )}

                    <ShareFormTitle>Mensagem</ShareFormTitle>

                    <FormInput>
                      <FormItem
                        validateStatus={validation.message.validateStatus}
                        help={validation.message.help}
                      >
                        <Input
                          onChange={({ target }) =>
                            handleChange('message', target.value)
                          }
                          value={data.message}
                          type="textarea"
                        />
                      </FormItem>
                    </FormInput>

                    <Divider size="large" />

                    <SubmitButtonWrapper>
                      <Button
                        type="primary"
                        loading={loading}
                        htmlType="submit"
                      >
                        {confirmText}
                      </Button>
                    </SubmitButtonWrapper>
                  </ShareForm>
                </Col>

                <Col xs={24} sm={24} md={24} lg={0} xl={0} xxl={0}>
                  {magicLinkCheckbox && (
                    <>
                      <div style={{ display: 'flex', width: '100%' }}>
                        <MagicLinkCheckbox
                          magicLinkEnabled={data.magicLinkEnabled}
                          handleToggleMagicLink={(value) => handleChange('magicLinkEnabled', value)}
                        />
                      </div>

                      <Divider size="medium" />
                    </>
                  )}
                </Col>

                <Col xs={0} sm={0} md={0} lg={12} xl={12} xxl={12}>
                  {magicLinkCheckbox && (
                    <>
                      <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                        <MagicLinkCheckbox
                          magicLinkEnabled={data.magicLinkEnabled}
                          handleToggleMagicLink={(value) => handleChange('magicLinkEnabled', value)}
                        />
                      </div>

                      <Divider size="medium" />
                    </>
                  )}

                  {shareMode === 'whatsapp' && (
                    <WhatsAppPreview
                      customerName={customerInfo.user_data.display_name}
                      galleryUrl={galleryInfo.url}
                      galleryCover={galleryInfo.cover}
                      galleryTitle={galleryInfo.title}
                      message={sanitizeMessage(data.message)}
                      privateSelection={privateSelection}
                      clientPassword={data.recipientPassword}
                      clientEmail={data.recipientEmail}
                      magicLinkEnabled={data.magicLinkEnabled}
                      magicLink={magicLink}
                    />
                  )}

                  {shareMode === 'email' && (
                    <MailPreview
                      sender={customerInfo.user_data.display_name}
                      recipient={data.recipientName}
                      message={sanitizeMessage(data.message)}
                      subject={data.subject}
                      galleryUrl={galleryInfo.url}
                      privateSelection={privateSelection}
                      clientPassword={data.recipientPassword}
                      clientEmail={data.recipientEmail}
                      magicLinkEnabled={data.magicLinkEnabled}
                      magicLink={magicLink}
                    />
                  )}
                </Col>
              </Row>
            </>
          )}

          {shareMode === "link" && (
            <>
              <Label>Copie o <b>link</b> e envie livremente para o seu cliente</Label>

              <Divider size="small" />

              <AntDivider style={{ marginTop: 0 }} />

              <LinkShareContainer>
                <LinkShareField value={shareLink} onClick={handleCopyTextClick} />
                <CopyToClipboard text={shareLink} onCopy={handleCopy}>
                  <Button
                    type="primary"
                    border="rightButton"
                    onClick={handleCopy}
                    size="large"
                  >
                    {copied ? 'COPIADO' : 'COPIAR'}
                  </Button>
                </CopyToClipboard>
              </LinkShareContainer>
            </>
          )}
      </SubmitContainer>
    </Container>
  );
};

Share.defaultProps = {
  defaultSubject: '',
  defaultMessage: '',
  onConfirm: () => {},
  confirmText: 'Enviar',
  modes: ['email', 'whatsapp', 'link'],
  recipientName: '',
  recipientEmail: '',
  recipientTel: '',
  recipientPassword: '',
  privateSelection: false,
  canChooseClient: false,
  magicLinkCheckbox: false,
  magicLink: '',
  magicLinkEnabled: false,
};

Share.propTypes = {
  defaultSubject: PropTypes.string,
  defaultMessage: PropTypes.string,
  onConfirm: PropTypes.func,
  confirmText: PropTypes.string,
  modes: PropTypes.arrayOf([PropTypes.oneOf(['email', 'whatsapp', 'link'])]),
  recipientName: PropTypes.string,
  recipientEmail: PropTypes.string,
  recipientTel: PropTypes.string,
  recipientPassword: PropTypes.string,
  canChooseClient: PropTypes.bool,
  privateSelection: PropTypes.bool,
  magicLinkCheckbox: PropTypes.bool,
  magicLink: PropTypes.string,
  magicLinkEnabled: PropTypes.bool,
};

export default Share;