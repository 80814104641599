/*
	- To initialize the hook 'usePlan' you'll need to do the following:
	import { usePlan } from 'some_place';
	const [checkSolution, triggerPopup] = usePlan();

	- To use the 'checkSolution' function, you need to know that
	the data inside user_subscription comes in the following format:
	{
		gallery_limits: {
			limit1: 100,
			limit2: true,
			...
		},
		proof_albums_limits: {
			...
		},
		...
	}

	- So, in order to use the 'checkSolution', you'll need to pass
	the key of user_subscription data as arguments to it
	checkSolution('gallery_limits', 'limit1');

	- The function above will give you a boolean response
	(true if the user has the solution of false if not)

	- Based on that response, you'll need to use the 'triggerPopup'
	to show the popup if user doesn't has the solution enabled on his account

	- And you're done!
*/

import { useSelector, useDispatch } from 'react-redux';

import { Creators as CustomerActions } from '../store/ducks/customer';

import PremiumPopup from '../components/PremiumPopup';
import UpgradePlan from '../components/UpgradePlan';
import { objectIsEmpty } from '../utils/object';
import api from './api';
import { notifyError, notifyWarn } from '../utils/notificationService';
import { notification } from 'antd';

const isSolutionEnabled = (limitTypeKey, limitKey, userSubscription) => {
  if (!userSubscription || !userSubscription[limitTypeKey]) {
    return false;
  }

  if (userSubscription[limitTypeKey][limitKey] === true) {
    return true;
  } else {
    return false;
  }
};

const isPlanEnabled = (expectedPlanCode, userSubscription) => {
  if (!userSubscription || !userSubscription.plan_map) {
    return false;
  }

  const isEnabled = Object.keys(userSubscription.plan_map).some((plan_code) =>
    plan_code.includes(expectedPlanCode)
  );

  if (isEnabled) {
    return true;
  } else {
    return false;
  }
};

const isUnderLimit = (limitTypeKey, limitKey, requested, userSubscription) => {
  if (!userSubscription || !userSubscription[limitTypeKey]) {
    return false;
  }

  if (userSubscription[limitTypeKey][limitKey] > requested) {
    return true;
  } else {
    return false;
  }
};

const showLimit = (limitTypeKey, limitKey, userSubscription) => {
  if (!userSubscription || !userSubscription[limitTypeKey]) {
    return null;
  } else {
    return userSubscription[limitTypeKey][limitKey];
  }
};

const getSuggestionPlanData = async (block_type, limitKey) => {
  try {
    const { data } = await api.get('/checkout/plan/suggestion', {
      params: {
        block_type: block_type,
        product_id: 2,
        blocked_feat: limitKey
      }
    });

    return data.planSuggestion;
  } catch (error) {
    notifyError('Algo deu errado');
    return null;
  }
};

export const usePlan = () => {
  const dispatch = useDispatch();
  const userSubscription = useSelector(
    (state) => state.customer.user_subscription
  );

  const onUpdateImmediately = async () => {
    const { data } = await api.get('/customer/info');

    dispatch(CustomerActions.setUserSubscription(data.user_subscription));
  };

  const triggerPlanChange = async (block_type, limitKey) => {
    notifyWarn('Carregando solicitação...');
    let planSuggestionData = await getSuggestionPlanData(block_type, limitKey);
    notification.destroy();
    return triggerPopup(planSuggestionData);
  };

  const checkSolution = (limitTypeKey, limitKey) => {
    return isSolutionEnabled(limitTypeKey, limitKey, userSubscription);
  };

  const triggerPopup = (suggestion_plan) => {
    if (suggestion_plan && !objectIsEmpty(suggestion_plan)) {
      return UpgradePlan.open(suggestion_plan, onUpdateImmediately);
    }
    return PremiumPopup.open();
  };

  const checkPlan = (plan_code) => {
    return isPlanEnabled(plan_code, userSubscription);
  };

  const checkLimit = (limitTypeKey, limitKey, requested) => {
    return isUnderLimit(limitTypeKey, limitKey, requested, userSubscription);
  };

  const getLimit = (limitTypeKey, limitKey) => {
    return showLimit(limitTypeKey, limitKey, userSubscription);
  };

  return {
    triggerPlanChange,
    checkSolution,
    triggerPopup,
    checkPlan,
    checkLimit,
    getLimit
  };
};
