import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  text-align: left;
	line-height: 1.2;
  margin-top: 1px;
  span{
    font-family: 'Open Sans', sans-serif !important;
    font-size: 10px;
    letter-spacing: 0.5px;
    color: #aaaaaa;
    font-weight: normal;
  }
`;
