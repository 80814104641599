/*
	That's our custom hook for input validations. To initialize this hook, you'll need to:
	
	import { useValidation } from '../services/validation'; // this path can change on the folder you're calling
	const inputNames = ['input1', 'input2']; // the names of inputs you want to validate
	const [validation, clearValidation, triggerValidation] = useValidation(inputNames);

	- The 'validation' contains a big object with a small object inside for every inputName
	you added above, it usually starts with default values:

	{
		'input1': {
			help: '',
			validateStatus: null,
		},
		'input2': {
			help: '',
			validateStatus: null,
		}
	}

	- The 'clearValidation' basically turns the given inputName's object into a default validation object,
	we usually use it in order to clean the error message being showed below the input:

	clearValidation('input1');

	- The 'triggerValidation' refactors all the validation objects with the status given by the back-end,
	so we usually use this function inside the catch of try/catch function:

	try {
		...
	} catch(error) { 
		// the default format of error responses given from the back-end is { success: false, error: 'SomeMessage', messages: [] }
		// we use the messages array that has the following format: [{ id: 'input1', help: 'some help' }, ...]
		triggerValidation(error);
	}

	- In order to display the error messages on input, you'll need to hug the input with a FormItem component,
	and pass the props help/validateStatus to this FormItem:

	const handleSubmit = () => {
		try {
			...
		} catch(error) {
			triggerValidation(error);
		}
	}

	<FormItem
		help={validation.input1.help}
		validateStatus={validation.input1.validateStatus}
	>
		<Input onChange={(value) => handleChange('input1', value)} />
	</FormItem>
	<Button onClick={() => handleSubmit()} />

	- In case your using the EditDialog component, you just need to pass a inputName prop to it and return the error
	on its 'onSave' function:

	const handleSubmit = (value) => {
		try {
			...
		} catch(error) {
			return error;
		}
	}

	<EditDialog
		inputName="input1"
		onSave={(value) => handleSubmit(value)}
	>
		<Input />
	</EditDialog>

	And you're done!
*/

import { useState } from "react"

import { notifyUndeterminedError } from '../utils/notificationService'

const validationSchema = {
	help: '',
	validateStatus: null,
}

const changeValidation = (help, validateStatus) => {
	const validation = {
		help,
		validateStatus,
	}

	return validation
}

const resetAllValidations = (keys) => {
	const states = {}

	keys.map((key) => {
		states[key] = validationSchema;
	})

	return states
}

const getValidationError = (error, validation) => {
	const messages = error && error.response && error.response.data && error.response.data.messages || error;

	// In case it receives no messages on error we trigger a default one
  if (!messages || !messages.length || !Array.isArray(messages)) {
    notifyUndeterminedError(error);
    return validation;
  }

	messages.filter(message => message).map((message) => {
    validation[message.id] = changeValidation(message.help, 'error');
	});

	return validation;
}

export const useValidation = (keys) => {
	const [validation, setValidation] = useState(resetAllValidations(keys));

	const clearValidation = (key) => {
		setValidation({
			...validation,
			[key]: changeValidation('', null),
		});
	};

	const clearAllValidations = () => {
		setValidation(resetAllValidations(keys));
	}

	const triggerValidation = (error) => {
		const validationError = getValidationError(error, validation);

		clearAllValidations();
		setValidation(validationError);
	};

	return [validation, clearValidation, triggerValidation, clearAllValidations];
}