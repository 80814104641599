import React from 'react';
import PropTypes from 'prop-types';
import { Select as AntSelect, Icon, Tag, Row, Col, Tooltip } from 'antd';

import Select from '../../../../components/Form/Select';
import Divider from '../../../../components/Divider';
import EditDialog from '../../../../components/EditDialog';
import FormItem from '../../../../components/Form/FormItem';
import Button from '../../../../components/Button';

import { query } from '../../../../services/query';

import {
  Footer,
  TotalPrice,
  Periodicity,
  CouponContainer,
  AddCouponText,
  CouponCard,
  CouponCardHeader,
  CouponName,
  CouponTextDescription,
  ProductsList,
  ProductHeader,
  ProductInfo,
  CouponDiscount,
  CouponDiscountText,
  CouponDiscountPrice,
  CouponDiscountMessage
} from './styles';

import { CardContainer, CardTitle, CardHeader, CardBody } from '../styles';

const ShoppingCart = (props) => {
  const {
    totalPrice,
    planPeriodicity,
    products,
    handleChangePlan,
    handleChangePeriodicity,
    handleChangeInstallments,
    handleAddCoupon,
    coupon,
    couponEnabled,
    validation,
    couponDiscount
  } = props;

  const isMobile = window.innerWidth <= 767;

  const getPeriodicity = () => {
    if (planPeriodicity === 'monthly') {
      return 'mês';
    } else if (
      planPeriodicity === 'annually' &&
      products[0].selected_installment_count === 1
    ) {
      return 'ano';
    }
  };

  const getRecurrence = () => {
    if (planPeriodicity === 'monthly') {
      return 'mensalidade';
    } else if (planPeriodicity === 'annually') {
      return 'anuidade';
    }
  };

  return (
    <CardContainer>
      <CardHeader>
        <CardTitle>Resumo da assinatura</CardTitle>
      </CardHeader>

      <Divider size="xsmall" />

      <CardBody>
        <ProductsList isMobile={isMobile}>
          {products.map((product) => [
            <Col xs={24} sm={24} md={3} lg={3} xl={3} xxl={3}>
              <Row>
                <ProductHeader isMobile={isMobile}>Solução</ProductHeader>
              </Row>
              <Row>
                <ProductInfo isMobile={isMobile}>
                  <span>{product.name}</span>
                </ProductInfo>
              </Row>
            </Col>,
            <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
              <Row>
                <ProductHeader isMobile={isMobile}>Plano</ProductHeader>
              </Row>
              <Row>
                <ProductInfo isMobile={isMobile}>
                  <FormItem
                    validateStatus={validation.plan.validateStatus}
                    help={validation.plan.help}
                  >
                    <Select
                      placeholder="Selecione um plano"
                      value={product.selected_plan_id}
                      onChange={(plan_id) =>
                        handleChangePlan(plan_id, product.name)
                      }
                    >
                      {query
                        .from(product.plans)
                        .retrieve()
                        .map((plan) => (
                          <AntSelect.Option value={plan.id}>
                            {plan.display_name}
                          </AntSelect.Option>
                        ))}
                    </Select>
                  </FormItem>
                </ProductInfo>
              </Row>
            </Col>,
            <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
              <Row>
                <ProductHeader isMobile={isMobile}>Período</ProductHeader>
              </Row>
              <Row>
                <ProductInfo isMobile={isMobile}>
                  <Select
                    placeholder="Selecione um período"
                    value={product.selected_periodicity_type}
                    onChange={async (selected_periodicity_type) =>
                      await handleChangePeriodicity(
                        selected_periodicity_type,
                        product.name
                      )
                    }
                  >
                    {query
                      .from(product.plans)
                      .where((plan) => plan.id === product.selected_plan_id)
                      .select((plan) => plan.periodicity)
                      .retrieve()
                      .map((periodicity) => (
                        <AntSelect.Option value={periodicity.type}>
                          {periodicity.display_name}
                        </AntSelect.Option>
                      ))}
                  </Select>
                </ProductInfo>
              </Row>
            </Col>,
            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
              <Row>
                <ProductHeader isMobile={isMobile}>Parcelas</ProductHeader>
              </Row>
              <Row>
                <ProductInfo isMobile={isMobile}>
                  <Tooltip
                    title={
                      product.selected_periodicity_type === 'monthly' &&
                      'Somente assinaturas anuais podem ser parceladas.'
                    }
                  >
                    <Select
                      placeholder="Selecione a quantidade de parcelas"
                      value={product.selected_installment_count}
                      onChange={(installment_count) =>
                        handleChangeInstallments(
                          installment_count,
                          product.name
                        )
                      }
                      disabled={product.selected_periodicity_type === 'monthly'}
                    >
                      {query
                        .from(product.plans)
                        .where((plan) => plan.id === product.selected_plan_id)
                        .select((plan) => plan.periodicity)
                        .where(
                          (periodicity) =>
                            periodicity.type ===
                            product.selected_periodicity_type
                        )
                        .select((periodicity) => periodicity.installments)
                        .retrieve()
                        .map((installment) => (
                          <AntSelect.Option value={installment.count}>
                            {installment.display_name}
                          </AntSelect.Option>
                        ))}
                    </Select>
                  </Tooltip>
                </ProductInfo>
              </Row>
            </Col>,
            <Col xs={24} sm={24} md={3} lg={3} xl={3} xxl={3}>
              <Row type="flex" justify="end">
                <ProductHeader isMobile={isMobile} alignRight={!isMobile}>
                  Valor total
                </ProductHeader>
              </Row>
              <Row>
                <ProductInfo isMobile={isMobile} alignRight={!isMobile}>
                  <span>
                    {
                      query
                        .from(product.plans)
                        .where((plan) => plan.id === product.selected_plan_id)
                        .select((plan) => plan.periodicity)
                        .where(
                          (periodicity) =>
                            periodicity.type ===
                            product.selected_periodicity_type
                        )
                        .retrieve().price
                    }
                  </span>
                </ProductInfo>
              </Row>
            </Col>
          ])}
        </ProductsList>

        <CouponContainer>
          {couponEnabled ? (
            <div>
              <Divider size="medium" />

              <CouponCard>
                <CouponCardHeader>
                  <CouponName>{coupon.name}</CouponName>
                  {couponDiscount && couponDiscount.valid ? (
                    <Tag color="#56C568">Cupom válido</Tag>
                  ) : (
                    <Tag color="#FF4949">Cupom inválido</Tag>
                  )}
                </CouponCardHeader>

                <Divider size="medium" />

                <EditDialog
                  title="Editar cupom"
                  onSave={handleAddCoupon}
                  inputName="coupon"
                  value={coupon.name}
                >
                  <Button type="success">Alterar cupom</Button>
                </EditDialog>

                <Divider size="medium" />

                <CouponTextDescription>
                  {(
                    (couponDiscount && couponDiscount.invalid_message) ||
                    coupon.description ||
                    ''
                  ).replace('recorrência', getRecurrence())}
                </CouponTextDescription>

                {couponDiscount && couponDiscount.valid && (
                  <>
                    <Divider size="medium" />
                    <CouponDiscount>
                      <CouponDiscountText>
                        DESCONTO DO CUPOM:
                      </CouponDiscountText>
                      <CouponDiscountPrice>
                        -{couponDiscount.discount_price}
                      </CouponDiscountPrice>
                    </CouponDiscount>
                  </>
                )}
              </CouponCard>
            </div>
          ) : (
            <div>
              <Divider size="medium" />

              <EditDialog
                title="Adicionar cupom"
                onSave={handleAddCoupon}
                inputName="coupon"
              >
                <AddCouponText>Adicionar cupom de desconto</AddCouponText>
              </EditDialog>
            </div>
          )}
        </CouponContainer>
      </CardBody>

      <Divider size="medium" />

      <Footer>
        {products[0].selected_installment_count > 1 ? (
          <>
            {couponDiscount && couponDiscount.valid ? (
              <TotalPrice>
                Total a pagar:{' '}
                {
                  query
                    .from(products[0].plans)
                    .where((plan) => plan.id === products[0].selected_plan_id)
                    .select((plan) => plan.periodicity)
                    .where(
                      (periodicity) =>
                        periodicity.type ===
                        products[0].selected_periodicity_type
                    )
                    .select((periodicity) => periodicity.installments)
                    .where(
                      (installment) =>
                        installment.count ===
                        products[0].selected_installment_count
                    )
                    .retrieve().coupon_discount_display_name
                }
              </TotalPrice>
            ) : (
              <TotalPrice>
                Total a pagar:{' '}
                {
                  query
                    .from(products[0].plans)
                    .where((plan) => plan.id === products[0].selected_plan_id)
                    .select((plan) => plan.periodicity)
                    .where(
                      (periodicity) =>
                        periodicity.type ===
                        products[0].selected_periodicity_type
                    )
                    .select((periodicity) => periodicity.installments)
                    .where(
                      (installment) =>
                        installment.count ===
                        products[0].selected_installment_count
                    )
                    .retrieve().display_name
                }
              </TotalPrice>
            )}
          </>
        ) : (
          <TotalPrice>
            Total a pagar:{' '}
            {couponDiscount && couponDiscount.valid
              ? couponDiscount.price_with_discount
              : totalPrice}
          </TotalPrice>
        )}
        {couponDiscount &&
        couponDiscount.valid &&
        couponDiscount.discount_type !== 'all_recurrence' ? (
          <CouponDiscountMessage>
            {couponDiscount.discount_message &&
              couponDiscount.discount_message.replace()}
          </CouponDiscountMessage>
        ) : (
          <>
            {planPeriodicity && getPeriodicity() && (
              <Periodicity>por {getPeriodicity()}</Periodicity>
            )}
          </>
        )}
      </Footer>
    </CardContainer>
  );
};

ShoppingCart.defaultProps = {
  totalPrice: 0,
  planPeriodicity: '',
  products: [],
  handleChangePlan: () => {},
  handleChangePeriodicity: () => {},
  handleChangeInstallments: () => {},
  handleAddCoupon: () => {},
  couponEnabled: false,
  coupon: null,
  couponDiscount: null,
  validation: {}
};

ShoppingCart.propTypes = {
  totalPrice: PropTypes.number,
  planPeriodicity: PropTypes.string,
  products: PropTypes.arrayOf(PropTypes.object),
  handleChangePlan: PropTypes.func,
  handleChangePeriodicity: PropTypes.func,
  handleChangeInstallments: PropTypes.func,
  handleAddCoupon: PropTypes.func,
  coupon: PropTypes.object,
  couponDiscount: PropTypes.object,
  couponEnabled: PropTypes.bool,
  validation: PropTypes.object
};

export default ShoppingCart;
