import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Modal, Icon } from 'antd';

import Button from '../Button';
import Divider from '../Divider';

import { ModalContent, ButtonWrapper } from './styles';

import { getElementNode } from '../../services/elementNode';

const PopConfirm = (props) => {
  const {
    onConfirm,
    title,
    titleIcon,
    cancelText,
    cancelIcon,
    confirmText,
    confirmIcon,
    showWarningIcon,
    info,
    waitConfirmPromise
  } = props;

  const [visibility, setVisibility] = useState(true);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setVisibility(false);
  };

  const handleConfirm = async () => {
    setLoading(true);

    if (waitConfirmPromise) {
      await onConfirm();
    } else {
      onConfirm();
    }

    setLoading(false);

    handleClose();
  };

  return (
    <Modal
      title={title}
      visible={visibility}
      onOk={handleConfirm}
      onCancel={handleClose}
      destroyOnClose={true}
      footer={[
        <ButtonWrapper>
          <Button key="back" icon={cancelIcon || 'close'} onClick={handleClose}>
            {cancelText}
          </Button>
          {(onConfirm || confirmText) && (
            <Button
              id="submit"
              key="submit"
              type="primary"
              icon={confirmIcon}
              loading={loading}
              onClick={handleConfirm}
            >
              {confirmText}
            </Button>
          )}
        </ButtonWrapper>
      ]}
      zIndex={1500}
    >
      <ModalContent>
        <Divider size="medium" />

        {showWarningIcon && (
          <Icon
            type={titleIcon}
            theme="twoTone"
            twoToneColor="#FF4949"
            style={{ fontSize: '18px' }}
          />
        )}
        {info}
      </ModalContent>
    </Modal>
  );
};

PopConfirm.defaultProps = {
  confirmText: null,
  confirmIcon: 'check',
  cancelIcon: null,
  cancelText: 'NÃO',
  title: 'Atenção',
  titleIcon: 'exclamation-circle',
  info: 'Tem certeza que deseja continuar?',
  loading: false,
  onConfirm: null,
  waitConfirmPromise: true,
  showWarningIcon: true
};

PopConfirm.propTypes = {
  info: PropTypes.string,
  confirmText: PropTypes.string,
  confirmIcon: PropTypes.string,
  cancelIcon: PropTypes.string,
  cancelText: PropTypes.string,
  title: PropTypes.string,
  titleIcon: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  waitConfirmPromise: PropTypes.bool,
  showWarningIcon: PropTypes.bool
};

PopConfirm.open = ({
  onConfirm,
  title,
  titleIcon,
  confirmText,
  confirmIcon,
  cancelText,
  cancelIcon,
  info,
  waitConfirmPromise,
  showWarningIcon
}) => {
  const popupConfirmNode = getElementNode('popup-confirm');

  ReactDOM.render(
    <PopConfirm
      onConfirm={onConfirm}
      title={title}
      titleIcon={titleIcon}
      confirmText={confirmText}
      confirmIcon={confirmIcon}
      cancelText={cancelText}
      cancelIcon={cancelIcon}
      info={info}
      waitConfirmPromise={waitConfirmPromise}
      showWarningIcon={showWarningIcon}
    />,
    popupConfirmNode
  );
};

export default PopConfirm;
