import { makeStyles } from '@material-ui/core';

import styled from 'styled-components';
import { Link } from 'react-router-dom';
import colors from '../../../styles/colors';

export const Container = styled.div`
  width: 100%;
  padding: 16px;
  background-color: #fff;

  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  button,
  .ant-input-search {
    width: 100%;
  }

  /*height: 60px;
  width: 100%;
  padding: 0;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  div {
    flex-basis: 250px;
    width: auto;
    margin-top: 0;
  }*/
`;

export const TableWrapper = styled.div`
  margin-top: 32px;

  th {
    min-width: 200px;
  }

  tr {
    td:nth-child(1) {
      div {
        div {
          cursor: pointer;
        }

        p {
          width: auto;
          cursor: pointer;
        }

        div:hover + p,
        p:hover {
          text-decoration: underline;
        }
      }
    }
  }

  p {
    margin-bottom: 0;
  }

  .ant-table {
    overflow-x: auto;
  }

  .ant-pagination {
    li:hover,
    li:focus {
      color: #fff;
      border-color: #56c568;

      a,
      .ant-pagination-item-link {
        color: #fff;
        background-color: #56c568;
        border-color: #56c568;
      }
    }

    .ant-pagination-disabled:hover {
      a {
        color: rgba(0, 0, 0, 0.25);
        border-color: #d9d9d9;
        background-color: transparent;
      }
    }

    .ant-pagination-item-active {
      background-color: #56c568;
      border-color: #56c568;

      a {
        color: #fff;
      }
    }

    a:hover {
      color: #fff;
    }
  }

  .ant-pagination-jump-next,
  .ant-pagination-jump-prev {
    .ant-pagination-item-container {
      .ant-pagination-item-link-icon {
        color: #56c568;
      }
    }
  }
`;

export const CoverContainer = styled.div`
  width: 75px;
  height: 75px;

  line-height: ${(props) => (props.isPlaceholder ? '75px' : null)};
  background: ${(props) => (props.isPlaceholder ? '#F8F8F8' : null)};
  text-align: ${(props) => (props.isPlaceholder ? 'center' : null)};
  vertical-align: ${(props) => (props.isPlaceholder ? 'middle' : null)};

  img {
    width: ${(props) => (props.isPlaceholder ? '45px' : '75px')};
    height: ${(props) => (props.isPlaceholder ? 'auto' : '75px')};
    object-fit: cover !important;
    object-position: center;
  }
`;

export const GalleryName = styled(Link)`
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.65);

  &:hover {
    color: rgba(0, 0, 0, 0.65);
    text-decoration: underline;
  }

  p {
    margin: 0 0 0 8px;
    display: flex;
    align-items: center;
    height: 100%;
    width: 172px;
  }

  img {
    object-fit: fill;
    border-radius: 4px;
  }
`;

export const Pill = styled.div`
  width: 70px;
  height: 25px;
  border-radius: 3px;
  font-weight: 700;
  font-size: 10px;

  color: #bdbdbd;
  color: ${(props) => props.status === 'Ativo' && '#ffffff'};
  color: ${(props) => props.status === 'Expirado' && '#ffffff'};

  background-color: #fbfbfb;
  background-color: ${(props) => props.status === 'Ativo' && '#39C395'};
  background-color: ${(props) => props.status === 'Expirado' && '#FF4949'};

  p {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
  }
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px;

  justify-content: center;

  h3 {
    text-align: center;
  }
`;

export const CounterCard = styled.div`
  background-color: #fff;

  border-radius: 3px;
  border-left: 1px solid #efefef;
  border-right: 1px solid #efefef;
  border-top: 1px solid #efefef;

  border-bottom: 3px solid
    ${({ status }) =>
      status === 'done'
        ? '#66CC42'
        : status === 'expired'
        ? '#FF0000'
        : status === 'open'
        ? '#FFE143'
        : '#81A3B5'};

  width: 30px;
  height: 30px;

  color: #949494;
  font-weight: bold;
  font-size: 13px;
  line-height: 25px;

  margin: 0 3px;
`;

export const SelectionStatusContainer = styled.div`
  display: flex;

  justify-content: center;
`;

const useStyles = makeStyles((theme) => ({
  formTitle: {
    color: colors.greyScale[6],
    fontWeight: 'bold'
  },
  filterContainer: {
    marginRight: '12px'
  },
  filterIcon: {
    fontSize: '28px',
    fontWeight: 'bold'
  },
  filterText: {
    textTransform: 'uppercase',
    letterSpacing: '0.07em',
    color: '#444444'
  },
  dateDeleteIcon: {
    marginLeft: '-28px',
    padding: '5px'
  },
  datePickerText: {
    marginBottom: '9px',
    [theme.breakpoints.between('md', 'lg')]: {
      marginBottom: '0px'
    }
  },
  datePickerContainer: {
    maxWidth: '180px'
  },
  datePicker: {
    width: '100%'
  },
  datePickerInput: {
    '& .MuiOutlinedInput-input': {
      padding: '12px 10px'
    }
  },
  clearFilterContainer: {
    maxWidth: '360px'
  },
  clearFilter: {
    color: `${colors.palette.primary} !important`,
    '&:hover, &:active': {
      color: colors.palette.primary,
      opacity: 0.8
    }
  }
}));

export default useStyles;
