import { makeStyles } from '@material-ui/core';

import colors from '../../styles/colors';

const useStyles = makeStyles((theme) => ({
  closeIcon: {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 1
  },
  circularProgress: {
    color: colors.greyScale[11]
  },
  dialogTitle: {
    color: '#333333',
    fontWeight: 'bold',
    fontSize: '16px'
  },
  dialogContent: {
    padding: (props) => (props.noPadding ? '0px' : theme.spacing(1, 2))
  },
  buttonSave: {
    lineHeight: '22px',
    fontSize: '11px',
    fontWeight: 600
  },
  paperHeight: {
    height: 'auto'
  },
  paperScrollPaper: {
    maxHeight: 'none',
    [theme.breakpoints.down('md')]: {
      maxHeight: 'calc(100% - 64px)'
    }
  }
}));

export default useStyles;
