import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Row, Col, Drawer } from 'antd';
import { Creators as GalleryActions } from '../../store/ducks/gallery';
import { Creators as AsideGalleryMenuActions } from '../../store/ducks/asideGalleryMenu';

import AsideGalleryMenu from './AsideGalleryMenu';
import Button from '../../components/Button';
import GalleryRoutes from '../../routes/gallery';
import Dropzone from '../../components/Dropzone';
import Divider from '../../components/Divider';
import Upload from '../../components/Gallery/Upload';
import Share from '../../components/Gallery/Share';

import api from '../../services/api';
import { sendMail, sendWhatsAppMessage } from '../../services/share';

import { notifyBackendError, notifySuccess } from '../../utils/notificationService';

import { Cover, ExpireInfo } from './styles';

import { Content, Portlet } from '../../styles/components';
import { Pill } from './Dashboard/styles';

import cover_placeholder from '../../assets/avatar-no-gallery-cover-pb.png';
import Loading from '../../components/Loading';

const Gallery = (props) => {
  const { location, history } = props;
  const { pathname } = location;
  const { collection_id } = props.match.params;

  const dispatch = useDispatch();
  const galleryInfo = useSelector((state) => state.gallery.info);
  const [loading, setLoading] = useState(true);

  const [shareDrawerVisibility, setShareDrawerVisibility] = useState(false);

  const getData = async () => {
    const response = await api.get(`/gallery?collection_id=${collection_id}`);

    const {
      cover, title, solutions: { selection_activated, download_activated }, url, pictures_ordenation, expires_in, expired
    } = response.data;

    const galleryInfo = {
      title,
      collection_id,
      selection_activated,
      download_activated,
      pictures_ordenation,
      url,
      cover,
      expires_in,
      expired
    };

    dispatch(GalleryActions.setInfo(galleryInfo));

    setLoading(false);
  };

  const onDrop = async ([file]) => {
    setLoading(true);

    const formData = new FormData();
    formData.append('cover', file);

    try{
      await api.put(`/gallery/${collection_id}/cover`, formData, {
        headers: {
          'Content-Type': 'multipart/data',
        },
      });

      await getData();
    } catch(error) {
      notifyBackendError(error);
      setLoading(false);
    }
  };

  const handleOpenGalleryFront = async () => {
    await window.open(galleryInfo.url, "_blank");
  }

  const handleOpenShare = () => {
    history.push(`/gallery/${collection_id}/share`);
    dispatch(AsideGalleryMenuActions.selectMenu('pictures'));
    dispatch(AsideGalleryMenuActions.selectSubMenu(''));
  }

  const init = () => {
    dispatch(GalleryActions.setInfo({
      title: null,
      collection_id: null,
      selection_activated: null,
      download_activated: false,
      cover: '',
      uploadStatus: 'invisible',
    }));
  };

  const redirect = () => {
    // In case the user changes his location to the gallery routes root
    props.history.listen((location) => {
      if (location.pathname === `/gallery/${collection_id}`) {
        props.history.push(`/gallery/${collection_id}/pictures`);
        dispatch(AsideGalleryMenuActions.selectMenu('pictures'));
        dispatch(AsideGalleryMenuActions.selectSubMenu(''));
      }
    })

    // In case the user reloads the page
    if (pathname === `/gallery/${collection_id}`) {
      props.history.push(`/gallery/${collection_id}/pictures`);
      dispatch(AsideGalleryMenuActions.selectMenu('pictures'));
      dispatch(AsideGalleryMenuActions.selectSubMenu(''));
    }
  }

  const onConfirm = async (data) => {
    // Send mail
    if (data.shareMode === 'email') {
      await sendMail({
        client_email: data.recipientEmail,
        client_message: data.message,
        client_name: data.recipientName,
        subject: data.subject,
        collection_id,
      });
      notifySuccess('E-mail enviado com sucesso!');
    }

    // Send whatsapp message
    if (data.shareMode === 'whatsapp') {
      await sendWhatsAppMessage({
        galleryUrl: galleryInfo.url,
        message: data.message,
        tel: data.recipientTel,
      });
    }

    setShareDrawerVisibility(false)
  }

  useEffect(() => {
    init();
    getData();
    redirect();
  }, []);

  return (
    <Content>
      <Drawer
			  onClose={() => setShareDrawerVisibility(false)}
        visible={shareDrawerVisibility}
        destroyOnClose={true}
        width={window.innerWidth < 1000 ? '100%' : 1000}
      >
        <Share
          canChooseClient={true}
          confirmText="ENVIAR"
          defaultSubject="As suas fotos estão disponíveis"
          defaultMessage="Você já pode ver as suas fotos!"
          onConfirm={onConfirm}
        />
      </Drawer>

      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={10} lg={8} xl={6} xxl={5}>
          <Row>
            <Col span={12}>
              <a href={galleryInfo.url} target="_blank">
                <Button
                  border="leftButton"
                  type="neutral"
                >
                  VISUALIZAR
                </Button>
              </a>
            </Col>
            <Col span={12}>
              <Button
                border="rightButton"
                type="primary"
                onClick={() => setShareDrawerVisibility(true)}
              >
                ENVIAR
              </Button>
            </Col>
          </Row>

          <Divider size="medium" />

          <Dropzone onDrop={onDrop}>
            <Cover background={galleryInfo.cover ? galleryInfo.cover : null}>
              {galleryInfo.cover ? null : (
                <img src={cover_placeholder} alt="Foto de Capa" />
              )}
              <Button loading={loading} type="primary">
                {// eslint-disable-next-line no-nested-ternary
                loading
                  ? 'CARREGANDO...'
                  : galleryInfo.cover
                  ? 'ALTERAR FOTO DE CAPA'
                  : 'INSERIR FOTO DE CAPA'}
              </Button>
            </Cover>
          </Dropzone>

          <Divider size="medium" />

          <AsideGalleryMenu collectionId={collection_id} pathname={pathname} />

          <Divider size="small" />

          {!loading && galleryInfo.expires_in && galleryInfo.expires_in !== 'Invalid date' ? (
            <>
              {galleryInfo.expired ? (
                <Pill
                  status="Expirado"
                  style={{
                    width: 'auto',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: '12px',
                  }}
                >
                  Essa galeria expirou em {galleryInfo.expires_in.toLocaleString()}
                </Pill>
              ) : (
                <ExpireInfo> Essa galeria expira em {galleryInfo.expires_in.toLocaleString()} </ExpireInfo>
              )}
            </>
          ) : null}
        </Col>

        <Col xs={24} sm={24} md={14} lg={16} xl={18} xxl={19}>
          <Upload {...props} />
          <GalleryRoutes />
        </Col>
      </Row>
    </Content>
  );
};

Gallery.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default Gallery;
