import React, { useState } from 'react';
import {
  Row,
  Col,
  Tooltip,
} from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import PropTypes from 'prop-types';

import {
  Box,
  LinkShare,
  LinkShareTitle,
  LinkShareSubTitle,
  LinkShareContainer,
  LinkShareField,
} from '../styles';

import Divider from '../../../../../components/Divider';
import Button from '../../../../../components/Button';

import MagicLinkCheckbox from './MagicLinkCheckbox';

const LinkShareBox = (props) => {
  const {
    magicLink,
    magicLinkEnabled,
    handleShare,
    clientFirstPassword,
    galleryShareUrl,
    selectionId,
    handleToggleMagicLink,
  } = props;

  const [copied, setCopied] = useState(false);

  const handleCopyMagicLink = () => {
    document.execCommand('copy', false, magicLink);

    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <Box style={{ padding: '5px 0px 5px 15px' }}>
      <LinkShare style={{ padding: 0, margin: 0 }}>
        <LinkShareTitle>Link para seleção de fotos</LinkShareTitle>
        <LinkShareSubTitle>
          Esse é o link para o seu cliente fazer a seleção das fotos, você pode
          reenviar para o seu cliente por onde e quando quiser.
        </LinkShareSubTitle>

        <Divider size="medium" />

        <LinkShareContainer>
          <Row
            gutter={[16, 16]}
            type="flex"
            style={{
              width: '100%',
              alignItems: 'flex-end',
              flexWrap: 'wrap',
              margin: 0,
            }}
          >
            <Col style={{ flex: 1, minWidth: 200 }}>
              <LinkShareSubTitle>Link</LinkShareSubTitle>

              <Divider size="small" />

              <LinkShareField>
                <a href={galleryShareUrl} target="_blank" rel="noopener noreferrer">
                  {galleryShareUrl}
                </a>
              </LinkShareField>
            </Col>
            <Col style={{ flex: 1, minWidth: 200 }}>
              <LinkShareSubTitle>Senha do cliente</LinkShareSubTitle>

              <Divider size="small" />

              <LinkShareField>
                {clientFirstPassword || (
                  <Tooltip title="O cliente alterou a senha dele e por isso você não tem mais acesso a ela.">
                    ******
                  </Tooltip>
                )}
              </LinkShareField>
            </Col>
            <Col style={{ padding: '8px 0 8px 8px' }}>
              <Button type="primary" onClick={() => handleShare(selectionId)}>
                REENVIAR
              </Button>
            </Col>
          </Row>
        </LinkShareContainer>
      </LinkShare>

      <Divider size="large" />

      <MagicLinkCheckbox
        magicLinkEnabled={magicLinkEnabled}
        handleToggleMagicLink={(value) => handleToggleMagicLink(selectionId, value)}
      />

      {magicLinkEnabled && (
        <>
          <Divider size="medium" />

          <LinkShareContainer>
            <LinkShareField>
              <a href={magicLink} target="_blank" rel="noopener noreferrer">
                {magicLink}
              </a>
            </LinkShareField>
            <CopyToClipboard text={magicLink} onCopy={handleCopyMagicLink}>
              <Button
                type="primary"
                border="rightButton"
                onClick={() => {}}
                size="large"
              >
                {copied ? 'COPIADO' : 'COPIAR'}
              </Button>
            </CopyToClipboard>
          </LinkShareContainer>
        </>
      )}
    </Box>
  );
};

LinkShareBox.defaultProps = {
  magicLink: '',
  magicLinkEnabled: false,
  clientFirstPassword: '',
};

LinkShareBox.propTypes = {
  magicLink: PropTypes.string,
  magicLinkEnabled: PropTypes.bool,
  handleShare: PropTypes.func.isRequired,
  clientFirstPassword: PropTypes.string,
  galleryShareUrl: PropTypes.string.isRequired,
  selectionId: PropTypes.number.isRequired,
  handleToggleMagicLink: PropTypes.func.isRequired,
};

export default LinkShareBox;
