import React, { useState, useEffect } from 'react';
import { Prompt } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row, Checkbox, Tooltip } from 'antd';
import moment from 'moment';
import 'moment/locale/pt-br';
import api from '../../../../services/api';
import { useValidation } from '../../../../services/validation';

import { Creators as GalleryActions } from '../../../../store/ducks/gallery';

import { CheckboxWrapper, TextContainer, CheckboxContainer } from './styles';

import { usePlan } from '../../../../services/plan';
import { sendMail, sendWhatsAppMessage } from '../../../../services/share';

import Input from '../../../../components/Form/Input';
import Label from '../../../../components/Form/Label';
import Small from '../../../../components/Form/Small';
import Switch from '../../../../components/Form/Switch';
import Button from '../../../../components/Button';
import DatePicker from '../../../../components/Form/DatePicker';
import InputNumber from '../../../../components/Form/InputNumber';
import SelectInput from '../../../../components/Form/Select';
import SwitchGroup from '../../../../components/Form/SwitchGroup';
import Divider from '../../../../components/Divider';
import FormItem from '../../../../components/Form/FormItem';
import Loading from '../../../../components/Loading';
import Pill from '../../../../components/Pill';
import ClientSelect from '../../../../components/Gallery/ClientSelect';
import Share from '../../../../components/Gallery/Share';

import {
  InputGroup,
  TitlePortlet,
  FormSwitch,
  FormDate,
  FormSubGroup,
  FormInput,
  FormLabel,
  SwitchContent,
  Portlet
} from '../../../../styles/components';

import InfoModal from '../../../../components/InfoModal';

const Preferences = ({ match, history }) => {
  const inputs = [
    'chargeType',
    'contractedPictures',
    'extraPicturePrice',
    'maxPictures'
  ];

  const galleryInfo = useSelector((state) => state.gallery.info);
  const collectionId = match.params.collection_id;
  const [sellPictures, setSellPictures] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingPage, setLoadingPage] = useState(true);
  const [validation, clearValidation, triggerValidation] = useValidation(
    inputs
  );
  const { checkSolution, triggerPlanChange } = usePlan();
  const [selectedClient, setSelectedClient] = useState({});
  const [formData, setFormData] = useState({
    charge_type: null,
    selling_photos: false,
    contracted_pictures: null,
    extra_picture_price: null,
    allow_extra_pictures: false,
    limit_date: null,
    selection_link: '',
    selLinkType: null,
    active: false
  });

  const handlePublicSelection = (value) => {
    // Warning: The value given by this input is the current before change
    if (value === false) {
      setFormData({ ...formData, selLinkType: 'public', active: true });
      setSelectedClient({});
    } else {
      setFormData({ ...formData, selLinkType: null, active: false });
      setSelectedClient({});
    }
  };

  const handlePrivateSelection = (client) => {
    setSelectedClient(client);
    setFormData({ ...formData, selLinkType: 'private', active: true });
  };

  const handleFormChange = (inputName, key, value) => {
    setFormData({
      ...formData,
      [key]: value
    });

    clearValidation(inputName);
  };

  const dispatch = useDispatch();

  const handleChargeTypeChange = (value) => {
    if (value === 'ALL') {
      setFormData({
        ...formData,
        contracted_pictures: null,
        charge_type: value
      });
    } else {
      setFormData({
        ...formData,
        charge_type: value
      });
    }
  };

  const handleSellPicture = async (val) => {
    const isEnabled = checkSolution('gallery_limits', 'buy_photos');

    if (!isEnabled) {
      return await triggerPlanChange('feat', 'buy_photos');
    }

    if (!val) {
      setFormData({
        ...formData,
        contracted_pictures: null,
        extra_picture_price: 0,
        allow_extra_pictures: val
      });
    } else {
      setFormData({
        ...formData,
        allow_extra_pictures: val
      });
    }
    setSellPictures(val);
  };

  const handleOpenMustSelectSelLinkTypeModal = () => {
    return InfoModal.warning({
      title: 'Você precisa selecionar um cliente.',
      info: (
        <>
          Caso você não tenha os dados do seu cliente para cadastrá-lo, você
          pode tornar essa galeria <strong>pública</strong>,
          <br />
          assim você não precisará cadastrar o seu cliente, e quando ele iniciar
          uma seleção de fotos, será
          <br />
          solicitado um breve cadastro.
        </>
      ),
      buttonText: 'OK, ENTENDI'
    });
  };

  const submitPublicSelection = async () => {
    setLoading(true);

    try {
      await api.put(`/selections/gallery/${collectionId}/link-preferences`, {
        ...formData,
        type: formData.selLinkType
      });

      dispatch(GalleryActions.setInfo({ selection_activated: true }));
      history.push(`/gallery/${collectionId}/selection/followup`);
    } catch (error) {
      triggerValidation(error);

      setLoading(false);

      return;
    }
  };

  const getSelLinkPreferences = async () => {
    const response = await api.get(
      `/selections/gallery/${collectionId}/link-preferences`
    );

    setFormData({
      ...formData,
      ...response.data,
      allow_extra_pictures: response.data.selling_photos
    });

    if (response.data.selling_photos) {
      setSellPictures(true);
    }
  };

  // Private selection submission
  const submitPrivateSelection = async (data) => {
    try {
      // Save selection link settings
      await api.put(`/selections/gallery/${collectionId}/link-preferences`, {
        ...formData,
        type: formData.selLinkType
      });

      // Create new selection
      await api.post('/selections/create', {
        collection_id: collectionId,
        customer_client_id: selectedClient.id,
        selection_data: {
          magic_link_enabled: data.magicLinkEnabled
        }
      });

      // Send mail
      if (data.shareMode === 'email') {
        await sendMail({
          client_email: data.recipientEmail,
          client_message: data.message,
          client_name: data.recipientName,
          client_password: data.recipientPassword,
          subject: data.subject,
          collection_id: collectionId,
          magicLink: data.magicLink
        });

        InfoModal.success({
          buttonText: 'IR PARA O ACOMPANHAMENTO DA SELEÇÃO DE FOTOS',
          info: (
            <>
              O link foi enviado com sucesso para o seu cliente!
              <br />
              Agora é só <strong>acompanhar</strong> a seleção das fotos.
            </>
          ),
          title: 'Maravilha!'
        });
      }

      // Send whatsapp message
      if (data.shareMode === 'whatsapp') {
        await sendWhatsAppMessage({
          galleryUrl: galleryInfo.url,
          message: data.message,
          tel: data.recipientTel,
          client_email: data.recipientEmail,
          client_password: data.recipientPassword,
          privateSelection: formData.selLinkType === 'private',
          magicLink: data.magicLink
        });

        InfoModal.success({
          buttonText: 'IR PARA O ACOMPANHAMENTO DA SELEÇÃO DE FOTOS',
          info: (
            <>
              Confirme que o seu cliente tenha recebido a mensagem no Whatsapp!
              <br />
              Agora é só <strong>acompanhar</strong> a seleção das fotos.
            </>
          ),
          title: 'Maravilha!'
        });
      }

      dispatch(GalleryActions.setInfo({ selection_activated: true }));
      history.push(`/gallery/${collectionId}/selection/followup`);
    } catch (error) {
      window.scrollTo(0, 0);

      triggerValidation(error);

      return;
    }
  };

  const getData = async () => {
    await getSelLinkPreferences();

    setLoadingPage(false);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Loading loading={loadingPage}>
      <Prompt
        when={formData.active === false}
        message="Você ainda não ativou a seleção de fotos, ao sair dessa página as informações serão perdidas."
      />
      <Portlet>
        <TitlePortlet>Defina as preferências da seleção de fotos</TitlePortlet>
        <Divider size="medium" />
        <FormDate>
          <Label>Data limite</Label>
          <InputGroup>
            <DatePicker
              size="large"
              value={formData.limit_date && moment(formData.limit_date)}
              onChange={(date) =>
                handleFormChange('limitDate', 'limit_date', date)
              }
            />
          </InputGroup>
          <Small>
            Quando chegar as 23:59 do dia escolhido, o seu cliente não poderá
            mais realizar a seleção de fotos. Se necessário você pode prolongar
            esse prazo posteriormente.
          </Small>
        </FormDate>
        <Divider size="medium" />
        <FormLabel>
          <FormSwitch>
            <SwitchGroup>
              <Label>Vender fotos</Label>
              <SwitchContent>
                <Switch checked={sellPictures} onChange={handleSellPicture} />
                {!checkSolution('gallery_limits', 'buy_photos') && <Pill />}
              </SwitchContent>
            </SwitchGroup>
            <Small>
              Se você optar por vender fotos, basta marcar essa opção que o seu
              cliente poderá selecionar fotos além do contratado, e será
              informado do valor à ser pago.
            </Small>
          </FormSwitch>
        </FormLabel>
        <Divider size={sellPictures ? 'small' : 'medium'} />
        {sellPictures && (
          <FormSubGroup>
            <Label isRequired>Forma de cobrar</Label>
            <FormInput>
              <FormItem
                validateStatus={validation.chargeType.validateStatus}
                help={validation.chargeType.help}
              >
                <SelectInput
                  required
                  defaultValue="selecione"
                  onChange={(value) => {
                    handleFormChange('chargeType', 'charge_type', value);
                    handleChargeTypeChange(value);
                  }}
                  value={formData.charge_type}
                >
                  <SelectInput.Option value={null}>
                    Selecione
                  </SelectInput.Option>
                  <SelectInput.Option value="ALL">
                    Meu cliente não contratou nenhum pacote de fotos, desejo
                    cobrar por todas as fotos que ele selecionar
                  </SelectInput.Option>
                  <SelectInput.Option value="ADDITIONAL">
                    Meu cliente contratou um pacote de fotos, desejo cobrar
                    apenas pelas fotos adicionais que ele selecionar
                  </SelectInput.Option>
                </SelectInput>
              </FormItem>
            </FormInput>

            <Divider size="medium" />

            {formData.charge_type === 'ADDITIONAL' ? (
              <>
                <Label isRequired={formData.charge_type === 'ADDITIONAL'}>
                  Quantidade de fotos do pacote contratado
                </Label>
                <FormInput>
                  <FormItem
                    validateStatus={
                      validation.contractedPictures.validateStatus
                    }
                    help={validation.contractedPictures.help}
                  >
                    <Input
                      disabled={formData.charge_type !== 'ADDITIONAL'}
                      value={formData.contracted_pictures}
                      onChange={({ target }) => {
                        handleFormChange(
                          'contractedPictures',
                          'contracted_pictures',
                          target.value
                        );
                      }}
                    />
                  </FormItem>
                  <Small>
                    Informe a quantidade de fotos já contratadas pelo seu
                    cliente. A partir dessa quantidade será iniciada a cobrança
                    por foto adicional.
                  </Small>
                </FormInput>
              </>
            ) : null}

            {formData.charge_type === 'ADDITIONAL' && <Divider size="medium" />}

            <Label isRequired>
              {formData.charge_type === 'ADDITIONAL'
                ? 'Valor de cada foto adicional'
                : 'Valor de cada foto'}
            </Label>
            <FormInput>
              <FormItem
                validateStatus={validation.extraPicturePrice.validateStatus}
                help={validation.extraPicturePrice.help}
              >
                <InputNumber
                  value={formData.extra_picture_price}
                  onChange={(value) => {
                    handleFormChange(
                      'extraPicturePrice',
                      'extra_picture_price',
                      value
                    );
                  }}
                  disabled={formData.charge_type === null}
                  type="bill"
                />
              </FormItem>
              <Small>
                Informe o valor a ser cobrado por foto. O sistema irá calcular
                automaticamente o valor total a ser pago baseado na quantidade
                de fotos.
              </Small>
            </FormInput>
          </FormSubGroup>
        )}
        {sellPictures ? null : (
          <>
            <Label>Quantidade máxima de fotos para selecionar</Label>
            <FormInput>
              <FormItem
                validateStatus={validation.maxPictures.validateStatus}
                help={validation.maxPictures.help}
              >
                <InputNumber
                  placeholder="Sem limite"
                  value={sellPictures ? null : formData.contracted_pictures}
                  onChange={(value) => {
                    handleFormChange(
                      'maxPictures',
                      'contracted_pictures',
                      value === '' ? null : value
                    );
                  }}
                />
              </FormItem>
              <Small>
                Ao atingir esse limite o seu cliente será bloqueado.
              </Small>
            </FormInput>
          </>
        )}

        {formData.selLinkType === 'public' ? (
          <>
            <Divider size="medium" />

            <CheckboxWrapper>
              <CheckboxContainer>
                <Checkbox
                  defaultChecked={true}
                  value={formData.selLinkType === 'public'}
                  onChange={({ target }) => handlePublicSelection(target.value)}
                />
              </CheckboxContainer>
              <TextContainer>
                <Label>Tornar essa seleção de fotos pública</Label>
                <span>
                  Qualquer pessoa que tiver o link dessa galeria poderá se
                  cadastrar e iniciar a seleção de fotos.
                  <br />
                  Ideal para formaturas, apresentações, eventos esportivos, etc.
                </span>
              </TextContainer>
            </CheckboxWrapper>
          </>
        ) : (
          <>
            <Divider size="medium" />

            <Label>Cliente</Label>

            <Divider size="small" />

            <Row gutter={[24, 0]}>
              <Col xs={24} sm={24} md={24} lg={10} xl={10} xxl={10}>
                <ClientSelect
                  onSelect={handlePrivateSelection}
                  collectionId={collectionId}
                />
                {!Object.keys(selectedClient).length && (
                  <Small>
                    Essa seleção de fotos será privada para o cliente que você
                    selecionar
                  </Small>
                )}
              </Col>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={14}
                xl={14}
                xxl={14}
                style={{ marginTop: '17px' }}
              >
                <CheckboxWrapper>
                  <CheckboxContainer>
                    <Checkbox
                      value={formData.selLinkType === 'public'}
                      onChange={({ target }) =>
                        handlePublicSelection(target.value)
                      }
                    />
                  </CheckboxContainer>
                  <Tooltip
                    title={
                      <span>
                        Qualquer pessoa que tiver o link dessa galeria poderá se
                        cadastrar e iniciar a seleção de fotos.
                        <br />
                        Ideal para formaturas, apresentações, eventos
                        esportivos, etc.
                      </span>
                    }
                  >
                    <TextContainer>
                      <Label>Tornar essa seleção de fotos pública</Label>
                    </TextContainer>
                  </Tooltip>
                </CheckboxWrapper>
              </Col>
            </Row>
          </>
        )}

        {formData.selLinkType === 'public' && (
          <>
            <Divider size="medium" />

            <Button
              type="primary"
              size="large"
              onClick={submitPublicSelection}
              loading={loading}
            >
              ATIVAR SELEÇÃO DE FOTOS
            </Button>

            <Divider size="small" />

            <span>
              * após ativar a seleção de fotos, você poderá enviar o link para
              os seus clientes
            </span>
          </>
        )}

        {!formData.selLinkType && (
          <>
            <Divider size="medium" />

            <Button
              type="primary"
              size="large"
              onClick={handleOpenMustSelectSelLinkTypeModal}
              loading={loading}
            >
              ATIVAR E ENVIAR
            </Button>
          </>
        )}

        {formData.selLinkType === 'private' && selectedClient.name && (
          <>
            <Divider size="large" />

            <Share
              confirmText="ATIVAR E ENVIAR"
              defaultSubject="Fotos disponíveis para seleção de fotos"
              defaultMessage="As suas fotos já estão disponíveis!"
              modes={['email', 'whatsapp']}
              recipientName={selectedClient.name}
              recipientEmail={selectedClient.email}
              recipientTel={selectedClient.tel}
              recipientPassword={
                selectedClient.extradata &&
                selectedClient.extradata.first_password
              }
              privateSelection
              onConfirm={submitPrivateSelection}
              magicLinkCheckbox={formData.selLinkType === 'private'}
              magicLink={selectedClient.homeFPMagicLink}
            />
          </>
        )}
      </Portlet>
    </Loading>
  );
};

export default Preferences;
