import styled from 'styled-components';

export const Container = styled.div`

`;

export const PlanName = styled.span`

`;

export const PlanType = styled.span`
	font-weight: lighter;
`;