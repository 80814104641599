import styled from 'styled-components';


export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.span`
  font-size: 20px;
  font-weight: bold;
  color: black;
  max-width: 100%;
`;

export const Subtitle = styled.span`
  text-align: center;
  font-size: 16px;
  color: #666666;
  max-width: 100%;
`;
