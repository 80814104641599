import React, { useState, useEffect } from 'react';
import { Row, Col, Divider as AntDivider } from 'antd';
import { useSelector } from 'react-redux';

import Label from '../../components/Form/Label';
import Button from '../../components/Button';
import Input from '../../components/Form/Input';
import Small from '../../components/Form/Small';
import Divider from '../../components/Divider';
import FormItem from '../../components/Form/FormItem';
import Loading from '../../components/Loading';
import SubmitContainer from '../../components/Form/SubmitContainer';

import { Portlet, FormSubGroup, TitleSection, FormInput, FormLabel } from '../../styles/components';

import { notifySuccess } from '../../utils/notificationService';
import { useValidation } from '../../services/validation';
import api from '../../services/api';

import ColPortlet from './PlanPortlets/ColPortlet';
import AlbumPortlet from './PlanPortlets/AlbumPortlet';
import SitePortlet from './PlanPortlets/SitePortlet';

const Account = () => {
  const inputs = ['currentPassword', 'newPassword'];
  const userSubscription = useSelector(state => state.customer.user_subscription);
  const [usage, setUsage] = useState({});

  const [email, setEmail] = useState('');
  const [passwords, setPasswords] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [loadingUsage, setLoadingUsage] = useState(true);
  const [validation, clearValidation, triggerValidation] = useValidation(inputs);

  const handleSubmit = async () => {
    setLoading(true);

    try{
      await api.put('/customer/password', { ...passwords });

      notifySuccess('Sua senha foi alterada com sucesso!');
    } catch(error) {
      triggerValidation(error);
    }

    setLoading(false);
  };

  const handleChange = async (key, value) => {
    setPasswords({
      ...passwords,
      [key]: value,
    })

    clearValidation(key);
  }

  const getEmail = async () => {
    const { data } = await api.get('/customer/email');
    setEmail(data.email);
    setLoadingData(false);
  };

  const getUsage = async () => {
    const { data } = await api.get('/customer/usage');
    setUsage(data);
    setLoadingUsage(false);
  }

  useEffect(() => {
    getEmail();
    getUsage();
  }, []);

  return (
      <Row gutter={[16, 16]}>
        <Col xs={24} md={12}>
          <Portlet>
            <SubmitContainer onSubmit={handleSubmit}>
              <TitleSection>DADOS DA CONTA</TitleSection>

              <Divider size="medium" />

              <Loading loading={loadingData}>
                <Label>E-mail da conta</Label>
                <FormInput>
                  <FormItem>
                    <Input disabled value={email} />
                  </FormItem>
                  <Small>Se desejar alterar o e-mail de notificações, acesse a página “configurações >> notificações”. <br /> Para alterar esse e-mail, é necessário fazer a solicitação em nosso suporte.</Small>
                </FormInput>

                <Divider size="medium" />

                <FormLabel>
                  <Label>Alterar senha</Label>
                </FormLabel>

                <Divider size="small" />

                <FormSubGroup>
                  
                  <Label>Senha atual</Label>
                  <FormInput>
                    <FormItem
                      help={validation.currentPassword.help}
                      validateStatus={validation.currentPassword.validateStatus}
                    >
                      <Input 
                        type="password" 
                        value={passwords.currentPassword}
                        onChange={({ target }) => handleChange('currentPassword', target.value)} 
                      />
                    </FormItem>
                  </FormInput>

                  <Divider size="medium" />

                  <Label>Nova senha</Label>
                  <FormInput>
                    <FormItem
                      help={validation.newPassword.help}
                      validateStatus={validation.newPassword.validateStatus}
                    >
                      <Input 
                        type="password" 
                        value={passwords.newPassword} 
                        onChange={({ target }) => handleChange('newPassword', target.value)} 
                      />
                    </FormItem>
                  </FormInput>

                </FormSubGroup>

                <AntDivider />

                <Button
                  htmlType="submit"
                  type="primary"
                  icon="check"
                  loading={loading}
                >
                  SALVAR
                </Button>
              </Loading>
            </SubmitContainer>
          </Portlet>
        </Col>
        <Col xs={24} md={12}>
          <Portlet>
            <TitleSection>PLANO CONTRATADO</TitleSection>

            <Divider size="medium" />
            
            <Loading loading={!userSubscription || loadingUsage}>
              {
                userSubscription && Object.keys(userSubscription.plan_map)
                .map(plan => {
                  if (plan.startsWith("col_")) {
                    return (
                      <ColPortlet
                        galleries={usage.galleries}
                        maxGalleries={userSubscription.gallery_limits.max_gallery}
                        galleryPictures={usage.gallery_pictures}
                        maxGalleryPictures={userSubscription.gallery_limits.max_picture}
                        gallerySpace={usage.gallery_space}
                        maxGallerySpace={userSubscription.gallery_limits.space}
                        planName={userSubscription.plan_map[plan]}
                        planType="Gallery Pro"
                      />
                    )
                  }
                })
              }

              {
                userSubscription && Object.keys(userSubscription.plan_map)
                .map(plan => {
                  if (plan.startsWith("sel_")) {
                    return (
                      <ColPortlet
                        galleries={usage.galleries}
                        maxGalleries={userSubscription.gallery_limits.max_gallery}
                        galleryPictures={usage.gallery_pictures}
                        maxGalleryPictures={userSubscription.gallery_limits.max_picture}
                        gallerySpace={usage.gallery_space}
                        maxGallerySpace={userSubscription.gallery_limits.space}
                        planName={userSubscription.plan_map[plan]}
                        planType="Galerias e Seleção"
                      />
                    )
                  }
                })
              }

              {
                userSubscription && Object.keys(userSubscription.plan_map)
                .map(plan => {
                  if (plan.startsWith("album_")) {
                    return (
                      <AlbumPortlet
                        planName={userSubscription.plan_map[plan]}
                        planCode={plan}
                        planType="Prova de Álbuns"
                      />
                    )
                  }
                })
              }

              {
                userSubscription && Object.keys(userSubscription.plan_map)
                .map(plan => {
                  if (plan.startsWith("site_")) {
                    return (
                      <SitePortlet
                        planName="Ilimitado"
                        planType="Site"
                      />
                    )
                  }
                })
              }
            </Loading>
          </Portlet>
        </Col>
      </Row>
  );
};

export default Account;
