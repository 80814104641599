import React, { useState } from 'react';
import { Modal } from 'antd';
import ReactDOM from 'react-dom';

import {
	ButtonWrapper,
	Info,
	ModalContent,
	Question,
	Title,
	Warning,
} from './styles';

import Button from '../../../../../Button';

import warningIcon from '../../../../../../assets/warning_icon.png';

const DuplicatedWarningModal = (props) => {
	const { callback, duplicatedPicturesCount } = props;

	const [visible, setVisible] = useState(true);

	const handleClose = (action) => {
		setVisible(false);

		callback(action);
	}

  return (
    <Modal
      visible={visible}
      onOk={() => handleClose('cancel')}
      onCancel={() => handleClose('cancel')}
      footer={null}
      closeIcon={<div />}
      width={700}
    >
      <ModalContent>
        <Warning src={warningIcon} alt="warning" />
        <Title>Duplicadas</Title>
        <Info>
          {duplicatedPicturesCount === 1
            ? 'Foi encontrada 1 foto duplicada.'
            : `Foram encontradas ${duplicatedPicturesCount} fotos duplicadas.`}
        </Info>
        <Question>
          <b>O que deseja fazer?</b>
        </Question>
        <Button block onClick={() => handleClose('overwrite')}>
          SUBSTITUIR DUPLICADAS
        </Button>
        <Button block onClick={() => handleClose('non-duplicates')}>
          IGNORAR DUPLICADAS
        </Button>
        <ButtonWrapper>
          <Button block onClick={() => handleClose('cancel')}>
            CANCELAR ENVIO
          </Button>
        </ButtonWrapper>
      </ModalContent>
    </Modal>
  );
};

DuplicatedWarningModal.open = async (duplicatedPicturesCount) => {
  // See if there's an existing node to render the modal,
  // since if we don't do that, after every invoke of this popup
  // we'll be created a new node and it can cause lack of performance
  let duplicatedWarningModalNode = document.getElementById("duplicated-warning-modal");

  if (duplicatedWarningModalNode) {
    ReactDOM.unmountComponentAtNode(duplicatedWarningModalNode);
  } else {
    duplicatedWarningModalNode = document.createElement("div");
    duplicatedWarningModalNode.setAttribute("id", "duplicated-warning-modal");
    document.body.appendChild(duplicatedWarningModalNode);
	}
	
	return new Promise((resolve) => {
		ReactDOM.render(
			<DuplicatedWarningModal
				callback={resolve}
				duplicatedPicturesCount={duplicatedPicturesCount}
			/>,
			duplicatedWarningModalNode
			);
	})
}

export default DuplicatedWarningModal;
