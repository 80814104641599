import styled from 'styled-components';

export const Content = styled.div`
  background-color: #f6f6f6;

  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  position: relative;
`;

export const Header = styled.div`
  position: relative;
  top: 0;

  height: 250px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const HeaderContainer = styled.div`
  height: 640px;
  width: 100%;
  background-color: #ff4949;

  padding: 16px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media (max-width: 400px) {
    text-align: center;
  }
`;

export const WelcomeText = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #FFFFFF;

  @media (max-width: 400px) {
    font-size: 16px;
  }
`;

export const Title = styled.h1`
  font-weight: 'lighter';
  color: #fff;

  font-size: 50px;

  margin-bottom: 0;

  @media (max-width: 400px) {
    font-size: 40px;
  }
`;

export const Body = styled.div`
  display: flex;

  justify-content: center;

  padding: 16px;
`;

export const SolutionCard = styled.div`
  max-width: 350px;
  height: 500px;

  background-color: #fff;
  border-radius: 5px;

  box-shadow: 0px 0px 10px #ececec;

  display: flex;

  flex-direction: column;

  z-index: 9;

  text-align: center;

  img {
    max-width: 300px;
    width: 100%;
    height: auto;

    margin: 0 auto;
  }
`;

export const SolutionName = styled.span`
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 38px;
  color: #000000;

  padding: 0 16px;
`;

export const SolutionSubtitle = styled.span`
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: -0.07em;
  color: #808080;

  padding: 0 24px;
`;

export const SolutionInfo = styled.span`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #444444;

  padding: 0 24px;
`;

export const ButtonWrapper = styled.div`
  margin: auto auto 16px;

  width: 100%;

  div {
    text-align: center;
  }
`;

export const VerticalDivider = styled.div`
  width: 16px;
`;
