import { combineReducers } from 'redux';
import gallery from './gallery';
import asideGalleryMenu from './asideGalleryMenu';
import customer from './customer';
import portfolio from './portfolio';

export default combineReducers({
  gallery,
  asideGalleryMenu,
  customer,
  portfolio,
});
