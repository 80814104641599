import styled from 'styled-components';

export const Container = styled.div`
	width: 100%;
	
	textarea {
		font-size: 16px;
		min-height: 195px  !important;
	}
`;
