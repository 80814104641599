const standardKey = '@picsize/';

class SessionStorageService {
  supportsSessionStorage() {
    try {
      return 'sessionStorage' in window && window['sessionStorage'] !== null;
    } catch (e) {
      return false;
    }
  }

  set(key, data) {
    if (this.supportsSessionStorage()) {
      const stringData = JSON.stringify(data);

      const encodedData = btoa(stringData);

      try {
        sessionStorage.setItem(`${standardKey}${key}`, encodedData);
      } catch (e) {}
    }
  }

  get(key) {
    if (this.supportsSessionStorage()) {
      let encodedData = null;

      try {
        encodedData = sessionStorage.getItem(`${standardKey}${key}`);
      } catch (e) {
        encodedData = null;
      }

      if (!encodedData) {
        return null;
      }

      const stringData = atob(encodedData);

      const data = JSON.parse(stringData);

      return data;
    } else {
      return null;
    }
  }

  delete(key) {
    if (this.supportsSessionStorage()) {
      try {
        sessionStorage.removeItem(`${standardKey}${key}`);
      } catch (e) {}
    }
  }
}

export default new SessionStorageService();
