export const MBtoGB = (number) => {
	return number/1024;
};

export const MBtoKB = (number) => {
	return number*1024*1024;
};

export const KBtoGB = (number) => {
	return number/1024/1024/1024;
};

export const toBytes = (bytes, decimals) => {
	if (bytes == 0) {
		return '0 Bytes';
	}

	const k = 1024;
	const dm = decimals || 2;
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
	const i = Math.floor(Math.log(bytes) / Math.log(k));
	
	return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const getPercentage = (current, total) => {
	return `${+((current / total) * 100).toFixed(2).split('.')[0]}`
}

export const forceLowerCase = (target) => {
	let start = target.selectionStart;
	let end = target.selectionEnd;
	target.value = target.value.toLocaleLowerCase();
	target.setSelectionRange(start, end);
}