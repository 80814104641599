import React from 'react';
import { notification } from 'antd';
import { getFormattedError } from './response';
import * as Sentry from '@sentry/browser';

import InfoModal from '../components/InfoModal';

export const notifyError = (message) => {
  notification.destroy();
  notification.error({
    message,
    style: { background: '#f5222d' }
  });
};

export const notifyWarn = (message) => {
  notification.destroy();
  notification.warning({
    message,
    style: { background: '#faad14' }
  });
};

export const notifySuccess = (message) => {
  notification.destroy();
  notification.success({
    message,
    style: { background: '#52c41a' }
  });
};

export const notifyBackendError = (error) => {
  const messages =
    error &&
    error.response &&
    error.response.data &&
    error.response.data.messages;

  const message = messages && messages.length && messages.shift();

  // In case it receives no messages on error we trigger a default one
  if (message && message.help) {
    InfoModal.warning({
      buttonText: 'FECHAR',
      title: 'Ação Negada',
      info: message.help,
      width: 500
    });
  } else {
    notifyUndeterminedError(error);
  }
};

export const notifyUndeterminedError = (error) => {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT
  });

  const formattedError = getFormattedError(error);

  class UndeterminedError extends Error {
    constructor(args) {
      super(args);
      this.name = 'UndeterminedError';
    }
  }

  Sentry.captureException(
    new UndeterminedError(JSON.stringify(formattedError, null, 2))
  );

  InfoModal.warning({
    buttonText: 'FECHAR',
    title: 'Algo deu errado',
    info: (
      <>
        Parece que ocorreu algum erro inesperado :(
        <br />
        <br />
        Por favor tire um print dessa tela e
        {window.innerWidth > 400 ? <br /> : ' '}
        mostre para o nosso suporte para
        {window.innerWidth > 400 ? <br /> : ' '}
        solucionarmos o seu problema.
        <br />
        <br />
        <div style={{ backgroundColor: '#ECECEC', padding: '10px' }}>
          {formattedError.summarizedError}
        </div>
      </>
    )
  });
};
