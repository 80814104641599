export const BASIC = [
  {
    name: 'Espaço para fotos:',
    value: '10GB',
    description: '(Aprox. 10.000 fotos)'
  },
  {
    name: 'Galerias',
    value: 'ilimitadas'
  },
  {
    name: 'Seleções de fotos',
    value: 'ilimitadas'
  },
  {
    name: 'Download de foto individual'
  },
  {
    name: 'Resolução da foto:',
    value: '3840px'
  },
  {
    name: 'Tamanho máximo por arquivo:',
    value: '20MB'
  },
  {
    name: 'Proteção',
    value: 'contra cópia'
  },
  {
    name: 'Integração com',
    value: 'Lightroom'
  },
  {
    name: 'Suporte por',
    value: 'Whatsapp'
  }
];

export const PLUS = [
  {
    name: 'Espaço para fotos:',
    value: '100GB',
    description: '(Aprox. 100.000 fotos)'
  },
  {
    name: 'Entrega de fotos em alta',
    description: '(Download de todas as fotos)'
  },
  // {
  //   value: 'Portfólio On-line'
  // },
  {
    name: 'Provas de álbuns:',
    value: 'ilimitadas'
  },
  {
    name: 'Resolução da foto:',
    value: 'Original',
    description: '(Mantém a resolução original da foto)'
  },
  {
    name: 'Tamanho máximo por arquivo:',
    value: '50MB'
  },
  {
    name: 'App foto'
  },
  {
    name: "Marca d'agua",
    value: 'customizável'
  },
  {
    name: 'Venda de fotos',
    value: 'adicionais'
  },
  {
    name: 'Sub-domínio',
    value: 'customizável'
  },
  {
    name: 'Seleção',
    value: 'sem senha'
  },
  {
    name: 'Mais de uma seleção na galeria privada'
  }
];

export const INFINITY = [
  {
    name: 'Espaço para fotos:',
    value: '300GB',
    description: '(Aprox. 300.000 fotos)'
  }
];

export const STANDARD = [
  {
    name: 'Espaço para fotos:',
    value: '20GB',
    description: '(Aprox. 20.000 fotos)'
  },
  {
    name: 'Galerias',
    value: 'ilimitadas'
  },
  {
    name: 'Seleções de fotos',
    value: 'ilimitadas'
  },
  {
    name: 'Download de foto individual'
  },
  {
    name: 'Resolução da foto:',
    value: '1920px'
  },
  {
    name: 'Tamanho máximo por arquivo:',
    value: '20MB'
  },
  {
    name: 'Proteção',
    value: 'contra cópia'
  },
  {
    name: 'Integração com',
    value: 'Lightroom'
  },
  {
    name: 'Suporte por',
    value: 'Whatsapp'
  }
];

export const galleryProData = {
  features: {
    BASIC,
    STANDARD,
    PLUS,
    INFINITY
  },
  // STANDARD, PLUS E INFINITY
  planIds: [28, 22, 19],
  coupon: 'GALLERYPRO20ANUAL'
};
