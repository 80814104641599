import styled from 'styled-components';

export const Container = styled.div`
	.ant-form-item {
		margin-bottom: ${({ validateStatus, enlargeOnError }) => validateStatus === 'error' && enlargeOnError === true
			? '24px'
			: 0
		};
	}

	.ant-select {
		width: 100%;
	}
`;