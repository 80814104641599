import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: ${(props) => (props.loading ? 'center' : '')};
`;

export const FormContainer = styled.div`
  text-align: left;
  width: 70%;
`;

export const AvatarContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  transition: opacity 0.3s;

  & > div:first-of-type {
    min-width: 110px;
    min-height: 110px;
    margin-right: 26px;
    position: relative;

    & > div:first-of-type {
      width: 110px;
      height: 110px;
      border: 4px solid #F3F3F3;
      box-shadow: 0 0 0 1px #5CC395;
      position: relative;
      color: #666;
    }

    .ant-btn-loading {
      padding: 0 !important;
      position: absolute;

      i {
        font-size: 26px;
        padding: 0 !important;
      }
    }
  }

  button {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    width: 110px;
    height: 110px;
    padding: 0;
    border-radius: 50%;
    font-size: 11px;
    background: transparent;
    opacity: 0;
    transition: opacity 0.3s, box-shadow 0.3s;
    color: #fff;
    border: 4px solid #F3F3F3;
    box-shadow: inset 0px 0px 0px 200px rgba(0, 0, 0, 0.5);

    i {
      font-size: 22px;
      padding-top: 8px;
      margin: 0;
      padding: 0;
      padding-bottom: 4px;
    }

    span {
      margin: 0 !important;
      padding: 0;
    }

    &:hover {
      opacity: 1;
      border: 4px solid #F3F3F3;
      background: transparent;
      box-shadow: inset 0px 0px 0px 200px rgba(0, 0, 0, 0.7);
    }

    &:active {
      box-shadow: inset 0px 0px 0px 200px rgba(0, 0, 0, 0.7) !important;
    }

    &:focus {
      background: transparent;
      border: 4px solid #F3F3F3;
      box-shadow: inset 0px 0px 0px 200px rgba(0, 0, 0, 0.5);
    }
  }
`;

export const UploadAvatarContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  width: 110px;
  height: 110px;
  border-radius: 50%;
  font-size: 10px;
  transition: box-shadow 0.3s;
  color: #fff;
  box-shadow: inset 0px 0px 0px 200px rgba(0, 0, 0, 0.5);

  i {
    font-size: 22px;
    padding-top: 8px;
  }

  &:hover {
    box-shadow: inset 0px 0px 0px 200px rgba(0, 0, 0, 0.7);
  }
`;

export const LogoContainer = styled.div`
  text-align: center;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  div {
    width: 100%;
    max-width: 400px;
  }

  button:first-of-type {
    margin-top: 16px;
  }
`;

export const DropzoneWrapper = styled.div`
  margin-top: 16px;
  outline: none;
`;

export const Placeholder = styled.div`
  display: flex;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  position: relative;
  background: ${(props) => `url(${props.background})`};
`;

export const PlaceholderText = styled.h1`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  margin: auto;
`;

export const Picture = styled.img`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;

  padding: 32px;

  margin: auto;
`;

export const PillWrapper = styled.div`
  div {
    margin-left: 0;
  }
`;

export const BioWrapper = styled.div`
  margin-top: 70px;
  margin-bottom: 70px;

  div{
    margin-bottom: 15px;
  }
`;
