import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import AceEditor from 'react-ace';
import { Modal, Menu, Alert, Icon } from 'antd';

import api from '../../../../services/api';
import foton from '../../../../services/foton';

import { notifyError } from '../../../../utils/notificationService';

import smile from '../../../../assets/icon/smile.png';
import warning from '../../../../assets/warning_icon.png';

import { getElementNode } from '../../../../services/elementNode';

import {
  Content,
  SelectedPicturesCount,
  SelectedBody,
  SelectedFooter,
  SelectedBox,
  MenuWrapper,
  AdviceBox,
  AdviceInfo,
  AdviceLink,
  AdviceText,
  ModalBody,
  Title,
  PdfContent
} from './styles';

import Button from '../../../../components/Button';
import Divider from '../../../../components/Divider';
import Loading from '../../../../components/Loading';

const Export = (props) => {
  const { modalVis, collection_id, selection_id, selection_hash } = props;

  const [modalVisibility, setModalVisibility] = useState(modalVis);
  const [selectedPictures, setSelectedPictures] = useState('');
  const [selectedPicturesCount, setSelectedPicturesCount] = useState('');
  const [whiteSpaceWarning, setWhiteSpaceWarning] = useState(false);
  const [currentService, setCurrentService] = useState('lightroom');
  const [copied, setCopied] = useState(false);
  const [chunckCopied, setChunckCopied] = useState({});
  const [chunckLengthArr, setChunckLengthArr] = useState([]);
  const [pdfReady, setPdfReady] = useState(null);
  const [pdfUrl, setPdfUrl] = useState('');
  const [loading, setLoading] = useState(true);

  const closeModal = () => {
    setModalVisibility(false);
  };

  const exportTitle = async (selection_id, service) => {
    const query = `?collection_id=${collection_id}&sel_selection_id=${selection_id}&service=${service}`;
    const response = await api.get(`/selections/export${query}`);

    setSelectedPictures(response.data.exported_selections);
    setSelectedPicturesCount(response.data.total_selected);
    setWhiteSpaceWarning(response.data.blank_space);
  };

  const exportPdf = async (selection_hash) => {
    try {
      const response = await foton.get(`/selection/pdf/${selection_hash}`);

      const { ready, url } = response.data;

      if (ready === false) {
        setPdfReady(false);
        setPdfUrl('');
      } else if (ready === true) {
        setPdfReady(true);
        setPdfUrl(url);
      } else {
        notifyError('Algo deu errado.');
      }
    } catch (error) {
      notifyError('Algo deu errado.');
    }
  };

  const handleDownloadPdf = async () => {
    await window.open(pdfUrl, '_self');
  };

  const onChunckCopy = (idx) => {
    setChunckCopied({ [idx]: true });
    setTimeout(() => {
      setChunckCopied({ [idx]: false });
    }, 2000);
  };

  const onCopy = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  const getActualChunckLength = (idx) => {
    chunckLengthArr.push(
      (chunckLengthArr[idx - 1] || 0) + selectedPictures[idx].length
    );
    if (chunckLengthArr.length > selectedPictures.length) {
      setChunckLengthArr([]);
    }

    return chunckLengthArr[idx];
  };

  const exportSelected = async (service) => {
    setLoading(true);

    if (service === 'pdf') {
      await exportPdf(selection_hash);
    } else {
      await exportTitle(selection_id, service);
    }

    setLoading(false);
  };

  const handleClick = (event) => {
    setCurrentService(event.key);
    exportSelected(event.key);
  };

  useEffect(() => {
    exportSelected(currentService);
  }, []);

  return (
    <Modal
      visible={modalVisibility}
      onOk={() => closeModal()}
      onCancel={() => closeModal()}
      footer={null}
      width="85%"
      title="EXPORTAÇÃO DAS FOTOS SELECIONADAS"
      maskClosable={false}
    >
      <Content>
        <MenuWrapper>
          <Menu
            onClick={handleClick}
            selectedKeys={[currentService]}
            mode="horizontal"
          >
            <Menu.Item key="lightroom">
              <Icon type="picture" /> Lightroom
            </Menu.Item>
            <Menu.Item key="finder">
              <Icon type="search" /> Finder
            </Menu.Item>
            <Menu.Item key="windows_explorer">
              <Icon type="windows" /> Windows Explorer
            </Menu.Item>
            <Menu.Item key="pdf">
              <Icon type="file-pdf" />
              PDF
            </Menu.Item>
          </Menu>
        </MenuWrapper>

        <Divider size="medium" />

        <Loading loading={loading}>
          {currentService === 'pdf' ? (
            <>
              {pdfReady === true ? (
                <PdfContent>
                  <img src={smile} />

                  <Divider size="medium" />

                  <p>
                    PDF gerado com sucesso!
                    <br />
                    <strong>
                      Clique no botão abaixo para efetuar o download.
                    </strong>
                  </p>

                  <Divider size="medium" />

                  <Button type="primary" onClick={() => handleDownloadPdf()}>
                    BAIXAR PDF
                  </Button>
                </PdfContent>
              ) : (
                <PdfContent>
                  <img src={warning} />

                  <Divider size="medium" />

                  <p>
                    O PDF com os detalhes desta seleção ainda não foi totalmente
                    processado!
                    <br />
                    <strong>
                      Por favor, aguarde 1 minuto e tente novamente!
                    </strong>
                  </p>
                </PdfContent>
              )}
            </>
          ) : (
            <>
              <Title>
                Lista para fazer a busca dos arquivos na coleção do{' '}
                {currentService === 'windows_explorer'
                  ? 'windows explorer'
                  : currentService}
              </Title>

              <Divider size="medium" />

              {whiteSpaceWarning && currentService === 'lightroom' && (
                <>
                  <Alert
                    message="Identificamos fotos com espaços no nome em sua galeria, isso impede o bom funcionamento
                        do sistema de busca do lightroom. Para que esse recurso funcione corretamente, não utilize espaço no
                        nome das fotos, substitua por “_” ou “-”."
                    type="warning"
                    showIcon
                  />

                  <Divider size="medium" />
                </>
              )}

              {currentService === 'windows_explorer' && (
                <>
                  <Alert
                    message="Devido à limitação de caracteres do Windows Explorer dividimos as fotos em
                      blocos, assim a busca pode ser realizada corretamente. Copie cada bloco separadamente e não se preocupe,
                      a formatação já está correta."
                    type="warning"
                    showIcon
                  />

                  <Divider size="medium" />
                </>
              )}

              <ModalBody>
                <SelectedBox service={currentService}>
                  {currentService === 'windows_explorer' &&
                  Array.isArray(selectedPictures) ? (
                    selectedPictures.map((chunck, idx) => (
                      <>
                        <SelectedBody>
                          <AceEditor
                            placeholder="Parece que não foram encontradas fotos selecionadas"
                            mode="handlebars"
                            theme="tomorrow"
                            name="blah2"
                            fontSize={14}
                            wrapEnabled
                            showPrintMargin={false}
                            showGutter
                            highlightActiveLine={false}
                            value={chunck
                              .join('')
                              .replace(/(?:^|\W)OR (?:$|\W)$/g, '')}
                            style={{ height: '100px', width: 'auto' }}
                            setOptions={{
                              enableBasicAutocompletion: false,
                              enableLiveAutocompletion: false,
                              enableSnippets: false,
                              showLineNumbers: false,
                              tabSize: 2,
                              readOnly: true,
                              theme: 'ace/theme/tomorrow',
                              mode: 'ace/mode/csharp'
                            }}
                          />
                        </SelectedBody>
                        <SelectedFooter style={{ marginBottom: '24px' }}>
                          <SelectedPicturesCount>
                            {chunck.length === 1 && idx === 0
                              ? `${chunck.length} foto de ${selectedPicturesCount}`
                              : `${getActualChunckLength(
                                  idx
                                )} fotos de ${selectedPicturesCount}`}
                          </SelectedPicturesCount>
                          <CopyToClipboard
                            text={chunck
                              .join('')
                              .replace(/(?:^|\W)OR (?:$|\W)$/g, '')}
                          >
                            <Button
                              onClick={() => onChunckCopy(idx)}
                              size="large"
                            >
                              {chunckCopied[idx] ? 'COPIADO' : 'COPIAR BLOCO'}
                            </Button>
                          </CopyToClipboard>
                        </SelectedFooter>
                      </>
                    ))
                  ) : (
                    <>
                      <SelectedBody>
                        <AceEditor
                          placeholder="Parece que não foram encontradas fotos selecionadas"
                          mode="handlebars"
                          theme="tomorrow"
                          name="blah2"
                          fontSize={14}
                          wrapEnabled
                          showPrintMargin={false}
                          showGutter
                          highlightActiveLine={false}
                          value={selectedPictures}
                          style={{ height: '300px', width: 'auto' }}
                          setOptions={{
                            enableBasicAutocompletion: false,
                            enableLiveAutocompletion: false,
                            enableSnippets: false,
                            showLineNumbers: false,
                            tabSize: 2,
                            readOnly: true,
                            theme: 'ace/theme/tomorrow',
                            mode: 'ace/mode/csharp'
                          }}
                        />
                      </SelectedBody>
                      <SelectedFooter>
                        <SelectedPicturesCount>
                          {selectedPicturesCount === 1
                            ? `${selectedPicturesCount} foto`
                            : `${selectedPicturesCount} fotos`}
                        </SelectedPicturesCount>
                        <CopyToClipboard text={selectedPictures}>
                          <Button onClick={onCopy} size="large">
                            {copied ? 'COPIADO' : 'COPIAR LISTA DE FOTOS'}
                          </Button>
                        </CopyToClipboard>
                      </SelectedFooter>
                    </>
                  )}
                </SelectedBox>

                <AdviceBox service={currentService}>
                  <AdviceInfo>
                    {currentService === 'lightroom' && (
                      <>
                        <AdviceText>
                          Copie a listagem das fotos selecionadas ao lado e cole
                          no campo de busca do lightroom dentro da coleção onde
                          estão as fotos dessa galeria.
                          <br />
                          <br />
                          Para entender o processo de forma mais detalhada,
                          <br />
                        </AdviceText>
                        <AdviceLink
                          href="https://blog.picsize.com.br/integracao-picsize-e-lightroom/"
                          target="_blank"
                        >
                          veja esse tutorial
                        </AdviceLink>
                      </>
                    )}

                    {currentService === 'windows_explorer' && (
                      <>
                        <AdviceText>
                          Para entender o processo de forma mais detalhada,
                          <br />
                        </AdviceText>
                        <AdviceLink
                          href="https://blog.picsize.com.br/filtro-do-windows-explorer/"
                          target="_blank"
                        >
                          veja esse tutorial
                        </AdviceLink>
                      </>
                    )}
                  </AdviceInfo>
                </AdviceBox>
              </ModalBody>
            </>
          )}
        </Loading>
      </Content>
    </Modal>
  );
};

Export.open = (props) => {
  const { modalVis, collection_id, selection_id, selection_hash } = props;

  const exportNode = getElementNode('export-popup');

  ReactDOM.render(
    <Export
      modalVis={modalVis}
      collection_id={collection_id}
      selection_id={selection_id}
      selection_hash={selection_hash}
    />,
    exportNode
  );
};

export default Export;
