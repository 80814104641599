import React from 'react';
import PropTypes from 'prop-types';
import { Button as AntButton, Icon } from 'antd';

import { Container } from './styles';

const Button = (props) => {
  const { color, type, children, border, addonLast } = props;
  return (
    <Container type={type} color={color} border={border}>
      <AntButton {...props}>
        {children}
        {addonLast ? (<Icon type={addonLast} />) : null}
      </AntButton>
    </Container>
  );
};

Button.defaultProps = {
  type: 'default',
  border: 'false',
  addonLast: false,
};

Button.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  type: PropTypes.oneOf(['primary', 'success', 'default', 'neutral', 'alert']),
  color: PropTypes.string.isRequired,
  border: PropTypes.string,
  addonLast: PropTypes.oneOfType(PropTypes.string, PropTypes.bool),
};

export default Button;
