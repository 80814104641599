import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import api from '../../../services/api';
import { notifyWarn } from '../../../utils/notificationService';

import { Creators as PortfolioActions } from '../../../store/ducks/portfolio';

import { Portlet } from '../../../styles/components';

import UploadProgress from './UploadProgress';
import UploadStart from './UploadStart';

import {
	UploadWarningModal,
	UploadSuccessModal,
	UploadFailedModal,
} from './UploadCompleted';

const Upload = (props) => {
	const dispatch = useDispatch();
	const [files, setFiles] = useState([]);
	const portfolioInfo = useSelector((state) => state.portfolio.info);

	const { uploadStatus } = portfolioInfo;

	const { work_id } = props.match.params;
	const { pathname } = props.location;
	const { history } = props;

	const refreshPortfolioCover = async () => {
    const response = await api.get(`/portfolio/work?work_id=${work_id}`);

		const { cover } = response.data.work;

    dispatch(PortfolioActions.setInfo({ cover }));
  };

	const getImages = async () => {
		try {
			const response = await api.get(`/portfolio/pictures?work_id=${work_id}&page=${portfolioInfo.page}`);
			const { pictures, total_pictures } = response.data;
			dispatch(PortfolioActions.setInfo({ total_pictures }));
			dispatch(PortfolioActions.setImages(pictures));
			dispatch(PortfolioActions.setInfo({ uploadStatus: 'invisible' }));
		} catch(error) {
			const status = error && error.response && error.response.status;

			if (status === 404) {
        dispatch(PortfolioActions.setInfo({ uploadStatus: 'work-empty' }));
        dispatch(PortfolioActions.setImages([]));
      }
		}
  };

	const handleStartUpload = (filesList) => {
		setFiles(filesList);

		if (portfolioInfo.uploadStatus === 'work-empty') {
			dispatch(PortfolioActions.setInfo({ uploadStatus: 'uploading-empty' }));
		} else {
			dispatch(PortfolioActions.setInfo({ uploadStatus: 'uploading' }));
		}
	};

	const handleUploadCompleted = async (payloadInfo) => {
		if (!portfolioInfo.cover) {
      refreshPortfolioCover();
    }

		await getImages();

		openReport(payloadInfo);
	};

	const handleOpenUpload = () => {
    if (!portfolioInfo.uploadStatus.includes('empty')) {
      dispatch(PortfolioActions.setInfo({ uploadStatus: 'waiting' }));
		} else {
			history.push(`/portfolio/works/${work_id}/pictures`);
		}
  };

  const handleOpenWork = () => {
		dispatch(PortfolioActions.selectMenu('pictures'));
    history.push(`/portfolio/works/${work_id}/pictures`);
	};

  const handleOpenSettings = () => {
		dispatch(PortfolioActions.selectMenu('settings'));
    history.push(`/portfolio/works/${work_id}/settings`);
	};

	const handleCancelUpload = () => {
		notifyWarn('Parece que você não possui novas fotos para serem enviadas.');

		if (!portfolioInfo.uploadStatus.includes('empty')) {
      dispatch(PortfolioActions.setInfo({ uploadStatus: 'invisible' }));
		} else {
			dispatch(PortfolioActions.setInfo({ uploadStatus: 'work-empty' }));
		}
	};

	const openReport = (payloadInfo) => {
		const {
			succededList,
			failedList
		} = payloadInfo;

		// In case nothing has succeded we open the failing modal type
    if (succededList.length === 0) {
      UploadFailedModal.open({
				failedList,
				openUpload: handleOpenUpload,
			})
		// In case nothing has failed we open the succeded modal type
    } else if (failedList.length === 0) {
      UploadSuccessModal.open({
        succededList,
				openPhotos: handleOpenWork,
			});
		// If some failed and succeded we open the warning modal type
    } else if (failedList.length !== 0 && succededList.length !== 0) {
      UploadWarningModal.open({
        succededList,
        failedList,
				openPhotos: handleOpenWork,
      });
    }
	};

	if (uploadStatus === 'waiting' || (uploadStatus === 'work-empty' && pathname.includes('pictures'))) {
		return (
			<Portlet style={{ marginBottom: '20px' }}>
				<UploadStart
					work_id={work_id}
					handleStartUpload={handleStartUpload}
					uploadStatus={uploadStatus}
				/>
			</Portlet>
		)
	} else if (uploadStatus === 'uploading' || uploadStatus === 'uploading-empty') {
		return (
			<Portlet style={{ marginBottom: '20px' }}>
				<UploadProgress
					handleCancelUpload={handleCancelUpload}
					handleUploadCompleted={handleUploadCompleted}
					work_id={work_id}
					files={files}
				/>
			</Portlet>
		)
	} else {
		return null
	}
}

export default Upload;
