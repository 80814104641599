import React from 'react';
import { Checkbox, Tooltip, Icon } from 'antd';
import PropTypes from 'prop-types';

import Pill from '../../../../../../components/Pill';

import { CheckboxContent } from '../../../../../../styles/components';

import { usePlan } from '../../../../../../services/plan';

const MagicLinkCheckbox = (props) => {
  const { magicLinkEnabled, handleToggleMagicLink } = props;

  const { checkSolution, triggerPlanChange } = usePlan();

  const onToggleMagicLink = async (value) => {
    const isEnabled = checkSolution('gallery_limits', 'magic_link');

    if (!isEnabled) {
      return await triggerPlanChange('feat', 'magic_link');
    }

    return handleToggleMagicLink(value);
  };

  return (
    <CheckboxContent>
      <Checkbox
        checked={magicLinkEnabled}
        onChange={({ target }) => onToggleMagicLink(target.checked)}
      >
        Permitir seleção sem senha{' '}
        <Tooltip title="Você pode gerar um link criptografado que o seu cliente conseguirá acessar a seleção de fotos sem a necessidade de ter informar login e senha. Esse link será acescido no corpo do e-mail enviado.">
          <Icon type="info-circle" />
        </Tooltip>
      </Checkbox>

      {!checkSolution('gallery_limits', 'magic_link') && (
        <Pill style={{ marginLeft: 0, marginBottom: 0 }} />
      )}
    </CheckboxContent>
  );
};

MagicLinkCheckbox.propTypes = {
  magicLinkEnabled: PropTypes.bool.isRequired,
  handleToggleMagicLink: PropTypes.func.isRequired
};

export default MagicLinkCheckbox;
