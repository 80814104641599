import React, { useState, useEffect } from 'react';
import { Form, Icon } from 'antd';

import api from '../../../services/api';
import { usePlan } from '../../../services/plan';

import Label from '../../../components/Form/Label';
import Input from '../../../components/Form/Input';
import Small from '../../../components/Form/Small';
import Pill from '../../../components/Pill';
import EditDialog from '../../../components/EditDialog';
import Loading from '../../../components/Loading';

import {
  LabelGroup,
  FormEdit,
  EditField,
  Portlet
} from '../../../styles/components';

import { notifySuccess } from '../../../utils/notificationService';

const Subdomain = (props) => {
  const [subdomain, setSubdomain] = useState('');
  const [loading, setLoading] = useState(true);

  const { checkSolution } = usePlan();

  useEffect(() => {
    getSubdomain();
  }, []);

  const getSubdomain = async () => {
    try {
      const { data } = await api.get('/customer/subdomain');
      setSubdomain(data.subdomain);
    } catch (error) {}

    setLoading(false);
  };

  const handleUpdateSubdomain = async (value) => {
    const subdomain = value;

    try {
      await api.put('/customer/subdomain', { subdomain });

      notifySuccess('O subdomínio foi atualizado com sucesso.');

      setSubdomain(subdomain);
      return true;
    } catch (error) {
      return error;
    }
  };

  return (
    <Loading loading={loading}>
      <Portlet>
        <FormEdit>
          <LabelGroup>
            <Label>Subdomínio das galerias</Label>
            {!checkSolution('gallery_limits', 'custom_subdomain') && <Pill />}
          </LabelGroup>
          <EditField>
            <EditDialog
              name="Subdomínio das galerias"
              smallText="Escolha o subdomínio da sua escolha, assim sua comunicação fica ainda
              mais profissional junto ao seus clientes."
              value={subdomain}
              onSave={(value) => handleUpdateSubdomain(value)}
              inputName="gallerySubdomain"
              validateSolution={true}
              limitTypeKey="gallery_limits"
              limitKey="custom_subdomain"
            >
              <Input
                addonBefore="https://"
                addonAfter=".picsize.com.br"
                value={subdomain}
                readOnly
              />
              <Icon type="edit" />
            </EditDialog>
          </EditField>

          <Small>
            Escolha o sub-domínio de sua preferência, assim sua comunicação fica
            ainda mais profissional junto ao seus clientes.
          </Small>
        </FormEdit>
      </Portlet>
    </Loading>
  );
};

export default Form.create({ name: 'subdomain' })(Subdomain);
