import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';

const Small = ({ children }) => (
  <Container>
    <span>
      {children}
    </span>
  </Container>
);

Small.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Small;
