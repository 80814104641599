import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

const SwitchGroup = (props) => {
  const { children } = props;

  return <Container>{children}</Container>;
};

SwitchGroup.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default SwitchGroup;
