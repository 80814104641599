import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  container: {
    padding: 0,
    margin: 0
  },
  cardWatermark: {
    maxWidth: (props) => (props.isLarger ? '235px' : 'auto')
  },
  addButton: {
    marginLeft: '6px',
    fontWeight: 'bold'
  },
  uploadIcon: {
    fontSize: '90px'
  }
});

export default useStyles;
