import React, { useState, useEffect } from 'react';
import { Icon, Select } from 'antd';
import { useDispatch } from 'react-redux';

import Api from '../../../../services/api';

import { Creators as PortfolioActions } from '../../../../store/ducks/portfolio';

import { Content } from './styles';

import { notifyWarn, notifySuccess } from '../../../../utils/notificationService';

import {
  FormEdit,
  FormSubGroup,
  FormDate,
  FormSwitch,
  FormLabel,
  EmptyEditPlaceholder,
  InputGroup,
  EditField,
  SwitchContent,
  Portlet,
} from '../../../../styles/components';

import Label from '../../../../components/Form/Label';
import Small from '../../../../components/Form/Small';
import Switch from '../../../../components/Form/Switch';
import Input from '../../../../components/Form/Input';
import TextArea from '../../../../components/TextArea';
import EditDialog from '../../../../components/EditDialog';
import Pill from '../../../../components/Pill';
import DatePicker from '../../../../components/Form/DatePicker';
import SwitchGroup from '../../../../components/Form/SwitchGroup';
import Divider from '../../../../components/Divider';
import Loading from '../../../../components/Loading';

const { Option } = Select;

const PortfolioWorkSettings = (props) => {
  const { work_id } = props.match.params;

	const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [categories, setCategories] = useState([]);
  const [data, setData] = useState({
    title: '',
    category: '',
    category_id: null,
	});

	const getData = async () => {
		setLoading(true);

    const cRes = await Api.get('/portfolio/categories');
    setCategories(cRes.data.categories);

    const res = await Api.get(`/portfolio/work?work_id=${work_id}`);
    const { title, category, category_id } = res.data.work;
    setData({
			title,
      category,
      category_id,
    });

		setLoading(false);
  };

  useEffect(() => {
    getData();
	}, []);

	const edit = async (key, value) => {
    setData({ [key]: value });

    const prev = { [key]: data[key] };

    setData({ ...data, [key]: value });

    if (key === 'category_id') {
      const [{ title }] = categories.filter((categ) => categ.id === value);
      setData({ ...data, category: title });
    }

    try {
      await Api.put(`/portfolio/work?work_id=${work_id}`, { ...data, [key]: value });

      dispatch(PortfolioActions.setWork(data));
      notifySuccess('As alterações foram salvas.');

      return true;
    } catch (error) {
      setData({ ...data, ...prev });
      notifyWarn("Não foi possível salvar as alterações.")
      return error;
    }
  };

	return (
		<Loading loading={loading}>
			<Portlet>
				<Content>
					<FormEdit>
						<Label>Nome do trabalho</Label>

						<EditField>
							<EditDialog
								name="Nome do trabalho"
								value={data.title}
                onSave={(value) => edit('title', value)}
                inputName="work_title"
							>
								<Input value={data.title} readOnly />
								<Icon type="edit" />
							</EditDialog>
						</EditField>
					</FormEdit>

					<Divider size="medium" />

					<FormEdit>
						<Label>Categoria</Label>

						<EditField>
							<EditDialog
								name="Categoria"
								value={data.category}
                onSave={(value) => edit('category_id', value)}
                type="select"
                categories={categories}
							>
                <Input value={data.category} readOnly />
								<Icon type="edit" />
							</EditDialog>
						</EditField>
					</FormEdit>
				</Content>
			</Portlet>
		</Loading>
	)
};

export default PortfolioWorkSettings;
