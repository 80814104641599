import ReactDOM from 'react-dom';
import React, { useState } from 'react';

import Button from '../Button';
import Pill from '../Pill';
import Divider from '../Divider';
import Dialog from '../Dialog';

import { Content } from './styles';

import premiumImg from '../../assets/premium-block.png';

import { getElementNode } from '../../services/elementNode';
import { seePlans } from '../../services/navigation';

const PremiumPopup = () => {
  const [premiumModalVisibility, setPremiumModalVisibility] = useState(true);

  const handleSeePlans = async () => {
    seePlans();
    setPremiumModalVisibility(false);
  };

  return (
    <Dialog
      isOpen={premiumModalVisibility}
      onClose={() => setPremiumModalVisibility(false)}
      maxWidth="xs"
    >
      <Content>
        <Pill />
        <Divider size="medium" />
        <img src={premiumImg} alt="é necessario ser usuario premium" />
        <Divider size="medium" />
        <p>
          Desbloqueie recursos premium e ofereça mais facilidade e
          profissionalismo para seus clientes!
        </p>
        <Divider size="medium" />
        <Button
          id="btn_VerPlanos"
          type="primary"
          onClick={() => handleSeePlans()}
        >
          COMPARAR PLANOS
        </Button>
      </Content>
    </Dialog>
  );
};

PremiumPopup.open = () => {
  const premiumPopupNode = getElementNode('premium-popup');

  ReactDOM.render(<PremiumPopup />, premiumPopupNode);
};

export default PremiumPopup;
