import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const InactiveSelection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const CheckboxContainer = styled.div`

`;

export const CheckboxWrapper = styled.div`
  display: flex;

  .ant-checkbox-wrapper:hover,
  .ant-checkbox:hover {
    .ant-checkbox-inner {
      border-color: #56C568;
    }
  }

  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #56C568;
  }

  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: #56C568;
      border-color: #56C568;
    }
  }

  .ant-checkbox-checked:after {
    border-color: #56C568;
  }

  .ant-checkbox-indeterminate {
    .ant-checkbox-inner:after {
      background-color: #56C568;
    }
  }
`;

export const TextContainer = styled.div`
  margin-left: 15px;
`;