import React from 'react';
import { useLocation } from 'react-router-dom';
import { Icon } from 'antd';

import { retrieveParsedQueryParams } from '../../../../services/navigation';

import Divider from '../../../../components/Divider';
import Button from '../../../../components/Button';
import Label from '../../../../components/Form/Label';

import { Container } from '../../Overview/styles';

import {
	InfoTitle,
	CardContainer,
	ButtonWrapper,
	ExtraInfo,
} from '../styles';

import emoticonEyeBlinking from '../../../../assets/icon/emoticon_eye_blinking.png';

const CreditCardPaymentSuccess = () => {
	const location = useLocation();

	const parsedQueryParams = retrieveParsedQueryParams(location);

	const { callback_url } = parsedQueryParams;

	const handleCallback = () => {
		window.location.href = callback_url || '/gallery';
	}

	return (
		<Container>
			<Divider size="large" />

			<CardContainer>
				<Divider size="xlarge" />

				<Icon type="check-circle" theme="twoTone" twoToneColor="#56C568"  style={{ fontSize: 80, color: "#858585" }} />

				<Divider size="medium" />

				<InfoTitle success={true}>
					Transação aprovada e
					<br />
					assinatura ativada!
				</InfoTitle>

				<Divider size="medium" />

				<Label>
					Estamos imensamente felizes por fazer parte da comunidade Picsize!
				</Label>

				<ExtraInfo>
					Temos a certeza de que iremos superar as suas expectativas! <img src={emoticonEyeBlinking} />
				</ExtraInfo>

				<Divider size="medium" />

				<ButtonWrapper onClick={handleCallback}>
					<Button type="success">
						INICIAR
					</Button>
				</ButtonWrapper>
			</CardContainer>
		</Container>
	)
};

export default CreditCardPaymentSuccess;
