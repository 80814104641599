import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Icon } from 'antd';
import PropTypes from 'prop-types';

import { Creators as PortfolioActions } from '../../../../store/ducks/portfolio';

import { Portlet, Menu, MenuItem } from '../../../../styles/components';

const AsidePortfolioMenu = ({ workId, pathname }) => {
	const dispatch = useDispatch();
  const menuSelected = useSelector((state) => state.portfolio.menu);

	const getCurrentMenu = () => {
		const menuMap = [
			'pictures',
			'settings',
		];

		menuMap.map((menu) => {
			if (pathname.includes(menu)) {
        dispatch(PortfolioActions.selectMenu(menu));
			} else {
        dispatch(PortfolioActions.selectMenu('pictures'));
			}
		});
	}

	useEffect(() => {
		getCurrentMenu();
	}, []);

	const handleClickMenuItem = (menuItem) => {
		dispatch(PortfolioActions.selectMenu(menuItem));
	};

	return (
		<Portlet>
			<Menu>
				<MenuItem isSelected={menuSelected === 'pictures'} onClick={() => handleClickMenuItem('pictures')}>
					<Link to={`/portfolio/works/${workId}/pictures`}>
          	<Icon type="picture" />
						FOTOS
					</Link>
				</MenuItem>
				<MenuItem isSelected={menuSelected === 'settings'} onClick={() => handleClickMenuItem('settings')}>
					<Link to={`/portfolio/works/${workId}/settings`}>
          	<Icon type="tool" />
						CONFIGURAÇÕES
					</Link>
				</MenuItem>
			</Menu>
		</Portlet>
	)
};

AsidePortfolioMenu.propTypes = {
  workId: PropTypes.number.isRequired,
  pathname: PropTypes.string.isRequired,
};

export default AsidePortfolioMenu;
