import styled from 'styled-components';

export const Container = styled.div`
	opacity: ${({ disabled }) => disabled && '0.5'};
	pointer-events: ${({ disabled }) => disabled && 'none'};
`;

export const SelectContainer = styled.div`
	display: flex;
	
	padding: 10px 15px;
	border: 1px solid #DDD;
	border-radius: 5px;

	align-items: center;

	cursor: pointer;

	.ant-skeleton-header .ant-skeleton-avatar-lg {
		width: 32px;
		height: 32px;

		margin: auto;
	}

	.ant-skeleton-with-avatar .ant-skeleton-content .ant-skeleton-title {
		margin-top: 5px;
	}

	.ant-skeleton-content .ant-skeleton-title {
		height: 8px;

		max-width: 200px;
	}

	.ant-skeleton-content .ant-skeleton-paragraph > li {
		height: 8px;
	}

	.ant-skeleton-with-avatar .ant-skeleton-content .ant-skeleton-title {
		margin-top: 0;
	}

	.ant-skeleton-with-avatar .ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
		margin-top: 15px;
		margin-bottom: 0;

		max-width: 300px;
	}
`;

export const SelectFieldContainer = styled.div`
	margin-left: 15px;

	display: flex;
	flex-direction: column;

	justify-content: flex-start;
`;

export const ClientName = styled.h1`
	font-weight: bold;
	font-size: 14px;
	
	line-height: 14px;

	margin-bottom: 0;
`;

export const ClientInfo = styled.span`
	font-size: 12px;
	
	line-height: 12px;
`;

export const ButtonWrapper = styled.div`
	width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
	margin: 8px 0 8px auto;
  
  button {
    margin-left: 10px;
  }
`;

export const EditClientText = styled.span`
	cursor: pointer;

	div {
		span {
			color: #0077B5;
			transition: all 0.1s ease-in-out;
			font-size: 12px;
			text-decoration: ${({ underline }) => underline && "underline"};

			&:hover {
				color: #0050A1;
				transition: all 0.1s ease-in-out;
			}
		}
	}
`;

export const EditableContainer = styled.div`
	display: flex;
	flex-wrap: wrap;

	div {
		width: auto;
	}
`;
