import styled from 'styled-components';

import { CardContainer as OverViewCardContainer } from '../Overview/styles';

export const InfoTitle = styled.h1`
	text-align: center;

	color: ${({ success }) => success ? '#56C568' : '#858585'};

	font-size: 26px;
	font-weight: bolder;

	margin-bottom: 0;
`;

export const CardContainer = styled(OverViewCardContainer)`
	align-items: center;

	padding: 24px;
`;

export const ButtonWrapper = styled.a`
	span {
		padding-left: 40px;
		padding-right: 40px;
	}
`;

export const ExtraInfo = styled.span`
	color: #8B8B8B;

	text-align: center;
`;

export const WhatsappLink = styled.a`
	text-decoration: underline;

	&:hover {
		text-decoration: underline;
	}
`;