import ReactDOM from "react-dom"

export const getElementNode = (element_id) => {
	let node = document.getElementById(element_id);

  if (node) {
    ReactDOM.unmountComponentAtNode(node);
  } else {
    node = document.createElement("div");
    node.setAttribute("id", element_id);
    document.body.appendChild(node);
	}
	
	return node
}