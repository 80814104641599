export const removeDash = (path) => path.replace('/', '');

export const removeAccentuation = (str) => {
  let newString = str;

  const hexMap = {
    a: /[\xE0-\xE6]/g,
    e: /[\xE8-\xEB]/g,
    i: /[\xEC-\xEF]/g,
    o: /[\xF2-\xF6]/g,
    u: /[\xF9-\xFC]/g,
    c: /\xE7/g,
    n: /\xF1/g,
  };

  Object.keys(hexMap).map((letter) => {
    const regex = hexMap[letter];
    newString = newString.replace(regex, letter);
  });

  return newString;
};
