import styled from 'styled-components';


export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img{
    margin-top: 40px;
  }
`;

export const Title = styled.span`
  margin-top: 23px;
  font-size: 20px;
  font-weight: bold;
  color: black;
`;

export const Subtitle = styled.span`
  margin-top: 13px;
  margin-bottom: ${(props) => props.mobile ? '18px' : null};
  text-align: center;
  font-size: 16px;
  color: #666666;
`;

export const ButtonWrapper = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const Button = styled.div`
  height: 100%;
  padding: 16px;
  background: #FFFFFF;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  cursor: pointer;

  svg{
    color: #666;
    font-size: 18px;
    margin-right: 8px;
  }

  h3{
    color: #fe4244;
    font-weight: bold;
    letter-spacing: 0.07em;
    text-transform: uppercase;
    line-height: 19px;
    margin: 0;
    padding:0;
    font-size: 14px;
    margin-bottom: 3px;
  }

  line-height: 1.2;

  span{
    font-size: 11px;
    line-height: inherit;
    color: #8f99a6;
    bottom: 0;
  }
`;
