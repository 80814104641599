import ReactDOM from 'react-dom';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';

import Button from '../Button';
import Divider from '../Divider';

import { Content } from './styles';

import premiumImg from '../../assets/premium-block.png';

import { getElementNode } from '../../services/elementNode';
import { seePlans } from '../../services/navigation';

const LimitReachedPopup = (props) => {
  const { message } = props;

  const [modalVisibility, setModalVisibility] = useState(true);

  const handleSeePlans = async () => {
    seePlans();
    setModalVisibility(false);
  }

  return (
    <Modal
      visible={modalVisibility}
      onOk={() => setModalVisibility(false)}
      onCancel={() => setModalVisibility(false)}
      footer={null}
    >
      <Content>
        <Divider size="medium" />
        <img src={premiumImg} alt="é necessario ser usuario premium" />
        <Divider size="medium" />
        {message}
        <Divider size="medium" />
        <Button id="btn_VerPlanos" type="primary" onClick={() => handleSeePlans()}>COMPARAR PLANOS</Button>
      </Content>
    </Modal>
  )
};

LimitReachedPopup.propTypes = {
  message: PropTypes.node.isRequired,
};

LimitReachedPopup.open = (message) => {
  const limitReachedPopupNode = getElementNode("limit-reached-popup");

  ReactDOM.render(<LimitReachedPopup message={message} />, limitReachedPopupNode);
}

export default LimitReachedPopup;
