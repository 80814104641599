import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

const Divider = (props) => {
  const { children, size } = props;

  return (
    <Container size={size}>
      {children}
    </Container>
  );
};

Divider.defaultProps = {
  size: '',
};

Divider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  size: PropTypes.oneOf(['small', 'xsmall', 'medium', 'large', 'xlarge']),
};

export default Divider;
