import styled from 'styled-components';
import { Menu as AntMenu, Input as AntInput } from 'antd';

import { SelectContainer } from '../styles';

const { Search: AntSearch } = AntInput

export const Menu = styled(AntMenu)`
	width: 100%;

	display: flex;

	flex-direction: column;

	border: 1px solid #DDD;
	border-radius: 5px;
`;

export const SearchContainer = styled.div`
	width: 100%;
	
	padding: 10px 15px;
`;

export const Search = styled(AntSearch)`
`;

export const MenuContainer = styled.div`
	max-height: 200px;

	overflow-y: scroll;
`;

export const MenuItem = styled(SelectContainer)`
	border: none;

	transition: all 0.1s ease-in-out;

	cursor: pointer;

	&:hover {
		background-color: rgba(0, 0, 0, 0.05);
		transition: all 0.1s ease-in-out;
	}

	user-select: none;
`;

export const NewClientContainer = styled.div`
	display: flex;

	align-items: center;

	background-color: #FF4949;

	padding: 10px 15px;

	i {
		color: #FFF;
		font-size: 20px;
	}

	transition: all 0.1s ease-in-out;

	cursor: pointer;

	&:hover {
		background-color: #FF3030;
		transition: all 0.1s ease-in-out;
	}

	margin-top: 10px;

	border-bottom-right-radius: 5px;
	border-bottom-left-radius: 5px;

	user-select: none;
`;

export const NewClientTitle = styled.h1`
	color: #FFF;

	font-weight: bold;

	font-size: 14px;

	line-height: 14px;

	margin-bottom: 0;
	margin-left: 10px;
`;

export const NoClientFound = styled.h1`
	padding: 10px 15px 5px;

	font-weight: bold;
	font-size: 14px;

	margin-top: 15px;
`;
