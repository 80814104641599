import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Col, Row, Icon } from 'antd';
import { Container as ContainerMui } from '@material-ui/core';

import Routes from '../../routes';

import Breadcrumb from '../Breadcrumb';
import Header from '../Header';

import { Container } from '../../styles/components';

import { RoutesWrapper, PortfolioLink, PortfolioLinkContainer } from './styles';
import AlertsPlan from './components/AlertsPlan';

import Divider from '../Divider';

const DefaultLayout = (props) => {
  const { alertPlanData } = props;

  const galleryInfo = useSelector((state) => state.gallery.info);
  const portfolioUrl = useSelector((state) => state.portfolio.url);
  const portfolioWork = useSelector((state) => state.portfolio.work);
  const portfolioOpts = {
    url: portfolioUrl,
    work: portfolioWork
  };
  const { pathname } = props.location;

  const isMyGalleryPage = pathname === '/gallery';

  const breadCrumbPathBlacklist = [
    'checkout',
    'expired',
    'albuns',
    'extend-trial',
    'canceled'
  ];
  const headerBlackList = ['wizard-solution-switch', 'checkout'];

  if (pathname === '/albuns') {
    return (
      <Container>
        <Header />
        <Routes />
      </Container>
    );
  }

  if (headerBlackList.some((path) => pathname.includes(path))) {
    return (
      <Container>
        <Routes />
      </Container>
    );
  }

  return (
    <Container>
      <Header />
      <Row>
        {!breadCrumbPathBlacklist.some((path) => pathname.includes(path)) && (
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={isMyGalleryPage ? 8 : 12}
            xl={isMyGalleryPage ? 8 : 12}
            xxl={isMyGalleryPage ? 8 : 12}
          >
            <Breadcrumb
              pathname={pathname}
              galleryInfo={galleryInfo}
              portfolioOpts={portfolioOpts}
            />
          </Col>
        )}
        {isMyGalleryPage && (
          <Col xs={24} sm={24} md={24} lg={16} xl={16} xxl={16}>
            <ContainerMui>
              <Divider size="small" />

              {alertPlanData && (
                <AlertsPlan
                  type={alertPlanData.type}
                  additionalInfo={alertPlanData.additionalInfo}
                />
              )}
            </ContainerMui>
          </Col>
        )}
        {pathname.includes('/portfolio') && (
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <PortfolioLinkContainer isMobile={window.innerWidth < 992}>
              <PortfolioLink>
                <Icon type="eye" />
                <a href={portfolioOpts.url} target="_blank">
                  {portfolioOpts.url ? portfolioOpts.url : '...'}
                </a>
              </PortfolioLink>
            </PortfolioLinkContainer>
          </Col>
        )}
      </Row>
      <RoutesWrapper>
        <Routes />
      </RoutesWrapper>
    </Container>
  );
};

DefaultLayout.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired,
  alertPlanData: PropTypes.shape({
    type: PropTypes.string.isRequired,
    additionalInfo: PropTypes.shape({
      howManyGalleries: PropTypes.number,
      expirationDate: PropTypes.instanceOf(Date)
    })
  }).isRequired
};

export default DefaultLayout;
