import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Avatar, Icon, Dropdown, Menu } from 'antd';
import CheckoutRoutes from '../../routes/checkout';

import {
  Container,
  FirstHeaderRow,
	ProfileName,
	CallbackButton
} from './styles';

import logo from '../../assets/logo.png';
import logoSmall from '../../assets/logotipo-picsize-icon.png';

const Checkout = () => {
	const userData = useSelector((state) => state.customer.user_data);
	const userSubscription = useSelector((state) => state.customer.user_subscription);

	const isAccessToSolutionsBlocked = userSubscription && (userSubscription.expired || userSubscription.canceled);

	const [avatar, setAvatar] = useState(null);
	
	const handleRedirect = () => {
		window.location.href = window.origin;
	}

	const profileMenu = (
    <Menu>
      <Menu.Item key="0" disabled={isAccessToSolutionsBlocked}>
        <Link to="/account">
          <Icon style={{ marginRight: '10px' }} type="user" />
          Minha Conta
        </Link>
      </Menu.Item>
      <Menu.Item key="1" disabled={isAccessToSolutionsBlocked} >
        <Link to="/financial">
          <Icon style={{ marginRight: '10px' }} type="credit-card" />
          Financeiro
        </Link>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="3">
        <Link to="/logout">
          <Icon style={{ marginRight: '10px' }} type="logout" />
		      Sair
        </Link>
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    setAvatar(userData.avatar);
  }, [userData]);

  return (
    <>
			<Container>
				<FirstHeaderRow>
					<CallbackButton
						type="neutral"
						onClick={handleRedirect}
					>
						<Icon type="arrow-left" /> Voltar para plataforma
					</CallbackButton>
					<Link onClick={handleRedirect}>
						{window.innerWidth < 800
							? (<img src={logoSmall} alt="Logomarca" style={{height: '32px', width: 'auto'}} />)
							: (<img src={logo} alt="Logomarca" />)
						}
					</Link>
					{window.innerWidth > 800 && (
						<ProfileName>
							<Avatar src={avatar} style={{ width: '26px', height: '26px', color: '#666', border: '1px solid #F3F3F3', boxShadow: '0 0 0 1px #5CC395' }} />
							<Dropdown overlay={profileMenu} trigger={['click']}>
								<a className="ant-dropdown-link" href="#">
									{userData.display_name.split(' ').slice(0, 2).join(' ')} <Icon type="down" />
								</a>
							</Dropdown>
						</ProfileName>
					)}
				</FirstHeaderRow>
			</Container>
			<CheckoutRoutes />
		</>
  );
};

export default Checkout;
