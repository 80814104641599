import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Icon } from 'antd';

import { notifySuccess, notifyError } from '../../../utils/notificationService';

import Button from '../../Button';
import PopConfirm from '../../PopConfirm';

import Api from '../../../services/api';

import {
  Container, ButtonsList, ButtonsListItem, ButtonWrapper,
} from './styles';

import { Creators as PortfolioActions } from '../../../store/ducks/portfolio';

const PortfolioHeader = (props) => {
  const listRef = useRef(null);
  const dispatch = useDispatch();
  const images = useSelector((state) => state.portfolio.images);
  const portfolioInfo = useSelector((state) => state.portfolio.info);
  const { uploadStatus } = portfolioInfo;

  const [showDelete, setShowDelete] = useState(false);
  const [showUnselectAll, setShowUnselectAll] = useState(false);

  const { work_id } = props;

  const handleUploadPictures = () => {
    if (portfolioInfo.uploadStatus === 'uploading' || portfolioInfo.uploadStatus === 'uploading-empty') {
      return;
    }

    dispatch(PortfolioActions.setInfo({ uploadStatus: 'waiting' }));
  };

  const selectAll = () => {
    dispatch(PortfolioActions.selectAll());
  };

  const unselectAll = () => {
    dispatch(PortfolioActions.unselectAll());
  };

  useEffect(() => {
    const shouldShowDelete = images.find((image) => image.isSelected);
    setShowDelete(shouldShowDelete);

    // verify if all images was selected
    const imagesSelected = images.filter((image) => image.isSelected);
    setShowUnselectAll(imagesSelected.length === images.length);
  }, [images]);

  useEffect(() => {
    showDelete && listRef.current.scrollTo(2000, 0);
  }, [showDelete]);

  const handleDeletePictures = async () => {
    const selectedImageIds = images.filter((image) => image.isSelected).map((image) => image.id).join(',');
    const newArr = images.filter((image) => !image.isSelected);

    try {
      await Api.delete(`/portfolio/pictures?work_id=${work_id}&picture_ids=${selectedImageIds}`);
      const data = Object.values(newArr).map((image) => image.id);
      await Api.put(`/portfolio/pictures/reorder?work_id=${work_id}`, data);
      notifySuccess('Fotos deletadas com sucesso.');
    } catch (err) {
      notifyError('Não foi possível deletar as fotos.');
    }

    await getImages();
  };

  const getImages = async () => {
    try {
      const response = await Api.get(`/portfolio/pictures?work_id=${work_id}&page=${portfolioInfo.page}`);
      const { pictures, total_pictures } = response.data;

      dispatch(PortfolioActions.setInfo({ total_pictures }));
      dispatch(PortfolioActions.setImages(pictures));
    } catch (error) {
      const status = error && error.response && error.response.status;

      if (status === 404) {
        dispatch(PortfolioActions.setImages([]));
        dispatch(PortfolioActions.setInfo({ uploadStatus: 'work-empty' }));
      }
    }
  };

  const handleOpenConfirmDeletePicturesModal = () => {
    PopConfirm.open({
      title: 'Tem certeza?',
      cancelText: 'CANCELAR',
      confirmText: 'DELETAR',
      confirmIcon: 'delete',
      info: 'Deletar fotos é uma ação irreversível. Você perderá todos os dados destas fotos.',
      onConfirm: handleDeletePictures,
    });
  };

  return (
    <Container ref={listRef}>
      <ButtonsList>
        <ButtonsListItem>
          <Button type="neutral" onClick={() => handleUploadPictures()} style={{ color: uploadStatus === 'waiting' && '#FF4949' }}>
            <Icon type="cloud-upload" />
            SUBIR FOTOS
          </Button>
        </ButtonsListItem>
        {showUnselectAll ? (
          <ButtonsListItem>
            <Button type="neutral" onClick={unselectAll}>
              <Icon type="close-circle" />
              SELECIONAR TODAS
            </Button>
          </ButtonsListItem>
        ) : (
          <ButtonsListItem>
            <Button type="neutral" onClick={selectAll}>
              <Icon type="check-circle" />
              SELECIONAR TODAS
            </Button>
          </ButtonsListItem>
        )}
      </ButtonsList>

      {showDelete && (
      <ButtonWrapper>
        <Button type="primary" icon="delete" onClick={handleOpenConfirmDeletePicturesModal}>
						DELETAR SELECIONADAS
        </Button>
      </ButtonWrapper>
      )}
    </Container>

  );
};

export default PortfolioHeader;
