import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { Modal } from 'antd';

import smileIcon from '../../assets/icon/smile.png';
import warningIcon from '../../assets/warning_icon.png';

import { getElementNode } from '../../services/elementNode';

import {
	Container,
	Title,
	Subtitle,
} from './styles';

import Button from '../../components/Button';
import Divider from '../../components/Divider';

const InfoModal = (props) => {
  const { title, info, buttonText, type, width, buttonCallback } = props;

	const [visibility, setVisibility] = useState(true);

	const handleClose = () => {
    setVisibility(false);
  }
  
  const handleAction = () => {
    setVisibility(false);
    buttonCallback();
  }

  return (
    <Modal
      visible={visibility}
      onOk={handleClose}
      onCancel={handleClose}
      footer={null}
      width={width}
    >
      <Container>
				<Divider size="large" />

        {type === "success" && <img src={smileIcon} alt="Smile Icon" />}
        {type === "warning" && <img src={warningIcon} alt="Warning Icon" />}

				<Divider size="medium" />

        <Title>{title}</Title>

				<Divider size="medium" />

        <Subtitle>
          {info}
        </Subtitle>

				<Divider size="medium" />

        <Button type="primary" onClick={handleAction}>
					{buttonText}
        </Button>

				<Divider size="large" />
      </Container>
    </Modal>
  );
};

InfoModal.defaultProps = {
  width: 850,
  buttonCallback: () => {},
}

InfoModal.propTypes = {
  title: PropTypes.string.isRequired,
  info: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  buttonText: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["success", "warning"]),
  width: PropTypes.number,
  buttonCallback: PropTypes.func,
};

InfoModal.success = ({ title, info, buttonText, width, buttonCallback }) => {
  const infoModalNode = getElementNode('info-modal');

  ReactDOM.render(
    <InfoModal title={title} info={info} buttonText={buttonText} buttonCallback={buttonCallback} type="success" width={width} />,
    infoModalNode
  );
};

InfoModal.warning = ({ title, info, buttonText, width, buttonCallback }) => {
  const infoModalNode = getElementNode('info-modal');

  ReactDOM.render(
    <InfoModal title={title} info={info} buttonText={buttonText} buttonCallback={buttonCallback} type="warning" width={width} />,
    infoModalNode
  );
};

export default InfoModal;
