import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../../Button';
import Divider from '../../../Divider';

import {
  PreviewWrapper,
  PreviewContainer,
  FooterPreview,
  ButtonWrapper,
  Message,
  PreviewHeader,
  Field,
  FieldKey,
  FieldValue,
  FieldInfo,
} from './styles';

const MailPreview = (props) => {
	const {
		sender,
		recipient,
		message,
		galleryUrl,
    subject,
    privateSelection,
    clientPassword,
    clientEmail,
    magicLinkEnabled,
    magicLink
	} = props;

  return (
    <PreviewWrapper isMobile={window.innerWidth < 600}>
      <PreviewHeader>
				<p>{subject}</p>
      </PreviewHeader>
      <PreviewContainer>
        <p>
          Olá{' '}
          {recipient
            ? recipient
            : '[nome_do_cliente]'}
          ! Tudo bem?
        </p>
        <Message>{message}</Message>
        
        {privateSelection && (
          <>
            {magicLinkEnabled ? (
              <>
                <Divider size="small" />

                <Field>
                  <ButtonWrapper href={magicLink} target="_blank" enlarge={true}>
                    <Button>Escolher fotos</Button>
                  </ButtonWrapper>
                </Field>

                <Divider size="medium" />
              </>
            ) : (
              <>
                <Field>
                  <FieldKey>
                    E-mail:
                  </FieldKey>
                  <FieldValue>
                    {clientEmail}
                  </FieldValue>
                </Field>

                <Divider size="small" />

                <Field>
                  <FieldKey>
                  Senha:
                  </FieldKey>
                  <FieldValue>
                    {clientPassword}
                  </FieldValue>
                </Field>

                <Divider size="medium" />

                <Field>
                  <ButtonWrapper href={galleryUrl} target="_blank" enlarge={true}>
                    <Button>Ver fotos</Button>
                  </ButtonWrapper>
                </Field>

                <Divider size="medium" />
              </>
            )}

            <FieldInfo>
              * Não compartilhe esse e-mail caso queira manter a seleção das suas fotos em segurança.
            </FieldInfo>

            <Divider size="large" />
          </>
        )}

        {!privateSelection && (
          <ButtonWrapper href={galleryUrl} target="_blank">
            <Button>Ver fotos</Button>
          </ButtonWrapper>
        )}
        <p style={{ marginBottom: '25px' }}>
          Por favor, confirme o recebimento deste e-mail. <br />
          <br />
          Um abraço, <br />
          {sender}.
        </p>
        <FooterPreview>
          <small>
            Caso tenha algum problema com o botão acima, copie e cole o seguinte
            link no seu navegador:
          </small>
          <br />
          {(privateSelection && magicLinkEnabled) ? (
            <a href={magicLink} target="_blank">{magicLink}</a>
          ) : (
            <a href={galleryUrl} target="_blank">{galleryUrl}</a>
          )}
        </FooterPreview>
      </PreviewContainer>
    </PreviewWrapper>
  );
};

MailPreview.defaultProps = {
	sender: "",
	recipient: "",
	message: "",
	subject: "",
  galleryUrl: "",
  privateSelection: "",
  clientPassword: "",
  clientEmail: "",
  magicLinkEnabled: false,
  magicLink: '',
};

MailPreview.propTypes = {
	sender: PropTypes.string,
	recipient: PropTypes.string,
	message: PropTypes.string,
	subject: PropTypes.string,
  galleryUrl: PropTypes.string,
  privateSelection: PropTypes.bool,
  clientPassword: PropTypes.string,
  clientEmail: PropTypes.string,
  magicLinkEnabled: PropTypes.bool,
  magicLink: PropTypes.string,
};

export default MailPreview;