import React, { useState, useEffect } from 'react';
import {
  Form, Row, Col, Icon,
} from 'antd';
import { Avatar } from '@material-ui/core';

import { useDispatch } from 'react-redux';
import { Creators as CustomerActions } from '../../../store/ducks/customer';

import Api from '../../../services/api';

import {
  LogoContainer,
  DropzoneWrapper,
  Placeholder,
  PlaceholderText,
  Picture,
  AvatarContainer,
  UploadAvatarContainer,
} from './styles';

import {
  TitleSection, FormEdit, TelGroup, EditField, Portlet,
} from '../../../styles/components';

import Label from '../../../components/Form/Label';
import Button from '../../../components/Button';
import Input from '../../../components/Form/Input';
import Small from '../../../components/Form/Small';
import SelectInput from '../../../components/Form/Select';
import Dropzone from '../../../components/Dropzone';
import Divider from '../../../components/Divider';
import EditDialog from '../../../components/EditDialog';
import TextArea from '../../../components/TextArea';
import Loading from '../../../components/Loading';

import { notifySuccess, notifyBackendError } from '../../../utils/notificationService';
import { usePlan } from '../../../services/plan';

import transparentPlaceholder from '../../../assets/transparent_placeholder.png';

export default Form.create()(() => {
  const dispatch = useDispatch();
  const [logo, setLogo] = useState(null);
  const [avatar, setAvatar] = useState(null);
  const [removeLoading, setRemoveLoading] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [uploadAvatarLoading, setUploadAvatarLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [telOption, setTelOption] = useState('telefone-whatsapp');
  const [userData, setUserData] = useState({
    display_name: '',
    display_email: '',
    whatsapp_number: '',
    bio: '',
  });
  const [userSocialData, setUserSocialData] = useState({
    site: '',
    instagram: '',
    facebook: '',
    twitter: '',
  });

  const getLogo = async () => {
    try {
      const response = await Api.get('/customer/logo');
      setLogo(response.data.logo);
    } catch (error) {
      setLogo(null);
    }
  };

  const getAvatar = async () => {
    try {
      const response = await Api.get('/customer/avatar');
      setAvatar(response.data.avatar);
      dispatch(CustomerActions.setUserData({ avatar: response.data.avatar }));
    } catch (error) {
      setAvatar(null);
    }
  };

  const getUserData = async () => {
    const { data } = await Api.get('/customer/profile/phone-type');
    if (data) {
      setTelOption(data.phone_type);
    }

    const res = await Api.get('/customer/profile');
    const {
      display_name,
      display_email,
      whatsapp_number,
      bio,
      social_medias,
    } = res.data;

    setUserData({
      display_name,
      display_email,
      whatsapp_number,
      bio,
    });

    setLoading(false);

    if (social_medias) {
      setUserSocialData(social_medias);
    }
  };

  const deleteLogo = async () => {
    setRemoveLoading(true);

    await Api.delete('/customer/logo');

    setLogo(null);
    setRemoveLoading(false);
  };

  const onDrop = async ([file]) => {
    setUploadLoading(true);

    const formData = new FormData();
    formData.append('logo', file);

    try {
      await Api.put('/customer/logo', formData, {
        headers: {
          'Content-Type': 'multipart/data',
        },
      });

      await getLogo();

      notifySuccess('Logotipo enviado com sucesso!');
    } catch (err) {
      notifyBackendError();
    }

    setUploadLoading(false);
  };

  const onDropAvatar = async ([file]) => {
    setUploadAvatarLoading(true);

    const formData = new FormData();
    formData.append('avatar', file);

    try {
      await Api.put('/customer/avatar', formData, {
        headers: {
          'Content-Type': 'multipart/data',
        },
      });

      await getAvatar();

      notifySuccess('Avatar enviado com sucesso!');
    } catch (err) {
      notifyBackendError();
    }

    setUploadAvatarLoading(false);
  };

  useEffect(() => {
    getLogo();
    getAvatar();
    getUserData();
  }, []);

  const editSocialMedias = async (key, value) => {
    try {
      await Api.put('/customer/profile/social', { [key]: value });

      notifySuccess('As alterações foram salvas.');

      setUserSocialData({ ...userSocialData, [key]: value });
      return true;
    } catch (err) {
      return err;
    }
  };

  const editProfile = async (key, value) => {
    try {
      await Api.put('/customer/profile', { [key]: value });

      notifySuccess('As alterações foram salvas.');

      if (key === 'display_name') {
        dispatch(CustomerActions.setUserData({ display_name: value }));
      }

      setUserData({ ...userData, [key]: value });
      return true;
    } catch (err) {
      return err;
    }
  };

  const handleTelOption = async (value) => {
    try {
      await Api.put('/customer/profile/phone-type', { phone_type: value });
      setTelOption(value);
      notifySuccess('As alterações foram salvas.');
    } catch (err) {
      return err;
    }
  };

  return (
    <Loading loading={loading}>
      <Portlet>
        <Row gutter={[16, 24]}>
          <Col lg={24} xl={12} xxl={12}>
            <TitleSection>DADOS SOBRE O FOTÓGRAFO</TitleSection>

            <Divider size="medium" />

            <Label>Foto de Perfil</Label>
            <Divider size="small" />
            <AvatarContainer>
              <Dropzone onDrop={onDropAvatar}>
                <Avatar src={avatar} />
                <Button loading={uploadAvatarLoading} style={uploadAvatarLoading ? { opacity: 1 } : null} type="primary">
                  {!uploadAvatarLoading && <Icon type="camera" />}
                  {!uploadAvatarLoading && 'SUBIR FOTO'}
                </Button>
              </Dropzone>
              <Small>Aumente sua credibilidade! Suba uma foto{window.innerWidth < 600 ? ' ' : <br />}e torne a sua conta ainda mais profissional!</Small>
            </AvatarContainer>

            <Divider size="medium" />

            <FormEdit>
              <Label>Nome</Label>
              <EditField>
                <EditDialog
                  name="Nome"
                  smallText="Esse é apenas o nome de exibição, ex: Estúdio Fotográfico Picsize."
                  value={userData.display_name}
                  onSave={(value) => editProfile('display_name', value)}
                  inputName="name"
                >
                  <Input value={userData.display_name} readOnly />
                  <Icon type="edit" />
                </EditDialog>
              </EditField>

              <Small>
                Esse é apenas o nome de exibição, ex: Estúdio Fotográfico Picsize.
              </Small>
            </FormEdit>

            <Divider size="medium" />

            <FormEdit>
              <Label>E-mail</Label>
              <EditField>
                <EditDialog
                  name="Email"
                  smallText="Esse não é o e-mail da sua conta, é apenas o e-mail que será exibido nas informações de contato."
                  value={userData.display_email}
                  onSave={(value) => editProfile('display_email', value)}
                  inputName="mail"
                >
                  <Input value={userData.display_email} readOnly />
                  <Icon type="edit" />
                </EditDialog>
              </EditField>

              <Small>
                Esse não é o e-mail da sua conta, é apenas o e-mail que será
                exibido nas informações de contato.
              </Small>
            </FormEdit>

            <Divider size="medium" />

            <FormEdit>
              <Label>Telefone / Whatsapp</Label>
              <TelGroup>
                <SelectInput
                  required
                  onChange={(value) => {
                    handleTelOption(value);
                  }}
                  value={telOption}
                >
                  <SelectInput.Option value="telefone-whatsapp">Telefone e Whatsapp</SelectInput.Option>
                  <SelectInput.Option value="telefone">Telefone</SelectInput.Option>
                  <SelectInput.Option value="whatsapp">Whatsapp</SelectInput.Option>
                </SelectInput>
                <EditField>
                  <EditDialog
                    name="Telefone"
                    value={userData.whatsapp_number}
                    onSave={(value) => editProfile('whatsapp_number', value)}
                    inputName="whatsapp"
                    type="phone"
                  >
                    <Input
                      value={userData.whatsapp_number}
                      type="phone"
                      readOnly
                    />
                    <Icon type="edit" />
                  </EditDialog>
                </EditField>
              </TelGroup>
            </FormEdit>

            <Divider size="medium" />

            <FormEdit>
              <Label>Site</Label>
              <EditField>
                <EditDialog
                  name="Site"
                  smallText="Ex: https://www.picsize.com.br"
                  value={userSocialData.site}
                  onSave={(value) => editSocialMedias('site', value)}
                  inputName="site"
                >
                  <Input value={userSocialData.site} readOnly />
                  <Icon type="edit" />
                </EditDialog>
              </EditField>

              <Small>Ex: https://www.picsize.com.br</Small>
            </FormEdit>

            <Divider size="medium" />

            <TitleSection>BIOGRAFIA</TitleSection>

            <Divider size="small" />

            <FormEdit>
              <EditField>
                <EditDialog
                  name="Biografia"
                  value={userData.bio}
                  type="textarea"
                  onSave={(value) => editProfile('bio', value)}
                  inputName="bio"
                >
                  <TextArea size="200px" value={userData.bio} readOnly />
                  <Icon type="edit" />
                </EditDialog>
              </EditField>
            </FormEdit>

            <Divider size="medium" />

            <TitleSection>REDES SOCIAIS</TitleSection>

            <Divider size="medium" />

            <FormEdit>
              <Label>Instagram</Label>
              <EditField>
                <EditDialog
                  name="Instagram"
                  value={userSocialData.instagram}
                  onSave={(value) => editSocialMedias('instagram', value)}
                  inputName="instagram"
                >
                  <Input
                    addonBefore="https://instagram.com/"
                    value={userSocialData.instagram}
                    readOnly
                  />
                  <Icon type="edit" />
                </EditDialog>
              </EditField>
            </FormEdit>

            <Divider size="medium" />

            <FormEdit>
              <Label>Facebook</Label>
              <EditField>
                <EditDialog
                  name="Facebook"
                  value={userSocialData.facebook}
                  onSave={(value) => editSocialMedias('facebook', value)}
                  inputName="facebook"
                >
                  <Input
                    addonBefore="https://facebook.com/"
                    value={userSocialData.facebook}
                    readOnly
                  />
                  <Icon type="edit" />
                </EditDialog>
              </EditField>
            </FormEdit>

            <Divider size="medium" />

            <FormEdit>
              <Label>Twitter</Label>
              <EditField>
                <EditDialog
                  name="Twitter"
                  value={userSocialData.twitter}
                  onSave={(value) => editSocialMedias('twitter', value)}
                  inputName="twitter"
                >
                  <Input
                    addonBefore="https://twitter.com/"
                    value={userSocialData.twitter}
                    readOnly
                  />
                  <Icon type="edit" />
                </EditDialog>
              </EditField>
            </FormEdit>
          </Col>

          <Col lg={24} xl={12} xxl={12}>
            <LogoContainer>
              <DropzoneWrapper>
                <Dropzone onDrop={onDrop}>
                  <Placeholder background={transparentPlaceholder}>
                    {logo ? (
                      <Picture src={logo} />
                    ) : (
                      <PlaceholderText>Adicione seu logotipo</PlaceholderText>
                    )}
                  </Placeholder>
                  <Button loading={uploadLoading} type="primary" block>
                    {!uploadLoading && <Icon type="cloud-upload" />}
                    SUBIR LOGOTIPO
                  </Button>
                </Dropzone>
              </DropzoneWrapper>

              <Button
                loading={removeLoading}
                onClick={deleteLogo}
                type="default"
                icon="delete"
                block
              >
                REMOVER LOGOTIPO
              </Button>
            </LogoContainer>
          </Col>
        </Row>
      </Portlet>
    </Loading>
  );
});
