import styled from 'styled-components';

export const Logo = styled.img`
  margin: 32px auto;
  width: 100%;
  max-width: 200px;
`;

export const RecoverPassword = styled.div`
  display: flex;
  flex-direction: column;

  max-width: 400px;

  justify-content: center;
  justify-items: center;

  margin: 0 auto auto auto;
`;