import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';
import locale from 'antd/es/locale/pt_BR';
import 'antd/dist/antd.css';
import { ThemeProvider } from '@material-ui/core';

import App from './App';
import store from './store';
import theme from './styles/theme';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
});

ReactDOM.render(
  <Provider store={store}>
    <ConfigProvider locale={locale}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </ConfigProvider>
  </Provider>,
  document.getElementById('root'),
);
