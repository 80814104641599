import React, { useState, useEffect } from 'react';
import Carousel, { Modal, ModalGateway, carouselComponents } from 'react-images';
import { useSelector, useDispatch } from 'react-redux';
import { Icon, Pagination, Tooltip } from 'antd';
import { PaginationWrapper } from '../styles';
import { Creators as GalleryActions } from '../../../store/ducks/gallery';

import Api from '../../../services/api';

import {
  Grid,
  ImageWrapper,
  IconsWrapper,
  LeftIcons,
  RightIcons,
  StarIconWrapper,
  Image,
  ImageName,
  Footer,
  PhotosCounter,
} from './styles';

import { Portlet } from '../../../styles/components';

import { notifySuccess, notifyError } from '../../../utils/notificationService';

import Loading from '../../../components/Loading';
import PopConfirm from '../../../components/PopConfirm';
import PictureHeader from '../../../components/Gallery/PictureHeader';

const Picture = (props) => {
  const { View } = carouselComponents;
  const dispatch = useDispatch();
  const galleryInfo = useSelector((state) => state.gallery.info);
  const images = useSelector((state) => state.gallery.images);
  const totalImages = useSelector((state) => state.gallery.info.total_pictures);
  const [hoveringObj, setHoveringObj] = useState(null);
  const [showLightbox, setShowLightbox] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [indexLightbox, setIndexLightbox] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [imagesPerPage] = useState(30);

  const [indexOfLastImage, setIndexOfLastImage] = useState(0);
  const [indexOfFirstImage, setIndexOfFirstImage] = useState(0);
  const [currentImages, setCurrentImages] = useState([]);

  const { collection_id } = props.match.params;

  useEffect(() => {
    init();
    getImages(collection_id, currentPage);
  }, []);

  useEffect(() => {
    setIndexOfLastImage(imagesPerPage);
    setIndexOfFirstImage(indexOfLastImage - imagesPerPage);
    setCurrentImages(images.slice(indexOfFirstImage, indexOfLastImage));
  }, [currentPage, imagesPerPage, indexOfLastImage, indexOfFirstImage, images]);

  useEffect(() => {
    images.map((img) => {
      if (img.suggested === true || img.suggested === false) {
        img.isSuggested = img.suggested;
      }
    });
  }, [images]);

  const handleOnHove = (idx) => {
    setHoveringObj(idx);
  };

  const handleSelectClick = (idx) => {
    dispatch(GalleryActions.selectImage(idx));
  };

  const handleSuggestClick = async (idx) => {
    dispatch(GalleryActions.suggestImage(idx));
    let bool = images[idx].isSuggested;
    if (bool === undefined) bool = false;

    await Api.put(
      `/pictures/${images[idx].id}/gallery/${collection_id}/suggest/${!bool}`
    );
    getImages(collection_id, currentPage);
  };

  const handleMouseLeave = () => {
    setHoveringObj(null);
  };

  const handleClickLightbox = (idx) => {
    setIndexLightbox(idx);
    setShowLightbox(true);
  };

  const toggleModal = () => {
    setShowLightbox(!showLightbox);
  };

  const handlePageChange = (page) => {
    getImages(collection_id, page);
    setCurrentPage(page);
  };

  const handleDeletePicture = async (pictureId) => {
    try {
      await Api.delete(`/pictures?picture_ids=${pictureId}&collection_id=${collection_id}`);
      notifySuccess('A foto foi deletada com sucesso.');
    } catch (err) {
      notifyError('Não foi possível deletar a foto.')
    }

    await getImages(collection_id, currentPage);
  }

  const getImages = async (collection_id, page) => {
    try {
      const response = await Api.get(`/pictures?collection_id=${collection_id}&page=${page}`);
      const { pictures, total_pictures } = response.data;

      dispatch(GalleryActions.setInfo({ total_pictures, page }));
      dispatch(GalleryActions.setImages(pictures));
    } catch (error) {
      const status = error && error.response && error.response.status;

      if (status === 404 && galleryInfo.uploadStatus !== 'uploading-empty' && galleryInfo.uploadStatus !== 'uploading') {
        dispatch(GalleryActions.setInfo({ uploadStatus: 'gallery-empty' }));
        dispatch(GalleryActions.setImages([]));
      }
    }

    setLoading(false);
  };

  const init = () => {
    dispatch(GalleryActions.setImages([]));
    dispatch(GalleryActions.setInfo({ total_pictures: 0 }));
  };

  // Changes picture name when trying to download it with right mouse button click
  const CustomDownloadName = ({ children, name }) => (
    <a href="" download={name} onClick={(event) => event.preventDefault()}>
      {children}
    </a>
  );

  const handleTooltipOver = () => {
    setShowTooltip(true);
  };

  const handleTooltipLeave = () => {
    setShowTooltip(false);
  };

  const CustomView = (props) => {
    const { currentView } = props;

    return (
      <CustomDownloadName name={currentView.title}>
        <View {...props} />
      </CustomDownloadName>
    )
  }; 
  
  const handleOpenConfirmDeletePictureModal = (picture_id) => {
    PopConfirm.open({
      title: 'Tem certeza?',
      cancelText: 'CANCELAR',
      confirmText: 'DELETAR',
      confirmIcon: 'delete',
      info: 'Deletar a foto é uma ação irreversível. Você perderá todos os dados desta foto.',
      onConfirm: () => handleDeletePicture(picture_id)
    })
  };

  if (galleryInfo.uploadStatus !== 'gallery-empty' && galleryInfo.uploadStatus !== 'uploading-empty') {
    return (
      <>
        <Loading loading={loading}>
          <Portlet style={{marginBottom: '-20px', padding: '8px'}}>
            <PictureHeader collection_id={collection_id} uploadStatus={galleryInfo.uploadStatus} />
          </Portlet>

          <br />

          <Portlet>
            <Grid>
              <ModalGateway>
                {showLightbox ? (
                  <Modal onClose={toggleModal}>
                    <Carousel currentIndex={indexLightbox} views={images} components={{ View: CustomView }} />
                  </Modal>
                ) : null}
              </ModalGateway>
              {currentImages.map((image, idx) => (
                <ImageWrapper
                  key={idx}
                  isSelected={image.isSelected}
                  onMouseLeave={handleMouseLeave}
                  // onMouseEnter={() => handleOnHove(idx)}
                  onMouseOver={() => handleOnHove(idx)}
                >
                  <div>
                    <CustomDownloadName name={image.title}>
                      <Image onClick={() => handleSelectClick(idx)} src={image.thumb} />
                    </CustomDownloadName>
                    <IconsWrapper>
                      <LeftIcons>
                        {image.isSelected || hoveringObj === idx ? (
                          <Icon
                            onClick={() => handleSelectClick(idx)}
                            type="check-circle"
                            theme="twoTone"
                            twoToneColor="#87D068"
                          />
                        ) : null}
                      </LeftIcons>
      
                      <RightIcons isSuggested={image.isSuggested}>
                        {hoveringObj === idx ? (
                          <>
                            <Icon
                              onClick={() => handleClickLightbox(idx)}
                              type="zoom-in"
                            />
                            <Icon
                              onClick={() => handleOpenConfirmDeletePictureModal(image.id)}
                              type="delete"
                              theme="filled"
                            />
                            <Tooltip title="Diga ao seu cliente que essa é uma foto favorita sua" visible={showTooltip}>
                              <Icon
                                onClick={() => handleSuggestClick(idx)}
                                type="star"
                                theme="filled"
                                onMouseOver={() => handleTooltipOver()}
                                onMouseLeave={() => handleTooltipLeave()}
                              />
                            </Tooltip>
                          </>
                        ) : null}
                        {image.isSuggested || hoveringObj === idx ? (
                          <StarIconWrapper isSuggested={image.isSuggested}>
                            <Icon
                              onClick={() => handleSuggestClick(idx)}
                              type="star"
                              theme="filled"
                            />
                          </StarIconWrapper>
                        ) : null}
                      </RightIcons>
                    </IconsWrapper>
                    <ImageName>{image.title}</ImageName>
                  </div>
                </ImageWrapper>
              ))}
            </Grid>
            <Footer>
              <PhotosCounter>Total de {totalImages} fotos</PhotosCounter>
              <PaginationWrapper>
                <Pagination
                  defaultCurrent={1}
                  onChange={handlePageChange}
                  defaultPageSize={imagesPerPage}
                  total={totalImages}
                />
              </PaginationWrapper>
            </Footer>
          </Portlet>
          
        </Loading>
      </>
    );
  } else {
    return null;
  }
};

export default Picture;
