import React from 'react';
import { useSelector } from 'react-redux';

import { Container, Content } from './styles';

import Divider from '../../components/Divider';
import Button from '../../components/Button';
import Label from '../../components/Form/Label';

import { seePlans } from '../../services/navigation';

import warningIcon from '../../assets/warning_icon.png';

const SubscriptionCanceled = () => {
  const userData = useSelector((state) => state.customer.user_data);

  const handleSeePlans = async () => {
    seePlans();
  };

  return (
    <Container>
      <Divider size="xlarge" />

      <Content>
        <Divider size="xlarge" />

        <img src={warningIcon} alt="warning" />

        <Divider size="xlarge" />

        <Label>
          Conta sem assinatura ativa.
        </Label>

        <Divider size="medium" />

        <p>
          Não identificamos nenhuma assinatura ativa para essa conta ({ userData.display_email }).

          <br />
          <br />

          Confira os nossos planos e assine agora mesmo!
        </p>

        <Divider size="xlarge" />

        <Button id="btn_VerPlanos" type="primary" onClick={() => handleSeePlans()}>
          VER PLANOS
        </Button>

        <Divider size="xlarge" />
      </Content>
    </Container>
  );
};

export default SubscriptionCanceled;
