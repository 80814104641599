import styled from 'styled-components';

export const Content = styled.div`
  width: 100%;
`;

export const SelectedBox = styled.div`
  display: flex;
  width: ${(props) =>
    props.service === 'finder' || props.service === 'windows_explorer'
      ? '100%'
      : '60%'};
  flex-direction: column;
`;

export const SelectedBody = styled.div`
  margin-bottom: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
`;

export const SelectedFooter = styled.div`
  display: flex;
  margin-bottom: 24px;

  small {
    margin-right: auto;
  }
`;

export const Small = styled.div`
  font-size: 12px;
  color: #777;
  text-align: left;
`;

export const Box = styled.div`
  width: 40%;
  margin: 20px 0 0 20px;
  height: 105px;
  border-left: 2px solid #ededed;
  padding: 0 20px 0 20px;
`;

export const MenuWrapper = styled.div`
  .ant-menu-horizontal {
    .ant-menu-item-selected,
    .ant-menu-submenu-selected,
    .ant-menu-item-active,
    .ant-menu-submenu-active,
    li:hover {
      color: #ff4949;
      border-bottom: 2px solid #ff4949;

      a:hover {
        color: #ff4949;
      }

      a {
        color: #ff4949;
      }
    }
  }
`;

export const AdviceBox = styled.div`
  display: ${(props) => (props.service === 'finder' ? 'none' : 'inherit')};
  width: 40%;
  height: 105px;

  margin-left: 20px;
`;

export const AdviceInfo = styled.div`
  border-left: 2px solid #ededed;
  padding-left: 20px;
`;

export const AdviceText = styled.span`
  color: #666;
`;

export const AdviceLink = styled.a`
  color: #ff4949;
  text-decoration: underline;

  &:hover {
    color: #ff4949;
    text-decoration: underline;
    opacity: 0.7;
  }
`;

export const ModalBody = styled.div`
  display: flex;
`;

export const SelectedPicturesCount = styled.span`
  font-size: 14px;
  color: #333;

  margin-right: auto;
`;

export const Title = styled.h1`
  font-size: 16px;
  line-height: 16px;
  font-weight: bold;
  margin-bottom: 0;
  color: #333;
`;

export const PdfContent = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;

  img {
    width: 80px;
    height: auto;
    margin: auto;
  }

  p {
    text-align: center;
  }

  div {
    margin: auto;
  }
`;
