import React from 'react';
import PropTypes from 'prop-types';
import { Input as AntInput } from 'antd';

import { Container } from './styles';

const { TextArea: AntTextArea } = AntInput;

const TextArea = (props) => {
  const { children, size } = props;
  return (
    <Container size={size}>
      <AntTextArea {...props}>
        {children}
      </AntTextArea>
    </Container>
  );
};

TextArea.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  size: PropTypes.string,
};

export default TextArea;
