export const getErrorCode = (error) => {
	const code = error && error.response && error.response.data && error.response.data.error;

	return code;
}

export const getStatusCode = (error) => {
	const status = error && error.response && error.response.status;

	return status;
}

export const getErrorDetails = (error) => {
	const errorDetails = error && error.response && error.response.data && error.response.data.messages && error.response.data.messages[0];

	return errorDetails;
}

export const getFormattedError = (error) => {
	const formattedError = {
		statusCode: null,
		errorCode: null,
		summarizedRequest: null,
		url: null,
		requestData: null,
	}

	if (error.response) {
		formattedError.statusCode = error.response.status

		if (error.response.data) {
			formattedError.errorCode = (error.response.data.error || "").toUpperCase()
		}
	} else if (error.request) {
		formattedError.statusCode = "SERVER"
		formattedError.errorCode = (error.message || "").toUpperCase()
	} else {
		formattedError.statusCode = "APP"
		formattedError.errorCode = (error.message || "").toUpperCase()
	}

	const getSummarizedRequest = (error) => {
		const baseURL = error.config && error.config.baseURL || ""
		const url = error.config && error.config.url || ""
		const method = (error.config && error.config.method || "").toUpperCase()

		const path = url.split(baseURL).pop()

		return `${method} ${path}`
	}

	formattedError.summarizedRequest = getSummarizedRequest(error)
	formattedError.url = error.config && error.config.url
	formattedError.requestData = error.config && error.config.data

	const encodedErrorParams = []
	encodedErrorParams.push(formattedError.statusCode)
	encodedErrorParams.push(formattedError.errorCode)
	encodedErrorParams.push(formattedError.summarizedRequest)
	
	const summarizedError = encodedErrorParams.join(" - ")

	const base64EncodedData = btoa(summarizedError)

	return {
		...formattedError,
		base64EncodedData,
		summarizedError
	}
}