import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  margin-top: 20px;
  margin-bottom: 12px;
  padding-left: 16px;
  position: relative;

  span{
    font-size: 15px;
    color: #333;
  }

  a {
    color: #333;
  }

  a:hover {
    color: #777;
  }
`;

