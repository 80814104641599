import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const Title = styled.span`
  margin-top: 23px;
  font-size: 20px;
  font-weight: bold;
  color: black;
`;

export const Subtitle = styled.span`
  margin-top: 16px;
  text-align: center;
  font-size: 16px;
  color: #333333;
`;

export const TextUnderline = styled.div`
  text-align: center;
  text-decoration: underline;
  color: #ff4949;
  font-size: 14px;
  line-height: 19px;
  margin-top: 47px;
  cursor: pointer;

  a, a:hover, a:visited {
    text-align: center;
    text-decoration: underline;
    color: #ff4949;
  }
`;

export const TableWrapper = styled.div`
  width: 100%;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  h2 {
    margin-bottom: 0;
    padding: 10px;
    border-top: 1px solid #d4d4d4;
    border-left: 1px solid #d4d4d4;
    border-right: 1px solid #d4d4d4;
    border-radius: 3px 3px 0px 0px;
    text-align: center;
    color: #333333;
    font-size: 15px;
    line-height: 25px;
    font-weight: bold;
    background: #ededed;
    width: 100%;
    display: block;
  }

  table{
    margin-bottom: 14px;
    border-top: 2px  solid white;
    border-left: 1px  solid #D4D4D4;
    border-right: 1px  solid #D4D4D4;
    border-bottom: 1px  solid #D4D4D4;
    border-radius: 4px;

    tbody{
      display: block;
      overflow-y: auto;
      max-height: 148px;
      width: 100%;
    }

    td{
      padding-left: 5px;
    }

    tr{
      width: 100%;
      background-color: #F4F4F4;
      display: block;
    }

    tbody tr:nth-child(odd){
      background-color: #F9F9F9;
    }

    tbody tr>td:nth-child(1){
      width: auto;
    }

    tbody tr>td:nth-child(2){
      width: auto;
    }

    tbody tr>td:nth-child(3){
      text-align: right;
      width: 100%;
      padding-right: 5px;
    }
  }

  button {
    margin-bottom: 74px;
  }
`;

export const Left = styled.div`
  width: 100%;
  height: 100%;
  background-color: #ff4949;
  padding-top: 18px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  h3 {
    width: ${(props) => props.mobile ? '90%' : null};
    margin: ${(props) => props.mobile ? '0 auto' : null};
    color: white;
    font-weight: 400;
    font-size: 24px;
    line-height: 33px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 40px;
  }

  div:nth-child(2) {
    width: ${(props) => props.mobile ? '90%' : null};
    margin: ${(props) => props.mobile ? '30px auto' : '30px 20px'};
  }

  div:nth-child(3) {
    width: ${(props) => props.mobile ? '90%' : null};
    margin: ${(props) => props.mobile ? 'auto' : '0 20px'};
    margin-bottom: 30px;
  }
`;

export const ModalButton = styled.div`
  width: 100%;
  padding: 16px;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  cursor: pointer;

  svg {
    color: #666;
    font-size: 18px;
    margin-right: 8px;
  }

  h3 {
    color: #fe4244;
    font-weight: bold;
    letter-spacing: 0.07em;
    text-transform: uppercase;
    line-height: 19px;
    margin: 0;
    margin-bottom: 3px;
    padding: 0;
    font-size: 14px;
  }

  line-height: 1.2;

  span {
    font-size: 11px;
    line-height: inherit;
    color: #8f99a6;
  }
`;

export const Right = styled.div`
  width: ${(props) => props.mobile ? '90%' : '100%'};
  height: 100%;
  margin: ${(props) => props.mobile ? '0 auto' : null};
  padding: ${(props) => props.mobile ? '18px 20px' : '18px 85px 18px 85px'};

  h3{
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    color: black;
    margin-top: 34px;
  }
`;

export const IconsWrapper = styled.div`

`;

export const FirstIcon = styled.div`
  display:flex;
  align-items: center;
  margin-top: 30px;

  span{
    font-size: 16px;
    line-height: 22px;
    color: #666666;
    margin-left: 30px;
  }
`;

export const SecondIcon = styled.div`
  display:flex;
  align-items: center;
  margin-top: 46px;

  span{
    font-size: 16px;
    line-height: 22px;
    color: #666666;
    margin-left: 30px;
  }
`;
