import React, { useEffect, useState } from 'react';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { Icon } from 'antd';

import { retrieveParsedQueryParams } from '../../../services/navigation';
import api from '../../../services/api';
import { financesContactInfo } from '../../../services/contact';

import { isMobile } from '../../../utils/device';

import Divider from '../../../components/Divider';
import Button from '../../../components/Button';
import Label from '../../../components/Form/Label';

import { Container } from '../Overview/styles';
import { PaymentSlip, CreditCardBrandsContainer, CreditCardBrand } from '../Overview/PaymentInfo/styles';

import {
	InfoTitle,
	CardContainer,
	ButtonWrapper,
	ExtraInfo,
	WhatsappLink,
} from './styles';

import creditCardMasterCard from '../../../assets/icon/credit_card_mastercard.png';
import creditCardVisa from '../../../assets/icon/credit_card_visa.png';
import creditCardElo from '../../../assets/icon/credit_card_elo.png';
import creditCardDinersClub from '../../../assets/icon/credit_card_dinnersclub.png';
import creditCardHiperCard from '../../../assets/icon/credit_card_hipercard.png';
import paymentSlip from '../../../assets/icon/payment_slip.png';
import emoticonEyeBlinking from '../../../assets/icon/emoticon_eye_blinking.png';

const PaymentStatus = () => {
	const [creditCardPaymentSuccess, setCreditCardPaymentSuccess] = useState(false);
	const [loadingChargeRequest, setLoadingChargeRequest] = useState(true);
	const [whatsappUrl, setWhatsappUrl] = useState('');

	const location = useLocation();
	const history = useHistory();
	const { solution_name } = useParams();

	const parsedQueryParams = retrieveParsedQueryParams(location);

	const { payment_method, payment_slip_url, callback_url, plan_id } = parsedQueryParams;

	const handleGoToCustomPaymentStatusPage = (page) => {
		const queryParams = [`payment_method=${payment_method}`]

		if (payment_slip_url) {
			queryParams.push(`&payment_slip_url=${payment_slip_url}`)
		}

		if (callback_url) {
			queryParams.push(`&callback_url=${callback_url}`)
		}

		if (plan_id) {
			queryParams.push(`&plan_id=${plan_id}`)
		}
		
		history.push(`/checkout/${solution_name}/payment-status/${page}?${queryParams.join('')}`)
	}

	const getData = async () => {
		// We only try to verify if payment was confirmed if it was made by credit card
		if (payment_method !== "credit-card") {
			return
		}

		try {
			const response = await api.get(`/checkout/charge/status?plan_id=${plan_id}`)

			const {
				credit_card_payment_failed,
				credit_card_payment_pending,
				credit_card_payment_success,
			} = response.data;

			if (credit_card_payment_success === true) {
				handleGoToCustomPaymentStatusPage('credit-card-payment-success');
			} else if (credit_card_payment_failed === true) {
				handleGoToCustomPaymentStatusPage('credit-card-payment-failed');
			} else if (credit_card_payment_pending === true) {
				// If the payment wasn't made with success, we try to get this info again
				// cause it can be only a delay to process the credit card
				await new Promise((resolve) => setTimeout(resolve, 5000))
				return getData();
			}
		} catch(error) {
			// In case our API goes down and the requests goes to catch, we
			// try to get the data again and again
			await new Promise((resolve) => setTimeout(resolve, 5000))
			return getData();
		}

		setLoadingChargeRequest(false);
	}

	const handleRedirect = async () => {
		if (payment_method !== "credit-card" && payment_method !== "payment-slip") {
			history.push(`/checkout/${solution_name}/overview`);
		}
	}

	const handleWhatsappLink = () => {
		setWhatsappUrl(financesContactInfo().whatsappLink);
	}

	const handleCallback = () => {
		window.location.href = callback_url || '/gallery';
	}

	useEffect(() => {
		handleRedirect();
		handleWhatsappLink();
		getData();
	}, [])

	return (
		<Container>
			<Divider size="large" />

			{payment_method === 'payment-slip' ? (
				<CardContainer>
					<Divider size="xlarge" />

					<InfoTitle>
						O seu boleto foi gerado!
					</InfoTitle>

					<Divider size="medium" />

					<PaymentSlip src={paymentSlip} />

					<Divider size="medium" />

					<strong style={{ color: "#FF4949" }}>
						ATENÇÃO!
					</strong>

					<ExtraInfo>
						Caso não consiga efetuar o pagamento do boleto imediatamente,
						<br />
						aguarde um período de 2 horas, é o tempo que o boleto leva para ser
						<br />
						registrado no banco central.

						<Divider size="medium" />

						Após o pagamento, o banco pode levar até 72h para nos informar do
						<br />
						pagamento e, assim que ele reconhecer o pagamento, sua assinatura
						<br />
						será ativada imediatamente.

						<Divider size="medium" />

						<ButtonWrapper href={payment_slip_url} target="_blank">
							<Button type="success">
								VER BOLETO
							</Button>
						</ButtonWrapper>

						<Divider size="medium" />

						Para agilizar a ativação da sua assinatura você pode enviar o
						<br />
						comprovante por whatsapp.

						<Divider size="medium" />

						<WhatsappLink href={whatsappUrl} target="_blank">
							Enviar comprovante por Whatsapp
						</WhatsappLink>
						<br />
						<small>+55 {financesContactInfo().phoneNumber.replace("(", "").replace(")", "")} (seg à sex 9h às 18h)</small>
					</ExtraInfo>

					<Divider size="xlarge" />
				</CardContainer>
			) : payment_method === 'credit-card' ? (
				<CardContainer>
					<Divider size="xlarge" />

					{creditCardPaymentSuccess ? (
						<>
							<Icon type="check-circle" theme="twoTone" twoToneColor="#56C568"  style={{ fontSize: 80, color: "#858585" }} />

							<Divider size="medium" />

							<InfoTitle success={true}>
								Transação aprovada e
								<br />
								assinatura ativada!
							</InfoTitle>

							<Divider size="medium" />

							<Label>
								Estamos imensamente felizes por fazer parte da comunidade Picsize!
							</Label>

							<ExtraInfo>
								Temos a certeza de que iremos superar as suas expectativas! <img src={emoticonEyeBlinking} />
							</ExtraInfo>

							<Divider size="medium" />

							<ButtonWrapper onClick={handleCallback}>
								<Button type="success">
									INICIAR
								</Button>
							</ButtonWrapper>
						</>
					): (
						<>
							<Icon type="loading-3-quarters" spin style={{ fontSize: 80, color: "#858585" }} />

							<Divider size="medium" />

							<InfoTitle>
								Assinatura em processamento...
							</InfoTitle>

							<Divider size="medium" />

							<Label>
								Estamos processando o seu cartão de crédito
							</Label>

							<Divider size="medium" />

							<CreditCardBrandsContainer>
								<CreditCardBrand src={creditCardMasterCard} />
								<CreditCardBrand src={creditCardVisa} />
								<CreditCardBrand src={creditCardElo} />
								<CreditCardBrand src={creditCardHiperCard} />
								<CreditCardBrand src={creditCardDinersClub} />
							</CreditCardBrandsContainer>

							<Divider size="medium" />

							<ExtraInfo>
								Imediatamente após a aprovação do cartão de crédito, a sua assinatura será ativada!
								<br />
								Te enviaremos um e-mail de confirmação assim que aprovado!
							</ExtraInfo>
						</>
					)}
					
					<Divider size="xlarge" />
				</CardContainer>
			) : null}
		</Container>
	)
};

export default PaymentStatus;