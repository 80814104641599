import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'antd';

import { Container } from './styles';

const Loading = (props) => {
	const { children, loading, backgroundColor, style } = props;

	return (
		<>
			{
				loading 
				? <Container style={style} loading={loading} backgroundColor={backgroundColor}>
						<Icon type="loading" style={{ fontSize: '40px', margin: 'auto' }} />
					</Container>
				: children
			}
		</>
	)
}

Loading.defaultProps = {
	loading: false,
	backgroundColor: '#FFF',
}

Loading.propTypes = {
	loading: PropTypes.bool.isRequired,
	children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
	]).isRequired,
	backgroundColor: PropTypes.string,
	style: PropTypes.object,
}

export default Loading;