const FREE = [
  {
    value: '200 Fotos',
  },
  {
    name: 'Portfólios',
    value: 'ilimitados',
  },
  {
    name: 'Integração com',
    value: 'Redes Sociais',
  },
  {
    name: 'Certificado de',
    value: 'segurança SSL',
  },
  {
    name: 'Troca de',
    value: 'temas',
  },
  {
    name: 'Anúncios Picsize',
  },
];

const PROFESSIONAL = [
  {
    name: 'Fotos',
    value: 'ilimitadas',
  },
  {
    name: 'Proteção',
    value: 'contra cópia',
  },
  {
    value: 'TURBO SEO',
  },
  {
    name: 'Integração com',
    value: 'Whatsapp',
  },
  {
    name: 'Integração com',
    value: 'Google Analytics',
  },
  {
    name: 'Integração com',
    value: 'Facebook',
  },
  {
    name: 'Domínio',
    value: 'personalizado',
  },
  {
    value: 'Personalização do layout',
  },
  {
    name: 'E-mail',
    value: 'profissional',
  },
  {
    name: 'Favicon',
    value: 'personalizado',
  },
  {
    value: 'Sem anúncios PICSIZE',
  },
];

export const siteProData = {
  features: {
    FREE,
    PROFESSIONAL,
  },
  planIds: [27],
  coupon: 'SITEPRO20ANUAL',
};
