import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Row, Col, Alert } from 'antd';

import api from '../../services/api';
import { isAuthenticated } from '../../services/auth';
import {
  postLoginRedirect,
  getContextFromAuthData
} from '../../services/solution';
import { useValidation } from '../../services/validation';
import { forceLowerCase } from '../../utils/sanitization';

import {
  LoginForm,
  AsideInfo,
  AdvertisingImage,
  Logo,
  ForgotPassword
} from './styles';

import Button from '../../components/Button';
import Label from '../../components/Form/Label';
import Input from '../../components/Form/Input';
import Divider from '../../components/Divider';
import FormItem from '../../components/Form/FormItem';
import External from '../../components/External';
import SubmitContainer from '../../components/Form/SubmitContainer';

import { FormInput, ContainerTransparent } from '../../styles/components';

import { notifySuccess } from '../../utils/notificationService';

import logo from '../../assets/logo.png';
import banner from '../../assets/banner_portal.png';

const Login = (props) => {
  const history = useHistory();

  const inputs = ['email', 'password'];

  const [loading, setLoading] = useState(false);
  const [validation, clearValidation, triggerValidation] = useValidation(
    inputs
  );
  const [loginData, setLoginData] = useState({
    email: '',
    password: ''
  });

  const handleLogin = async () => {
    setLoading(true);

    try {
      const { data: authData } = await api.post('/auth/signin', {
        ...loginData
      });

      const context = getContextFromAuthData(authData);

      postLoginRedirect(document.referrer || context);
    } catch (error) {
      triggerValidation(error);

      setLoading(false);
    }
  };

  const verifyUserLogin = async () => {
    const isUserAuthenticated = await isAuthenticated();
    if (isUserAuthenticated) {
      notifySuccess('Sua conta já está conectada.');
      return (window.location.href = '/');
    }
  };

  const handleChange = (key, value) => {
    setLoginData({
      ...loginData,
      [key]: value
    });

    clearValidation(key);
  };

  const handleRequestPasswordRecovery = () => {
    props.history.push('/recovery/request');
  };

  useEffect(() => {
    verifyUserLogin();
  }, []);

  return (
    <ContainerTransparent>
      <Row style={{ height: '100%' }}>
        <Col xs={24} sm={24} md={24} lg={24} xl={7} style={{ height: '100%' }}>
          <SubmitContainer onSubmit={handleLogin}>
            <LoginForm>
              <Logo src={logo} alt="logo" />
              <p style={{ fontSize: '14px', textAlign: 'center' }}>
                Ainda não tem conta na Picsize?{' '}
                <Link to="/signup">
                  <a>Criar uma conta</a>
                </Link>
              </p>

              <Divider size="large" />

              <Label>E-mail</Label>
              <FormInput>
                <FormItem
                  validateStatus={validation.email.validateStatus}
                  help={validation.email.help}
                >
                  <Input
                    onChange={({ target }) =>
                      handleChange('email', target.value)
                    }
                    value={loginData.email}
                    onKeyUp={({ target }) => forceLowerCase(target)}
                  />
                </FormItem>
              </FormInput>

              <Divider size="medium" />

              <Label>Senha</Label>
              <FormInput>
                <FormItem
                  validateStatus={validation.password.validateStatus}
                  help={validation.password.help}
                >
                  <Input
                    type="password"
                    onChange={({ target }) =>
                      handleChange('password', target.value)
                    }
                    value={loginData.password}
                  />
                </FormItem>
              </FormInput>

              <Divider size="small" />

              {validation.password.validateStatus ? (
                <>
                  <Divider size="small" />
                  <Alert
                    message={[
                      <p style={{ fontSize: '14px' }}>
                        Caso tenha esquecido sua senha, utilize nosso recurso de
                        <ForgotPassword
                          onClick={() => handleRequestPasswordRecovery()}
                        >
                          <span style={{ fontSize: '14px' }}>
                            {' '}
                            recuperar senha
                          </span>
                        </ForgotPassword>
                        .
                      </p>
                    ]}
                    type="warning"
                  />
                </>
              ) : (
                <ForgotPassword onClick={() => handleRequestPasswordRecovery()}>
                  <span>Esqueceu sua senha?</span>
                </ForgotPassword>
              )}

              <Divider size="xlarge" />

              <Button htmlType="submit" loading={loading} type="primary" block>
                ENTRAR
              </Button>

              <External message="Ou entre com" />
            </LoginForm>
          </SubmitContainer>
        </Col>
        <Col xs={0} sm={0} md={0} lg={0} xl={17} style={{ height: '100%' }}>
          <AsideInfo>
            <AdvertisingImage src={banner} />
          </AsideInfo>
        </Col>
      </Row>
    </ContainerTransparent>
  );
};

export default Login;
