import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import Button from '../../../../../Button';
import sadIcon from '../../../../../../assets/icon/sad.png';
import {
  Container,
  Title,
  Subtitle,
  TableWrapper,
  TextUnderline
} from './styles';

import { getElementNode } from '../../../../../../services/elementNode';

const UploadFailedModal = (props) => {
  const { failedList, openUpload } = props;

  const [visibility, setVisibility] = useState(true);

  const handleClick = (type) => {
    if (type === 'close') {
      setVisibility(false);
    } else if (type === 'upload') {
      openUpload();
      setVisibility(false);
    }
  };

  return (
    <Modal
      visible={visibility}
      onOk={() => handleClick('close')}
      onCancel={() => handleClick('close')}
      footer={null}
      width={850}
    >
      <Container>
        <img src={sadIcon} alt="Smile Icon" />
        <Title>Ops!</Title>
        <Subtitle>
          Não foi possível enviar seus arquivos, listamos
          <br /> abaixo todos os motivos. Verifique e envie novamente.
        </Subtitle>
        <TextUnderline>
          <a href="https://blog.picsize.com.br/erro-de-upload/" target="_blank">
            Entenda os principais motivos que causam problemas ao subir fotos.
          </a>
        </TextUnderline>

        <TableWrapper mobile={window.innerWidth < 600}>
          <h2>Lista de fotos que não subiram</h2>
          <table>
            <tbody>
              {failedList.map((picture, index) => (
                <tr>
                  <td>{index + 1}</td>
                  <td>{picture.title}</td>
                  <td>{picture.error}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <Button block type="primary" onClick={() => handleClick('upload')}>
            Enviar Novamente
          </Button>
        </TableWrapper>
      </Container>
    </Modal>
  );
};

UploadFailedModal.open = (props) => {
  const { failedList, openUpload } = props;

  const uploadFailedModalNode = getElementNode('upload-failed-modal');

  ReactDOM.render(
    <UploadFailedModal failedList={failedList} openUpload={openUpload} />,
    uploadFailedModalNode
  );
};

UploadFailedModal.propTypes = {
  failedList: PropTypes.array.isRequired,
  openUpload: PropTypes.func.isRequired
};

export default UploadFailedModal;
