import React, { useState, useEffect } from 'react';
import { Form, Icon } from 'antd';

import api from '../../../services/api';

import { EditField, FormEdit, Portlet } from '../../../styles/components';

import Label from '../../../components/Form/Label';
import Input from '../../../components/Form/Input';
import Small from '../../../components/Form/Small';
import EditDialog from '../../../components/EditDialog';
import Loading from '../../../components/Loading';

import { notifySuccess } from '../../../utils/notificationService';

const Notifications = (props) => {
  const [loading, setLoading] = useState(true);
  const [notifEmail, setNotifEmail] = useState('');

  useEffect(() => {
    getDefaultEmail();
  }, []);

  const getDefaultEmail = async () => {
    const { data } = await api.get('/customer/notification/email');
    setNotifEmail(data.email_default);
    setLoading(false);
  };

  const handleUpdateNotificationMail = async (value) => {
    const email_default = value;

    try {
      await api.put('/customer/notification/email', {
        email_default
      });

      notifySuccess('O e-mail foi atualizado com sucesso.');

      setNotifEmail(email_default);
      return true;
    } catch (error) {
      return error;
    }
  };

  return (
    <Loading loading={loading}>
      <Portlet>
        <FormEdit>
          <Label>E-mail de notificações gerais</Label>
          <EditField>
            <EditDialog
              name="E-mail de notificações gerais"
              smallText="Esse é o e-mail em que você será notificado nas interações do sistema. Por exemplo, quando um cliente concluir uma seleção de fotos."
              value={notifEmail}
              onSave={(value) => handleUpdateNotificationMail(value)}
              inputName="notificationMail"
            >
              <Input value={notifEmail} readOnly />
              <Icon type="edit" />
            </EditDialog>
          </EditField>

          <Small>
            Esse é o e-mail em que você será notificado nas interações do sistema. Por exemplo, quando um cliente concluir uma seleção de fotos.
          </Small>
        </FormEdit>
      </Portlet>
    </Loading>
  );
};

export default Form.create({ name: 'notifications' })(Notifications);
