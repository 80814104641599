import styled from 'styled-components';

export const OpenerButton = styled.div`
  position: relative;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;

  i {
    /*position: absolute;
    right: 0;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    margin: 0;
    margin-right: 11px;*/
    font-size: 18px;
    margin-left: 16px;
  }
`;

export const Label = styled.label`
  color: #333333;
  font-weight: 700;
  font-size: 16px;

  display: flex;
`;

export const ButtonWrapper = styled.div`
  display: flex;
`;


export const ButtonGroup = styled.div`
  display: flex;
  width: auto;
  flex-direction: row;
  margin: 8px 0 8px auto;

  button{
    margin-left: 10px;
  }
`;
