import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Modal } from 'antd';

import Button from '../../Button';
import Divider from '../../Divider';
import Label from '../../Form/Label';
import Input from '../../Form/Input';
import FormItem from '../../Form/FormItem';
import SubmitContainer from '../../Form/SubmitContainer';

import { ButtonWrapper } from './styles';

import { FormInput } from '../../../styles/components';

import { getElementNode } from '../../../services/elementNode';
import { useValidation } from '../../../services/validation';

import { forceLowerCase } from '../../../utils/sanitization';

const ChangeClientModal = (props) => {
  const {
		onUpdate,
		onCreate,
		action,
		data
  } = props;

  const [visibility, setVisibility] = useState(true);
	const [loading, setLoading] = useState(false);

	const inputs = ["name", "email", "tel"];
	const [validation, clearValidation, triggerValidation] = useValidation(inputs);
	
	const [clientData, setClientData] = useState({
		name: data.name || '',
		email: data.email || '',
		tel: data.tel || '',
	});

  const handleClientDataChange = (key, value) => {
    setClientData({
      ...clientData,
      [key]: value
    })

    clearValidation(key);
  };

  const handleClose = () => {
    setVisibility(false);
	};

  const handleConfirm = async () => {
    setLoading(true);

		try {
			if (action === "create") {
				await onCreate(clientData);
			} else if (action === "update") {
				await onUpdate(clientData);
			}

			handleClose();
		} catch(error) {
			triggerValidation(error);
		}
    
    setLoading(false);
  };

  return (
    <Modal
      visible={visibility}
      destroyOnClose={true}
      onCancel={handleClose}
      title={
        action === 'create'
          ? 'Criar novo cliente'
          : 'Editar dados do cliente'
      }
      footer={[
        <ButtonWrapper>
          <Button key="back" icon="close" onClick={handleClose}>
            CANCELAR
          </Button>
          <Button
            htmlType="submit"
            type="primary"
            icon="check"
            loading={loading}
            onClick={handleConfirm}
          >
            {action === 'create' ? "CRIAR" : "SALVAR"}
          </Button>
        </ButtonWrapper>
      ]}
    >
      
        <Label isRequired={action === 'create'}>Nome</Label>
        <FormInput>
          <FormItem
            validateStatus={validation.name.validateStatus}
            help={validation.name.help}
          >
            <SubmitContainer onSubmit={handleConfirm}>
              <Input
                value={clientData.name}
                onChange={({ target }) =>
                  handleClientDataChange('name', target.value)
                }
              />
            </SubmitContainer>
          </FormItem>
        </FormInput>

        <Divider size="medium" />

        <Label isRequired={action === 'create'}>E-mail</Label>
        <FormInput>
          <FormItem
            validateStatus={validation.email.validateStatus}
            help={validation.email.help}
          >
            <SubmitContainer onSubmit={handleConfirm}>
              <Input
                value={clientData.email}
                onChange={({ target }) =>
                  handleClientDataChange('email', target.value)
                }
                onKeyUp={({ target }) => forceLowerCase(target)}
              />
            </SubmitContainer>
          </FormItem>
        </FormInput>

        <Divider size="medium" />

        <Label isRequired={action === 'create'}>Telefone / Whatsapp</Label>
        <FormInput>
          <FormItem
            validateStatus={validation.tel.validateStatus}
            help={validation.tel.help}
          >
            <SubmitContainer onSubmit={handleConfirm}>
              <Input
                value={clientData.tel}
                onChange={({ target }) =>
                  handleClientDataChange('tel', target.value)
                }
                type="phone"
              />
            </SubmitContainer>
          </FormItem>
        </FormInput>
        {action === 'create' && (
          <>
            <Divider size="medium" />

            <Label>Senha</Label>
            <FormInput>
              <FormItem>
                <Input
                  placeholder="A senha será gerada automaticamente."
                  disabled
                />
              </FormItem>
            </FormInput>
          </>
        )}
    </Modal>
  );
};

ChangeClientModal.defaultProps = {
  onUpdate: () => {},
	onCreate: () => {},
	data: {},
};

ChangeClientModal.propTypes = {
  onCreate: PropTypes.func,
	onUpdate: PropTypes.func,
	data: PropTypes.object,
  action: PropTypes.oneOf(['create', 'update']).isRequired
};

ChangeClientModal.open = ({ onUpdate, onCreate, action, data }) => {
  const changeClientModalNode = getElementNode('change-client-modal');

  ReactDOM.render(
    <ChangeClientModal
      onUpdate={onUpdate}
      onCreate={onCreate}
			action={action}
			data={data}
    />,
    changeClientModalNode
  );
};

export default ChangeClientModal;
