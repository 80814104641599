import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Select } from 'antd';

import { useValidation } from '../../services/validation';
import { usePlan } from '../../services/plan';

import { OpenerButton, ButtonWrapper, ButtonGroup } from './styles';

import Label from '../Form/Label';
import Input from '../Form/Input';
import Small from '../Form/Small';
import InputNumber from '../Form/InputNumber';
import DatePicker from '../../components/Form/DatePicker';
import FormItem from '../Form/FormItem';
import Button from '../Button';
import SubmitContainer from '../Form/SubmitContainer';
import api from '../../services/api';

const { Option } = Select;

const EditDialog = (props) => {
  const {
    name,
    children,
    onSave,
    value,
    type,
    inputName,
    smallText,
    validateSolution,
    limitTypeKey,
    limitKey,
    categories,
    title
  } = props;

  const editDialogInputId = `${name}-edit-dialog-input`;

  const [editValue, setEditValue] = useState('');
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [validation, clearValidation, triggerValidation] = useValidation([
    inputName
  ]);
  const { checkSolution, triggerPlanChange } = usePlan();

  const showModal = async () => {
    if (validateSolution) {
      const isEnabled = checkSolution(limitTypeKey, limitKey);

      if (!isEnabled) {
        return await triggerPlanChange('feat', limitKey);
      }
    }

    setVisible(true);
    setEditValue(value);

    setTimeout(() => {
      const input = document.getElementById(editDialogInputId);

      if (input) {
        input.focus();
      }
    });
  };

  const close = () => {
    setLoading(false);
    setVisible(false);
  };

  const handleOk = async () => {
    if (typeof onSave !== 'function') {
      return;
    }

    setLoading(true);

    const result = await onSave(editValue);

    if (result === true) {
      close();
    } else {
      triggerValidation(result);

      setLoading(false);
    }
  };

  const handleCancel = () => {
    close();
  };

  const handleInput = (value) => {
    setEditValue(value);

    clearValidation(inputName);
  };

  const chooseInput = (type, props) => {
    const inputs = {
      datepicker: (
        <DatePicker
          id={props.id}
          value={props.value}
          onChange={(value) => handleInput(value)}
        />
      ),
      bill: (
        <InputNumber
          id={props.id}
          type="bill"
          value={props.value}
          onChange={(value) => handleInput(value)}
        />
      ),
      default: (
        <Input
          id={props.id}
          type={type}
          value={props.value}
          onInput={({ target }) => handleInput(target.value)}
        />
      ),
      select: (
        <Select
          id={props.id}
          showSearch
          size="large"
          value={props.value}
          placeholder="Procure e selecione uma categoria"
          optionFilterProp="children"
          onSelect={(optionId) => handleInput(optionId)}
          filterOption={(input, option) =>
            option.props.children
              .toLocaleLowerCase()
              .indexOf(input.toLocaleLowerCase()) >= 0
          }
        >
          {props.categories &&
            props.categories.map((categ) => (
              <Option value={categ.id}>{categ.title}</Option>
            ))}
        </Select>
      )
    };

    // In case the type exists, we take that input, else, we take the default
    return inputs[type] || inputs['default'];
  };

  return (
    <>
      <OpenerButton onClick={() => showModal()}>{children}</OpenerButton>
      <Modal
        title={title ? title : `Alterar ${name.toLowerCase()}`}
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose={true}
        footer={[
          <ButtonWrapper>
            <ButtonGroup>
              <Button key="back" icon="close" onClick={handleCancel}>
                CANCELAR
              </Button>
              <Button
                htmlType="submit"
                key="submit"
                type="primary"
                icon="check"
                loading={loading}
                onClick={handleOk}
              >
                SALVAR
              </Button>
            </ButtonGroup>
          </ButtonWrapper>
        ]}
      >
        <SubmitContainer onSubmit={handleOk}>
          <FormItem
            validateStatus={validation[inputName].validateStatus}
            help={validation[inputName].help}
          >
            <Label>{name}</Label>
            {chooseInput(type, {
              value: editValue,
              categories,
              id: editDialogInputId
            })}
          </FormItem>
          <Small>{smallText}</Small>
        </SubmitContainer>
      </Modal>
    </>
  );
};

EditDialog.defaultProps = {
  inputName: '',
  type: 'default',
  smallText: '',
  categories: [],
  validateSolution: false,
  title: null
};

EditDialog.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onSave: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  inputName: PropTypes.string.isRequired,
  smallText: PropTypes.string,
  validateSolution: PropTypes.bool,
  limitTypeKey: PropTypes.string,
  limitKey: PropTypes.string,
  categories: PropTypes.array,
  title: PropTypes.string
};

export default EditDialog;
