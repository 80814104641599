import React, { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Icon } from 'antd';

import { Menu, MenuItem, Portlet } from '../../../styles/components';

const AsideMenu = ({ history }) => {
  const { location } = history;

  const [menuSelected, setMenuSelected] = useState('');

  useEffect(() => {
    const { pathname } = location;
    if (pathname.includes('profile')) {
      setMenuSelected('profile');
    } else if (pathname.includes('watermark')) {
      setMenuSelected('watermark');
    } else if (pathname.includes('subdomain')) {
      setMenuSelected('subdomain');
    } else {
      setMenuSelected('notifications');
    }
  }, [location]);

  return (
    <Portlet>
      <Menu>
        <MenuItem isSelected={menuSelected === 'profile'}>
          <Link to="/settings/profile">
            <Icon type="solution" />
            PERFIL
          </Link>
        </MenuItem>

        <MenuItem isSelected={menuSelected === 'watermark'}>
          <Link to="/settings/watermark">
            <Icon type="trademark" />
            Marca D'Água
          </Link>
        </MenuItem>

        <MenuItem isSelected={menuSelected === 'subdomain'}>
          <Link to="/settings/subdomain">
            <Icon type="cluster" />
            Domínio
          </Link>
        </MenuItem>
        <MenuItem isSelected={menuSelected === 'notifications'}>
          <Link to="/settings/notifications">
            <Icon type="bell" />
            Notificações
          </Link>
        </MenuItem>
      </Menu>
    </Portlet>
  );
};

export default withRouter(AsideMenu);
