import { makeStyles } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled.div`
  overflow-x: auto;
  scrollbar-width: thin;
  scroll-behavior: smooth;

  background-color: #fff;
  font-size: 12px;
  color: #666666;
  font-weight: 700;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
`;

export const ButtonsList = styled.div`
  display: flex;
  padding: 0;
  margin: 0;
  position: relative;
`;

export const ButtonsListItem = styled.div`
  cursor: pointer;
  list-style: none;

  display: flex;
  justify-content: center;
  align-items: center;

  i {
    margin-right: 8px;
    font-size: 16px;
  }

  button {
    color: #666;
    background-color: transparent;
    box-shadow: 0 0 0 transparent;
    border: 0;
    display: flex;
    font-size: 10px;
    height: 40px;

    span {
      margin-left: 0;
      padding-left: 0;
    }

    i {
      margin: auto;
    }
  }

  button:hover {
    background-color: transparent;
    box-shadow: 0 0 0 transparent;
    border: 0;
  }
  button:focus {
    background-color: transparent;
    box-shadow: 0 0 0 transparent;
    border: 0;
  }
  img.listIcon {
    width: 14px;
    margin-right: 8px;
  }
`;

export const ButtonWrapper = styled.div``;

export const OrdenationModalContent = styled.div`
  margin: 0 16px;

  & > label {
    display: flex;
    flex-direction: row;
    align-items: center;

    line-height: 32px;
  }

  label {
    button {
      height: 30px;
    }
  }

  label:hover {
    color: #ff4949;

    .ant-radio-inner {
      border-color: #56c568;
    }
  }

  label > span:nth-child(2) {
    margin-left: 24px;
  }

  .ant-radio-input:focus + .ant-radio-inner {
    border-color: #56c568;
  }

  .ant-radio:hover {
    .ant-radio-inner {
      border-color: #56c568;
    }
  }

  .ant-radio-checked {
    .ant-radio-inner {
      border-color: #56c568;
    }

    .ant-radio-inner:after {
      background-color: #56c568;
    }
  }

  .ant-radio-checked:after {
    border-color: #56c568;
  }

  .ant-divider {
    margin: 16px 0;
  }
`;

export const ModalContentRow = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 0;
  margin: 3px 0;
  cursor: pointer;
  transition: box-shadow 100ms ease-in;

  &:hover {
    background: #e6f7ff;
  }

  .total-suggested {
    margin-left: 32px;
    font-weight: bolder;
  }

  .ant-checkbox-wrapper:hover,
  .ant-checkbox:hover {
    .ant-checkbox-inner {
      border-color: #56c568;
    }
  }

  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #56c568;
  }

  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: #56c568;
      border-color: #56c568;
    }
  }

  .ant-checkbox-checked:after {
    border-color: #56c568;
  }

  .ant-checkbox-indeterminate {
    .ant-checkbox-inner:after {
      background-color: #56c568;
    }
  }
`;

export const TagWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 50px;
  min-width: 130px;
  width: 130px;
  margin-left: 32px;

  .ant-rate-star.ant-rate-star-full,
  .ant-rate-disabled .ant-rate-star,
  .ant-rate-star.ant-rate-star-zero,
  .ant-rate-star,
  .ant-rate,
  .ant-rate-disabled,
  .ant-rate-star.ant-rate-star-half.ant-rate-star-active {
    cursor: pointer;
    transition: none;
  }

  .ant-rate-star-zero {
    .ant-rate-star-first,
    .ant-rate-star-second {
      color: #bbb;
    }
  }

  .ant-rate-star > div:hover,
  .ant-rate-star > div:focus {
    transform: none;
  }

  .ant-rate-star:hover {
    transform: none;
  }

  .ant-tag {
    cursor: pointer;
  }

  .ant-tag:hover {
    opacity: 1;
  }

  &:hover {
    transform: none;
  }
`;

const useStyles = makeStyles((theme) => ({
  premiumChipContainer: {
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      paddingRight: '0',
      margin: '0 auto'
    }
  },
  premiumChipWrapper: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      marginTop: theme.spacing(1)
    }
  },
  titleDialog: {
    color: '#333333',
    fontWeight: 'bold',
    fontSize: '16px'
  }
}));

export default useStyles;
