import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { Icon, Tag } from 'antd';
import { Link } from 'react-router-dom';
import { Creators as AsideGalleryMenuActions } from '../../../store/ducks/asideGalleryMenu';

import { Menu, MenuItem, Portlet } from '../../../styles/components';

const AsideGalleryMenu = ({ collectionId, pathname }) => {
  const menuSelected = useSelector((state) => state.asideGalleryMenu.menuSelected)
  const galleryInfo = useSelector((state) => state.gallery.info);
  const { selection_activated, download_activated } = galleryInfo;

  const dispatch = useDispatch();

  const getCurrentMenu = () => {
    const menuMap = [
      'pictures',
      'download',
      'selection',
      'settings',
    ];

    menuMap.map((menu) => {
      if (pathname.includes(menu)) {
        dispatch(AsideGalleryMenuActions.selectMenu(menu));
      }
    });
  };

  useEffect(() => {
    getCurrentMenu();
  }, [menuSelected]);

  const handleClickMenuItem = (MenuItem) => {
    dispatch(AsideGalleryMenuActions.selectMenu(MenuItem));
  };

  return (
    <Portlet>
      <Menu>
        <MenuItem isSelected={menuSelected === 'pictures'}>
          <Link
            onClick={() => handleClickMenuItem('pictures')}
            to={`/gallery/${collectionId}/pictures`}
          >
            <Icon type="picture" />
            fotos
          </Link>
        </MenuItem>

        <MenuItem isSelected={menuSelected === 'download'}>
          <Link onClick={() => handleClickMenuItem('download')} to={`/gallery/${collectionId}/download`}>
            <Icon type="download" /> 
            entrega em alta
            <Tag style={{ height: '16px', borderRadius: '16px' }} color={download_activated ? '#56C568' : '#CCC'} >
              {download_activated ? 'ON' : 'OFF'}
            </Tag>
          </Link>
        </MenuItem>
        
        <MenuItem isSelected={menuSelected === 'selection' || menuSelected === 'send' || menuSelected === 'followup'}>
          <span
            onKeyPress={() => {}}
            onClick={() => handleClickMenuItem('selection')}
            role="presentation"
          >
            <Link to={`/gallery/${collectionId}/selection${selection_activated ? '/followup' : '/preferences'}`}>
              <Icon type="select" />
              seleção de fotos
              <Tag
                style={{ height: '16px', borderRadius: '16px' }}
                color={selection_activated ? '#56C568' : '#CCC'}
              >
                {selection_activated ? 'ON' : 'OFF'}
              </Tag>
            </Link>
          </span>
        </MenuItem>

        <MenuItem
          onClick={() => handleClickMenuItem('settings')}
          isSelected={menuSelected === 'settings'}
        >
          <Link to={`/gallery/${collectionId}/settings`}>
            <Icon type="tool" />
            configurações
          </Link>
        </MenuItem>
      </Menu>
    </Portlet>
  );
};

AsideGalleryMenu.propTypes = {
  collectionId: PropTypes.number.isRequired,
  pathname: PropTypes.string.isRequired,
};

export default AsideGalleryMenu;
