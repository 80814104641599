/* eslint-disable no-nested-ternary */
import styled from 'styled-components';

export const Container = styled.div`
  button {
    background-color: ${
  ({ type }) => (
    type === 'primary' ? '#FF4949'
      : type === 'default' ? '#ffffff'
        : type === 'success' ? '#56C568'
          : type === 'neutral' ? '#EBEBEB'
            : type === 'alert' ? '#FAAD14'
              : null
  )
};

    border-color: ${
  ({ type }) => (
    type === 'primary' ? '#FF4949'
      : type === 'default' ? '#ffffff'
        : type === 'success' ? '#56C568'
          : type === 'neutral' ? '#EBEBEB'
            : type === 'alert' ? '#FAAD14'
              : null
  )
};

    color: ${
  ({ type }) => (
    type === 'primary' ? '#ffffff'
      : type === 'default' ? '#FF4949'
        : type === 'success' ? '#ffffff'
          : type === 'neutral' ? '#3C3C3C'
            : type === 'alert' ? '#ffffff'
              : null
  )
};

    width: ${
  ({ border }) => (
    border === 'leftButton' ? '100%'
      : border === 'centerButton' ? '100%'
        : border === 'rightButton' ? '100%'
          : null
  )
};

    border-radius: ${
  ({ border }) => (
    border === 'leftButton' ? '8px 0 0 8px'
      : border === 'centerButton' ? '0 0 0 0'
        : border === 'rightButton' ? '0 8px 8px 0'
          : null
  )
};

    box-shadow: 0px 4px 4px rgba(135, 135, 135, 0.25);
    /*border-radius: 6px;*/
    font-weight: bold;
    font-size: 11px;
    height: auto;
    line-height: 22px;
    letter-spacing: 0.07em;
    text-transform: uppercase;
  }

  button:hover{
    background-color: ${
  ({ type }) => (
    type === 'primary' ? '#ed4242'
      : type === 'default' ? '#f2f2f2'
        : type === 'success' ? '#4fb860'
          : type === 'neutral' ? '#E0E0E0'
            : type === 'alert' ? '#E09C12'
              : null
  )
};
    border-color: ${
  ({ type }) => (
    type === 'primary' ? '#ed4242'
      : type === 'default' ? '#f2f2f2'
        : type === 'success' ? '#4fb860'
          : type === 'neutral' ? '#E0E0E0'
            : type === 'alert' ? '#E09C12'
              : null
  )
};

    color: ${
  ({ type }) => (
    type === 'primary' ? '#ffffff'
      : type === 'default' ? '#FF4949'
        : type === 'success' ? '#ffffff'
          : type === 'neutral' ? '#3C3C3C'
            : type === 'alert' ? '#ffffff'
              : null
  )
};
  }

  button:focus{
    background-color: ${
  ({ type }) => (
    type === 'primary' ? '#FF4949'
      : type === 'default' ? '#ffffff'
        : type === 'success' ? '#56C568'
          : type === 'neutral' ? '#EBEBEB'
            : type === 'alert' ? '#FAAD14'
              : null
  )
};

    border-color: ${
  ({ type }) => (
    type === 'primary' ? '#FF4949'
      : type === 'default' ? '#ffffff'
        : type === 'success' ? '#56C568'
          : type === 'neutral' ? '#EBEBEB'
            : type === 'alert' ? '#FAAD14'
              : null
  )
};

    color: ${
  ({ type }) => (
    type === 'primary' ? '#ffffff'
      : type === 'default' ? '#FF4949'
        : type === 'success' ? '#ffffff'
          : type === 'neutral' ? '#3C3C3C'
            : type === 'alert' ? '#ffffff'
              : null
  )
};

    border-radius: ${
  ({ border }) => (
    border === 'leftButton' ? '8px 0 0 8px'
      : border === 'centerButton' ? '0 0 0 0'
        : border === 'rightButton' ? '0 8px 8px 0'
          : null
  )
};
    box-shadow: 0px 4px 4px rgba(135, 135, 135, 0.25);
    font-weight: bold;
    letter-spacing: 0.07em;
  }

  .ant-btn[disabled]{
    background-color: #CCC;
    color: #FFF;
    box-shadow: 0px 4px 4px rgba(135, 135, 135, 0.25);

    &:hover{
      background-color: #CCC;
      color: #FFF;
      box-shadow: 0px 4px 4px rgba(135, 135, 135, 0.25);
    }
  }

  span {
    padding: 8px 0;
  }

  i {
    padding-right: 4px;
    font-size: 1.0rem;
  }
`;
