import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'antd';
import { Container } from './styles';

const Pill = (props) => {
  const { style, children } = props;

  return (
    <Container style={style}>
      <Icon type="star" theme="filled" />
      {children ? children : 'Recurso Plus'}
    </Container>
  );
};

Pill.defaultProps = {
  children: null,
  style: {}
};

Pill.propTypes = {
  style: PropTypes.object,
  children: PropTypes.node
};

export default Pill;
