import React, { useState } from 'react';
import { Modal, Divider as AntDivider } from 'antd';
import PropTypes from 'prop-types';

import Divider from '../../../../Divider';

import { Content, Radio, Opener } from './styles';

const OrdinationModal = (props) => {
	const {
		children,
		handleChangeOrdination,
		pictures_ordenation,
	} = props;

	const [visible, setVisible] = useState(false);

	const handleChange = async (value) => {
		await handleChangeOrdination(value);
		setVisible(false);
	}

	return (
		<>
			<Opener onClick={() => setVisible(true)}>{children}</Opener>
			<Modal
        title='Ordenar por'
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        footer={null}
      >
        <Radio.Group
          onChange={({ target }) => handleChange(target.value)}
          value={pictures_ordenation}
          style={{width: '100%'}}
        >
          <Content>
            <Radio value={7} marked={pictures_ordenation === 7}>
              <b>Numeração da foto [0-9]</b>
            </Radio>
            <Radio value={8} marked={pictures_ordenation === 8}>
              <b>Numeração da foto [9-0]</b>
            </Radio>

            <Divider size="medium" />

            <Radio value={5} marked={pictures_ordenation === 5}>
              <b>Hora de captura da foto [A-Z]</b>
            </Radio>
            <Radio value={6} marked={pictures_ordenation === 6}>
              <b>Hora de captura da foto [Z-A]</b>
            </Radio>

            <Divider size="medium" />

            <Radio value={3} marked={pictures_ordenation === 3}>
              <b>Nome do arquivo [A-Z]</b>
            </Radio>
            <Radio value={4} marked={pictures_ordenation === 4}>
              <b>Nome do arquivo [Z-A]</b>
            </Radio>
						
            <Divider size="medium" />

            <Radio value={1} marked={pictures_ordenation === 1}>
              <b>Data do upload [A-Z]</b>
            </Radio>
            <Radio value={2} marked={pictures_ordenation === 2}>
              <b>Data do upload [Z-A]</b>
            </Radio>
          </Content>
        </Radio.Group>
      </Modal>
		</>
	)
};

OrdinationModal.propTypes = {
	children: PropTypes.node.isRequired,
	handleChangeOrdination: PropTypes.func.isRequired,
	pictures_ordenation: PropTypes.number.isRequired,
};

export default OrdinationModal;