import GoogleTagManager from 'react-gtm-module';
import api from './api';

const getLeadVariables = async () => {
  try {
    const response = await api.get('/marketing/lead/variables');

    return response.data;
  } catch (error) {
    return {};
  }
};

const getDataLayerInfo = async () => {
  const dataLayer = await getLeadVariables();

  return dataLayer;
};

const clearDataLayer = () => {
	if (window.dataLayer) {
		window.dataLayer = undefined;
	}
}

const triggerGoogleTagManagerDOMLoadedEvent = () => {
	const DOMLoaded = { event: 'gtm.dom', ['gtm.uniqueEventId']: 100 }

	let lastGtmUniqueEventId = 0

	Object.keys(window.dataLayer).map((key, index) => {
		if (key === 'gtm.uniqueEventId') {
			lastGtmUniqueEventId = window.dataLayer[index]['gtm.uniqueEventId'] + 1
		}
	})

	DOMLoaded["gtm.uniqueEventId"] = lastGtmUniqueEventId

	window.onload = window.dataLayer.push(DOMLoaded)
}

export const addDataLayerVariables = async () => {
	const dataLayer = await getDataLayerInfo();

	if (window.dataLayer) {
		window.dataLayer.push(dataLayer);
	} else {
		window.dataLayer = [dataLayer];
	}

	// Adds a DOM Loaded event on GTM, since that's a SPA and
	// gtm doesn't recognize by itself.
	triggerGoogleTagManagerDOMLoadedEvent();
}

export const initGoogleTagManager = () => {
	GoogleTagManager.initialize({ gtmId: 'GTM-PNNRBG4' });
};

export const triggerGoogleAnalyticsPageView = (pathname) => {
	// As we're adding google analytics using google tag manager, we
	// can just use this function after the google tag manager added it
	// to the window scope.
	if (window.ga) {
		window.ga('send', 'pageview', pathname)
	}
}