import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'antd';

import { getTrackData, clearTrackData } from '../../../services/track';
import { forceLowerCase } from '../../../utils/sanitization';

import api from '../../../services/api';
import {
  activateSolution,
  postLoginRedirect,
  getContextFromAuthData,
  Query
} from '../../../services/solution';

import logoRed from '../../../assets/logo_red_background.png';
import logoHorizontal from '../../../assets/logo.png';

import {
  Registered,
  AsideInfo,
  SignupForm,
  Content,
  Logo,
  SmallWrapper,
  Title,
  TitleWrapper,
  LogoMobile
} from '../styles';

import Button from '../../../components/Button';
import Small from '../../../components/Form/Small';
import Input from '../../../components/Form/Input';
import Label from '../../../components/Form/Label';
import FormItem from '../../../components/Form/FormItem';
import Divider from '../../../components/Divider';
import SubmitContainer from '../../../components/Form/SubmitContainer';

import { FormInput, ContainerTransparent } from '../../../styles/components';
import { useValidation } from '../../../services/validation';

const Signup = () => {
  const inputs = ['name', 'email', 'password', 'whatsapp_number'];

  const [loading, setLoading] = useState(false);
  const [validation, clearValidation, triggerValidation] = useValidation(
    inputs
  );
  const [signupData, setSignupData] = useState({
    email: '',
    password: '',
    name: '',
    whatsapp_number: ''
  });

  const sendInfoToBuzzlead = async ({ customer_id, name, email }) => {
    await window.Tracker({
      _event: 'subscribe',
      numeroVenda: customer_id,
      cliente: {
        nome: name,
        email: email,
      }
    });
  };

  const handleSignup = async () => {
    setLoading(true);

    try {
      const track = getTrackData();

      const signupResponse = await api.post('/customer/signup?userType=architecture', {
        ...signupData,
        track
      });
      const customer_id = signupResponse?.data?.customer_id;

      try {
        await sendInfoToBuzzlead({
          customer_id,
          name: signupData.name,
          email: signupData.email
        });
      } catch (err) {
        console.log(err);
      }

      clearTrackData();

      const { email, password } = signupData;
      const { data: authData } = await api.post('/auth/signin', {
        email,
        password
      });

      const context = getContextFromAuthData(authData);

      await activateSolution(context);

      let query = '';

      if (context === 'site_pro') {
        query = Query.NEW_USER_SITE_PRO;
      }

      postLoginRedirect(context, query);
    } catch (error) {
      triggerValidation(error);
      setLoading(false);
    }
  };

  const handleChange = (key, value) => {
    setSignupData({
      ...signupData,
      [key]: value
    });

    clearValidation(key);
  };

  return (
    <ContainerTransparent>
      <Row style={{ height: '100%' }}>
        <Col xs={0} sm={0} md={0} lg={0} xl={7} style={{ height: '100%' }}>
          <AsideInfo>
            <a href="https://picsize.com.br" target="_self">
              <Logo src={logoRed} />
            </a>
            <TitleWrapper>
              <Title>
                Todo arquiteto precisa de um site.
                <br />
                Na Picsize você constrói o seu <br />
                em <strong>10 minutos.</strong>
              </Title>
            </TitleWrapper>
          </AsideInfo>
        </Col>

        <Col xs={24} sm={24} md={24} lg={24} xl={17} style={{ height: '100%' }}>
          <Registered>
            Já é cadastrado?
            <br />
            <Link to="/login">Faça login</Link>
          </Registered>
          <Content>
            <SignupForm>
              <SubmitContainer onSubmit={handleSignup}>
                <LogoMobile src={logoHorizontal} />
                <Title>
                  Teste grátis a Picsize e comece agora a ver resultados
                </Title>

                <Divider size="medium" />

                <Label isRequired>Nome completo</Label>
                <FormInput>
                  <FormItem
                    validateStatus={validation.name.validateStatus}
                    help={validation.name.help}
                  >
                    <Input
                      onChange={({ target }) =>
                        handleChange('name', target.value)
                      }
                      value={signupData.name}
                    />
                  </FormItem>
                </FormInput>

                <Divider size="xsmall" />

                <Label isRequired>E-mail</Label>
                <FormInput>
                  <FormItem
                    validateStatus={validation.email.validateStatus}
                    help={validation.email.help}
                  >
                    <Input
                      onChange={({ target }) =>
                        handleChange('email', target.value)
                      }
                      value={signupData.email}
                      onKeyUp={({ target }) => forceLowerCase(target)}
                    />
                  </FormItem>
                </FormInput>

                <Divider size="xsmall" />

                <Label isRequired>Senha</Label>
                <FormInput>
                  <FormItem
                    validateStatus={validation.password.validateStatus}
                    help={validation.password.help}
                  >
                    <Input
                      type="password"
                      onChange={({ target }) =>
                        handleChange('password', target.value)
                      }
                      value={signupData.password}
                    />
                  </FormItem>
                </FormInput>

                <Divider size="xsmall" />

                <Label isRequired>WhatsApp/Celular</Label>
                <FormInput>
                  <FormItem
                    validateStatus={validation.whatsapp_number.validateStatus}
                    help={validation.whatsapp_number.help}
                  >
                    <Input
                      type="phone"
                      onChange={({ target }) =>
                        handleChange('whatsapp_number', target.value)
                      }
                      value={signupData.whatsapp_number}
                    />
                  </FormItem>
                </FormInput>

                <Divider size="medium" />

                <Button
                  htmlType="submit"
                  type="primary"
                  loading={loading}
                  block
                >
                  CADASTRAR
                </Button>
                <SmallWrapper>
                  <Small>Teste sem informar o cartão de crédito</Small>
                </SmallWrapper>
              </SubmitContainer>
            </SignupForm>
          </Content>
        </Col>
      </Row>
    </ContainerTransparent>
  );
};

export default Signup;
