export const positions = {
  // TOP - LEFT
  1: {
    top: 0,
    left: 0
  },
  // TOP - CENTER
  2: {
    top: 0,
    left: 0,
    right: 0
  },
  // TOP - RIGHT
  3: {
    top: 0,
    right: 0
  },
  // MIDDLE - LEFT
  4: {
    top: 0,
    left: 0,
    bottom: 0
  },
  // MIDDLE - CENTER
  5: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  },
  // MIDDLE - RIGHT
  6: {
    top: 0,
    right: 0,
    bottom: 0
  },
  // BOTTOM - LEFT
  7: {
    left: 0,
    bottom: 0
  },
  // BOTTOM - CENTER
  8: {
    left: 0,
    right: 0,
    bottom: 0
  },
  // BOTTOM - RIGHT
  9: {
    right: 0,
    bottom: 0
  }
};

export const calculateMaxWidthAndMaxHeight = (heightParams, widthParams) => {
  const { maxHeightWatermark, marginY, maxHeight } = heightParams;
  const { maxWidthWatermark, marginX, maxWidth } = widthParams;

  const result = {
    maxHeightWatermark: maxHeightWatermark,
    maxWidthWatermark: maxWidthWatermark
  };

  // If the size of the watermark added to the margin is greater than
  // the total size of the image, resizing occurs.

  const watermarkHeightPlusMarginYIsLarger =
    maxHeightWatermark + marginY > maxHeight;

  const watermarkWidthPlusMarginXIsLarger =
    maxWidthWatermark + marginX > maxWidth;

  if (watermarkHeightPlusMarginYIsLarger)
    result.maxHeightWatermark =
      maxHeightWatermark - (maxHeightWatermark + marginY - maxHeight);

  if (watermarkWidthPlusMarginXIsLarger)
    result.maxWidthWatermark =
      maxWidthWatermark - (maxWidthWatermark + marginX - maxWidth);

  return result;
};
