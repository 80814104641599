import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Row, Col, Icon, Dropdown, Menu as AntMenu, Drawer } from 'antd';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-regular-svg-icons';
import { Link } from 'react-router-dom';

import { Creators as GalleryActions } from '../../../../store/ducks/gallery';

import {
  notifyWarn,
  notifySuccess,
  notifyBackendError
} from '../../../../utils/notificationService';

import Api from '../../../../services/api';
import { usePlan } from '../../../../services/plan';
import { sendMail, sendWhatsAppMessage } from '../../../../services/share';

import {
  InactiveFollowup,
  SelectionPreferences,
  SelectionPreferencesBody,
  AboutTitle,
  FormEdit,
  Label2,
  EditGroup,
  EditField,
  FormSwitch,
  LinkShare,
  LinkShareTitle,
  LinkShareSubTitle,
  LinkShareContainer,
  LinkShareField,
  SelectionPreferencesMenu,
  SelectionPreferencesForm,
  CreateLinkShareTitle,
  CreateLinkShareSubTitle,
  CreateLinkShareContainer,
  CreateLinkShareInfo
} from './styles';

import { Portlet } from '../../../../styles/components';

import OpenPortlet from './Portlets/Open';
import ExpiredPortlet from './Portlets/Expired';
import DonePortlet from './Portlets/Done';

import Divider from '../../../../components/Divider';
import Loading from '../../../../components/Loading';
import EditDialog from '../../../../components/EditDialog';
import Switch from '../../../../components/Form/Switch';
import PopConfirm from '../../../../components/PopConfirm';
import Button from '../../../../components/Button';
import Pill from '../../../../components/Pill';
import Share from '../../../../components/Gallery/Share';

import Export from '../Export';

import photoIcon from '../../../../assets/ativar_provas.png';

const FollowUp = (props) => {
  const { collection_id } = props.match.params;
  const galleryInfo = useSelector((state) => state.gallery.info);

  const dispatch = useDispatch();

  const [shareDrawerVisibility, setShareDrawerVisibility] = useState(false);

  const [selections, setSelections] = useState([]);
  const [loading, setLoading] = useState(true);
  const { checkSolution, triggerPlanChange } = usePlan();
  const [selectionSettings, setSelectionSettings] = useState({});
  const [privateClient, setPrivateClient] = useState({});

  const getSelectionSettings = async () => {
    const response = await Api.get(
      `/selections/gallery/${collection_id}/link-preferences`
    );
    setSelectionSettings(response.data);
  };

  const getSelections = async () => {
    const response = await Api.get(
      `/selections/list?collection_id=${collection_id}`
    );
    const { selections } = response.data;
    setSelections(selections);
  };

  const getData = async () => {
    await getSelections();
    await getSelectionSettings();
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const handleChangeSelectionSettings = async (key, value) => {
    try {
      await Api.put(`/selections/gallery/${collection_id}/link-preferences`, {
        [key]: value
      });

      setSelectionSettings({
        ...selectionSettings,
        [key]: value
      });

      notifySuccess('A informação foi alterada com sucesso!');

      return true;
    } catch (error) {
      return error;
    }
  };

  const handleMakeSelectionInactive = async (selectionId) => {
    try {
      dispatch(GalleryActions.setInfo({ selection_activated: false }));
      props.history.push(`/gallery/${collection_id}/selection/preferences`);

      if (selectionId) {
        await Api.delete(`/selections/${selectionId}`);
      }

      await Api.put(`/selections/gallery/${collection_id}/link-preferences`, {
        active: false,
        type: null
      });
    } catch (error) {
      notifyBackendError(error);
      dispatch(GalleryActions.setInfo({ selection_activated: true }));
      props.history.push(`/gallery/${collection_id}/selection/followup`);
    }
  };

  const handleSellSelectionSettingsPictures = async (value) => {
    const isEnabled = checkSolution('gallery_limits', 'buy_photos');

    if (!isEnabled) {
      return await triggerPlanChange('feat', 'buy_photos');
    }

    const oldSelectionSettingsState = { ...selectionSettings };

    setSelectionSettings({
      ...selectionSettings,
      selling_photos: value,
      contracted_pictures: null,
      extra_picture_price: null
    });

    try {
      await Api.put(`/selections/gallery/${collection_id}/link-preferences`, {
        allow_extra_pictures: value,
        contracted_pictures: null,
        extra_picture_price: null
      });

      notifySuccess('Informação alterada com sucesso!');
    } catch (error) {
      notifyWarn('Algo deu errado');
      setSelections(oldSelectionSettingsState);
    }
  };

  const handleSellPictures = async (selectionId, value) => {
    const isEnabled = checkSolution('gallery_limits', 'buy_photos');

    if (!isEnabled) {
      return await triggerPlanChange('feat', 'buy_photos');
    }

    const oldSelectionState = selections;

    const newSelectionState = selections.map((selection) => {
      if (selection.id === selectionId) {
        return {
          ...selection,
          allow_extra_pictures: value,
          contracted_pictures: null,
          extra_picture_price: null
        };
      } else {
        return selection;
      }
    });

    setSelections(newSelectionState);

    try {
      await Api.put(`/selections/${selectionId}`, {
        allow_extra_pictures: value,
        contracted_pictures: null,
        extra_picture_price: null
      });

      notifySuccess('Informação alterada com sucesso!');
    } catch (error) {
      notifyWarn('Algo deu errado');
      setSelections(oldSelectionState);
    }
  };

  const handleOpenOverSelection = async (selectionId) => {
    try {
      await Api.put(`/selections/${selectionId}`, {
        status: 0
      });
      getSelections();
      notifySuccess('Seleção reaberta com sucesso!');
    } catch (error) {
      notifyWarn('Algo deu errado');
    }
  };

  const handleExportSelection = async (selectionId) => {
    const isEnabled = checkSolution('gallery_limits', 'export_selected_list');

    if (!isEnabled) {
      return await triggerPlanChange('feat', 'export_selected_list');
    }

    const selection = selections.find(
      (selection) => selection.id === selectionId
    );

    Export.open({
      modalVis: true,
      collection_id: selection.sel_collection_id,
      selection_id: selectionId,
      selection_hash: selection.sel_hash
    });
  };

  const handleDeleteSelection = async (selectionId) => {
    try {
      await Api.delete(`/selections/${selectionId}`);
      getSelections();
      notifySuccess('Seleção deletada com sucesso!');
    } catch (error) {
      notifyWarn('Algo deu errado');
    }
  };

  const handleShare = (selectionId) => {
    if (selections.length && selectionSettings.sel_link_type === 'private') {
      const selectedSelection =
        selections.find((selection) => selection.id === selectionId) || {};

      setPrivateClient({
        name: selectedSelection.client_name,
        email: selectedSelection.client_email,
        tel: selectedSelection.client_tel,
        first_password: selectedSelection.client_first_password,
        homeFPMagicLink:
          selectedSelection.magic_link_enabled &&
          (selectedSelection.sel_old_selection_link ||
            selectedSelection.home_fp_magic_link)
      });
    }

    setShareDrawerVisibility(true);
  };

  const handleToggleMagicLink = async (selectionId, value) => {
    const oldSelectionState = selections;

    const newSelectionState = selections.map((selection) => {
      if (selection.id === selectionId) {
        return {
          ...selection,
          magic_link_enabled: value
        };
      }

      return selection;
    });

    setSelections(newSelectionState);

    try {
      await Api.put(`/selections/${selectionId}`, {
        magic_link_enabled: value
      });
      notifySuccess('Permissão atualizada com sucesso!');
    } catch (error) {
      notifyWarn('Algo deu errado');
      setSelections(oldSelectionState);
    }
  };

  const selectionPreferencesMenu = (
    <AntMenu>
      <AntMenu.Item key="0" onClick={() => handleMakeSelectionInactive()}>
        Desativar seleção
      </AntMenu.Item>
    </AntMenu>
  );

  const edit = async (key, value, selectionId) => {
    let status;
    const newSelectionState = selections.map((selection) => {
      if (selection.id === selectionId) {
        if (key === 'limit_date' && !value) {
          selection.status = 'open';
          status = 0;
        } else if (
          key === 'limit_date' &&
          value < new Date().setHours(0, 0, 0, 0)
        ) {
          selection.status = 'expired';
          status = 0;
        } else if (
          key === 'limit_date' &&
          value >= new Date().setHours(0, 0, 0, 0)
        ) {
          selection.status = 'open';
          status = 0;
        }

        return {
          ...selection,
          [key]: value
        };
      } else {
        return selection;
      }
    });

    try {
      if (status) {
        await Api.put(`/selections/${selectionId}`, {
          [key]: value,
          status
        });
      } else {
        await Api.put(`/selections/${selectionId}`, {
          [key]: value
        });
      }

      notifySuccess('A informação foi alterada com sucesso!');

      setSelections(newSelectionState);
      return true;
    } catch (error) {
      return error;
    }
  };

  const onConfirm = async (data) => {
    // Send mail
    if (data.shareMode === 'email') {
      await sendMail({
        client_email: data.recipientEmail,
        client_message: data.message,
        client_name: data.recipientName,
        client_password: data.recipientPassword,
        subject: data.subject,
        collection_id,
        magicLink: data.magicLink
      });
      notifySuccess('E-mail enviado com sucesso!');
    }

    // Send whatsapp message
    if (data.shareMode === 'whatsapp') {
      await sendWhatsAppMessage({
        galleryUrl: galleryInfo.url,
        message: data.message,
        tel: data.recipientTel,
        client_email: data.recipientEmail,
        client_password: data.recipientPassword,
        privateSelection: selectionSettings.sel_link_type === 'private',
        magicLink: data.magicLink
      });
    }

    setShareDrawerVisibility(false);
  };

  const handleOpenConfirmSellPicturesModal = (value) => {
    PopConfirm.open({
      onConfirm: () => handleSellSelectionSettingsPictures(value),
      info: `Ao desativar a venda de fotos a quantidade e o valor das fotos serão
      zerados. Tem certeza que deseja continuar?`,
      waitConfirmPromise: false
    });
  };

  const handleCreateNewSection = async () => {
    const isEnabled = checkSolution(
      'gallery_limits',
      'multiple_private_selection'
    );

    if (!isEnabled) {
      return await triggerPlanChange('feat', 'multiple_private_selection');
    }

    props.history.push(`/gallery/${collection_id}/selection/preferences`);
  };

  return (
    <Loading loading={loading}>
      <Drawer
        onClose={() => setShareDrawerVisibility(false)}
        visible={shareDrawerVisibility}
        destroyOnClose={true}
        width={window.innerWidth < 1000 ? '100%' : 1000}
      >
        <Share
          canChooseClient={selectionSettings.sel_link_type === 'public'}
          confirmText="ENVIAR"
          defaultSubject="Fotos disponíveis para seleção de fotos"
          defaultMessage="As suas fotos já estão disponíveis!"
          recipientName={privateClient.name}
          recipientEmail={privateClient.email}
          recipientTel={privateClient.tel}
          recipientPassword={privateClient.first_password}
          privateSelection={selectionSettings.sel_link_type === 'private'}
          magicLink={privateClient.homeFPMagicLink}
          magicLinkEnabled={!!privateClient.homeFPMagicLink}
          onConfirm={onConfirm}
        />
      </Drawer>

      {selectionSettings.sel_link_type === 'public' && (
        <>
          <SelectionPreferences>
            <AboutTitle>PREFERÊNCIAS DA SELEÇÃO DE FOTOS</AboutTitle>
            <SelectionPreferencesBody>
              <SelectionPreferencesForm>
                <FormEdit>
                  <Label2>Prazo limite</Label2>
                  <EditGroup>
                    <EditDialog
                      name="Prazo limite"
                      value={
                        selectionSettings.limit_date
                          ? moment(
                              new Date(selectionSettings.limit_date).setHours(
                                0,
                                0,
                                0,
                                0
                              )
                            )
                          : null
                      }
                      onSave={(value) =>
                        handleChangeSelectionSettings('limit_date', value)
                      }
                      type="datepicker"
                    >
                      <EditField>
                        {selectionSettings.limit_date
                          ? moment(selectionSettings.limit_date).format('L')
                          : 'Sem prazo'}
                      </EditField>
                      <Icon type="edit" />
                    </EditDialog>
                  </EditGroup>
                </FormEdit>

                <FormSwitch>
                  <Label2>Venda de fotos</Label2>
                  <Switch
                    checked={selectionSettings.selling_photos}
                    onChange={(value) => {
                      if (value === false) {
                        handleOpenConfirmSellPicturesModal(value);
                      } else {
                        handleSellSelectionSettingsPictures(value);
                      }
                    }}
                  />
                </FormSwitch>

                {selectionSettings.selling_photos && (
                  <>
                    <FormEdit>
                      <Label2>Quantidade contratada</Label2>
                      <EditGroup>
                        <EditDialog
                          name="Quantidade contratada"
                          value={
                            selectionSettings.contracted_pictures
                              ? selectionSettings.contracted_pictures
                              : 0
                          }
                          onSave={(value) =>
                            handleChangeSelectionSettings(
                              'contracted_pictures',
                              value
                            )
                          }
                          inputName="contractedPictures"
                        >
                          <EditField>
                            {selectionSettings.contracted_pictures
                              ? selectionSettings.contracted_pictures
                              : 0}
                          </EditField>
                          <Icon type="edit" />
                        </EditDialog>
                      </EditGroup>
                    </FormEdit>

                    <FormEdit>
                      <Label2>Valor de cada foto</Label2>
                      <EditGroup>
                        <EditDialog
                          name="Valor de cada foto"
                          value={
                            selectionSettings.extra_picture_price
                              ? selectionSettings.extra_picture_price
                              : 0
                          }
                          onSave={(value) =>
                            handleChangeSelectionSettings(
                              'extra_picture_price',
                              value
                            )
                          }
                          inputName="extraPicturePrice"
                          type="bill"
                        >
                          <EditField>
                            {selectionSettings.extra_picture_price
                              ? 'R$ ' +
                                (selectionSettings.extra_picture_price / 100)
                                  .toFixed(2)
                                  .replace('.', ',')
                              : 'R$ 0,00'}
                          </EditField>
                          <Icon type="edit" />
                        </EditDialog>
                      </EditGroup>
                    </FormEdit>
                  </>
                )}

                {!selectionSettings.selling_photos && (
                  <FormEdit>
                    <Label2>Quantidade máxima de fotos</Label2>
                    <EditGroup>
                      <EditDialog
                        name="Quantidade máxima de fotos"
                        value={
                          selectionSettings.contracted_pictures
                            ? selectionSettings.contracted_pictures
                            : 0
                        }
                        onSave={(value) =>
                          handleChangeSelectionSettings(
                            'contracted_pictures',
                            value
                          )
                        }
                        inputName="maxPictures"
                      >
                        <EditField>
                          {selectionSettings.contracted_pictures
                            ? selectionSettings.contracted_pictures
                            : 'Sem limite'}
                        </EditField>
                        <Icon type="edit" />
                      </EditDialog>
                    </EditGroup>
                  </FormEdit>
                )}
              </SelectionPreferencesForm>
              <SelectionPreferencesMenu>
                <Dropdown
                  overlay={selectionPreferencesMenu}
                  trigger={['click']}
                >
                  <Icon type="more" style={{ fontSize: 25 }} />
                </Dropdown>
              </SelectionPreferencesMenu>
            </SelectionPreferencesBody>
          </SelectionPreferences>

          <LinkShare>
            <LinkShareTitle>
              Envie o link para os seus clientes iniciarem a escolha das fotos
            </LinkShareTitle>

            <LinkShareSubTitle>
              A partir desse link, o seu cliente poderá fazer um simples
              cadastro e iniciar uma seleção de fotos seguindo as preferências
              definidas por você.
            </LinkShareSubTitle>

            <Divider size="medium" />

            <LinkShareContainer>
              <LinkShareField style={{ width: 'auto' }}>
                <a href={galleryInfo.url} target="_blank">
                  {galleryInfo.url}
                </a>
              </LinkShareField>
              <Button type="primary" border="rightButton" onClick={handleShare}>
                <i style={{ marginRight: '8px' }}>
                  <FontAwesomeIcon icon={faPaperPlane} />
                </i>
                ENVIAR
              </Button>
            </LinkShareContainer>
          </LinkShare>
        </>
      )}

      {selections.length ? (
        <AboutTitle>
          {selectionSettings.sel_link_type === 'private'
            ? 'ACOMPANHAMENTO DA SELEÇÃO DE FOTOS'
            : 'SELEÇÕES DESSA GALERIA'}
        </AboutTitle>
      ) : null}

      {selections.length ? (
        selections.map((selection) => {
          if (selection.status === 'open') {
            return (
              <>
                <OpenPortlet
                  totalSelected={selection.selected_pictures.length}
                  sellingPhotos={selection.allow_extra_pictures}
                  limitDate={selection.limit_date}
                  lastAccess={selection.last_access}
                  contractedPictures={selection.contracted_pictures}
                  maxPictures={selection.contracted_pictures}
                  picturePrice={selection.extra_picture_price}
                  clientName={selection.client_name}
                  clientMail={selection.client_email}
                  clientTel={selection.client_tel}
                  selectionId={selection.id}
                  summaryUrl={selection.sel_summary_link}
                  handleSellPictures={handleSellPictures}
                  handleDeleteSelection={handleDeleteSelection}
                  selOldSelectionLink={selection.sel_old_selection_link}
                  edit={edit}
                  selLinkType={selectionSettings.sel_link_type}
                  clientFirstPassword={selection.client_first_password}
                  magicLinkEnabled={selection.magic_link_enabled}
                  homeFPMagicLink={selection.home_fp_magic_link}
                  galleryShareUrl={galleryInfo.url}
                  handleShare={handleShare}
                  handleMakeSelectionInactive={handleMakeSelectionInactive}
                  handleToggleMagicLink={handleToggleMagicLink}
                />
                <Divider size="medium" />
              </>
            );
          } else if (selection.status === 'expired') {
            return (
              <>
                <ExpiredPortlet
                  totalSelected={selection.selected_pictures.length}
                  sellingPhotos={selection.allow_extra_pictures}
                  limitDate={selection.limit_date}
                  lastAccess={selection.last_access}
                  contractedPictures={selection.contracted_pictures}
                  maxPictures={selection.contracted_pictures}
                  picturePrice={selection.extra_picture_price}
                  clientName={selection.client_name}
                  clientMail={selection.client_email}
                  clientTel={selection.client_tel}
                  selectionId={selection.id}
                  summaryUrl={selection.sel_summary_link}
                  handleSellPictures={handleSellPictures}
                  handleExportSelection={handleExportSelection}
                  handleDeleteSelection={handleDeleteSelection}
                  selOldSelectionLink={selection.sel_old_selection_link}
                  edit={edit}
                  selLinkType={selectionSettings.sel_link_type}
                  handleMakeSelectionInactive={handleMakeSelectionInactive}
                  clientFirstPassword={selection.client_first_password}
                  magicLinkEnabled={selection.magic_link_enabled}
                  homeFPMagicLink={selection.home_fp_magic_link}
                  galleryShareUrl={galleryInfo.url}
                  handleShare={handleShare}
                  handleMakeSelectionInactive={handleMakeSelectionInactive}
                  handleToggleMagicLink={handleToggleMagicLink}
                />
                <Divider size="medium" />
              </>
            );
          } else if (selection.status === 'done') {
            return (
              <>
                <DonePortlet
                  totalSelected={selection.selected_pictures.length}
                  sellingPhotos={selection.allow_extra_pictures}
                  limitDate={selection.limit_date}
                  lastAccess={selection.last_access}
                  contractedPictures={selection.contracted_pictures}
                  maxPictures={selection.contracted_pictures}
                  picturePrice={selection.extra_picture_price}
                  clientName={selection.client_name}
                  clientMail={selection.client_email}
                  clientTel={selection.client_tel}
                  selectionId={selection.id}
                  summaryUrl={selection.sel_summary_link}
                  handleSellPictures={handleSellPictures}
                  handleOpenOverSelection={handleOpenOverSelection}
                  handleExportSelection={handleExportSelection}
                  handleDeleteSelection={handleDeleteSelection}
                  selOldSelectionLink={selection.sel_old_selection_link}
                  edit={edit}
                  selLinkType={selectionSettings.sel_link_type}
                  handleMakeSelectionInactive={handleMakeSelectionInactive}
                  clientFirstPassword={selection.client_first_password}
                  magicLinkEnabled={selection.magic_link_enabled}
                  homeFPMagicLink={selection.home_fp_magic_link}
                  galleryShareUrl={galleryInfo.url}
                  handleShare={handleShare}
                  handleMakeSelectionInactive={handleMakeSelectionInactive}
                  handleToggleMagicLink={handleToggleMagicLink}
                />
                <Divider size="medium" />
              </>
            );
          } else {
            return null;
          }
        })
      ) : (
        <>
          <AboutTitle>ACOMPANHAMENTO DA SELEÇÃO DE FOTOS</AboutTitle>

          <Portlet>
            <Divider size="medium" />
            <Row justify="center">
              <Col span={12} offset={6}>
                <InactiveFollowup>
                  <img src={photoIcon} alt="foto" />
                  <Divider size="medium" />
                  <p>
                    Nenhuma seleção de fotos foi iniciada para essa galeria.{' '}
                    <br />
                    Aqui serão exibidas todas as informações das seleções assim
                    que forem iniciadas pelo seu cliente.
                  </p>
                  {/*<Divider size="medium" />
                    <Button
                      size="large"
                      type="default"
                    >
                      EU MESMO QUERO CADASTRAR O CLIENTE
                    </Button>*/}
                  <Divider size="medium" />
                </InactiveFollowup>
              </Col>
            </Row>
          </Portlet>
        </>
      )}

      {selectionSettings.sel_link_type === 'private' && (
        <Portlet>
          <CreateLinkShareContainer>
            <CreateLinkShareInfo>
              {!checkSolution(
                'gallery_limits',
                'multiple_private_selection'
              ) && <Pill style={{ marginLeft: 0, marginBottom: 8 }} />}

              <CreateLinkShareTitle>
                Precisa de mais seleções dessa galeria?
              </CreateLinkShareTitle>
              <CreateLinkShareSubTitle>
                Você pode criar quantas seleções desejar. Cada seleção terá
                configurações próprias.
              </CreateLinkShareSubTitle>
            </CreateLinkShareInfo>
            <Button type="default" onClick={handleCreateNewSection}>
              CRIAR NOVA SELEÇÃO PARA ESSA GALERIA
            </Button>
          </CreateLinkShareContainer>
        </Portlet>
      )}
    </Loading>
  );
};

FollowUp.propTypes = {
  collection_id: PropTypes.number.isRequired
};

export default FollowUp;
