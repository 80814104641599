import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  Icon,
  Dropdown,
  Skeleton,
} from 'antd';

import api from '../../../services/api';

import { notifySuccess } from '../../../utils/notificationService';

import {
  Container,
  SelectContainer,
  SelectFieldContainer,
  ClientName,
  ClientInfo,
  EditClientText,
  EditableContainer,
} from './styles';

import Small from '../../Form/Small';
import ChangeClientModal from '../ChangeClientModal';
import EditDialog from '../../EditDialog';

import ClientList from './ClientList';

const placeholderValue = {
  id: null,
  name: 'Selecione um cliente',
  tel: 'telefone/whatsapp',
  email: 'e-mail',
  extradata: { first_password: null },
  selected: false,
  homeFPMagicLink: null,
};

const ClientSelect = (props) => {
  const {
    onSelect,
    disabled,
    editable,
    editableText,
    collectionId,
  } = props;

  const [clients, setClients] = useState([]);
  const [getClientLoading, setGetClientLoading] = useState(false);

  const [selectedClient, setSelectedClient] = useState(placeholderValue);
  const [isClientSelected, setIsClientSelected] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const handleVisibleChange = (flag) => {
    setDropdownVisible(flag);
  };

  const getClients = async () => {
    setGetClientLoading(true);

    const response = await api.get(`/customer/clients?collection_id=${collectionId}`).catch(() => {});

    setClients(response.data.clients);

    setGetClientLoading(false);
  };

  const handleSelectClient = (client) => {
    setSelectedClient(client);

    setIsClientSelected(true);

    setDropdownVisible(false);

    onSelect(client);
  };

  const handleChangeClientPassword = async (password) => {
    // eslint-disable-next-line
    const customer_client_id = selectedClient.id;

    try {
      // eslint-disable-next-line
      await api.put(`/customer/clients/${customer_client_id}/password`, { password });

      const updatedClient = {
        ...selectedClient,
        extradata: {
          first_password: password,
        },
      };

      setClients(clients.map((client) => {
        if (client.id === selectedClient.id) {
          return updatedClient;
        }

        return client;
      }));

      handleSelectClient(updatedClient);

      notifySuccess('A senha foi alterada com sucesso.');

      return true;
    } catch (error) {
      return error;
    }
  };

  const handleCreateClient = async (clientData) => {
    const response = await api.post(`/customer/clients?collection_id=${collectionId}`, { ...clientData });

    const newClient = response.data;

    setClients([newClient, ...clients]);

    handleSelectClient(newClient);

    notifySuccess('Cliente criado com sucesso!');
  };

  const handleUpdateClient = async (clientData) => {
    const response = await api.put(`/customer/clients/${selectedClient.id}`, { ...clientData });

    const newData = response.data;

    const updatedClientData = {
      ...selectedClient,
      ...newData,
    };

    setClients(clients.map((client) => {
      if (client.id === selectedClient.id) {
        return updatedClientData;
      }

      return client;
    }));

    handleSelectClient(updatedClientData);

    notifySuccess('Os dados do cliente foram atualizados com sucesso!');
  };

  const handleOpenCreateClientModal = () => {
    setDropdownVisible(false);
    ChangeClientModal.open({
      action: 'create',
      onCreate: handleCreateClient,
    });
  };

  const handleOpenUpdateClientModal = () => {
    ChangeClientModal.open({
      action: 'update',
      data: selectedClient,
      onUpdate: handleUpdateClient,
    });
  };

  useEffect(() => {
    getClients();
  }, []);

  return (
    <>
      <Container disabled={disabled}>
        <Dropdown
          overlay={(
            <ClientList
              clients={clients}
              handleSelectClient={handleSelectClient}
              handleOpenCreateClientModal={handleOpenCreateClientModal}
              visible={dropdownVisible}
            />
          )}
          trigger={['click']}
          onVisibleChange={handleVisibleChange}
          visible={dropdownVisible}
          disabled={getClientLoading || disabled}
        >
          <SelectContainer>
            <Skeleton loading={getClientLoading} paragraph={{ rows: 1 }} active avatar>
              <Avatar
                style={{ backgroundColor: isClientSelected && '#39C395' }}
              >
                {isClientSelected && (
                  selectedClient.name
                    .split(' ')
                    .slice(0, 2)
                    .map((name) => name[0])
                    .join('')
                )}
              </Avatar>
              <SelectFieldContainer>
                <ClientName style={{ color: !isClientSelected && '#999' }}>
                  {selectedClient.name}
                </ClientName>
                <ClientInfo style={{ color: !isClientSelected && '#999' }}>
                  {`<${selectedClient.email}>`}{' '}
                  {selectedClient.tel ? ` <${selectedClient.tel}>` : ''}
                </ClientInfo>
              </SelectFieldContainer>
            </Skeleton>
            <Icon
              type="down-circle"
              style={{ marginLeft: 'auto', fontSize: 20 }}
            />
          </SelectContainer>
        </Dropdown>
      </Container>
      {editable && isClientSelected && (
        <EditableContainer>
          <EditClientText onClick={handleOpenUpdateClientModal} underline>
            <Small>{editableText}</Small>
          </EditClientText>
          <EditClientText style={{ margin: '0 5px' }}>
            <Small>
              |
            </Small>
          </EditClientText>
          <EditDialog
            value={selectedClient.extradata && selectedClient.extradata.first_password}
            name="Senha do cliente"
            onSave={(password) => handleChangeClientPassword(password)}
            inputName="password"
            type="password"
          >
            <EditClientText underline>
              <Small>Redefinir senha</Small>
            </EditClientText>
          </EditDialog>
        </EditableContainer>
      )}
    </>
  );
};

ClientSelect.defaultProps = {
  onSelect: () => {},
  editable: true,
  editableText: 'Editar dados do cliente antes de enviar',
  disabled: false,
  collectionId: '',
};

ClientSelect.propTypes = {
  onSelect: PropTypes.func,
  editable: PropTypes.bool,
  editableText: PropTypes.string,
  disabled: PropTypes.bool,
  collectionId: PropTypes.number,
};

export default ClientSelect;
