import axios from 'axios';
import { logout } from './auth';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true
});

api.interceptors.response.use(
  (response) => Promise.resolve(response),
  async (error) => {
    const status = error && error.response && error.response.status;
    const errorName =
      error &&
      error.response &&
      error.response.data &&
      error.response.data.error;

    if (status === 401 && errorName !== 'NotLoggedIn') {
      // In case the user is not authenticated
      return (window.location = `${window.origin}/logout`);
    } else if (status === 403) {
      // In case the user is trying to access something doesn't belong to him
      return (window.location = `${window.origin}/gallery`);
    } else if (status === 402 && errorName === 'InactiveAccount') {
      // In case the user subscription is inactive
      // await logout();
      return (window.location = `${window.origin}/inactive`);
    } else if (status === 402 && errorName === 'MissingPlan') {
      // In case the user has no plan on his account
      await logout();
      return (window.location = `${window.origin}/noplan`);
    }

    return Promise.reject(error);
  }
);

export default api;
