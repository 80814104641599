import React, { useState } from 'react';

import { Container, Content } from './styles';

import Divider from '../../components/Divider';
import Button from '../../components/Button';
import Label from '../../components/Form/Label';
import InfoModal from '../../components/InfoModal';

import api from '../../services/api';
import { seePlans } from '../../services/navigation';

import premiumBlock from '../../assets/premium-block.png';

const SubscriptionExpired = () => {
  const [loading, setLoading] = useState(false);

  const handleExtendTrial = async () => {
    setLoading(true);

    try {
      await api.get('/subscription/bonus/extend-trial');
      InfoModal.success({
        title: "Período de testes extendido!",
        info: "Seu período de testes foi extendido por mais 7 dias!",
        buttonText: "IR PARA PLATAFORMA",
        buttonCallback: () => window.location.href = '/gallery'
      })
    } catch(error) {
      InfoModal.warning({
        title: "Bônus de 7 dias já utilizado",
        info: (
          <>
            Manteremos as suas fotos e galerias por um período de 30 dias, após essa data, esse conteúdo será permanentemente excluído.
            <br />
            Confira os nossos planos!
          </>
        ),
        buttonText: "VER PLANOS",
        buttonCallback: () => seePlans()
      })
    }

    setLoading(false);
  };

  return (
    <Container>
      <Divider size="xlarge" />

      <Content>
        <Divider size="xlarge" />

        <img src={premiumBlock} />

        <Divider size="xlarge" />

        <Label>
          Aumentar duração do período de testes.
        </Label>

        <Divider size="medium" />

        <p>
          Clique no botão abaixo para aproveitar nossa plataforma por mais 7 dias!
        </p>

        <Divider size="xlarge" />

        <Button id="btn_VerPlanos" type="primary" onClick={() => handleExtendTrial()} loading={loading}>
          AUMENTAR PERÍODO DE TESTES EM 7 DIAS
        </Button>

        <Divider size="xlarge" />
      </Content>
    </Container>
  );
};

export default SubscriptionExpired;
