import styled from 'styled-components';

export const Grid = styled.div`
  /*display: flex;
  flex-wrap: wrap;*/
  height: 100%;
  width: 100%;
  display: grid;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: repeat(2, 1fr);
  justify-items: center;

  @media (min-width: 576px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: 992px) {
    grid-template-columns: repeat(5, 1fr);
  }

  @media (min-width: 1200px) {
    grid-template-columns: repeat(6, 1fr);
  }

  @media (min-width: 1600px) {
    grid-template-columns: repeat(10, 1fr);
  }
`;

export const Footer = styled.div`
  border-top: 1px solid #F8F8F8;
  margin-top: 32px;
  padding-top: 16px;
  width: 100%;
  height: 64px;
  position: relative;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const PhotosCounter = styled.div`
  position: relative;
  left: 8px;
`;

export const ImageWrapper = styled.div`
  /*--size: ${(props) => props.gridSize};

  width: calc(100% / var(--size));*/
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: relative;
  margin: 0;

  background-color: #EDEDED;
  display: flex;
  align-items: center;

  span:first-of-type {
    cursor: move;
  }

  .selected{
    background:black;
  }

  &:hover{
    background: linear-gradient(to bottom, rgba(0,0,0,0.28) 0%, rgba(237,237,237,1) 100%);
    span{
      display: block;
    }

    div > div {
      display: flex;
    }
  }

  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }

  /* float: left;
  width: 50%;
  @media (min-width: 576px) {
    width: 33.333333334%;
  }

  @media (min-width: 768px) {
    width: 33.333333334%;
  }

  @media (min-width: 992px) {
    width: 20%;
  }

  @media (min-width: 1200px) {
    width: 16.666666667%;
  }

  @media (min-width: 1600px) {
    width: 10%;
  } */

  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -ms-user-select: none;

  & > div {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: ${(props) => (props.isSelected ? '3px solid #87D068' : '0px')};
  }
`;

export const Image = styled.img`
  position: absolute;
  text-align: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
export const ImageName = styled.span`
  font-size: 12px;
  color: white;
  display: none;
  position: absolute;
  bottom: 0;
  width: 100%;
  background: rgba(0,0,0,0.5);
  text-align: left;
  padding-left: 8px;
`;

export const IconsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const LeftIcons = styled.div`
  display: ${(props) => (props.isSelected ? 'flex' : 'none')};
  flex-direction: row;
  justify-content: center;
  align-items: center;

  i{
    font-size: 90%;
    transition: color 100ms ease-in;
    padding: 5px;
    background: #87d068;
    color: #fff;
    z-index: 1;
    /* color: ${(props) => (props.isSelected ? '#87d068' : '#d6d6d6')}; */
  }

  i:nth-child(2) {
    background: #FF4949;
  }
`;

export const RightIcons = styled.div`
  display: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  i{
    background: rgba(0,0,0,0.5);
    font-size: 90%;
    transition: color 100ms ease-in;
    padding: 5px;
    color: #fff;
    z-index: 1;
  }

  i:hover {
    color: white;
  }

  i:nth-child(2):hover {
    color: #FF4949;
  }

  i:nth-child(3):hover {
    color: ${(props) => (props.isSuggested ? 'white' : '#FFBB52')};
  }
`;

export const ImageColumn = styled.div`
  /*--size: ${(props) => props.gridSize};

  width: 100%;
  height: 100%;
  display: grid;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: repeat(var(--size), 1fr);
  justify-items: center;
  position: relative;
  margin-bottom: 16px;

  transform: ${(props) => props.isDragging ? console.log('dragging') : null};
  overflow: hidden;*/

  &:after {
    content: "";
    clear: both;
    display: table;
  }
`;
