import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';

import CategoriesList from './Categories';
import WorksList from './Works';

import Loading from '../../components/Loading';

import { Creators as PortfolioActions } from '../../store/ducks/portfolio';

import { usePlan } from '../../services/plan';
import api from '../../services/api';

import { Content } from '../../styles/components';

const Portfolio = (props) => {
  const dispatch = useDispatch();
  const { checkSolution, triggerPlanChange } = usePlan();
  const customerSubscription = useSelector(
    (state) => state.customer.user_subscription
  );
  const [loading, setLoading] = useState(true);

  const getData = async () => {
    await api.post('/portfolio').catch(() => {});

    const { data } = await api.get('/portfolio');
    dispatch(PortfolioActions.setUrl({ url: data.url }));

    setLoading(false);
  };

  useEffect(async () => {
    if (customerSubscription) {
      const isEnabled =
        checkSolution('portfolio_limits', 'portfolio') &&
        customerSubscription.can_use_portfolio === true;

      if (!isEnabled) {
        props.history.push('/gallery');
        return await triggerPlanChange('feat', 'portfolio');
      }

      getData();
    }
  }, []);

  return (
    <Content>
      <Loading loading={loading} backgroundColor={null}>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={10} lg={8} xl={6} xxl={5}>
            <CategoriesList {...props} />
          </Col>

          <Col xs={24} sm={24} md={14} lg={16} xl={18} xxl={19}>
            <WorksList {...props} />
          </Col>
        </Row>
      </Loading>
    </Content>
  );
};

Portfolio.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired
};

export default Portfolio;
