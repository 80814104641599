import { makeStyles } from '@material-ui/core';

import colors from '../../styles/colors';

const useStyles = makeStyles((theme) => ({
  upgradeContainer: {
    background: colors.greyScale[11],
    overflow: 'auto',
    height: '100%'
  },
  fontBold: {
    fontWeight: 'bold !important'
  },
  textContentUpgrade: {
    fontWeight: 400,
    color: '#333333'
  },
  pillUpgrade: {
    textTransform: 'uppercase',
    fontSize: '16px',
    lineHeight: '20px'
  },
  contentUpgradeContainer: {
    height: '100%',
    position: 'relative',
    backgroundColor: '#F8F8F8'
  },
  viewUpgradeWrapper: {
    padding: theme.spacing(3, 3, 0),
    width: '100%',
    background: colors.greyScale[11],
    borderBottom: '1px solid #EBF0F2'
  },
  upgradeImageContainer: {
    height: '100%',

    background: 'linear-gradient(180deg, #82D2FF 0%, #2D9CDB 100%)',
    padding: '20px 10px'
  },
  benefitsUpgrade: {
    color: '#786625'
  },
  listItemUpgrade: {
    padding: '1px 0'
  },
  itemTextUpgrade: {
    color: colors.greyScale[3],
    fontWeight: 300,
    fontSize: '16px',
    linHeight: '20px'
  },
  listIconUpgrade: {
    color: colors.palette.confirm,
    minWidth: '0px',
    marginRight: theme.spacing(1.5)
  },
  upgradeIllustration: {
    width: '100%',
    height: 'auto',
    maxHeight: '430px',
    maxWidth: '450px'
  },
  textUpgrade: {
    color: colors.greyScale[11],
    fontWeight: 'bold'
  },
  buttonUpgrade: {
    background: colors.palette.confirm,
    margin: '0 auto',
    fontWeight: 'bold',
    color: colors.greyScale[11],
    '&:hover': {
      background: colors.palette.confirm,
      opacity: 0.8
    }
  },
  loadingButton: {
    color: colors.greyScale[11]
  },
  pillWrapper: {
    padding: theme.spacing(2, 2, 0)
  },
  warningPriceTextUpgradeMain: {
    fontSize: '14px'
  },
  warningPriceTextUpgradeObs: {
    fontSize: '12px',
    padding: theme.spacing(0, 3)
  },
  actionUpgradeWrapper: {
    position: 'absolute',
    bottom: '30px',
    [theme.breakpoints.down('sm')]: {
      bottom: '0px',
      position: 'relative',
      padding: theme.spacing(4, 2)
    }
  },
  headerSuccessDialog: {
    background: 'linear-gradient(180deg, #82D2FF 0%, #2D9CDB 100%)',
    borderRadius: '4px 4px 0px 0px',

    padding: theme.spacing(5)
  },
  textHeaderSuccessDialog: {
    fontSize: '22px',
    lineHeight: '30px',

    color: colors.greyScale[11]
  },
  contentSuccessDialog: {
    padding: theme.spacing(5)
  },
  textWarningSuccessDialog: {
    fontSize: '12px'
  }
}));

export default useStyles;
