import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import {
  Box,
  Grid,
  Typography,
  Slider,
  CircularProgress,
  Fade,
  Fab,
  useMediaQuery,
  useTheme
} from '@material-ui/core';

import { CloudUploadOutlined as UploadIcon } from '@material-ui/icons';

import Dropzone from '../../Dropzone';
import Divider from '../../Divider';

import GridPosition from '../components/GridPosition';

import useStyles from './styles';
import { calculateMaxWidthAndMaxHeight, positions } from '../utils/image';

const newWatermarkId = 'new-watermark-preview';
const previewBoxId = 'preview-box';

const EditWatermark = (props) => {
  const { onChange } = props;

  const [uploadLoading, setUploadLoading] = useState(false);

  const [watermarkBlobUrl, setWatermarkBlobUrl] = useState(null);
  const [watermarkPosition, setWatermarkPosition] = useState(5);
  const [watermarkSize, setWatermarkSize] = useState(null);
  const [watermarkMargin, setWatermarkMargin] = useState(null);

  const [showWatermarkPlaceholder, setShowWatermarkPlaceholder] = useState(
    false
  );

  const [isViewPanelContainerActive, setIsPanelContainerActive] = useState(
    false
  );

  const MDTheme = useTheme();
  const isSmall = useMediaQuery(MDTheme.breakpoints.down('sm'));
  const classes = useStyles({
    watermarkBlobUrl,
    isViewPanelContainerActive,
    isSmall
  });

  const previewBoxElement = document.getElementById(previewBoxId);
  const watermarkElement = document.getElementById(newWatermarkId);

  const watermarkElementRef = useRef(null);

  const onDrop = async ([file]) => {
    setUploadLoading(true);

    const blobURL = URL.createObjectURL(file);

    onChange({
      media: blobURL,
      watermarkData: {
        settings: {
          position_image: watermarkPosition,
          size_image: watermarkSize,
          margin_image: watermarkMargin
        }
      }
    });
    setWatermarkBlobUrl(blobURL);

    setUploadLoading(false);
  };

  const changeWatermarkSizeTemp = (value) => {
    setWatermarkSize(value);
  };

  const changeWatermarkMarginTemp = (value) => {
    setWatermarkMargin(value);
  };

  const handleChangeSettings = (type, value) => {
    const options = {
      position_image: handleChangePosition,
      size_image: handleChangeSize,
      margin_image: handleChangeMargin
    };

    options[type](value);

    onChange({
      media: watermarkBlobUrl,
      watermarkData: {
        settings: {
          position_image: watermarkPosition,
          size_image: watermarkSize,
          margin_image: watermarkMargin,
          [type]: value
        }
      }
    });
  };

  const handleChangePosition = (newPosition) => {
    setWatermarkPosition(newPosition);
  };

  const handleChangeSize = (newSize) => {
    const previewBoxWidth = previewBoxElement && previewBoxElement.offsetWidth;
    const previewBoxHeight =
      previewBoxElement && previewBoxElement.offsetHeight;

    let maxHeightWatermark = Math.floor((newSize / 100) * previewBoxHeight);
    let maxWidthWatermark = Math.floor((newSize / 100) * previewBoxWidth);

    const naturalHeightWatermark = watermarkElement.naturalHeight;
    const naturalWidthWatermark = watermarkElement.naturalWidth;

    let heightWatermark = '100%';
    let widthWatermark = '100%';

    if (naturalWidthWatermark > naturalHeightWatermark) {
      heightWatermark = 'auto';
      widthWatermark = '100%';
    } else {
      heightWatermark = '100%';
      widthWatermark = 'auto';
    }

    if (watermarkMargin) {
      // The margin is built in proportion to the size of the image,
      // to prevent images with smaller dimensions from being harmed

      const marginY = [4, 6].includes(watermarkPosition)
        ? 0
        : Math.floor((watermarkMargin / 100) * previewBoxHeight);
      const marginX = [2, 8].includes(watermarkPosition)
        ? 0
        : Math.floor((watermarkMargin / 100) * previewBoxWidth);

      const heightParams = {
        maxHeightWatermark,
        marginY,
        maxHeight: previewBoxHeight
      };

      const widthParams = {
        maxWidthWatermark,
        marginX,
        maxWidth: previewBoxWidth
      };

      const newMaxSizeWatermark = calculateMaxWidthAndMaxHeight(
        heightParams,
        widthParams
      );

      maxHeightWatermark = newMaxSizeWatermark.maxHeightWatermark;
      maxWidthWatermark = newMaxSizeWatermark.maxWidthWatermark;
    }

    if (watermarkElementRef.current) {
      watermarkElementRef.current.style.maxHeight = `${maxHeightWatermark}px`;
      watermarkElementRef.current.style.maxWidth = `${maxWidthWatermark}px`;

      watermarkElementRef.current.style.height = `${heightWatermark}`;
      watermarkElementRef.current.style.width = `${widthWatermark}`;
    }

    setWatermarkSize(newSize);
  };

  const handleChangeMargin = (newMargin) => {
    watermarkElementRef.current &&
      handleChangeSettings('size_image', watermarkSize || 20);

    setWatermarkMargin(newMargin);

    if (watermarkElementRef.current) {
      if (!newMargin) {
        watermarkElementRef.current.style.margin = 'auto';
        return;
      }

      const previewBoxWidth =
        previewBoxElement && previewBoxElement.offsetWidth;
      const previewBoxHeight =
        previewBoxElement && previewBoxElement.offsetHeight;

      let maxWidthWatermark = watermarkElement && watermarkElement.clientWidth;
      let maxHeightWatermark =
        watermarkElement && watermarkElement.clientHeight;

      // The margin is built in proportion to the size of the image,
      // to prevent images with smaller dimensions from being harmed

      const marginY = [4, 6].includes(watermarkPosition)
        ? 0
        : Math.floor((newMargin / 100) * previewBoxHeight);
      const marginX = [2, 8].includes(watermarkPosition)
        ? 0
        : Math.floor((newMargin / 100) * previewBoxWidth);

      const maxHeight = Math.floor((watermarkSize / 100) * previewBoxHeight);
      const maxWidth = Math.floor((watermarkSize / 100) * previewBoxWidth);

      const heightParams = {
        maxHeightWatermark,
        marginY,
        maxHeight
      };

      const widthParams = {
        maxWidthWatermark,
        marginX,
        maxWidth
      };

      const newMaxSizeWatermark = calculateMaxWidthAndMaxHeight(
        heightParams,
        widthParams
      );

      watermarkElementRef.current.style.maxHeight = `${newMaxSizeWatermark.maxHeightWatermark}px`;
      watermarkElementRef.current.style.maxWidth = `${newMaxSizeWatermark.maxWidthWatermark}px`;

      const marginOptions = {
        1: `${marginY}px auto auto ${marginX}px`,
        2: `${marginY}px auto auto`,
        3: `${marginY}px ${marginX}px auto auto`,
        4: `auto auto auto ${marginX}px`,
        6: `auto ${marginX}px auto auto`,
        7: `auto auto ${marginY}px ${marginX}px`,
        8: `auto auto ${marginY}px`,
        9: `auto ${marginX}px ${marginY}px auto`,
        default: `auto`
      };

      watermarkElementRef.current.style.margin =
        marginOptions[watermarkPosition] || marginOptions.default;

      return;
    }
  };

  const initializeWatermarkProps = () => {
    if (watermarkPosition === 5) {
      handleChangeSettings('margin_image', null);
    } else {
      handleChangeSettings('margin_image', 0);
    }

    if (watermarkElement) {
      const previewBoxWidth =
        previewBoxElement && previewBoxElement.offsetWidth;
      const previewBoxHeight =
        previewBoxElement && previewBoxElement.offsetHeight;

      const maxSizeRaw = 20;

      const maxSizeInPercentage = maxSizeRaw / 100;

      const maxHeightWatermark = Math.floor(
        maxSizeInPercentage * previewBoxHeight
      );
      const maxWidthWatermark = Math.floor(
        maxSizeInPercentage * previewBoxWidth
      );

      const naturalHeightWatermark = watermarkElement.naturalHeight;
      const naturalWidthWatermark = watermarkElement.naturalWidth;

      let heightWatermark = '100%';
      let widthWatermark = '100%';

      if (naturalWidthWatermark > naturalHeightWatermark) {
        heightWatermark = 'auto';
        widthWatermark = '100%';
      } else {
        heightWatermark = '100%';
        widthWatermark = 'auto';
      }

      watermarkElementRef.current.style.width = `${widthWatermark}`;
      watermarkElementRef.current.style.height = `${heightWatermark}`;

      watermarkElementRef.current.style.maxWidth = `${maxWidthWatermark}px`;
      watermarkElementRef.current.style.maxHeight = `${maxHeightWatermark}px`;

      setWatermarkSize(maxSizeRaw);

      onChange({
        media: watermarkBlobUrl,
        watermarkData: {
          settings: {
            position_image: watermarkPosition,
            size_image: maxSizeRaw,
            margin_image: watermarkMargin
          }
        }
      });
    }
  };

  const onWatermarkEnter = () => {
    setShowWatermarkPlaceholder(true);
  };

  const onWatermarkLeave = () => {
    setShowWatermarkPlaceholder(false);
  };

  const handleToggleViewPanelContainer = () => {
    setIsPanelContainerActive(!isViewPanelContainerActive);
  };

  useEffect(() => {
    if (watermarkPosition === 5) {
      handleChangeSettings('margin_image', null);
    } else {
      handleChangeSettings('margin_image', watermarkMargin || 0);
    }
  }, [watermarkPosition]);

  useEffect(() => {
    if (watermarkPosition === 5) {
      watermarkElementRef.current &&
        handleChangeSettings('size_image', watermarkSize || 20);
    }
  }, [watermarkMargin]);

  useEffect(() => {
    watermarkElementRef.current &&
      handleChangeSettings('size_image', watermarkSize || 20);
  }, [isViewPanelContainerActive]);

  return (
    <Grid container spacing={2} className={classes.editWatermarkContainer}>
      <Grid item xs={12}>
        <Grid
          container
          className={classes.viewEditPanelContainer}
          alignItems="center"
          justify="center"
          alignContent={'center'}
        >
          <Fab
            className={classes.viewEditPanelButton}
            variant="extended"
            size="small"
            aria-label="add"
            onClick={handleToggleViewPanelContainer}
          >
            {isViewPanelContainerActive ? 'Editar' : 'Visualizar'}
          </Fab>
        </Grid>
      </Grid>

      <Grid item xs={12} md={3} className={classes.panelEditorContainer}>
        <Grid
          container
          justify="space-between"
          alignContent="center"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12} md={12}>
            <Dropzone onDrop={onDrop}>
              <Grid
                container
                justify="center"
                alignContent="center"
                alignItems="center"
                className={classes.uploadContainer}
                onMouseEnter={onWatermarkEnter}
                onMouseLeave={onWatermarkLeave}
              >
                {uploadLoading ? (
                  <CircularProgress size={45} color="primary" />
                ) : watermarkBlobUrl ? (
                  <>
                    <img
                      src={watermarkBlobUrl}
                      alt="Watermark Preview"
                      className={classes.previewNewWatermark}
                    />

                    <Fade in={showWatermarkPlaceholder}>
                      <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                        className={classes.watermarkPlaceholder}
                      >
                        <UploadIcon
                          color="primary"
                          className={classes.watermarkPlaceholderIcon}
                        />
                        <Typography
                          className={classes.watermarkPlaceholderText}
                          color="primary"
                        >
                          Alterar foto
                        </Typography>
                      </Grid>
                    </Fade>
                  </>
                ) : (
                  <>
                    <Grid item xs={12}>
                      <UploadIcon
                        color="primary"
                        className={classes.uploadIcon}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Typography color="primary">
                        SUBIR MARCA D’ÁGUA
                      </Typography>
                    </Grid>
                  </>
                )}
              </Grid>
            </Dropzone>
          </Grid>

          <Divider size="large" />

          <Grid item xs={12} md={12}>
            <Box className={classes.boxIsBlocked}>
              <Grid container>
                <Grid item xs={5}>
                  <Typography variant="h3" className={classes.titleItem}>
                    Posição
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <GridPosition
                    size="small"
                    currentPosition={watermarkPosition}
                    onChange={(newPosition) =>
                      handleChangeSettings('position_image', newPosition)
                    }
                    disabled={!watermarkBlobUrl}
                  />
                </Grid>
              </Grid>

              <Divider size="medium" />

              <Grid container>
                <Grid item xs={5}>
                  <Typography variant="h3" className={classes.titleItem}>
                    Tamanho
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Slider
                    color="secondary"
                    value={watermarkSize}
                    onChange={(_, value) => changeWatermarkSizeTemp(value)}
                    onChangeCommitted={(_, value) =>
                      handleChangeSettings('size_image', value)
                    }
                    step={5}
                    min={10}
                    max={100}
                    disabled={watermarkSize === null}
                    className={classes.sliderAction}
                  />
                </Grid>
              </Grid>

              <Divider size="medium" />

              <Grid container>
                <Grid item xs={5}>
                  <Typography variant="h3" className={classes.titleItem}>
                    Margem
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <Slider
                    color="secondary"
                    value={watermarkMargin}
                    onChange={(_, value) => changeWatermarkMarginTemp(value)}
                    onChangeCommitted={(_, value) =>
                      handleChangeSettings('margin_image', value)
                    }
                    step={1}
                    min={0}
                    max={30}
                    disabled={watermarkMargin === null}
                    className={classes.sliderAction}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        md={9}
        className={[classes.boxIsBlocked, classes.previewContainer]}
      >
        <Typography>Pré-visualização</Typography>

        <Divider size="small" />

        <Box id={previewBoxId} className={classes.previewBox}>
          {watermarkBlobUrl && (
            <img
              ref={watermarkElementRef}
              id={newWatermarkId}
              src={watermarkBlobUrl}
              alt="Nova Watermark"
              className={classes.newWatermark}
              style={{ ...positions[watermarkPosition] }}
              onLoad={initializeWatermarkProps}
            />
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

EditWatermark.propTypes = {
  onChange: PropTypes.func.isRequired
};

export default EditWatermark;
