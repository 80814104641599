// Action Types
export const Types = {
  SET_USER_DATA: 'customer/SET_USER_DATA',
  SET_USER_SUBSCRIPTION: 'customer/SET_USER_SUBSCRIPTION',
  SET_USER_USAGE: 'customer/SET_USER_USAGE'
};

// Reducer
const initialState = {
  user_data: {
    display_name: '...',
    display_email: '...',
    flags: {},
    firstAccess: false,
    isTester: false
  },
  user_subscriptions: {
    gallery_limits: {},
    knowledge_limits: {},
    proof_albums_limits: {},
    expiration_date: null,
    expiration_days: null,
    expired: null,
    active: null,
    trial: null,
    plan_ids: [],
    plan_map: {},
    can_use_portfolio: null,
    isGalleryProFreeCustomerBlocked: false
  },
  user_usage: {
    galleries: null,
    gallery_space: null,
    gallery_pictures: null
  }
};

const customer = (state = initialState, action) => {
  switch (action.type) {
    case Types.SET_USER_DATA:
      return {
        ...state,
        user_data: {
          ...state.user_data,
          ...action.payload.user_data
        }
      };
    case Types.SET_USER_SUBSCRIPTION:
      return {
        ...state,
        user_subscription: {
          ...state.user_subscription,
          ...action.payload.user_subscription
        }
      };
    case Types.SET_USER_USAGE:
      return {
        ...state,
        user_usage: {
          ...state.user_usage,
          ...action.payload.user_usage
        }
      };
    default:
      return state;
  }
};

export default customer;

// Action Creators
export const Creators = {
  setUserData: (user_data) => ({
    type: Types.SET_USER_DATA,
    payload: { user_data }
  }),
  setUserSubscription: (user_subscription) => ({
    type: Types.SET_USER_SUBSCRIPTION,
    payload: { user_subscription }
  }),
  setUserUsage: (user_usage) => ({
    type: Types.SET_USER_USAGE,
    payload: { user_usage }
  })
};
