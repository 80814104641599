import React from 'react';
import { Select as AntSelect } from 'antd';
import PropTypes from 'prop-types';
import { Container } from './styles';

const Select = (props) => {
  return (
    <Container>
      <AntSelect {...props} />
    </Container>
  );
};

Select.Option = AntSelect.Option

Select.defaultProps = {
  size: 'large',
};

Select.propTypes = {
  size: PropTypes.oneOf(['large']),
};

export default Select;
