import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Cover = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  background-image: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)), ${(props) => `url(${props.background})`};
  border-radius: 4px;
  background-position: center;
  background-size: cover;
  outline: none;
  display: flex;
  
  div {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin: auto;
  }

  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 48px;
    object-fit: contain;
  }

  button {
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0px 4px 4px rgba(135, 135, 135, 0);
    border-color: transparent;
    border-radius: 50px;
  }

  button:hover {
    background-color: rgba(0, 0, 0, 0.7);
    box-shadow: 0px 4px 4px rgba(135, 135, 135, 0);
    border-color: transparent;
  }

  button:focus {
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0px 4px 4px rgba(135, 135, 135, 0);
    border-color: transparent;
    border-radius: 30px;
  }
`;
