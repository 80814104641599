import React, { useState, useEffect, useRef } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Avatar,
  Typography,
  Popper,
  List,
  ListItem,
  Paper,
  ListItemIcon,
  ListItemText,
  ClickAwayListener
} from '@material-ui/core';
import {
  Apps as AppsIcon,
  CloudUploadOutlined as CloudUploadOutlinedIcon,
  Devices as DevicesIcon
} from '@material-ui/icons';

import { Menu, Dropdown, Icon, Tag } from 'antd';
import { usePlan } from '../../services/plan';
import { supportContactInfo, financesContactInfo } from '../../services/contact';
import { getSiteCrossAuthUrl } from '../../services/auth';

import { notifyError } from '../../utils/notificationService';

import {
  Container,
  FirstHeaderRow,
  ProfileName,
  MenuWrapper,
  SolutionsWrapper,
  MaterialContainer,
  ListItemHeader
} from './styles';

import logo from '../../assets/logo.png';
import logoSmall from '../../assets/logotipo-picsize-icon.png';

const SHOW_NEW_MENU = false;

const Header = (props) => {
  const userData = useSelector((state) => state.customer.user_data);
  const userSubscription = useSelector(
    (state) => state.customer.user_subscription
  );
  const [avatar, setAvatar] = useState(null);
  const [currentSolution, setCurrentSolution] = useState('gallery');
  const [solutionMenuOpened, setSolutionMenuOpened] = useState(false);
  const [siteLoading, setSiteLoading] = useState(false);
  const { checkSolution, triggerPlanChange, checkPlan } = usePlan();
  const [siteCrossAuthUrl, setSiteCrossAuthUrl] = useState("")
  const solutionsMenuElement = useRef();

  const isAccessToSolutionsBlocked =
    userSubscription &&
    (userSubscription.expired ||
      userSubscription.canceled ||
      userSubscription.isGalleryProFreeCustomerBlocked);

  const page = {
    gallery: 'Gallery Pro',
    site: 'Site'
  };

  const handleOpenSolutionMenu = () => {
    setSolutionMenuOpened(true);
  };

  const handleCloseSolutionMenu = (event) => {
    setSolutionMenuOpened(false);
  };

  const getCurrentMenu = () => {
    const { pathname } = props.location;

    if (pathname === '/settings/profile') return 'settings:1';
    if (pathname === '/settings/watermark') return 'settings:2';
    if (pathname === '/settings/subdomain') return 'settings:3';
    if (pathname === '/settings/notifications') return 'settings:4';

    const menu = pathname.split('/')[1];

    return menu;
  };

  const [currentMenu, setCurrentMenu] = useState(getCurrentMenu());

  const setupSiteCrossAuthUrl = async () => {
    setSiteLoading(true);

    try {
      const crossAuthUrl = await getSiteCrossAuthUrl()

      setSiteCrossAuthUrl(crossAuthUrl)
    } catch (error) {
      console.log(error)
    }

    setSiteLoading(false);
  }

  const handleOpenSite = () => {
    setSiteLoading(true);

    /**
     * We use this notation since when we need to avoid calling window.open
     * inside an async blocks, since sometimes Safari browser blocks the async call.
     */
    getSiteCrossAuthUrl()
      .then(url => {
        window.open(url, "_blank")
      })
      .catch(() => {
        notifyError("Algo deu errado.")
      })

    setSiteLoading(false);
  };

  const handleCurrentMenu = (menu) => {
    setCurrentMenu(menu.key);
  };

  const handleOpenAlbums = async (event) => {
    const isEnabled =
      checkSolution('proof_albums_limits', 'proof_album') || checkPlan('album');

    if (!isEnabled) {
      event.preventDefault();
      return await triggerPlanChange('feat', 'proof_album');
    }
  };

  const handleOpenPortfolio = async (event) => {
    const isEnabled = checkSolution('portfolio_limits', 'portfolio');

    if (!isEnabled) {
      event.preventDefault();
      return await triggerPlanChange('feat', 'portfolio');
    }
  };

  useEffect(() => {
    setAvatar(userData.avatar);
  }, [userData]);

  useEffect(() => {
    setupSiteCrossAuthUrl();
  }, []);

  const profileMenu = (
    <Menu>
      <Menu.Item key="0" disabled={isAccessToSolutionsBlocked}>
        <Link to="/account">
          <Icon style={{ marginRight: '10px' }} type="user" />
          Minha Conta
        </Link>
      </Menu.Item>
      <Menu.Item key="1" disabled={isAccessToSolutionsBlocked}>
        <Link to="/financial">
          <Icon style={{ marginRight: '10px' }} type="credit-card" />
          Financeiro
        </Link>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="3">
        <Link to="/logout">
          <Icon style={{ marginRight: '10px' }} type="logout" />
          Sair
        </Link>
      </Menu.Item>
    </Menu>
  );

  const proSolutionsMenu = (
    <Menu>
      <Menu.Item key="gallery">
        <Icon style={{ marginRight: '10px' }} type="picture" />
        Gallery Pro
      </Menu.Item>
      <Menu.Item key="site-pro">
        <a href={process.env.REACT_APP_SITE_PRO_URL}>
          <Icon style={{ marginRight: '10px' }} type="global" />
          Site Pro
        </a>
      </Menu.Item>
    </Menu>
  );

  const solutionsMenu = (
    <Menu>
      <Menu.Item key="gallery">
        <Icon style={{ marginRight: '10px' }} type="picture" />
        Gallery Pro
      </Menu.Item>
      <Menu.Item
        key="site"
        onClick={() => !siteCrossAuthUrl && handleOpenSite()}
        disabled={siteLoading}
      >
        {siteCrossAuthUrl ? (
          <a
            href={siteCrossAuthUrl}
            target="_blank"
          >
            <Icon
              style={{ marginRight: '10px' }}
              type={siteLoading ? 'loading' : 'global'}
            />
            Site
          </a>
        ) : (
          <>
            <Icon
              style={{ marginRight: '10px' }}
              type={siteLoading ? 'loading' : 'global'}
            />
            Site
          </>
        )}
      </Menu.Item>
    </Menu>
  );

  return (
    <Container>
      <FirstHeaderRow>
        <Link to="/gallery">
          {window.innerWidth < 800 ? (
            <img
              src={logoSmall}
              alt="Logomarca"
              style={{ height: '32px', width: 'auto' }}
            />
          ) : (
            <img src={logo} alt="Logomarca" />
          )}
        </Link>
        {SHOW_NEW_MENU ? (
          <>
            <Popper
              open={solutionMenuOpened}
              anchorEl={solutionsMenuElement.current}
              style={{ zIndex: 1500 }}
              placement="bottom"
              transition
            >
              <ClickAwayListener
                mouseEvent="onMouseDown"
                touchEvent="onTouchStart"
                onClickAway={handleCloseSolutionMenu}
              >
                <Paper>
                  <List disablePadding>
                    <ListItemHeader>
                      <Typography variant="h2">SOLUÇÕES</Typography>
                    </ListItemHeader>
                    <ListItem
                      component="a"
                      style={{ color: 'rgba(0, 0, 0, 0.87)' }}
                      href="https://sitepro.picsize.com.br"
                      target="_self"
                      button
                      divider
                    >
                      <ListItemIcon>
                        <DevicesIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary="Site Pro"
                        secondary="Site, portfólio, depoimentos, blog"
                      />
                    </ListItem>
                    <ListItem onClick={handleCloseSolutionMenu} button>
                      <ListItemIcon>
                        <CloudUploadOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary="Gallery Pro"
                        secondary="Entrega em alta, prova de fotos e álbum, venda de fotos"
                      />
                    </ListItem>
                  </List>
                </Paper>
              </ClickAwayListener>
            </Popper>
            <SolutionsWrapper>
              <MaterialContainer
                disableGutters
                innerRef={solutionsMenuElement}
                onClick={handleOpenSolutionMenu}
              >
                <Typography style={{ color: '#F0F0F0' }}>
                  Gallery Pro
                </Typography>

                <AppsIcon style={{ color: '#DADADA' }} />
              </MaterialContainer>
            </SolutionsWrapper>
          </>
        ) : (
          <>
            {checkPlan('site') ? (
              <SolutionsWrapper>
                <Dropdown overlay={solutionsMenu} trigger={['click']}>
                  <a className="ant-dropdown-link" href="#">
                    {page[currentSolution]} <Icon type="down" />
                  </a>
                </Dropdown>
              </SolutionsWrapper>
            ) : (
              <SolutionsWrapper>
                <Dropdown overlay={proSolutionsMenu} trigger={['click']}>
                  <a className="ant-dropdown-link" href="#">
                    {page[currentSolution]} <Icon type="down" />
                  </a>
                </Dropdown>
              </SolutionsWrapper>
            )}
          </>
        )}
        <span
          style={{
            color: "#e97a00",
            widht: "100%",
            flex: window.innerWidth < 800 ? undefined : 1,
            textAlign: "center",
            marginRight: 64,
            fontSize: window.innerWidth < 800 ? "12px" : undefined
          }}
        >
          <b>Novos números:</b> Financeiro → <a style={{ color: "#e97a00", textDecoration: "underline" }} href={financesContactInfo().whatsappLink} target="_blank">+55 {financesContactInfo().phoneNumber}</a> | Suporte Técnico → <a style={{ color: "#e97a00", textDecoration: "underline" }} href={supportContactInfo().whatsappLink} target="_blank">+55 {supportContactInfo().phoneNumber}</a>
        </span>
        <ProfileName>
          <Avatar
            src={avatar}
            style={{
              width: '26px',
              height: '26px',
              color: '#666',
              border: '1px solid #F3F3F3',
              boxShadow: '0 0 0 1px #5CC395'
            }}
          />
          <Dropdown overlay={profileMenu} trigger={['click']}>
            <a className="ant-dropdown-link" href="#">
              {userData.display_name
                .split(' ')
                .slice(0, 2)
                .join(' ')}{' '}
              <Icon type="down" />
            </a>
          </Dropdown>
        </ProfileName>
      </FirstHeaderRow>
      <MenuWrapper>
        <Menu
          onClick={handleCurrentMenu}
          selectedKeys={[currentMenu]}
          mode="horizontal"
        >
          <Menu.Item key="gallery" disabled={isAccessToSolutionsBlocked}>
            <Link to="/gallery">
              {' '}
              <Icon type="picture" /> Galerias{' '}
            </Link>
          </Menu.Item>
          <Menu.Item key="albuns" disabled={isAccessToSolutionsBlocked}>
            <Link to="/albuns" onClick={handleOpenAlbums}>
              {' '}
              <Icon type="book" /> Prova de Álbuns{' '}
            </Link>
          </Menu.Item>
          {userSubscription && userSubscription.can_use_portfolio === true && (
            <Menu.Item key="portfolio" disabled={isAccessToSolutionsBlocked}>
              <Link
                to="/portfolio"
                onClick={handleOpenPortfolio}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <Icon type="layout" />
                Portfólio
                <Tag
                  style={{
                    marginLeft: '10px',
                    marginRight: 0,
                    borderRadius: '25px',
                    height: '18px',
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: '9px',
                    fontWeight: 'bold'
                  }}
                  color="#ff4949"
                >
                  NOVO
                </Tag>
              </Link>
            </Menu.Item>
          )}
          <Menu.Item key="clients" disabled={isAccessToSolutionsBlocked}>
            <Link to="/clients">
              {' '}
              <Icon type="user" /> Clientes{' '}
            </Link>
          </Menu.Item>
          {/* <Menu.Item key="knowledge">
            <Link to="/"> <Icon type="read" /> Área do Conhecimento </Link>
          </Menu.Item> */}
          <Menu.SubMenu
            disabled={isAccessToSolutionsBlocked}
            title={
              <div style={{ userSelect: 'none' }}>
                {' '}
                <Icon type="setting" /> Configurações{' '}
              </div>
            }
            onTitleClick={() => {
              handleCurrentMenu({ key: 'settings:1' });
              props.history.push('/settings/profile');
            }}
          >
            <Menu.Item key="settings:1">
              <Link to="/settings/profile">Perfil</Link>
            </Menu.Item>
            <Menu.Item key="settings:2">
              <Link to="/settings/watermark">Marca d'água</Link>
            </Menu.Item>
            <Menu.Item key="settings:3">
              <Link to="/settings/subdomain">Domínio</Link>
            </Menu.Item>
            <Menu.Item key="settings:4">
              <Link to="/settings/notifications">Notificações</Link>
            </Menu.Item>
          </Menu.SubMenu>
        </Menu>
      </MenuWrapper>
    </Container>
  );
};

export default withRouter(Header);
