// Action Types
export const Types = {
  SELECT_IMAGE: 'portfolio/SELECT_IMAGE',
  SELECT_ALL: 'portfolio/SELECT_ALL',
  UNSELECT_ALL: 'portfolio/UNSELECT_ALL',
  SET_IMAGES: 'portfolio/SET_IMAGES',
  SET_INFO: 'portfolio/SET_INFO',
  SET_URL: 'portfolio/SET_URL',
  SET_WORK: 'portfolio/SET_WORK',
  SELECT_MENU: 'portfolio/SELECT_MENU',
  SELECT_SUBMENU: 'portfolio/SELECT_SUBMENU',
  SET_INSIDE_CATEGORIES: 'portfolio/SET_INSIDE_CATEGORIES',
  SET_OUTSIDE_CATEGORIES: 'portfolio/SET_OUTSIDE_CATEGORIES',
};


// Reducer
const initialState = {
  info: {
    category: null,
    work_id: null,
    title: '',
    total_works: 0,
    pictures_ordenation: [],
    page: 1,
    cover: '',
    uploadStatus: 'invisible',
  },
  work: {
    work_id: null,
    title: '',
    total_pictures: 0,
  },
  inside_categories: [],
  outside_categories: [],
  menu: '',
  submenu: '',
  images: [],
  url: '',
};

const portfolio = (state = initialState, action) => {
  switch (action.type) {
    case Types.SET_INSIDE_CATEGORIES:
      return {
        ...state,
        inside_categories: action.payload.categories,
      };
    case Types.SET_OUTSIDE_CATEGORIES:
      return {
        ...state,
        outside_categories: action.payload.categories,
      };
    case Types.SELECT_MENU:
      return {
        ...state,
        menu: action.payload.menu,
      };
    case Types.SELECT_SUBMENU:
      return {
        ...state,
        submenu: action.payload.submenu,
      };
    case Types.SET_INFO:
      return {
        ...state,
        info: {
          ...state.info,
          ...action.payload.info,
        },
      };
    case Types.SET_URL:
      return {
        ...state,
        url: action.payload.url.url,
      };
    case Types.SET_WORK:
      return {
        ...state,
        work: {
          ...state.work,
          ...action.payload.work,
        },
      };
    case Types.SELECT_IMAGE:
      return {
        ...state,
        images: state.images.map((image, idx) => {
          if (idx === action.payload.idx) {
            if ('isSelected' in image && image.isSelected) {
              return {
                ...image,
                isSelected: false,
              };
            }
            return {
              ...image,
              isSelected: true,
            };
          }
          return image;
        }),
      };
    case Types.SELECT_ALL:
      return {
        ...state,
        images: state.images.map((image) => (
          {
            ...image,
            isSelected: true,
          }
        )),
      };
    case Types.UNSELECT_ALL:
      return {
        ...state,
        images: state.images.map((image) => (
          {
            ...image,
            isSelected: false,
          }
        )),
      };
    case Types.SET_IMAGES:
      return {
        ...state,
        images: action.payload.images,
      };
    default:
      return state;
  }
};

export default portfolio;

// Action Creators
export const Creators = {
  setInsideCategories: (categories) => ({
    type: Types.SET_INSIDE_CATEGORIES,
    payload: { categories },
  }),
  setOutsideCategories: (categories) => ({
    type: Types.SET_OUTSIDE_CATEGORIES,
    payload: { categories },
  }),
  selectMenu: (menu) => ({
    type: Types.SELECT_MENU,
    payload: { menu },
  }),
  selectSubMenu: (submenu) => ({
    type: Types.SELECT_SUBMENU,
    payload: { submenu },
  }),
  setInfo: (info) => ({
    type: Types.SET_INFO,
    payload: { info },
  }),
  setUrl: (url) => ({
    type: Types.SET_URL,
    payload: { url },
  }),
  setWork: (work) => ({
    type: Types.SET_WORK,
    payload: { work },
  }),
  selectImage: (idx) => ({
    type: Types.SELECT_IMAGE,
    payload: { idx },
  }),
  selectAll: () => ({
    type: Types.SELECT_ALL,
  }),
  unselectAll: () => ({
    type: Types.UNSELECT_ALL,
  }),
  setImages: (images) => ({
    type: Types.SET_IMAGES,
    payload: { images },
  }),
};
