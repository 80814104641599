import styled from 'styled-components';
import { Radio } from 'antd';

import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  titleFaq: {
    color: '#555',
    fontSize: '32px',
    textAlign: 'center',
    fontWeight: 'lighter',
    lineHeight: '45px',
    marginBottom: 0
  }
}));

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  height: 100%;
`;

export const RadioGroup = styled(Radio.Group)`
  .ant-radio-button-wrapper {
    background-color: transparent;

    &:hover {
      color: #ff4949;
    }
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background-color: #ff4949;
    border-color: #ff4949;
    -webkit-box-shadow: -1px 0 0 0 #ff4949;
    box-shadow: -1px 0 0 0 #ff4949;

    &:hover {
      background-color: #ff5555;
      border-color: #ff5555;
    }
  }
`;

export const Title = styled.h1`
  color: #8f99a6;
  font-weight: lighter;
  font-size: 45px;
  text-align: center;
  line-height: 45px;

  margin-bottom: 0;
`;

export const SubTitle = styled.span`
  color: #b9b9b9;
  font-size: 16px;
`;

export const ExtraInfo = styled(SubTitle)`
  color: #828282;
  font-weight: bold;
  text-align: center;
`;
