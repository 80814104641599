import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Carousel, { Modal, ModalGateway, carouselComponents } from 'react-images';
import { Icon } from 'antd';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';

import { Creators as PortfolioActions } from '../../../../store/ducks/portfolio';

import Api from '../../../../services/api';

import { Portlet } from '../../../../styles/components';

import { notifySuccess, notifyError, notifyBackendError } from '../../../../utils/notificationService';

import Loading from '../../../../components/Loading';
import PopConfirm from '../../../../components/PopConfirm';
import PortfolioHeader from '../../../../components/Portfolio/PortfolioHeader';

import {
	Grid,
	ImageWrapper,
	Image,
	Footer,
	PhotosCounter,
	LeftIcons,
	RightIcons,
	IconsWrapper,
  ImageName,
} from './styles';

const Picture = (props) => {
	const { View } = carouselComponents;
	const { work_id } = props.match.params;

	const dispatch = useDispatch();
	const images = useSelector((state) => state.portfolio.images);
  const totalImages = useSelector((state) => state.portfolio.info.total_pictures);
	const portfolioInfo = useSelector((state) => state.portfolio.info);
  const [hoveringObj, setHoveringObj] = useState(null);
	const [loading, setLoading] = useState(false);
  const [showLightbox, setShowLightbox] = useState(false);
  const [indexLightbox, setIndexLightbox] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
	const [imagesPerPage] = useState(50);

  const [indexOfLastImage, setIndexOfLastImage] = useState(0);
  const [indexOfFirstImage, setIndexOfFirstImage] = useState(0);
	const [currentImages, setCurrentImages] = useState([]);

  useEffect(() => {
    setIndexOfLastImage(imagesPerPage);
    setIndexOfFirstImage(indexOfLastImage - imagesPerPage);
    setCurrentImages(images.slice(indexOfFirstImage, indexOfLastImage));
  }, [currentPage, imagesPerPage, indexOfLastImage, indexOfFirstImage, images]);

  const getImages = async (work_id, page) => {
		setLoading(true);

    try {
      const response = await Api.get(`/portfolio/pictures?work_id=${work_id}&page=${page}`);
      const { pictures, total_pictures } = response.data;

      dispatch(PortfolioActions.setInfo({ total_pictures, page }));
      dispatch(PortfolioActions.setImages(pictures));
    } catch (error) {
      const status = error && error.response && error.response.status;

      if (status === 404 && portfolioInfo.uploadStatus !== 'uploading-empty' && portfolioInfo.uploadStatus !== 'uploading') {
        dispatch(PortfolioActions.setInfo({ uploadStatus: 'work-empty' }));
        dispatch(PortfolioActions.setImages([]));
      }
    }

    setLoading(false);
	};

  const init = () => {
    dispatch(PortfolioActions.setImages([]));
    dispatch(PortfolioActions.setInfo({ total_pictures: 0 }));
  };

  const handleOnHove = (idx) => {
    setHoveringObj(idx);
  };

  const handleMouseLeave = () => {
    setHoveringObj(null);
  };

  const handleClickLightbox = (idx) => {
    setIndexLightbox(idx);
    setShowLightbox(true);
  };

  const toggleModal = () => {
    setShowLightbox(!showLightbox);
  };

  const handleSelectClick = (idx) => {
    dispatch(PortfolioActions.selectImage(idx));
  };

  const handleDeletePicture = async (pictureId) => {
    try {
      await Api.delete(`/portfolio/pictures?picture_ids=${pictureId}&work_id=${work_id}`);
      const newArr = images.filter((image) => image.id !== pictureId);
      const data = Object.values(newArr).map((image) => image.id);
      await Api.put(`/portfolio/pictures/reorder?work_id=${work_id}`, data);
      notifySuccess('A foto foi deletada com sucesso.');
    } catch (err) {
      notifyError('Não foi possível deletar a foto.')
    }

    await getImages(work_id, currentPage);
  }

  const handlePageChange = (page) => {
    setCurrentPage(page);
    getImages(work_id, page);
  };

  useEffect(() => {
    init();
    getImages(work_id, currentPage);
  }, []);

  const SortableList = SortableContainer(({ items }) => {
    if (!items.length) {
      return null;
    }

    return (
      <Grid onMouseLeave={handleMouseLeave}>
        {items.map((value, index) => (
          <SortableItem
            key={`item-${value.id.toString()}`}
            index={index}
            value={value}
            customProp={index}
            onMouseOver={() => handleOnHove(index)}
            onMouseLeave={handleMouseLeave}
          />
        ))}
      </Grid>
    );
  });

  const Handle = SortableHandle(() => (
    <span style={{ width: '100%', height: '100%', margin: 'auto', zIndex: '1' }} />
  ));

  const handleOpenConfirmDeletePictureModal = (picture_id) => {
    PopConfirm.open({
      title: "Tem certeza?",
      cancelText: "CANCELAR",
      confirmText: "DELETAR",
      confirmIcon: "delete",
      info: 'Deletar a foto é uma ação irreversível. Você perderá todos os dados desta foto.',
      onConfirm: () => handleDeletePicture(picture_id)
    })
  };

  const SortableItem = SortableElement(({ value, customProp }) => {
    const index = customProp;
    return (
      <ImageWrapper isSelected={value.isSelected}>
        <Handle />
        <div>
          <CustomDownloadName name={value.title}>
            <Image onClick={() => handleSelectClick(index)} src={value.thumb} />
          </CustomDownloadName>
          <IconsWrapper>
            <LeftIcons isSelected={value.isSelected}>
              <Icon
                onClick={() => handleSelectClick(index)}
                type="check-circle"
                theme="twoTone"
                twoToneColor="#87D068"
              />
            </LeftIcons>

            <RightIcons>
              <Icon onClick={() => handleClickLightbox(index)} type="zoom-in" />
              <Icon
                onClick={() => handleOpenConfirmDeletePictureModal(value.id)}
                type="delete"
                theme="filled"
              />
            </RightIcons>
          </IconsWrapper>
          <ImageName>{value.title}</ImageName>
        </div>
      </ImageWrapper>
    );
  });

  const handleSortEnd = ({ oldIndex, newIndex }) => {
    const newArr = arrayMove(images, oldIndex, newIndex);
    dispatch(PortfolioActions.setImages(newArr));
    const data = Object.values(newArr).map((image) => image.id);
    Api.put(`/portfolio/pictures/reorder?work_id=${work_id}`, data);
  };

  // Changes picture name when trying to download it with right mouse button click
  const CustomDownloadName = ({ children, name }) => (
    <a href="" download={name} onClick={(event) => event.preventDefault()}>
      {children}
    </a>
	);

  const CustomView = (props) => {
    const { currentView } = props;

    return (
      <CustomDownloadName name={currentView.title}>
        <View {...props} />
      </CustomDownloadName>
    )
  };

	if (portfolioInfo.uploadStatus !== 'work-empty' && portfolioInfo.uploadStatus !== 'uploading-empty') {
		return (
      <Loading loading={loading}>
        <Portlet style={{ marginBottom: '-20px', padding: '8px' }}>
          <PortfolioHeader
            work_id={work_id}
            uploadStatus={portfolioInfo.uploadStatus}
          />
        </Portlet>

        <br />

        <Portlet>
          <ModalGateway>
            {showLightbox ? (
              <Modal onClose={toggleModal}>
                <Carousel
                  currentIndex={indexLightbox}
                  views={images}
                  components={{ View: CustomView }}
                />
              </Modal>
            ) : null}
          </ModalGateway>
          {images ? <SortableList items={images} onSortEnd={handleSortEnd} axis="xy" useDragHandle /> : null}
          <Footer>
            <PhotosCounter>Total de {totalImages} fotos</PhotosCounter>
          </Footer>
        </Portlet>
      </Loading>
    );
	} else {
		return null;
	}
};

export default Picture;
